/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sporten_Images/Softbal1.jpeg";
import Image2 from "assets/images/Sporten_Images/Softbal2.jpg";
import Image3 from "assets/images/Sporten_Images/Softbal3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";



// Images
import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Softbalbeurs USA: Hoe je kunt Studeren en Softballen in Amerika', isSubheader: false },

    { id: 'Hoofdstuk 1', title: '1. Geschiedenis en Ontwikkeling van Softbal in Amerika', isSubheader: false },
    { id: '1.1', title: 'Vroege Jaren en de Rol van Scholen en Universiteiten', isSubheader: true },
    { id: '1.2', title: '1.2 Groei en Professionalisering', isSubheader: true },

    { id: 'Hoofdstuk 2', title: '2. Universitair Softbal Vandaag de Dag', isSubheader: false },
    { id: '2.1', title: '2.1 Belangrijke Competities en Conferenties', isSubheader: true },
    { id: '2.2', title: '2.2 Topprogramma’s en Prestigieuze Teams', isSubheader: true },

    { id: 'Hoofdstuk 3', title: '3: Van College naar de Profs', isSubheader: false },
    { id: '3.1', title: '3.1 Het Pad naar Professionalisme', isSubheader: true },
    { id: '3.2', title: '3.2 Professionele Softbal en Andere Carrièremogelijkheden', isSubheader: true },


    { id: 'Hoofdstuk 4', title: '4. Internationale Invloeden en Evenementen', isSubheader: false },
    { id: '4.1', title: '4.1 Buitenlandse Invloeden', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikanen op het Wereldtoneel', isSubheader: true },

    { id: 'Hoofdstuk 5', title: '5. Softbalbeurzen en de Toekomst', isSubheader: false },
    { id: '5.1', title: '5.1 Het Belang van Beurzen', isSubheader: true },
    { id: '5.2', title: '5.2 Hoe Sportbeurs Amerika NL Kan Helpen', isSubheader: true },

  ];

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };
  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Inhoud
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Verberg</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Toon</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
            <MKBox id="intro" mb={3}>
            <MKTypography variant="h1" className="h1-as-h3" mb={2}>
              Softbalbeurs USA: Hoe je kunt Studeren en Softballen in Amerika

              </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
  <img
    src={Image1}
    alt="Luchtfoto van een volgepakt stadion bij een college softbalwedstrijd."
    style={{
      maxWidth: "100%",
      border: "2px solid #fff",
      boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
      cursor: "pointer",
      outline: "none",
    }}
    onClick={() => openModal1(Image1)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
  Een vol stadion tijdens een wedstrijd
</figcaption>
</figure>
              Softbal is een sport die ontstaan is in de Verenigde Staten in de late 19e eeuw. Het is een variant van honkbal, maar met een grotere bal, een kleiner veld en kortere innings. Softbal wordt gespeeld door twee teams van negen spelers, die om beurten slaan en verdedigen. Het doel is om meer punten te scoren dan het andere team door over de vier honken te lopen na het slaan van de bal.
<br/>
<br/>
Softbal is een sport die al snel populair werd in de VS, vooral onder scholen en universiteiten. Het werd gezien als een sport die fysieke fitheid, mentale scherpte en sociale interactie bevorderde. Het werd ook een sport die veel talent en competitie aantrok, vooral op collegiaal niveau. Collegiaal softbal is het hoogste niveau van softbal in de VS, waar student-atleten spelen voor hun universiteit of college. Collegiaal softbal is een sport die veel prestige, erkenning en beurzen biedt voor de beste spelers.
<br/>
<br/>
Sportbeursamerika.nl is een organisatie die zich toelegt op het helpen van jonge atleten om sportbeurzen voor softbal te verkrijgen. Sportbeursamerika.nl heeft een team van experts die je kunnen begeleiden bij het vinden van de beste universiteit of college voor jou, op basis van je academische en sportieve profiel. Sportbeursamerika.nl kan je ook helpen bij het voorbereiden van je aanvraag, het maken van je video, het contact leggen met coaches en het regelen van je visum. Sportbeursamerika.nl heeft al veel succesverhalen geholpen om hun droom te verwezenlijken: studeren en softballen in Amerika.
<br/>
<br/>
Wil je meer weten over softbal in Amerika en hoe je een softbalbeurs kunt krijgen? Lees dan verder!
              </MKTypography>
              {/* Add bulleted list or other content as needed */}
            </MKBox>
             {/* Main chapters and subchapters with placeholder text */}
            <MKBox id="Hoofdstuk 1" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 1: Geschiedenis en Ontwikkeling van Softbal in Amerika
              </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              Softbal is een sport die zijn oorsprong vindt in de Verenigde Staten, maar die zich over de hele wereld heeft verspreid. Het is een sport die veel veranderingen en innovaties heeft ondergaan, en die veel invloedrijke figuren en gebeurtenissen heeft gekend. In dit hoofdstuk zullen we je een overzicht geven van de geschiedenis en de ontwikkeling van softbal in Amerika, van de vroege jaren tot het heden.              </MKTypography>

              <MKBox id="1.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                1.1 Vroege Jaren en de Rol van Scholen en Universiteiten
                </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Softbal werd voor het eerst gespeeld in 1887 in Chicago, als een indoorvariant van honkbal. Het werd bedacht door een groep mannen die een Thanksgiving-feest vierden in een gymzaal. Ze gebruikten een bokshandschoen als bal en een bezemsteel als knuppel. Het spel werd al snel populair onder verschillende groepen mensen, zoals brandweerlieden, fabrieksarbeiders en kerkleden.
<br/>
<br/>
In de vroege jaren van softbal waren er geen gestandaardiseerde regels of afmetingen. Het spel werd aangepast aan de beschikbare ruimte en het aantal spelers. Er waren verschillende varianten van softbal, zoals mushball, kittenball, diamond ball en indoor baseball. De term softbal werd pas in 1926 officieel gebruikt.
<br/>
<br/>
Een belangrijke rol in de ontwikkeling van softbal werd gespeeld door scholen en universiteiten. Zij zagen softbal als een sport die geschikt was voor zowel jongens als meisjes, en die fysieke opvoeding en recreatie bevorderde. Zij organiseerden ook de eerste school- en universiteitsteams, en de eerste georganiseerde competities. Een van de eerste schoolcompetities was de Public Schools Athletic League in New York City, die in 1913 begon met softbal voor meisjes. Een van de eerste universiteitscompetities was de National Collegiate Athletic Association (NCAA), die in 1957 begon met softbal voor vrouwen.
                </MKTypography>
              </MKBox>
              <MKBox id="1.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                1.2 Groei en Professionalisering
                </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Softbal groeide uit tot een populaire en prestigieuze sport in Amerika, vooral onder vrouwen. Dit kwam mede door de invoering van Title IX in 1972, een wet die gelijke kansen voor vrouwen in het onderwijs garandeerde, inclusief sportbeurzen. Hierdoor kregen meer vrouwelijke student-atleten de kans om softbal te spelen op universitair niveau.
<br/>
<br/>
Naast het universitaire softbal ontstonden er ook professionele softbalcompetities in Amerika. De eerste professionele softballeague voor vrouwen was de International Women’s Professional Softball Association (IWPSA), die werd opgericht in 1976. Deze league bestond uit tien teams uit verschillende steden, zoals New York, Chicago, en Los Angeles. De league duurde vier seizoenen, tot 1980.
<br/>
<br/>
De tweede professionele softballeague voor vrouwen was de Women’s Pro Softball League (WPSL), die werd opgericht in 1997. Deze league bestond uit zes teams uit verschillende staten, zoals Florida, Georgia, en Virginia. De league veranderde later zijn naam naar National Pro Fastpitch (NPF). De NPF was actief tot 2021 en onderging twee geannuleerde seizoenen vanwege COVID-19. Een nieuwe softballeague, de Women's Professional Fastpitch (WPF), werd gelanceerd in 2022
<br/>
<br/>
De professionele softbalcompetities hebben veel invloed gehad op het universitaire softbal. Veel professionele spelers zijn voormalige student-atleten die hun carrière hebben voortgezet na hun afstuderen. Sommige professionele spelers zijn ook coaches of instructeurs geworden voor universitaire teams of individuele spelers. Daarnaast hebben veel professionele spelers zich onderscheiden als rolmodellen en inspiratiebronnen voor jonge softbalspelers.
<br/>
<br/>
Enkele voorbeelden van belangrijke figuren in de softbalwereld zijn:

                </MKTypography>
                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
Jennie Finch: een voormalige pitcher voor de Arizona Wildcats en de USA national team. Ze won twee Olympische medailles, een gouden in 2004 en een zilveren in 2008. Ze speelde ook voor de Chicago Bandits in de NPF. Ze wordt beschouwd als een van de beste pitchers aller tijden, en als een icoon voor de sport.
</li>
<li>
Cat Osterman: een voormalige pitcher voor de Texas Longhorns en de USA national team. Ze won ook twee Olympische medailles, een gouden in 2004 en een zilveren in 2008. Ze speelde ook voor de Rockford Thunder en de Houston Scrap Yard Dawgs in de NPF. Ze werd later coach van de Texas State Bobcats.

</li>
<li>
Monica Abbott: een voormalige pitcher voor de Tennessee Volunteers en de USA national team. Ze won een zilveren Olympische medaille in 2008. Ze speelde latere voor de Scrap Yard Fast Pitch in de NPF, en voor de Toyota Red Terriers in Japan. Ze is de eerste vrouwelijke softbalspeler die een miljoenencontract heeft getekend.

</li>
</ul>
</MKTypography>

              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 2" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 2: Universitair Softbal Vandaag de Dag
                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              Softbal is een van de meest populaire en competitieve sporten op universitair niveau in Amerika. Elk jaar nemen meer dan duizend teams deel aan het universitaire softbalseizoen, dat loopt van februari tot juni. Het hoogtepunt van het seizoen is de Women’s College World Series (WCWS), het nationale kampioenschap voor universitair softbal.
              </MKTypography>

              <MKBox id="2.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                2.1 Belangrijke Competities en Conferenties
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
  <img
    src={Image2}
    alt="Speelsters van Tennessee en Alabama in actie tijdens de College World Series."
    style={{
      maxWidth: "100%",
      border: "2px solid #fff",
      boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
      cursor: "pointer",
      outline: "none",
    }}
    onClick={() => openModal2(Image2)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
  Speelsters in actie tijdens de College World Series
</figcaption>
</figure>
                Het universitaire softbal wordt gereguleerd door de National Collegiate Athletic Association (NCAA), een organisatie die verantwoordelijk is voor het organiseren en beheren van sportprogramma’s voor universiteiten en hogescholen in Amerika. De NCAA verdeelt de universiteiten in drie divisies, gebaseerd op hun grootte, budget, en sportieve prestaties. Deze divisies zijn:
                </MKTypography>
                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
Division I: de hoogste en meest prestigieuze divisie, met meer dan 300 universiteiten en meer dan 10.000 student-atleten. De Division I heeft de meeste beurzen, de beste faciliteiten, en de sterkste competitie. De Division I bestaat uit 32 conferenties, die regionale groepen van universiteiten zijn die onderling wedstrijden spelen. Enkele voorbeelden van conferenties zijn de Southeastern Conference (SEC), de Pac-12 Conference, en de Big Ten Conference.

</li>
<li>
Division II: de middelste divisie, met meer dan 300 universiteiten en meer dan 8.000 student-atleten. De Division II heeft minder beurzen, maar nog steeds goede faciliteiten en een sterke competitie. De Division II bestaat uit 23 conferenties, zoals de Great Lakes Valley Conference (GLVC), de Sunshine State Conference, en de California Collegiate Athletic Association (CCAA).
</li>
<li>
Division III: de laagste divisie, met meer dan 400 universiteiten en meer dan 6.000 student-atleten. De Division III heeft geen beurzen, maar wel goede academische programma’s en een sportieve ervaring. De Division III bestaat uit 43 conferenties, zoals de New England Small College Athletic Conference (NESCAC), de Centennial Conference, en de University Athletic Association (UAA).

</li>
</ul>
</MKTypography>
<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
Elke divisie heeft zijn eigen kampioenschap aan het einde van het seizoen, waarbij de beste teams uit elke conferentie zich kwalificeren voor een toernooi met een knock-out systeem. Het kampioenschap van Division I heet de Women’s College World Series (WCWS), dat wordt gehouden in Oklahoma City. Het kampioenschap van Division II heet de NCAA Division II Softball Championship, dat wordt gehouden in Denver. Het kampioenschap van Division III heet de NCAA Division III Softball Championship, dat wordt gehouden in Salem.
                </MKTypography>

              </MKBox>
              <MKBox id="2.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                2.2 Topprogramma’s en Prestigieuze Teams
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Het universitaire softbal heeft veel topprogramma’s en prestigieuze teams voortgebracht, die zich hebben onderscheiden door hun succesvolle geschiedenis, hun getalenteerde spelers, en hun invloedrijke coaches. Sommige van deze programma’s en teams zijn:
                </MKTypography>
                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
UCLA Bruins: Dit programma wordt beschouwd als een van de meest succesvolle in de geschiedenis van universitair softbal, met een indrukwekkend aantal nationale titels op hun naam. Gedurende de jaren hebben ze Olympische medaillewinnaars voortgebracht, waaronder bekende namen zoals Lisa Fernandez, Natasha Watley en Stacey Nuveman.


</li>
<li>
Oklahoma Sooners: De Oklahoma Sooners zijn een opvallend sterk team en hebben regelmatig topprestaties geleverd. Ze hebben meerdere nationale titels gewonnen en hebben in het verleden indrukwekkende reeksen overwinningen neergezet.

</li>
<li>
Arizona Wildcats: Het softbalprogramma van de Arizona Wildcats heeft een indrukwekkende erelijst met nationale titels. Ze hebben ook een gerespecteerde coach, Mike Candrea, die een uitstekende staat van dienst heeft met talloze overwinningen en zelfs Olympisch goud als coach van het Amerikaanse nationale softbalteam.

</li>
</ul>
</MKTypography>
<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
Deze programma’s en teams dragen bij aan de ontwikkeling van softbalspelers door hen een hoog niveau van training, coaching, en competitie te bieden. Ze helpen hen ook om hun vaardigheden, kennis, en mentaliteit te verbeteren, zodat ze klaar zijn voor de volgende stap in hun carrière.
                </MKTypography>


              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 3" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 3: Van College naar de Profs
                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              Softbal is een sport die veel mogelijkheden biedt voor student-atleten om hun carrière voort te zetten na hun universitaire periode. Sommige student-atleten kiezen ervoor om professioneel softbal te spelen, terwijl anderen andere carrièremogelijkheden verkennen. In dit hoofdstuk bespreken we het pad naar het professionalisme, de professionele softbalcompetities en andere carrièremogelijkheden voor softbalspelers.
              </MKTypography>

              <MKBox id="3.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                3.1 Het Pad naar Professionalisme
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Het spelen van universitair softbal is een goede voorbereiding voor het spelen van professioneel softbal. Veel student-atleten die uitblinken in het universitaire softbal worden gescout of gedraft door professionele teams. Dit betekent dat ze een contract krijgen aangeboden om voor een bepaald team te spelen, meestal in ruil voor een salaris en andere voordelen.
<br/>
<br/>
Het spelen van professioneel softbal is echter niet gemakkelijk. De competitie is zeer hoog, en de spelers moeten zich voortdurend aanpassen aan nieuwe situaties, tegenstanders, en uitdagingen. Bovendien is het salaris van professionele softbalspelers niet erg hoog, vergeleken met andere sporten. Volgens een rapport uit 2016 bedroeg het gemiddelde salaris van een NPF-speler ongeveer $5.000 tot $6.000 per seizoen. Dit betekent dat veel professionele spelers een tweede baan of een andere bron van inkomsten moeten hebben om rond te komen.
<br/>
<br/>
Daarom kiezen sommige student-atleten ervoor om niet professioneel softbal te spelen, maar om hun studie af te maken of een andere carrière na te streven. Dit kan ook een goede keuze zijn, omdat het universitaire softbal veel vaardigheden en kwaliteiten ontwikkelt die nuttig zijn in andere domeinen, zoals leiderschap, teamwork, communicatie, discipline, en probleemoplossing.
                </MKTypography>
              </MKBox>
              <MKBox id="3.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                3.2 Professionele Softbal en Andere Carrièremogelijkheden
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Zoals eerder vermeld, is de belangrijkste professionele softbalcompetitie in Amerika de National Pro Fastpitch (NPF). De NPF bestaat momenteel uit vijf teams: de Aussie Peppers of Minnesota, de Canadian Wild of Southern Illinois, de Chicago Bandits, the Cleveland Comets, and the USSSA Pride. De NPF heeft ook een samenwerkingsverband met de Chinese Softball Association (CSA), die twee teams afvaardigt naar de NPF: the Beijing Eagles and the Shanghai Shengli.
<br/>
<br/>
De NPF is echter niet de enige professionele softbalcompetitie in de wereld. Er zijn ook andere competities in landen zoals Japan, Australië, Italië, en Mexico. Sommige Amerikaanse spelers kiezen ervoor om in deze competities te spelen, omdat ze beter betaald worden of omdat ze een andere cultuur willen ervaren.
<br/>
<br/>
Naast het spelen van professioneel softbal zijn er ook andere carrièremogelijkheden voor softbalspelers. Sommige voorbeelden zijn:
                </MKTypography>
                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
Coaching: sommige spelers worden coaches voor school-, universiteits-, of professionele teams. Ze gebruiken hun kennis en ervaring om andere spelers te trainen, te motiveren, en te adviseren.

</li>
<li>
Onderwijs: sommige spelers worden leraren of professoren op verschillende niveaus en vakgebieden. Ze gebruiken hun academische achtergrond en hun pedagogische vaardigheden om studenten te onderwijzen en te begeleiden.

</li>
<li>
Media: sommige spelers worden journalisten, commentatoren, of analisten voor verschillende media-outlets. Ze gebruiken hun communicatieve vaardigheden en hun inzicht in de sport om verslag te doen van softbalnieuws en -evenementen.

</li>
<li>
Ondernemen: sommige spelers worden ondernemers of managers in verschillende sectoren en industrieën. Ze gebruiken hun creatieve en zakelijke vaardigheden om producten of diensten te ontwikkelen en te verkopen.

</li>
</ul>
</MKTypography>
<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
Zoals je kunt zien, zijn er veel mogelijkheden voor softbalspelers om hun passie en talent te gebruiken in verschillende domeinen. Het belangrijkste is om je doelen te stellen, je opties te verkennen, en je dromen na te jagen.
                </MKTypography>


              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 4" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 4: Internationale Invloeden en Evenementen
                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              Softbal is niet alleen een Amerikaanse sport, maar ook een internationale sport. Softbal wordt gespeeld in meer dan 140 landen over de hele wereld, en heeft veel invloed gehad op de sportcultuur en -ontwikkeling in verschillende regio’s. In dit hoofdstuk bespreken we de buitenlandse invloeden op het softbal in Amerika, en de Amerikaanse invloeden op het softbal op het wereldtoneel.
              </MKTypography>

              <MKBox id="4.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                4.1 Buitenlandse Invloeden
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
  <img
    src={Image3}
    alt="Oklahoma University softbalteam viert het winnen van de NCAA titel."
    style={{
      maxWidth: "100%",
      border: "2px solid #fff",
      boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
      cursor: "pointer",
      outline: "none",
    }}
    onClick={() => openModal3(Image3)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
  Speelsters van Oklahoma vieren het nationaal kampioenschap
</figcaption>
</figure>
                Het softbal in Amerika heeft veel te danken aan de buitenlandse invloeden die de sport hebben verrijkt en uitgedaagd. Sommige van deze invloeden zijn:
                </MKTypography>
                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
Japan: Japan is een van de sterkste landen in het softbal, zowel op professioneel als op universitair niveau. Japan heeft een lange traditie van softbal, die teruggaat tot de jaren 1930. Japan heeft ook een eigen professionele softbalcompetitie, de Japan Softball League (JSL), die bestaat uit 12 teams. Japan heeft veel invloed gehad op het softbal in Amerika, door het introduceren van nieuwe technieken, tactieken, en stijlen van spelen. Japan heeft ook veel uitwisselingsprogramma’s en samenwerkingsverbanden met Amerikaanse universiteiten en teams, waardoor veel spelers de kans krijgen om te leren van elkaar.

</li>
<li>
Australië: Australië is een ander land dat een grote rol speelt in het softbal, vooral op internationaal niveau. Australië heeft een eigen nationale softbalteam, de Aussie Spirit, die meerdere medailles heeft gewonnen op de Olympische Spelen, de Wereldkampioenschappen, en de World Cup of Softball. Australië heeft ook veel spelers die in de NPF spelen, zoals Stacey Porter, Kaia Parnaby, en Taylah Tsitsikronis. Australië heeft veel invloed gehad op het softbal in Amerika, door het tonen van een hoge mate van atletisch vermogen, doorzettingsvermogen, en teamgeest.
</li>
<li>
Canada: Canada is een buurland van Amerika dat ook een sterke softbalcultuur heeft. Canada heeft een eigen nationale softbalteam, de Canadian Wild, die ook deelneemt aan de NPF. Canada heeft ook veel spelers die in Amerikaanse universiteiten spelen of hebben gespeeld, zoals Danielle Lawrie, Jenn Salling, en Sara Groenewegen. Canada heeft veel invloed gehad op het softbal in Amerika, door het delen van een gemeenschappelijke passie, visie, en waardering voor de sport.

</li>
</ul>
</MKTypography>
<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
Deze buitenlandse invloeden hebben bijgedragen aan de ontwikkeling van het softbal in Amerika, door het creëren van meer diversiteit, competitie, en samenwerking. Ze hebben ook bijgedragen aan de ontwikkeling van universitaire spelers, door hen bloot te stellen aan verschillende culturen, perspectieven, en ervaringen.
                </MKTypography>

              </MKBox>
              <MKBox id="4.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                4.2 Amerikanen op het Wereldtoneel                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Het softbal in Amerika heeft ook veel invloed gehad op het softbal op het wereldtoneel. Amerika is al jarenlang een toonaangevend land in het softbal, zowel op professioneel als op universitair niveau. Amerika heeft veel succesverhalen en inspiratiebronnen voortgebracht voor andere landen en spelers. 
<br/>
<br/>
Deze succesverhalen hebben veel invloed gehad op het softbal op het wereldtoneel, door het tonen van een hoog niveau van prestatie, excellentie, en leiderschap. Ze hebben ook veel respect en bewondering gekregen van andere landen en spelers, die hen zien als voorbeelden en uitdagers.
<br/>
<br/>
De rol van universitair softbal in de voorbereiding op internationale competities is cruciaal. Universitair softbal biedt de spelers een solide basis van vaardigheden, kennis, en ervaring, die ze kunnen gebruiken om zich te meten met de beste spelers ter wereld. Universitair softbal helpt ook om het talent en het potentieel van de spelers te ontdekken, te ontwikkelen, en te promoten, zodat ze kunnen uitblinken op het hoogste niveau.

                </MKTypography>
              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 5" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
                Hoofdstuk 5
                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              Ben je een jonge softbalspeler met grote dromen? Wil je je talent naar een hoger niveau tillen en de kans krijgen om in de Verenigde Staten te studeren en softballen? Dan is dit hoofdstuk speciaal voor jou! We gaan het hebben over het belang van softbalbeurzen en hoe ze jouw carrière in de sport kunnen veranderen.

</MKTypography>

              <MKBox id="5.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                5.1 Het Belang van Beurzen</MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Softbal is een prachtige sport, maar het kan soms moeilijk zijn om de kosten van je opleiding te dekken, vooral als je droomt van spelen op universitair niveau in de VS. Hier komen softbalbeurzen om de hoek kijken. Deze beurzen bieden financiële ondersteuning aan getalenteerde spelers en maken het mogelijk om je passie voor softbal te combineren met een waardevolle opleiding. Ze zijn niet alleen een kans om te softballen, maar ook om te studeren aan gerenommeerde universiteiten.
                <br/>
<br/>
Maar waarom zijn softbalbeurzen zo belangrijk? Ze helpen niet alleen individuele spelers, maar dragen ook bij aan de groei en ontwikkeling van de sport als geheel. Door getalenteerde spelers de kans te geven om te studeren en te softballen, wordt de concurrentie versterkt en blijft het niveau van het spel stijgen.
<br/>
<br/>
De toekomst van softbalbeurzen lijkt rooskleurig, zelfs in een competitief sportklimaat. Er zijn tal van kansen beschikbaar voor spelers van alle niveaus, van beginners tot ervaren atleten. Met de groeiende populariteit van softbal in de Verenigde Staten en de toenemende erkenning van de sport, kunnen we verwachten dat er nog meer kansen zullen ontstaan in de komende jaren.                </MKTypography>
              </MKBox>
              <MKBox id="5.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                5.2 Hoe Sportbeurs Amerika NL Kan Helpen 
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
  <img
    src={Image4}
    alt="Logo van Sportbeurs Amerika NL, bestaande uit een voetbal met het continent Noord-Amerika erop afgebeeld, met daarboven een afstudeerhoed (graduation cap) die bovenop de bal rust alsof het een hoofd is. De naam van het bedrijf is prominent weergegeven, wat de focus op sportbeurzen in Amerika voor Europese studenten benadrukt."
    style={{
      maxWidth: "100%",
      height: "auto",
      border: "2px solid #fff",
      boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)", // Apply shadow around the entire image
      cursor: "pointer",
      outline: "none",
      borderRadius: "50%", // Set border-radius to 50% for a circular image
      marginTop: "0px", // Add top margin to move the image up


    }}
    onClick={() => openModal4(Image4)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
  
</figcaption>
</figure>
Sportbeurs Amerika NL is een organisatie gespecialiseerd in het begeleiden van jonge sporttalenten naar sportbeurzen in Amerika.  Met een gepassioneerde en ervaren expert aan het roer, ondersteunt Sportbeurs Amerika NL de student-atleet bij iedere stap in het traject, vanaf de eerste oriëntatie tot aan de uiteindelijke plaatsing. Sportbeurs Amerika NL biedt onder andere de volgende diensten aan:
                </MKTypography>

                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
Een persoonlijke intakegesprek om de wensen, mogelijkheden en verwachtingen van de student-atleet te bespreken.
</li>
<li>
Een professionele evaluatie van het sportieve en academische niveau van de student-atleet.
</li>
<li>
Een uitgebreid netwerk van contacten met Amerikaanse coaches, universiteiten en colleges.
</li>
<li>
Een effectieve promotie van het profiel en de video’s van de student-atleet bij potentiële coaches.
</li>
<li>
Een deskundig advies over de beste opties voor de student-atleet op basis van zijn of haar voorkeuren, doelen en budget.
</li>
<li>
Een onderhandeling over de beste beursaanbiedingen namens de student-atleet.
</li>
<li>
Een hulp bij het aanvragen van de benodigde documenten, zoals het visum, het transcript, het diploma en de toelatingstesten.
</li>
<li>
Een voorbereiding op het leven in Amerika, zoals de cultuur, de taal, de regels en de tips.
</li>
<li>
Een nazorg en een follow-up tijdens het verblijf in Amerika.</li>

</ul>
</MKTypography>

<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Wil jij ook je passie voor American football combineren met een goede opleiding in Amerika? <Link to="/Diensten-Producten">Bekijk het volledige aanbod</Link> van Sportbeurs Amerika NL en maak een vrijblijvende afspraak om je te laten informeren over de mogelijkheden. 
      Ik sta voor je klaar om je te helpen bij het realiseren van je Amerikaanse droom.
    </MKTypography>


              </MKBox>
            </MKBox>

          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;
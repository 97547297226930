/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import { Helmet } from 'react-helmet';

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Author page sections
import Profile from "pages/Over/Overmij/Jeugd/sections/Profile";
import Breadcrumbs from "examples/Breadcrumbs";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import DetailedFooter from "examples/Footers/DetailedFooter";

// Images
import bgImage from "assets/images/Achtergronden/Jeugd_BG.png";

function Jeugd() {
  return (
    <>
    <Helmet>
    <title>Deel 1: Mijn Jeugd in Nederland - De Start van Mijn Reis</title>
    <meta name="description" content="Ontdek mijn vroege jaren in Nederland en hoe deze mijn pad naar sport en onderwijs in Amerika hebben gevormd. Een persoonlijk verhaal van ambitie en dromen." />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta charset="UTF-8" />
    <meta name="robots" content="index, follow" />
    
    {/* Open Graph Tags */}
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://sportbeursamerika.nl//Over-Mij/Jeugd-Voetbal" />
    <meta property="og:title" content="Deel 1: Mijn Jeugd in Nederland - De Start van Mijn Reis" />
    <meta property="og:description" content="Een blik op mijn jeugd in Nederland, de vormende jaren die de basis legden voor mijn latere avonturen in de VS." />
    <meta property="og:image" content="https://sportbeursamerika.nl/path/to/my-youth-in-netherlands-image.jpg" />

    {/* Favicon */}
    <link rel="icon" href="https://sportbeursamerika.nl/32x32Sportbeurs.png" />
</Helmet>

     <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://calendly.com/sportbeurs-amerika/intake",
          label: "MAAK AFSPRAAK",
          color: "info",
        }}
        transparent
        light
         
      />
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.8),
                rgba(gradients.dark.state, 0.8)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center 60%",
            display: "grid",
            placeItems: "center",
          }}
        />
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
                <MKBox
  width={{ xs: "80%", md: "50%", lg: "25%" }}
  mb={1}
  sx={{
    backgroundColor: "transparent", // Transparent background color
  }}
>
  <Breadcrumbs
    routes={[
      { label: "Home", route: "/" },
      { label: "Over Mij", route: "/Over-Mij" },
      { label: "Mijn Jeugd in Nederland" },
    ]}
  />
</MKBox>  
          <Profile />
        </Card>
      
        <MKBox pt={6} px={1} mt={6}>
        <DetailedFooter content={footerRoutes} />
      </MKBox>    </>
  );
}

export default Jeugd;

/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
import Image1 from "assets/images/Sporten_Images/Tennis1.jpg";
import Image2 from "assets/images/Sporten_Images/Tennis2.jpeg";
import Image3 from "assets/images/Sporten_Images/Tennis3.jpg";
import Image4 from "assets/images/Logos/Sportbeurs.png";



// Images
import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Tennisbeurs USA: Hoe je kunt studeren en tennissen in Amerika', isSubheader: false },

    { id: 'Hoofdstuk 1', title: '1. De Geschiedenis en Ontwikkeling van Tennis in Amerika', isSubheader: false },
    { id: '1.1', title: '1.1 Vroege Jaren en de Invloed van Universiteiten', isSubheader: true },
    { id: '1.2', title: '1.2 Groei en Professionalisering', isSubheader: true },

    { id: 'Hoofdstuk 2', title: '2. Universitair Tennis Vandaag de Dag', isSubheader: false },
    { id: '2.1', title: '2.1 Belangrijke Competities en Conferenties', isSubheader: true },
    { id: '2.2', title: '2.2 Topprogramma’s en Prestigieuze Teams', isSubheader: true },

    { id: 'Hoofdstuk 3', title: '3: Van College naar de Profs', isSubheader: false },
    { id: '3.1', title: '3.1 Het Pad naar Professionalisme', isSubheader: true },
    { id: '3.2', title: '3.2 Professionele Tenniscircuits', isSubheader: true },


    { id: 'Hoofdstuk 4', title: '4. Internationale Invloeden', isSubheader: false },
    { id: '4.1', title: '4.1 Buitenlandse Spelers in de Collegiale Scene', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikanen op het Internationale Podium', isSubheader: true },

    { id: 'Hoofdstuk 5', title: '5. Tennissportbeurzen en de Toekomst', isSubheader: false },
    { id: '5.1', title: '5.1 Het Belang van Sportbeurzen', isSubheader: true },
    { id: '5.2', title: '5.2 Hoe Sportbeurs Amerika NL Kan Helpen', isSubheader: true },

  ];

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };

  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Inhoud
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Verberg</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Toon</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
            <MKBox id="intro" mb={3}>
            <MKTypography variant="h1" className="h1-as-h3" mb={2}>
              Tennisbeurs USA: Hoe je kunt studeren en tennissen in Amerika
              </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
  <img
    src={Image1}
    alt="Tenniscomplex van Georgia met volle tribunes tijdens een college wedstrijd."
    style={{
      maxWidth: "100%",
      border: "2px solid #fff",
      boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
      cursor: "pointer",
      outline: "none",
    }}
    onClick={() => openModal1(Image1)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
Volgepakte tribunes bij het Georgia tenniscomplex.
</figcaption>
</figure>
              Tennis is een sport die al meer dan een eeuw wordt gespeeld in de Verenigde Staten. Het begon als een recreatieve activiteit voor de elite, maar al snel werd het een competitieve en professionele sport die miljoenen fans en spelers over de hele wereld aantrok. Een van de belangrijkste factoren die hebben bijgedragen aan de groei en ontwikkeling van tennis in Amerika is het universitaire systeem. Universiteiten bieden niet alleen hoogwaardig onderwijs, maar ook uitstekende faciliteiten, coaching, begeleiding en beurzen voor getalenteerde tennissers. Door te tennissen op universitair niveau kunnen spelers hun vaardigheden verbeteren, hun netwerk uitbreiden, hun kansen op een professionele carrière vergroten en tegelijkertijd een waardevol diploma behalen.
<br/>
<br/>
Maar hoe kun je als jonge tennisser uit Nederland of België naar Amerika gaan om te studeren en te tennissen? Het antwoord is: met een tennisbeurs. Een tennisbeurs is een financiële ondersteuning die wordt verleend door een universiteit aan een speler die voldoet aan bepaalde academische en sportieve criteria. Met een tennisbeurs kun je je collegegeld, boeken, huisvesting, maaltijden en andere kosten betalen of verminderen. Een tennisbeurs kan variëren van een volledige beurs, die alle kosten dekt, tot een gedeeltelijke beurs, die een percentage van de kosten dekt.
<br/>
<br/>
Maar hoe kom je aan een tennisbeurs? Dat is waar Sportbeursamerika.nl je kan helpen. Sportbeursamerika.nl is een platform dat jonge tennissers helpt om hun droom om naar Amerika te gaan te realiseren. Sportbeursamerika.nl biedt advies, begeleiding, bemiddeling en ondersteuning bij het vinden van de beste universiteit en beurs voor jou. Sportbeursamerika.nl heeft een team van experts die weten hoe het Amerikaanse universitaire systeem werkt en die contacten hebben met honderden coaches en universiteiten. Sportbeursamerika.nl heeft al vele spelers geholpen om hun potentieel te bereiken en hun leven te veranderen.
<br/>
<br/>
Wil je meer weten over tennis in Amerika en hoe Sportbeursamerika.nl je kan helpen? Lees dan verder!
              </MKTypography>
              {/* Add bulleted list or other content as needed */}
            </MKBox>
             {/* Main chapters and subchapters with placeholder text */}
            <MKBox id="Hoofdstuk 1" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 1: Geschiedenis en Ontwikkeling van Tennis in Amerika
              </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              Tennis is een sport die zijn oorsprong vindt in Europa, maar die zich snel heeft verspreid naar andere delen van de wereld, vooral naar de Verenigde Staten. In dit hoofdstuk zullen we kijken naar hoe tennis zich in de VS heeft ontwikkeld en hoe universiteiten een belangrijke rol hebben gespeeld in de evolutie en professionalisering van de sport.
              </MKTypography>

              <MKBox id="1.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                1.1 Vroege Jaren en de Invloed van Universiteiten
                </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Tennis werd voor het eerst geïntroduceerd in de VS in de vroege 19e eeuw door rijke Amerikanen die het spel hadden geleerd tijdens hun reizen naar Europa. Het werd al snel een populaire recreatieve activiteit voor de elite, die tennisbanen bouwden op hun landgoederen en clubs. De eerste officiële tenniswedstrijd in de VS werd gespeeld in 1874 tussen twee Engelse broers, James Dwight en Fred Renshaw, die beiden studenten waren aan Harvard University. Ze speelden op een tennisbaan die ze hadden gebouwd op het landgoed van hun oom in Nahant, Massachusetts. De eerste nationale kampioenschappen, toen bekend als de US National Lawn Tennis Association Men’s Singles Championship, werden gehouden in 1881 in Newport, Rhode Island.<br/>
<br/>
Universiteiten waren een van de eerste instellingen die tennis omarmden als een competitieve sport. Ze organiseerden intercollegiate toernooien en vormden teams om tegen elkaar te spelen. De eerste intercollegiate tenniskampioenschappen werden gehouden in 1883 tussen Harvard, Yale, Princeton en Columbia en in 1885 werd het eerste officiële intercollegiate tenniskampioenschap erkend. Deze universiteiten werden al snel bekend als de “Big Four” van het Amerikaanse universiteitstennis. Ze domineerden de sport tot het begin van de 20e eeuw, toen andere universiteiten zoals Stanford, California, Chicago en Cornell zich bij hen voegden.
<br/>
<br/>
Universiteiten speelden ook een belangrijke rol in de promotie en verspreiding van tennis onder verschillende groepen mensen. Ze boden kansen aan vrouwen, Afro-Amerikanen, immigranten en andere minderheden om de sport te beoefenen en te excelleren. Een van de meest opmerkelijke voorbeelden is Althea Gibson, die in 1950 de eerste Afro-Amerikaanse vrouw was die werd toegelaten tot het nationale kampioenschap. Ze won in 1956 haar eerste Grand Slam-titel op de Franse kampioenschappen, nadat ze was afgestudeerd aan Florida A&M University en zich had aangesloten bij de American Tennis Association, een organisatie voor zwarte tennisspelers. Ze won later twee keer Wimbledon en twee keer de US Open.

                </MKTypography>
              </MKBox>
              <MKBox id="1.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                1.2 Groei en Professionalisering
                </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                De tweede helft van de 20e eeuw zag een enorme groei en professionalisering van tennis in Amerika. Dit werd mede mogelijk gemaakt door de opkomst van televisie, die de sport meer zichtbaarheid en populariteit gaf. Ook werden er nieuwe technologieën en materialen geïntroduceerd die het spel sneller en spannender maakten. Bovendien ontstonden er nieuwe organisaties en circuits die het tennis reguleerden en stimuleerden, zoals de International Tennis Federation (ITF), de Association of Tennis Professionals (ATP) en de Women’s Tennis Association (WTA).
<br/>
<br/>
Deze veranderingen hadden ook invloed op het universitaire tennis, dat zich moest aanpassen aan de nieuwe realiteit van het professionele tennis. Universiteiten moesten concurreren met andere opties om talentvolle spelers aan te trekken en te behouden. Ze moesten ook hun programma’s verbeteren om spelers voor te bereiden op een mogelijke professionele carrière. Een van de belangrijkste stappen die universiteiten namen was het creëren van de National Collegiate Athletic Association (NCAA), een overkoepelende organisatie die verantwoordelijk is voor het organiseren en reguleren van universitaire sporten in de VS. De NCAA verdeelde universiteiten in drie divisies op basis van hun grootte, budget en niveau van concurrentie. De NCAA organiseert jaarlijks nationale kampioenschappen voor elke divisie, zowel voor teams als voor individuen.
<br/>
<br/>
Een ander gevolg van de professionalisering van tennis was dat veel Amerikaanse spelers uitblonken op het hoogste niveau van de sport. Sommigen van hen hadden een universitaire achtergrond, wat bewijst dat universiteitstennis een goede basis kan bieden voor een succesvolle professionele carrière. Enkele van de meest bekende Amerikaanse tennissers die op universiteit hebben gespeeld zijn:
                </MKTypography>
                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
Arthur Ashe, die studeerde aan UCLA en de eerste Afro-Amerikaanse man was die Wimbledon, de US Open en de Australian Open won.

</li>
<li>
John McEnroe, die studeerde aan Stanford en zeven Grand Slam-titels won in het enkelspel en negen in het dubbelspel.

</li>
<li>
Jimmy Connors, die studeerde aan UCLA en acht Grand Slam-titels won in het enkelspel en twee in het dubbelspel.

</li>
<li>
Billie Jean King, die studeerde aan California State University, Los Angeles en 12 Grand Slam-titels won in het enkelspel en 16 in het dubbelspel. Ze was ook een pionier in de strijd voor gelijkheid tussen mannen en vrouwen in de sport.

</li>



</ul>
</MKTypography>
              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 2" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 2: Universitair Tennis Vandaag de Dag
                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              Tennis is nog steeds een van de meest populaire en prestigieuze sporten in het Amerikaanse universitaire systeem. Elk jaar nemen duizenden spelers deel aan verschillende competities en conferenties, die het beste van het beste in de sport laten zien. In dit hoofdstuk zullen we kijken naar de belangrijkste competities en conferenties in het universitaire tennis, en naar de topprogramma’s en prestigieuze teams die de sport domineren.
              </MKTypography>

              <MKBox id="2.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                2.1 Belangrijke Competities en Conferenties
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
  <img
    src={Image2}
    alt="Cameron Norrie in actie voor TCU in college tennis."
    style={{
      maxWidth: "100%",
      border: "2px solid #fff",
      boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
      cursor: "pointer",
      outline: "none",
    }}
    onClick={() => openModal2(Image2)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
  Cameron Norrie in actie als speler van TCU
</figcaption>
</figure>
                Zoals we in het vorige hoofdstuk hebben gezien, is de NCAA de belangrijkste organisatie die verantwoordelijk is voor het organiseren en reguleren van universitaire sporten in de VS. De NCAA verdeelt universiteiten in drie divisies: Division I, Division II en Division III. Elke divisie heeft zijn eigen regels, vereisten en kampioenschappen. Voor tennis zijn er twee belangrijke competities die worden gehouden door de NCAA: het teamkampioenschap en het individuele kampioenschap.
<br/>
<br/>
Het teamkampioenschap is een knock-outtoernooi dat wordt gespeeld tussen de beste teams van elke divisie. De teams worden geselecteerd op basis van hun prestaties gedurende het seizoen, hun ranking en hun regionale resultaten. Het teamkampioenschap bestaat uit twee fasen: de regionale fase en de nationale fase. De regionale fase wordt gespeeld tussen teams uit dezelfde regio, die zijn onderverdeeld in vier geografische zones: Noordoost, Zuidoost, Midwest en West. De winnaars van elke regio gaan door naar de nationale fase, die wordt gespeeld tussen 16 teams uit elke divisie. Het teamkampioenschap wordt gespeeld volgens het format van de Davis Cup, dat wil zeggen dat elk duel bestaat uit drie dubbelspellen en zes enkelspellen.
<br/>
<br/>
Het individuele kampioenschap is een knock-outtoernooi dat wordt gespeeld tussen de beste spelers van elke divisie. De spelers worden geselecteerd op basis van hun ranking, hun resultaten in het teamkampioenschap en hun prestaties in andere toernooien. Het individuele kampioenschap bestaat uit twee categorieën: het enkelspel en het dubbelspel. Elke categorie heeft 64 spelers in Division I, 48 spelers in Division II en 32 spelers in Division III.
<br/>
<br/>
Naast de NCAA-kampioenschappen zijn er ook andere competities die worden gespeeld tussen universiteiten die behoren tot dezelfde conferentie. Een conferentie is een groep universiteiten die samenwerken op academisch, sportief en administratief gebied. Er zijn meer dan 30 conferenties in de VS, die elk hun eigen regels, schema’s en kampioenschappen hebben. Sommige van de meest bekende conferenties zijn:
                </MKTypography>
                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
De Atlantic Coast Conference (ACC), die bestaat uit 15 universiteiten uit de oostkust, zoals Duke, North Carolina, Virginia en Florida State.
</li>
<li>
De Big Ten Conference, die bestaat uit 14 universiteiten uit het middenwesten, zoals Michigan, Ohio State, Illinois en Wisconsin.
</li>
<li>
De Pacific-12 Conference (Pac-12), die bestaat uit 12 universiteiten uit de westkust, zoals Stanford, California, UCLA en USC.
</li>
<li>
De Southeastern Conference (SEC), die bestaat uit 14 universiteiten uit het zuidoosten, zoals Georgia, Florida, Kentucky en Vanderbilt.
</li>
</ul>
</MKTypography>

<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
Deze conferenties zijn niet alleen belangrijk voor de sportieve rivaliteit tussen universiteiten, maar ook voor de academische reputatie en prestige. Bovendien bieden ze meer mogelijkheden voor spelers om zich te onderscheiden en zich te kwalificeren voor nationale kampioenschappen.


                </MKTypography>


              </MKBox>
              <MKBox id="2.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                2.2 Topprogramma’s en Prestigieuze Teams
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Het niveau van concurrentie in het universitaire tennis is zeer hoog, wat betekent dat alleen de beste spelers en teams kunnen overleven en slagen. Sommige universiteiten hebben echter een lange traditie en een sterke reputatie opgebouwd op het gebied van tennis, waardoor ze toonaangevende programma’s hebben ontwikkeld die jaar na jaar uitstekende resultaten behalen. Deze universiteiten hebben niet alleen de beste faciliteiten, coaches en beurzen, maar ook de beste rekrutering, training en begeleiding voor hun spelers. Sommige van de meest succesvolle en prestigieuze universitaire tennisprogramma’s zijn:               
                 </MKTypography>
                 <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
Stanford University, die 21 NCAA-teamtitels heeft gewonnen in het vrouwentennis en 17 in het mannentennis, meer dan elke andere universiteit. Stanford heeft ook 25 NCAA-individuele titels gewonnen in het vrouwentennis en 14 in het mannentennis. Enkele van de meest bekende spelers die voor Stanford hebben gespeeld zijn John McEnroe, Bob en Mike Bryan, Nicole Gibbs en Mallory Burdette.
</li>
<li>
USC (University of Southern California), die bijna evenveel NCAA-teamtitels heeft gewonnen als Stanford in het mannentennis en een paar in het vrouwentennis. USC heeft ook meer NCAA-individuele titels gewonnen dan elke andere universiteit in het mannentennis en enkele in het vrouwentennis. Enkele van de meest bekende spelers die voor USC hebben gespeeld zijn Stan Smith, Alex Olmedo, Steve Johnson en Danielle Collins.
</li>

</ul>
</MKTypography>



              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 3" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 3: Van College naar de Profs
                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              Een van de grootste dromen van veel jonge tennissers is om een professionele carrière na te streven en te schitteren op het wereldtoneel. Maar hoe kun je die droom waarmaken? Is universiteitstennis een goede voorbereiding op het professionele circuit? In dit hoofdstuk zullen we kijken naar het pad naar professionalisme en hoe universiteitstennis spelers kan helpen om die sprong te maken.
              </MKTypography>

              <MKBox id="3.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                3.1 Het Pad naar Professionalisme
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Het worden van een professionele tennisser is geen gemakkelijke taak. Het vereist niet alleen veel talent, maar ook veel toewijding, discipline, hard werken en opoffering. Het vereist ook het nemen van belangrijke beslissingen die je toekomst kunnen beïnvloeden. Een van de belangrijkste beslissingen die je moet nemen is of je naar de universiteit gaat of niet.
<br/>
<br/>
Er zijn voor- en nadelen aan beide opties. Als je ervoor kiest om direct na de middelbare school professioneel te worden, kun je je volledig concentreren op je tennis en proberen zo snel mogelijk een plek op de ranglijst te veroveren. Je kunt ook profiteren van je jonge leeftijd, je energie en je potentieel. Je kunt echter ook veel risico’s lopen, zoals blessures, burn-out, financiële problemen of gebrek aan resultaten. Bovendien kun je je academische opleiding opgeven of uitstellen, wat je opties kan beperken als je tennis niet werkt.
<br/>
<br/>
Als je ervoor kiest om naar de universiteit te gaan, kun je profiteren van de vele voordelen die universiteitstennis biedt. Je kunt niet alleen een hoogwaardig onderwijs krijgen dat je kan helpen om een diploma te behalen en een alternatieve carrière te hebben, maar ook genieten van een geweldige levenservaring die je kan helpen om te groeien als persoon. Je kunt ook profiteren van de uitstekende faciliteiten, coaching, begeleiding en beurzen die universiteiten bieden. Bovendien kun je je spel verbeteren door regelmatig te trainen en te concurreren tegen andere top spelers in een zeer competitieve omgeving. Je kunt echter ook nadelen ondervinden, zoals het verliezen van tijd en kansen om professioneel te worden, het achterblijven bij andere spelers die eerder zijn begonnen of het moeilijk vinden om de overstap te maken van college naar pro.
<br/>
<br/>
Er is geen eenduidig antwoord op de vraag of je naar de universiteit moet gaan of niet. Het hangt af van je persoonlijke situatie, je doelen, je voorkeuren en je mogelijkheden. Sommige spelers hebben succes gehad door direct professioneel te worden, terwijl anderen hebben geprofiteerd van hun universitaire ervaring. Het belangrijkste is dat je een weloverwogen beslissing neemt die bij jou past.
                </MKTypography>
              </MKBox>
              <MKBox id="3.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                3.2 Professionele Tenniscircuits
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Als je besluit om een professionele tennisser te worden, moet je weten hoe de professionele tenniscircuits werken en hoe je daar kunt komen. Er zijn verschillende circuits voor mannen en vrouwen, die elk hun eigen organisaties, regels en toernooien hebben.
<br/>
<br/>
Voor mannen zijn er twee belangrijke circuits: de ATP Tour en de ITF World Tennis Tour. De ATP Tour is het hoogste niveau van het professionele mannentennis, waar de beste spelers ter wereld strijden om prestigieuze titels en prijzengeld. De ATP Tour bestaat uit vier categorieën toernooien: de Grand Slams, de ATP Finals, de ATP Masters 1000 en de ATP 250/500. Om deel te nemen aan deze toernooien moet je een hoge ranking hebben, die wordt bepaald door het aantal punten dat je verdient door wedstrijden te winnen.
<br/>
<br/>
De ITF World Tennis Tour is het lagere niveau van het professionele mannentennis, waar spelers proberen hun ranking te verbeteren en zich te kwalificeren voor de ATP Tour. De ITF World Tennis Tour bestaat uit drie categorieën toernooien: de ITF World Tennis Tour M25/M15, de ITF World Tennis Tour M25/M15+H en de ITF World Tennis Tour M15/M15+H. Om deel te nemen aan deze toernooien moet je een ITF-ranking hebben, die wordt bepaald door het aantal punten dat je verdient door wedstrijden te winnen.
<br/>
<br/>
Voor vrouwen zijn er ook twee belangrijke circuits: de WTA Tour en de ITF World Tennis Tour. De WTA Tour is het hoogste niveau van het professionele vrouwentennis, waar de beste speelsters ter wereld strijden om prestigieuze titels en prijzengeld. De WTA Tour bestaat uit vier categorieën toernooien: de Grand Slams, de WTA Finals, de WTA Premier en de WTA International. Om deel te nemen aan deze toernooien moet je een hoge ranking hebben, die wordt bepaald door het aantal punten dat je verdient door wedstrijden te winnen.
<br/>
<br/>
De ITF World Tennis Tour is het lagere niveau van het professionele vrouwentennis, waar speelsters proberen hun ranking te verbeteren en zich te kwalificeren voor de WTA Tour. De ITF World Tennis Tour bestaat uit drie categorieën toernooien: de ITF World Tennis Tour W100/W60/W25/W15, de ITF World Tennis Tour W100/W60/W25/W15+H en de ITF World Tennis Tour W15/W15+H. Om deel te nemen aan deze toernooien moet je een ITF-ranking hebben, die wordt bepaald door het aantal punten dat je verdient door wedstrijden te winnen.
<br/>
<br/>
Om van universiteitstennis naar professioneel tennis over te stappen, moet je dus eerst een ranking opbouwen in de ITF World Tennis Tour en vervolgens proberen je te kwalificeren voor de ATP of WTA Tour. Dit kan een moeilijk en langdurig proces zijn, dat veel inspanning, geduld en doorzettingsvermogen vereist. Maar het is niet onmogelijk. Er zijn veel voorbeelden van spelers die met succes de overstap hebben gemaakt en die nu schitteren op het professionele circuit.
                </MKTypography>
              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 4" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 4: Internationale Invloeden
                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              Tennis is een wereldwijde sport, die mensen van verschillende nationaliteiten, culturen en achtergronden samenbrengt. Dit geldt ook voor het universitaire tennis in Amerika, dat een grote diversiteit en rijkdom aan internationale invloeden kent. In dit hoofdstuk zullen we kijken naar hoe buitenlandse spelers de collegiale scene hebben beïnvloed en hoe Amerikaanse spelers het internationale podium hebben bereikt.
              </MKTypography>

              <MKBox id="4.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                4.1 Buitenlandse Spelers in de Collegiale Scene
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
  <img
    src={Image3}
    alt="Spelers van Virginia met de nationale kampioenschapstrofee in college tennis."
    style={{
      maxWidth: "100%",
      border: "2px solid #fff",
      boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
      cursor: "pointer",
      outline: "none",
    }}
    onClick={() => openModal3(Image3)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
  Spelers van Virginia vieren het nationaal kampioenschap
</figcaption>
</figure>
                Een van de meest opvallende kenmerken van het universitaire tennis in Amerika is het hoge percentage buitenlandse spelers dat deelneemt aan de sport. Volgens de Intercollegiate Tennis Association (ITA) waren er in 2020 meer dan 3.000 buitenlandse spelers die voor Amerikaanse universiteiten speelden, wat neerkomt op ongeveer 40% van het totale aantal spelers. Deze spelers kwamen uit meer dan 100 landen, waarbij de meest voorkomende landen waren: Groot-Brittannië, Duitsland, Frankrijk, Spanje, Australië, Canada, Brazilië, Japan, China en India.
<br/>
<br/>
De redenen waarom buitenlandse spelers ervoor kiezen om naar Amerika te gaan om te studeren en te tennissen zijn divers. Sommigen zijn op zoek naar een betere academische opleiding, die ze in hun eigen land niet kunnen krijgen. Anderen zijn op zoek naar een betere sportieve ontwikkeling, die ze in hun eigen land niet kunnen vinden. Weer anderen zijn op zoek naar een nieuwe levenservaring, die ze in hun eigen land niet kunnen hebben. Wat hun motivatie ook is, buitenlandse spelers brengen veel voordelen met zich mee voor het Amerikaanse universiteitstennis.
<br/>
<br/>
Een van de voordelen is dat buitenlandse spelers het niveau en de kwaliteit van het spel verhogen. Ze brengen nieuwe vaardigheden, stijlen, tactieken en strategieën mee die het spel diverser en interessanter maken. Ze dagen ook de Amerikaanse spelers uit om hun best te doen en zich aan te passen aan verschillende situaties. Bovendien dragen ze bij aan de prestige en reputatie van de universiteiten waarvoor ze spelen, waardoor ze meer aandacht en erkenning krijgen.
<br/>
<br/>
Een ander voordeel is dat buitenlandse spelers de cultuur en de sfeer van het spel verrijken. Ze brengen nieuwe perspectieven, ideeën, waarden en tradities mee die het spel meer open en inclusief maken. Ze creëren ook een gevoel van gemeenschap en vriendschap tussen spelers van verschillende achtergronden, die samenwerken en elkaar ondersteunen. Bovendien leren ze van elkaar en delen ze hun ervaringen, waardoor ze hun horizon verbreden en hun persoonlijke groei bevorderen.
                </MKTypography>
              </MKBox>
              <MKBox id="4.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                4.2 Amerikanen op het Internationale Podium                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Terwijl buitenlandse spelers een grote invloed hebben op het Amerikaanse universiteitstennis, hebben Amerikaanse spelers ook een grote impact op het internationale tennis. Veel Amerikaanse spelers die op universiteit hebben gespeeld, hebben later succes gehad op het professionele circuit en hebben hun land vertegenwoordigd op het wereldpodium. Sommige van deze spelers zijn:
                </MKTypography>
                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
John Isner, die studeerde aan de University of Georgia en vier keer All-American was. Hij staat bekend om zijn krachtige service en zijn lange wedstrijden, zoals de langste wedstrijd in de geschiedenis tegen Nicolas Mahut op Wimbledon in 2010. Hij heeft 15 ATP-titels gewonnen en heeft de vierde plaats bereikt op de wereldranglijst.
</li>
<li>
Kevin Anderson, die studeerde aan de University of Illinois en twee keer All-American was. Hij staat bekend om zijn sterke baseline-spel en zijn consistente prestaties. Hij heeft zes ATP-titels gewonnen en heeft twee Grand Slam-finales bereikt: de US Open in 2017 en Wimbledon in 2018.
</li>
<li>
Danielle Collins, die studeerde aan de University of Virginia en twee keer NCAA-kampioene was. Ze staat bekend om haar agressieve en expressieve spelstijl en haar vechtlust. Ze heeft één WTA-titel gewonnen en heeft de halve finale bereikt van de Australian Open in 2019.
</li>
<li>
Jennifer Brady, die studeerde aan UCLA en één keer All-American was. Ze staat bekend om haar krachtige slagen en haar verbeterde beweging. Ze heeft één WTA-titel gewonnen en heeft de finale bereikt van de Australian Open in 2021.
</li>
</ul>
</MKTypography>


              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 5" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 5: Tennissportbeurzen en de Toekomst
                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              Tennis is een sport die veel kansen biedt voor jonge spelers die hun talent willen ontwikkelen en hun dromen willen waarmaken. Een van de beste manieren om dat te doen is door te kiezen voor een tennissportbeurs in Amerika. In dit hoofdstuk zullen we uitleggen wat een tennissportbeurs inhoudt, waarom het zo belangrijk is, en hoe Sportbeursamerika.nl je kan helpen om deze unieke kans te grijpen.
              </MKTypography>

              <MKBox id="5.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                5.1 Het Belang van Sportbeurzen
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Een sportbeurs is een financiële ondersteuning die een universiteit biedt aan een student-atleet om zijn of haar studie en sport te combineren. Een sportbeurs kan variëren van een gedeeltelijke vergoeding van het collegegeld tot een volledige dekking van alle kosten, inclusief huisvesting, boeken, maaltijden en reizen. Een sportbeurs wordt toegekend op basis van de sportieve prestaties en potentieel van de student-atleet, maar ook op basis van zijn of haar academische niveau en motivatie.
<br/>
<br/>
Een sportbeurs is een geweldige kans voor jonge tennissers om hun spel naar een hoger niveau te tillen en tegelijkertijd een waardevol diploma te behalen. Door te tennissen op universitair niveau krijg je de kans om te trainen en te spelen met en tegen de beste spelers van je leeftijd, onder begeleiding van professionele coaches en met toegang tot uitstekende faciliteiten. Je krijgt ook de kans om deel te nemen aan spannende competities en toernooien, waar je jezelf kunt meten met de top van het Amerikaanse en internationale tennis.
<br/>
<br/>
Bovendien biedt een sportbeurs je de mogelijkheid om te studeren aan een gerenommeerde universiteit, waar je kunt kiezen uit een breed scala aan opleidingen die aansluiten bij je interesses en ambities. Je kunt profiteren van het hoge academische niveau, de moderne lesmethoden en de internationale sfeer die de Amerikaanse universiteiten kenmerken. Je kunt ook genieten van het bruisende campusleven, waar je nieuwe vrienden kunt maken, nieuwe culturen kunt ontdekken en jezelf kunt ontplooien op persoonlijk vlak.
<br/>
<br/>
Een sportbeurs is dus niet alleen een investering in je sportieve carrière, maar ook in je toekomst als geheel. Met een sportbeurs kun je je horizon verbreden, je vaardigheden ontwikkelen en je kansen vergroten op zowel professioneel als persoonlijk vlak.
                </MKTypography>
              </MKBox>
              <MKBox id="5.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                5.2 Hoe Sportbeurs Amerika NL Kan Helpen 
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
  <img
    src={Image4}
    alt="Logo van Sportbeurs Amerika NL, bestaande uit een voetbal met het continent Noord-Amerika erop afgebeeld, met daarboven een afstudeerhoed (graduation cap) die bovenop de bal rust alsof het een hoofd is. De naam van het bedrijf is prominent weergegeven, wat de focus op sportbeurzen in Amerika voor Europese studenten benadrukt."
    style={{
      maxWidth: "100%",
      height: "auto",
      border: "2px solid #fff",
      boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)", // Apply shadow around the entire image
      cursor: "pointer",
      outline: "none",
      borderRadius: "50%", // Set border-radius to 50% for a circular image
      marginTop: "0px", // Add top margin to move the image up


    }}
    onClick={() => openModal4(Image4)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
  
</figcaption>
</figure>
Sportbeurs Amerika NL is een organisatie gespecialiseerd in het begeleiden van jonge sporttalenten naar sportbeurzen in Amerika.  Met een gepassioneerde en ervaren expert aan het roer, ondersteunt Sportbeurs Amerika NL de student-atleet bij iedere stap in het traject, vanaf de eerste oriëntatie tot aan de uiteindelijke plaatsing. Sportbeurs Amerika NL biedt onder andere de volgende diensten aan:
                </MKTypography>

                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
Een persoonlijke intakegesprek om de wensen, mogelijkheden en verwachtingen van de student-atleet te bespreken.
</li>
<li>
Een professionele evaluatie van het sportieve en academische niveau van de student-atleet.
</li>
<li>
Een uitgebreid netwerk van contacten met Amerikaanse coaches, universiteiten en colleges.
</li>
<li>
Een effectieve promotie van het profiel en de video’s van de student-atleet bij potentiële coaches.
</li>
<li>
Een deskundig advies over de beste opties voor de student-atleet op basis van zijn of haar voorkeuren, doelen en budget.
</li>
<li>
Een onderhandeling over de beste beursaanbiedingen namens de student-atleet.
</li>
<li>
Een hulp bij het aanvragen van de benodigde documenten, zoals het visum, het transcript, het diploma en de toelatingstesten.
</li>
<li>
Een voorbereiding op het leven in Amerika, zoals de cultuur, de taal, de regels en de tips.
</li>
<li>
Een nazorg en een follow-up tijdens het verblijf in Amerika.</li>

</ul>
</MKTypography>

<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Wil jij ook je passie voor American football combineren met een goede opleiding in Amerika? <Link to="/Diensten-Producten">Bekijk het volledige aanbod</Link> van Sportbeurs Amerika NL en maak een vrijblijvende afspraak om je te laten informeren over de mogelijkheden. 
      Ik sta voor je klaar om je te helpen bij het realiseren van je Amerikaanse droom.
    </MKTypography>


              </MKBox>
            </MKBox>

          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;
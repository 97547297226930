/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import americanfootball from "assets/images/SportsIcons/americanfootball.png";
import atletiek from "assets/images/SportsIcons/atletiek.png";
import basketbal from "assets/images/SportsIcons/basketbal.png";
import golf from "assets/images/SportsIcons/golf.png";
import hockey from "assets/images/SportsIcons/hockey.png";
import honkbal from "assets/images/SportsIcons/honkbal.png";
import roeien from "assets/images/SportsIcons/roeien.png";
import softbal from "assets/images/SportsIcons/softbal.png"
import tennis from "assets/images/SportsIcons/tennis.png";
import turnen from "assets/images/SportsIcons/turnen.png";
import voetbal from "assets/images/SportsIcons/voetbal.png";
import volleybal from "assets/images/SportsIcons/volleybal.png";
import waterpolo from "assets/images/SportsIcons/waterpolo.png";
import worstelen from "assets/images/SportsIcons/worstelen.png";
import zwemmen from "assets/images/SportsIcons/zwemmen.png";
import ijshockey from "assets/images/SportsIcons/ijshockey.png"

export default [
  {
    title: "Sporten",
    description: "Een selectie van 16 sporten waarvoor ik begeleiding bied naar een sportbeurs",
    items: [
      {
        image: americanfootball,
        name: "American Football",
        badgeContent: "Mannen",
        secondBadgeContent: "",
        route: "/Alle-Sporten/American-Football",
        popularity: 13,
        altText: "Icoon van American football"

      },
      {
        image: atletiek,
        name: "Atletiek",
        badgeContent: "Mannen",
        secondBadgeContent: "vrouwen",
        route: "/Alle-Sporten/Atletiek",
        popularity: 5,
        altText: "Icoon van atletiek"

      },
      {
        image: basketbal,
        name: "Basketbal",
        badgeContent: "Mannen",
        secondBadgeContent: "vrouwen",
        route: "/Alle-Sporten/Basketbal",
        popularity: 7,
        altText: "Icoon van basketbal"

      },
      {
        image: golf,
        name: "Golf",
        badgeContent: "Mannen",
        secondBadgeContent: "vrouwen",
        route: "/Alle-Sporten/Golf",
        popularity: 8,
        altText: "Icoon van golf"
        

      },
      {
        image: hockey,
        name: "Hockey",
        badgeContent: "",
        secondBadgeContent: "vrouwen",
        route: "/Alle-Sporten/Hockey",
        popularity: 3,
        altText: "Icoon van hockey"

      },
      {
        image: honkbal,
        name: "Honkbal",
        badgeContent: "Mannen",
        secondBadgeContent: "",
        route: "/Alle-Sporten/Honkbal",
        popularity: 15,
        altText: "Icoon van honkbal"

      },
      {
        image: ijshockey,
        name: "Ijshockey",
        badgeContent: "Mannen",
        secondBadgeContent: "vrouwen",
        route: "/Alle-Sporten/Ijshockey",
        popularity: 14,
        altText: "Icoon van ijshockey"

      },
      {
        image: roeien,
        name: "Roeien",
        badgeContent: "Mannen",
        secondBadgeContent: "vrouwen",
        route: "/Alle-Sporten/Roeien",
        popularity: 9,
        altText: "Icoon van roeien"

      },
      {
        image: softbal,
        name: "Softbal",
        badgeContent: "",
        secondBadgeContent: "vrouwen",
        route: "/Alle-Sporten/Softbal",
        popularity: 12,
        altText: "Icoon van softbal"

      },
      {
        image: tennis,
        name: "Tennis",
        badgeContent: "Mannen",
        secondBadgeContent: "vrouwen",
        route: "/Alle-Sporten/Tennis",
        popularity: 2,
        altText: "Icoon van tennis"

      },
      {
        image: turnen,
        name: "Turnen",
        badgeContent: "Mannen",
        secondBadgeContent: "vrouwen",
        route: "/Alle-Sporten/Turnen",
        popularity: 10,
        altText: "Icoon van turnen"

      },
      {
        image: voetbal,
        name: "Voetbal",
        badgeContent: "Mannen",
        secondBadgeContent: "vrouwen",
        route: "/Alle-Sporten/Voetbal",
        popularity: 1,
        altText: "Icoon van voetbal"

      },
      {
        image: volleybal,
        name: "Volleybal",
        badgeContent: "Mannen",
        secondBadgeContent: "vrouwen",
        route: "/Alle-Sporten/Volleybal",
        popularity: 6,
        altText: "Icoon van volleybal"

      },
      {
        image: waterpolo,
        name: "Waterpolo",
        badgeContent: "Mannen",
        secondBadgeContent: "vrouwen",
        route: "/Alle-Sporten/Waterpolo",
        popularity: 11,
        altText: "Icoon van waterpolo"

      },
      {
        image: worstelen,
        name: "Worstelen",
        badgeContent: "Mannen",
        secondBadgeContent: "vrouwen",
        route: "/Alle-Sporten/Worstelen",
        popularity: 16,
        altText: "Icoon van worstelen"

      },
      {
        image: zwemmen,
        name: "Zwemmen",
        badgeContent: "Mannen",
        secondBadgeContent: "vrouwen",
        route: "/Alle-Sporten/Zwemmen",
        popularity: 4,
        altText: "Icoon van zwemmen"

      },
    ],
  },
];

/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { Helmet } from 'react-helmet';

// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Author page sections
import Beschrijving from "pages//Alle Sporten/Sporten/Voetbal/sections/Beschrijving";

// Routes
import routes from "routes";
import Breadcrumbs from "examples/Breadcrumbs";
import footerRoutes from "footer.routes";
import DetailedFooter from "examples/Footers/DetailedFooter";

// Images
import bgImage from "assets/images/Achtergronden/Voetbal_BG.png";

function Voetbal() {
  return (
    <>
         <Helmet>
    <title>College Voetbal in de VS - Sportbeurs Amerika NL</title>
    <meta name="description" content="Ontdek de geschiedenis, ontwikkeling en huidige stand van voetbal op Amerikaanse colleges. Leer hoe sportbeurzen een rol spelen en hoe ik kan helpen." />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta charset="UTF-8" />
    <meta name="robots" content="index, follow" />
    
    {/* Open Graph Tags */}
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://sportbeursamerika.nl/Alle-Sporten/Voetbal" />
    <meta property="og:title" content="Voetbal - Een Complete Gids voor Sportbeurzen in de VS" />
    <meta property="og:description" content="Alle informatie die je nodig hebt over college voetbal in Amerika. De beginjaren, vandaag de dag, weg naar de profs, sportbeurzen en meer." />
    <meta property="og:image" content="https://sportbeursamerika.nl/path/to/services-products-image.jpg" /> 
    {/* Favicon */}
    <link rel="icon" href="https://sportbeursamerika.nl/32x32Sportbeurs.png" />
</Helmet>
    <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://calendly.com/sportbeurs-amerika/intake",
          label: "MAAK AFSPRAAK",
          color: "info",
        }}
        transparent
        light
         
      />
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.8),
                rgba(gradients.dark.state, 0.8)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        />
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
    <MKBox
  width={{ xs: "80%", md: "50%", lg: "25%" }}
  mb={1}
  sx={{
    backgroundColor: "transparent", // Transparent background color
  }}
>
  <Breadcrumbs
    routes={[
      { label: "Home", route: "/" },
      { label: "Alle Sporten", route: "/Alle-Sporten" },
      { label: "Voetbal" },
    ]}
  />
</MKBox>
          <Beschrijving />
        </Card>
        <MKBox pt={6} px={1} mt={6}>
        <DetailedFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Voetbal;

import { useRef } from "react";

import { Helmet } from 'react-helmet';



// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DetailedFooter from "examples/Footers/DetailedFooter";


// Coworking page sections
import DesignBlocks from "./ACT"

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/Achtergronden/Home_BG.png";
import Breadcrumbs from "examples/Breadcrumbs";

function ProductPage() {
  const headerRef = useRef(null);
  const typedJSRef = useRef(null);

  // Setting up rellax
 
 

  return (
    <>
    <Helmet>

       <title>ACT Studiegids - Optimaliseer Je Score voor een Sportbeurs in de VS</title>
    <meta name="description" content="Bereid je voor op de ACT met onze uitgebreide studiegids. Essentieel voor Nederlandse student-atleten die streven naar sportbeurzen in Amerika." />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta charset="UTF-8" />
    <meta name="robots" content="index, follow" />
    
    {/* Open Graph Tags */}
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://sportbeursamerika.nl/Diensten-Producten/ACT-gids" />
    <meta property="og:title" content="ACT Studiegids - Optimaliseer Je Score voor een Sportbeurs in de VS" />
    <meta property="og:description" content="De ultieme voorbereiding voor de ACT. Onmisbaar voor student-atleten die mikken op sportbeurzen in de VS." />
        <meta property="og:image" content="https://sportbeursamerika.nl/static/media/ACT_kaft.6520efb0f2bd9308f172.png" />
     
        {/* Favicon */}
        <link rel="icon" href="https://sportbeursamerika.nl/32x32Sportbeurs.png" />
      </Helmet>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://calendly.com/sportbeurs-amerika/intake",
          label: "MAAK AFSPRAAK",
          color: "info",
        }}
        transparent
        light
         
      />
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.8),
                rgba(gradients.dark.state, 0.8)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center 40%",
            display: "grid",
            placeItems: "center",
          }}
        />
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -14,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
         <MKBox
  width={{ xs: "80%", md: "50%", lg: "25%" }}
  mb={1}
  sx={{
    backgroundColor: "transparent", // Transparent background color
  }}
>
  <Breadcrumbs
    routes={[
      { label: "Home", route: "/" },
      { label: "Diensten & Producten", route: "/Diensten-Producten" },
      { label: "ACT Studiegids" },
    ]}
  />
</MKBox>  
        <DesignBlocks />
        
      </Card>
        <DetailedFooter content={footerRoutes} />
    </>
  );
}

export default ProductPage;

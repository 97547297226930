/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sporten_Images/Atletiek1.jpg";
import Image2 from "assets/images/Sporten_Images/Atletiek2.jpeg";
import Image3 from "assets/images/Sporten_Images/Atletiek3.webp";
import Image4 from "assets/images/Logos/Sportbeurs.png";
import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"



// Images



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Atletiek en studeren in Amerika: een droom die werkelijkheid kan worden', isSubheader: false },

    { id: 'Hoofdstuk 1', title: '1. De Geschiedenis en Ontwikkeling van Atletiek op Collegeniveau', isSubheader: false },
    { id: '1.1', title: '1.1 Vroege Jaren en Ontstaan', isSubheader: true },
    { id: '1.2', title: '1.2 Groei en Professionalisering', isSubheader: true },

    { id: 'Hoofdstuk 2', title: '2. Atletiek op Collegenniveau Vandaag de Dag', isSubheader: false },
    { id: '2.1', title: '2.1 Belangrijke Competities en Conferenties', isSubheader: true },
    { id: '2.2', title: '2.2 Topprogramma’s en Prestigieuze Teams', isSubheader: true },

    { id: 'Hoofdstuk 3', title: '3. Van College naar Professioneel', isSubheader: false },
    { id: '3.1', title: '3.1 Het Pad naar Professionalisme', isSubheader: true },
    { id: '3.2', title: '3.2 Relatie met Professionele Atletiek', isSubheader: true },


    { id: 'Hoofdstuk 4', title: '4. Internationale Invloeden en Kansen', isSubheader: false },
    { id: '4.1', title: '4.1 Buitenlandse Atleten en Competities', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikaanse Atleten in het Buitenland', isSubheader: true },

    { id: 'Hoofdstuk 5', title: '5. Atletiekbeurzen en de Toekomst', isSubheader: false },
    { id: '5.1', title: '5.1 Het Belang van Sportbeurzen', isSubheader: true },
    { id: '5.2', title: '5.2 Hoe Sportbeurs Amerika NL Kan Helpen', isSubheader: true },

  ];

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Inhoud
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Verberg</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Toon</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
            <MKBox id="intro" mb={3}>
            <MKTypography variant="h1" className="h1-as-h3" mb={2}>
                Atletiek en studeren in Amerika: een droom die werkelijkheid kan worden
              </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
  <img
    src={Image1}
    alt="Hardlopers in volle actie tijdens de Penn State Relays, een prominente college atletiek evenement."
    style={{
      maxWidth: "100%",
      border: "2px solid #fff",
      boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
      cursor: "pointer",
      outline: "none",
    }}
    onClick={() => openModal1(Image1)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
Actie bij de Penn State Relays.</figcaption>
</figure>
              Atletiek is een van de meest populaire en belangrijke sporten in de Verenigde Staten. Miljoenen mensen beoefenen atletiek als hobby, als onderdeel van hun school- of universiteitsprogramma, of als professionele atleten. Atletiek is ook een sport die veel kansen biedt voor jonge talenten om zich te ontwikkelen, te concurreren en te excelleren op het hoogste niveau.
<br/>
<br/>
Een van de beste manieren om deze kansen te benutten is door een atletiekbeurs te krijgen in de VS. Een atletiekbeurs is een financiële ondersteuning die je krijgt van een universiteit om daar te studeren en atletiek te beoefenen. Met een atletiekbeurs kun je niet alleen je academische diploma behalen, maar ook deelnemen aan een van de meest prestigieuze en competitieve atletiekprogramma’s ter wereld.
<br/>
<br/>
Maar hoe kom je aan een atletiekbeurs in de VS? Wat zijn de voordelen en uitdagingen van het studeren en sporten op collegenniveau? En hoe kun je je voorbereiden op een succesvolle carrière als atleet na je studie? Dat zijn enkele van de vragen die we zullen beantwoorden in dit artikel.
<br/>
<br/>
We zullen je ook vertellen hoe Sportbeursamerika.nl je kan helpen om je droom waar te maken. Sportbeursamerika.nl is een organisatie die gespecialiseerd is in het begeleiden van jonge atleten naar sportbeurzen in de VS. Ik heb veel ervaring in het vinden van de beste match tussen atleten en universiteiten. Ik bied persoonlijke begeleiding, professioneel advies en praktische ondersteuning bij elke stap van het proces.
<br/>
<br/>
Als je geïnteresseerd bent in het krijgen van een atletiekbeurs in de VS, dan is dit artikel voor jou. Lees verder en ontdek alles wat je moet weten over atletiek op collegenniveau in de VS.
              </MKTypography>
              {/* Add bulleted list or other content as needed */}
            </MKBox>
             {/* Main chapters and subchapters with placeholder text */}
            <MKBox id="Hoofdstuk 1" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 1: Geschiedenis en Ontwikkeling van Atletiek op Collegenniveau
              </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              Atletiek op collegenniveau heeft een lange en rijke geschiedenis in de VS. Het begon in de 19e eeuw als een manier om de fysieke en mentale gezondheid van studenten te bevorderen. Het groeide uit tot een belangrijke sport die miljoenen toeschouwers trok en veel atleten voortbracht die later wereldberoemd werden.              </MKTypography>

              <MKBox id="1.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                1.1 Vroege Jaren en Ontstaan
                </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                De eerste atletiekcompetitie op universiteiten vond plaats in 1864, toen studenten van Yale en Oxford elkaar uitdaagden voor een wedstrijd in Londen. De wedstrijd bestond uit vier onderdelen: hardlopen, verspringen, hoogspringen en polsstokhoogspringen. Yale won de wedstrijd met 7-3.
<br/>
<br/>
Deze wedstrijd inspireerde andere universiteiten om ook atletiekteams te vormen en onderling te concurreren. In 1873 werd de eerste intercollegiate atletiekvereniging opgericht, de Intercollegiate Association of Amateur Athletes of America (IAAAA). De IAAAA organiseerde jaarlijkse kampioenschappen voor universiteiten in het oosten van de VS.
<br/>
<br/>
Eind 19e eeuw was een belangrijk tijdperk voor de ontwikkeling van atletiek. Het werd gekenmerkt door de formalisering van regels en de opkomst van invloedrijke figuren zoals James E. Sullivan, medeoprichter van de Amateur Athletic Union in de VS. Hij speelde een belangrijke rol in het populariseren van atletiek en het organiseren van belangrijke evenementen. De introductie van de moderne Olympische Spelen in 1896 gaf een internationaal platform aan de sport, wat leidde tot de standaardisatie van regels en onderdelen. Ook technologische vooruitgang, zoals verbeteringen in schoeisel en hardloopbanen, droeg bij aan de evolutie van atletiek in deze periode.                </MKTypography>
              </MKBox>
              <MKBox id="1.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                1.2 Groei en Professionalisering
                </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                In de 20e eeuw werd atletiek steeds populairder en belangrijker op universiteiten. Het aantal atletiekprogramma’s, competities en beurzen nam toe. Ook het niveau van de atleten steeg, mede dankzij betere training, faciliteiten en coaching.
<br/>
<br/>
Een van de factoren die bijdroegen aan de groei en professionalisering van atletiek op collegenniveau was de oprichting van de National Collegiate Athletic Association (NCAA) in 1906. De NCAA is een organisatie die toezicht houdt op de sportactiviteiten van meer dan 1000 universiteiten in de VS. De NCAA reguleert onder andere de regels, kwalificaties, beurzen en sancties voor atletiek op collegenniveau.
<br/>
<br/>
Een andere factor die invloed had op de ontwikkeling van atletiek op collegenniveau was de relatie met professionele atletiek. Veel atleten die succesvol waren op collegenniveau maakten later de overstap naar het professionele circuit. Sommige voorbeelden zijn Jesse Owens, Carl Lewis, Jackie Joyner-Kersee en Michael Johnson.
                </MKTypography>
              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 2" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 2: Atletiek op Collegenniveau Vandaag de Dag
                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              Atletiek op collegenniveau is vandaag de dag een van de meest populaire en prestigieuze sporten in de VS. Elk jaar nemen duizenden atleten deel aan verschillende competities en evenementen, die miljoenen fans en media-aandacht trekken. Atletiek op collegenniveau is ook een sport die veel kansen biedt voor atleten om zich te ontwikkelen, te presteren en te genieten van hun sport.
              </MKTypography>

              <MKBox id="2.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                2.1 Belangrijke Competities en Conferenties
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
  <img
    src={Image2}
    alt="Sydney McLaughlin, uitblinkend als atleet voor de University of Kentucky, in wedstrijdtenue."
    style={{
      maxWidth: "100%",
      border: "2px solid #fff",
      boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
      cursor: "pointer",
      outline: "none",
    }}
    onClick={() => openModal2(Image2)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
    Sydney McLaughlin studeerde aan de University of Kentucky

</figcaption>
</figure>
                De belangrijkste organisatie die atletiek op collegenniveau regelt is de NCAA. De NCAA verdeelt de universiteiten in drie divisies, gebaseerd op hun grootte, budget en niveau van competitie. Divisie I is de hoogste divisie, waar de meeste topuniversiteiten en atleten in zitten. Divisie II en III zijn lagere divisies, waar de universiteiten minder beurzen en middelen hebben, maar meer nadruk leggen op academische prestaties.
<br/>
<br/>
Binnen elke divisie zijn er verschillende conferenties, die groepen van universiteiten zijn die geografisch of historisch verbonden zijn. Elke conferentie organiseert zijn eigen kampioenschappen en competities voor zijn leden. Sommige van de bekendste conferenties zijn de Big Ten, de Pac-12, de SEC, de ACC en de Ivy League.
<br/>
<br/>
De belangrijkste evenementen in het college atletiekseizoen zijn de NCAA kampioenschappen, die elk jaar worden gehouden in maart (indoor) en juni (outdoor). Deze kampioenschappen bepalen welke universiteiten en atleten de beste zijn in elke divisie en elk onderdeel. De kampioenschappen trekken veel toeschouwers, sponsors en media-aandacht.
                </MKTypography>
              </MKBox>
              <MKBox id="2.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                2.2 Topprogramma’s en Prestigieuze Teams
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Er zijn veel universiteiten die bekend staan om hun uitstekende atletiekprogramma’s. Deze universiteiten hebben een lange traditie van succes, een hoge kwaliteit van coaching en faciliteiten, en een grote aantrekkingskracht voor talentvolle atleten. Sommige van deze universiteiten zijn:
                </MKTypography>
                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
Oregon: Oregon is een van de meest succesvolle universiteiten in atletiek, vooral in hardlopen. Oregon heeft meer dan 30 NCAA titels gewonnen, en heeft veel beroemde atleten voortgebracht, zoals Steve Prefontaine, Alberto Salazar, Galen Rupp en Matthew Centrowitz.
</li>
<li>
USC: USC is een andere topuniversiteit in atletiek, met een sterke focus op sprinten, springen en werpen. USC heeft meer dan 25 NCAA titels gewonnen, en heeft veel Olympische medaillewinnaars geproduceerd, zoals Quincy Watts, Andre De Grasse en Michael Norman.
</li>
<li>
Arkansas: Arkansas is een dominantie universiteit in atletiek, vooral in het indoorseizoen. Arkansas heeft meer dan 40 NCAA titels gewonnen, waarvan meer dan 30 indoor. Arkansas staat bekend om zijn diepte en veelzijdigheid in verschillende onderdelen.
</li>
<li>
Texas: Texas is een van de meest veelzijdige universiteiten in atletiek, met een goede balans tussen mannen- en vrouwenteams, en tussen individuele en estafetteonderdelen. Texas heeft meer dan 20 NCAA titels gewonnen, en heeft veel wereldklasse atleten voortgebracht, zoals Sanya Richards-Ross, Trey Hardee, Michelle Carter en Courtney Okolo.
</li>
</ul>
</MKTypography>
<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
Deze universiteiten bieden niet alleen een hoog niveau van competitie en training voor hun atleten, maar ook een geweldige sfeer en cultuur. De atleten maken deel uit van een hechte gemeenschap die hen steunt en motiveert om hun potentieel te bereiken.
                </MKTypography>
              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 3" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 3: Van College naar Professioneel
                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              Atletiek op collegenniveau is niet alleen een sport, maar ook een leerschool voor atleten die een professionele carrière ambiëren. Veel atleten die op collegenniveau hebben gepresteerd, hebben later de overstap gemaakt naar het professionele circuit. Atletiek op collegenniveau biedt atleten de mogelijkheid om zich te ontwikkelen, te ervaren en te netwerken met andere atleten, coaches en organisaties.              </MKTypography>

              <MKBox id="3.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                3.1 Het Pad naar Professionalisme
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Voor veel atleten is het doel om na hun studie professioneel atleet te worden. Dit betekent dat ze hun sport als hun beroep gaan beoefenen, en dat ze geld verdienen met hun prestaties, contracten en sponsoring. Om professioneel atleet te worden, moeten atleten aan een aantal voorwaarden voldoen, zoals:
                </MKTypography>
                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
Het hebben van een hoog niveau van prestatie en potentieel. Professionele atleten moeten in staat zijn om te concurreren met de beste atleten ter wereld, en om zich voortdurend te verbeteren en aan te passen aan de veranderende omstandigheden.
</li>
<li>
Het hebben van een goede gezondheid en conditie. Professionele atleten moeten hun lichaam goed verzorgen en beschermen tegen blessures, ziektes en overbelasting. Ze moeten ook een goede balans vinden tussen training, rust en voeding.
</li>
<li>
Het hebben van een goede begeleiding en ondersteuning. Professionele atleten moeten kunnen rekenen op een team van experts die hen kunnen helpen met hun techniek, tactiek, mentale voorbereiding, juridische zaken, financiële zaken en marketing.
</li>
<li>
Het hebben van een goede planning en strategie. Professionele atleten moeten weten welke doelen ze willen bereiken, welke wedstrijden ze willen deelnemen, hoe ze zich willen kwalificeren voor grote evenementen, en hoe ze hun carrière willen beheren.
</li>
</ul>
</MKTypography>
<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
Atletiek op collegenniveau kan atleten helpen om aan deze voorwaarden te voldoen. Atletiek op collegenniveau biedt atleten de kans om hun talent te tonen, hun vaardigheden te verbeteren, hun ervaring te vergroten, en hun netwerk uit te breiden. Atletiek op collegenniveau kan ook dienen als een springplank naar professioneel niveau, door atleten bloot te stellen aan hoge niveaus van competitie, media-aandacht en publieke belangstelling.
                </MKTypography>
              </MKBox>
              <MKBox id="3.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                3.2 Relatie met Professionele Atletiek
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Atletiek op collegenniveau heeft een nauwe relatie met professionele atletiek. De twee niveaus beïnvloeden elkaar op verschillende manieren, zoals:
                </MKTypography>
                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
Talentontwikkeling: Atletiek op collegenniveau is een belangrijke bron van talent voor professionele atletiek. Veel professionele atleten zijn begonnen als collegeatleten, en hebben daar hun basis gelegd voor hun latere succes. 
</li>
<li>
Talentenscouting: Professionele atletiekorganisaties houden collegeatletiek nauwlettend in de gaten om potentiële sterren te ontdekken en aan te trekken. Ze bieden collegeatleten contracten, sponsoring en begeleiding aan om hen te verleiden om professioneel te worden.
</li>
<li>
Talentuitwisseling: Atletiek op collegenniveau staat ook open voor professionele atleten die nog studeren of terugkeren naar school. Sommige professionele atleten kiezen ervoor om hun studie af te maken of te hervatten terwijl ze hun sportcarrière voortzetten. 
</li>
</ul>
</MKTypography>
<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
Atletiek op collegenniveau is dus niet alleen een eindstation, maar ook een tussenstation voor atleten die een professionele carrière nastreven. Atletiek op collegenniveau biedt atleten de mogelijkheid om zich voor te bereiden op het professionele niveau, maar ook om andere paden te verkennen.
                </MKTypography>
              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 4" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 4: Internationale Invloeden en Kansen
                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              Atletiek op collegenniveau is niet alleen een Amerikaanse sport, maar ook een internationale sport. Atletiek op collegenniveau wordt beïnvloed door en biedt kansen voor atleten uit verschillende landen en culturen. Atletiek op collegenniveau is een sport die de diversiteit en de verbondenheid van de wereld weerspiegelt.
              </MKTypography>

              <MKBox id="4.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                4.1 Buitenlandse Atleten en Competities
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
  <img
    src={Image3}
    alt="Het beroemde Oregon atletiekstadion, thuisbasis van talrijke college atletiekevenementen."
    style={{
      maxWidth: "100%",
      border: "2px solid #fff",
      boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
      cursor: "pointer",
      outline: "none",
    }}
    onClick={() => openModal3(Image3)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
Oregon atletiekstadion, een icoon van college sport.
</figcaption>
</figure>
                Een van de manieren waarop atletiek op collegenniveau internationaal is, is door de aanwezigheid en de prestaties van buitenlandse atleten. Elk jaar komen er honderden atleten uit andere landen naar de VS om te studeren en te sporten op collegenniveau. Deze atleten brengen hun eigen talent, stijl en cultuur mee naar de Amerikaanse universiteiten. Ze verrijken de atletiekgemeenschap met hun perspectieven en ervaringen.
<br/>
<br/>
Buitenlandse atleten hebben ook een grote impact op de resultaten en de ranglijsten van atletiek op collegenniveau. Veel buitenlandse atleten behoren tot de beste atleten in hun onderdeel, en winnen vaak medailles, titels en records. Sommige buitenlandse atleten zijn zelfs zo goed dat ze de Amerikaanse atleten overtreffen of uitdagen.
<br/>
<br/>
Enkele voorbeelden van succesvolle buitenlandse atleten in de Amerikaanse universitaire competities zijn:
                </MKTypography>
                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
Andre De Grasse: een Canadese sprinter, excelleerde bij Coffeyville Community College en de University of Southern California (USC), waar hij meerdere NJCAA- en NCAA-titels won op de sprintafstanden. Deze prestaties bij USC vestigden zijn reputatie als een vooraanstaande sprinter. Na zijn universitaire carrière won De Grasse meerdere Olympische medailles, waaronder goud op de 200 meter tijdens de Olympische Spelen van 2020 in Tokio, waarmee hij zich als een van de wereldtoppers in de sprint etaleerde.
</li>

<li>
Kerron Clement: Kerron Clement is een Trinidadiaans-Amerikaanse atleet die uitblinkt op de 400 meter horden. Hij studeerde aan de University of Florida, waar hij NCAA-titels won en zich ontwikkelde tot een wereldklasse hordeloper. Clement veroverde goud op de Olympische Spelen van 2008 en 2016 en heeft meerdere wereldkampioenschappen op zijn naam staan, waardoor hij een van de meest succesvolle hordelopers van zijn generatie is.
</li>
</ul>
</MKTypography>
<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
Buitenlandse atleten hebben niet alleen invloed op atletiek op collegenniveau, maar ook op andere internationale competities en evenementen. Veel buitenlandse atleten die op collegenniveau hebben gesport, nemen later ook deel aan de Olympische Spelen, de Wereldkampioenschappen, of andere regionale of continentale kampioenschappen. Ze vertegenwoordigen hun eigen land, maar hebben ook banden met hun Amerikaanse universiteit.
                </MKTypography>
              </MKBox>
              <MKBox id="4.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                4.2 Amerikaanse Atleten in het Buitenland
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Een andere manier waarop atletiek op collegenniveau internationaal is, is door de kansen en de uitdagingen die het biedt voor Amerikaanse atleten om in het buitenland te concurreren. Veel Amerikaanse atleten die op collegenniveau hebben gesport, krijgen de kans om hun horizon te verbreden en hun vaardigheden te testen tegen andere atleten uit verschillende landen en culturen.
<br/>
<br/>
Een van de belangrijkste kansen voor Amerikaanse atleten om in het buitenland te concurreren is natuurlijk de Olympische Spelen. De Olympische Spelen zijn het grootste en meest prestigieuze sportevenement ter wereld, waar duizenden atleten uit meer dan 200 landen samenkomen om te strijden voor goud, zilver en brons. De Olympische Spelen zijn ook een kans voor Amerikaanse atleten om hun land te vertegenwoordigen, hun patriottisme te tonen en hun nationale trots te delen.
<br/>
<br/>
Een ander belangrijk evenement voor Amerikaanse atleten om in het buitenland te concurreren is de Wereldkampioenschappen Atletiek. De Wereldkampioenschappen Atletiek zijn het belangrijkste atletiekevenement na de Olympische Spelen, waar de beste atleten ter wereld elkaar ontmoeten om te strijden voor de wereldtitel. De Wereldkampioenschappen Atletiek zijn ook een kans voor Amerikaanse atleten om hun niveau te meten, hun rivaliteit te voeden en hun reputatie te vestigen.
<br/>
<br/>
Naast deze grote evenementen zijn er ook andere kansen voor Amerikaanse atleten om in het buitenland te concurreren, zoals de Diamond League, de Continental Tour, de World Athletics Relays, of andere regionale of continentale kampioenschappen. Deze evenementen bieden Amerikaanse atleten de mogelijkheid om hun ervaring te vergroten, hun inkomsten te verhogen en hun fanbase uit te breiden.
<br/>
<br/>
Atletiek op collegenniveau speelt een belangrijke rol in de ontwikkeling en het succes van Amerikaanse atleten die in het buitenland concurreren. Atletiek op collegenniveau bereidt atleten voor op het internationale niveau, door hen een hoge kwaliteit van training, coaching en competitie te bieden. Atletiek op collegenniveau ondersteunt ook atleten die in het buitenland concurreren, door hen een netwerk van contacten, sponsors en fans te bieden.

                </MKTypography>
              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 5" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 5: Atletiekbeurzen en de Toekomst
                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              Als je een talentvolle atleet bent die graag wil sporten en studeren in Amerika, dan is een atletiekbeurs misschien wel de beste optie voor jou. Een atletiekbeurs is een financiële ondersteuning die je krijgt van een universiteit om je studiekosten te dekken, in ruil voor je deelname aan het atletiekteam. Met een atletiekbeurs kun je dus je sportieve en academische ambities combineren, terwijl je geniet van een unieke ervaring in het land van de onbegrensde mogelijkheden.
              </MKTypography>

              <MKBox id="5.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                5.1 Het Belang van Sportbeurzen
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Een sportbeurs is niet alleen een financieel voordeel, maar ook een erkenning van je talent en potentieel. Het betekent dat een universiteit in jou gelooft en bereid is om in jou te investeren. Met een sportbeurs krijg je toegang tot de beste faciliteiten, coaches, trainers en medische begeleiding die je nodig hebt om jezelf te ontwikkelen als atleet. Bovendien krijg je de kans om te concurreren op het hoogste niveau tegen andere topatleten uit de hele wereld.
<br/>
<br/>
Een sportbeurs is ook een academisch voordeel, want het stelt je in staat om te studeren aan een gerenommeerde universiteit in Amerika. Je kunt kiezen uit een breed scala aan opleidingen die aansluiten bij je interesses en doelen. Je krijgt ook academische begeleiding en ondersteuning om je studie te combineren met je sport. Met een sportbeurs kun je dus een waardevol diploma behalen dat je toekomstperspectieven zal vergroten.
<br/>
<br/>
Een sportbeurs is ten slotte ook een persoonlijk voordeel, want het biedt je de mogelijkheid om te groeien als mens. Je maakt deel uit van een hechte gemeenschap van student-atleten die elkaar steunen en motiveren. Je leert nieuwe culturen, talen en mensen kennen. Je doet onvergetelijke ervaringen en herinneringen op die je voor altijd zult koesteren.
                </MKTypography>
              </MKBox>
              <MKBox id="5.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                5.2 Hoe Sportbeurs Amerika NL Kan Helpen 
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
  <img
    src={Image4}
    alt="Logo van Sportbeurs Amerika NL, bestaande uit een voetbal met het continent Noord-Amerika erop afgebeeld, met daarboven een afstudeerhoed (graduation cap) die bovenop de bal rust alsof het een hoofd is. De naam van het bedrijf is prominent weergegeven, wat de focus op sportbeurzen in Amerika voor Europese studenten benadrukt."
    style={{
      maxWidth: "100%",
      height: "auto",
      border: "2px solid #fff",
      boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)", // Apply shadow around the entire image
      cursor: "pointer",
      outline: "none",
      borderRadius: "50%", // Set border-radius to 50% for a circular image
      marginTop: "0px", // Add top margin to move the image up


    }}
    onClick={() => openModal4(Image4)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
  
</figcaption>
</figure>
Sportbeurs Amerika NL is een organisatie gespecialiseerd in het begeleiden van jonge sporttalenten naar sportbeurzen in Amerika.  Met een gepassioneerde en ervaren expert aan het roer, ondersteunt Sportbeurs Amerika NL de student-atleet bij iedere stap in het traject, vanaf de eerste oriëntatie tot aan de uiteindelijke plaatsing. Sportbeurs Amerika NL biedt onder andere de volgende diensten aan:
                </MKTypography>

                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
Een persoonlijke intakegesprek om de wensen, mogelijkheden en verwachtingen van de student-atleet te bespreken.
</li>
<li>
Een professionele evaluatie van het sportieve en academische niveau van de student-atleet.
</li>
<li>
Een uitgebreid netwerk van contacten met Amerikaanse coaches, universiteiten en colleges.
</li>
<li>
Een effectieve promotie van het profiel en de video’s van de student-atleet bij potentiële coaches.
</li>
<li>
Een deskundig advies over de beste opties voor de student-atleet op basis van zijn of haar voorkeuren, doelen en budget.
</li>
<li>
Een onderhandeling over de beste beursaanbiedingen namens de student-atleet.
</li>
<li>
Een hulp bij het aanvragen van de benodigde documenten, zoals het visum, het transcript, het diploma en de toelatingstesten.
</li>
<li>
Een voorbereiding op het leven in Amerika, zoals de cultuur, de taal, de regels en de tips.
</li>
<li>
Een nazorg en een follow-up tijdens het verblijf in Amerika.</li>

</ul>
</MKTypography>

<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Wil jij ook je passie voor American football combineren met een goede opleiding in Amerika? <Link to="/Diensten-Producten">Bekijk het volledige aanbod</Link> van Sportbeurs Amerika NL en maak een vrijblijvende afspraak om je te laten informeren over de mogelijkheden. 
      Ik sta voor je klaar om je te helpen bij het realiseren van je Amerikaanse droom.
    </MKTypography>


              </MKBox>
            </MKBox>

          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;
import { useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import SimplePricingCard from "examples/Cards/PricingCards/SimplePricingCard";
import SimplePricingCardRibbon from "examples/Cards/PricingCards/SimplePricingCardRibbon";
import { PopupButton } from "react-calendly";
import { Link } from 'react-scroll';
import TextField from "@mui/material/TextField";  // Add this line
import Box from '@mui/material/Box'; // Ensure Box is imported
import emailjs from 'emailjs-com';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Link as RouterLink } from 'react-router-dom';







function Diensten() {
  const [activeTab, setActiveTab] = useState(0);
  const [tabType, setTabType] = useState("monthly");
  const [showModal, setShowModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [email, setEmail] = useState("");
  const [isAgreed, setIsAgreed] = useState(false);


  const toggleModal = () => setShowModal(!showModal);

  const handleTabType = ({ currentTarget }, newValue) => {
    setActiveTab(newValue);
    setTabType(currentTarget.id);
  };

  const handleEmailSubmit = async () => {
    if (email && isAgreed) {
      const emailParams = {
        from_email: email,
        // other parameters if needed
      };
  
      emailjs.send('service_sj82src', 'template_2eyxhno', emailParams, 'xOBnOFlYt964jvT3q')
        .then((response) => {
          console.log('Email sent successfully', response.status, response.text);
          alert('Link aangevraagd, controleer uw inbox.');
          setShowEmailModal(false);
          setEmail("");
          setIsAgreed(false);
        }, (error) => {
          console.log('Failed to send email', error);
          alert('Aanvraag mislukt, probeer nogmaals.');
        });
    } else if (!isAgreed) {
      alert('Gelieve akkoord te gaan met de privacyverklaring.');
    } else {
      alert('Voer alstublieft een e-mailadres in');
    }
  };


  

  return (
    <section id="dienstenSection">
      <Container sx={{ pb: { xs: 4, lg: 4 }, pt: 12 }}>
          {/* Email Input Modal */}
          <Modal
        open={showEmailModal}
        onClose={() => setShowEmailModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            width: { xs: '90%', sm: '70%', md: '50%', lg: '40%' },
            maxWidth: '500px',
            maxHeight: '90vh',
            overflowY: 'auto',
          }}
        >
          <TextField
            label="Email Address"
            variant="outlined"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{ mb: 2 }}
          />
          {/* Checkbox for privacy policy agreement */}
          <FormControlLabel
  control={<Checkbox checked={isAgreed} onChange={(e) => setIsAgreed(e.target.checked)} />}
  label={
    <span>
       Ik heb de{' '}
      <RouterLink 
        to="/Privacyverklaring" 
        component="a" 
        target="_blank" 
        style={{ textDecoration: 'none' }}>
         privacyverklaring
      </RouterLink> gelezen en ga ermee akkoord.
    </span>
  }
  sx={{ my: 2 }}
/>


          <MKButton variant="gradient" color="dark" onClick={handleEmailSubmit}>
            Verstuur
          </MKButton>
        </Box>
      </Modal>


      <Grid container item xs={12} justifyContent="center" md={8} mx="auto" textAlign="center">
      <Grid item xs={12}> {/* Full width for the heading */}
    <MKTypography variant="h3" color="text">Diensten</MKTypography>
  </Grid>
  <Grid item xs={12}> {/* Full width for the body text */}
    <MKTypography variant="body2" color="text">
Alle services die worden aangeboden door Sportbeurs Amerika NL   </MKTypography>
  </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={10}
          lg={8}
          justifyContent="center"
          textAlign="center"
          mx="auto"
          mt={6}
        >
          <AppBar position="static">
          <Tabs value={activeTab} onChange={handleTabType}>
  <Tab
    id="monthly"
    label={
      <Link 
        to="dienstenSection" 
        spy={true}
        smooth={true}
        duration={500}
        offset={-70}
        style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <MKBox py={0.5} px={2} color="inherit">
          Diensten
        </MKBox>
      </Link>
    }
  />
  <Tab
    id="annual"
    label={
      <Link 
        to="productenSection" 
        spy={true}
        smooth={true}
        duration={500}
        offset={-70}
        style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <MKBox py={0.5} px={2} color="inherit">
          Producten
        </MKBox>
      </Link>
    }
  />
</Tabs>
          </AppBar>
        </Grid>
        <Grid container spacing={3} mt={6}>
        <Modal open={showModal} onClose={toggleModal} sx={{ display: "grid", placeItems: "center" }}>
          <Slide direction="down" in={showModal} timeout={500}>
            <MKBox
              position="relative"
              width="500px"
              display="flex"
              flexDirection="column"
              borderRadius="xl"
              bgColor="white"
              shadow="xl"
            >
              <MKBox display="flex" alginItems="center" justifyContent="space-between" p={2}>
                <MKTypography variant="h5">
Kies voor een intakegesprek
</MKTypography>
                <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={toggleModal} />
              </MKBox>
              <Divider sx={{ my: 0 }} />
              <MKBox p={2}>
                <MKTypography variant="body2" color="secondary" fontWeight="regular">
                Beste klant,
                <br />
                  <br />

                  Ik ben verheugd dat u interesse heeft in mijn standaard service! Om ervoor te zorgen dat mijn service perfect aansluit op uw specifieke behoeften, raad ik ten zeerste aan om eerst een vrijblijvend intakegesprek te boeken.
                  <br />
                  <br />
                  Tijdens dit gesprek kunnen we uw situatie in detail bespreken en samen bepalen welke aanpak het beste bij u past. Dit zorgt voor een meer gepersonaliseerde ervaring en helpt mij om de service te optimaliseren voor uw unieke situatie.


                </MKTypography>
              </MKBox>
              <Divider sx={{ my: 0 }} />
              <MKBox display="flex" justifyContent="space-between" p={1.5}>
                <MKButton variant="gradient" color="dark" onClick={toggleModal}>
                  sluit
                </MKButton>
                
                <PopupButton
    url="https://calendly.com/sportbeurs-amerika/intake"
    rootElement={document.getElementById("root")}
    text="MAAK AFSPRAAK"
    className="custom-calendly-button"
  />
              </MKBox>
            </MKBox>
          </Slide>
        </Modal>
          <Grid item xs={12} sm={6} lg={3}>
            <SimplePricingCard
              color="dark"
              title="Informatiegids"
              description="Volledige informatiegids in PDF"
              price={{ value: "Gratis", type: "" }}
  action={{
    type: "downloadPDF",
    label: "Download PDF",
    onClick: () => setShowEmailModal(true) // Function to show email input modal
  }}              specifications={["Volledige informatiegids in PDF"]}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <SimplePricingCardRibbon
              color="dark"
              title="Intakegesprek"
              description="60 minuten online consult"
              price={{ value: " € 14,99*", type: "" }}
              action={{
                type: "external", // Change to external for Calendly
                route: "https://calendly.com/sportbeurs-amerika/intake", // Your Calendly link
                label: "MAAK AFSPRAAK"
              }}              specifications={[
                "Doelen en ambitie afstemmen",
                "Sportieve prestatiebeoordeling",
                "Academisch integratieplan",
                "Individuele trajectstrategie",
                "Succeskansenanalyse VS",
              
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <SimplePricingCard
              color="dark"
              title="Standaard"
              description="Jouw pad naar de VS"
              price={{ value: "Op Aanvraag", type: "" }}
              action={{
                type: "modal",
                label: "Vraag aan",
                onClick: toggleModal
              }}
              specifications={[
                "Op maat gemaakt plan",
                "Ondersteuning bij maken sportfolio",
                "Ondersteuning academische voorbereiding",
                "Promotie bij universiteiten",
                "Onderhandelingen sportbeurs",
                "Advies keuze universiteit sportbeurs",
                "Ondersteuning visumaanvraag",
                "Ondersteuning inschrijvingen",
                "Ondersteuning praktsiche zaken",
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
          <SimplePricingCard
              color="dark"
              title="Premium"
              description="Jouw pad naar de VS"
              price={{ value: "Op Aanvraag", type: "" }}
              action={{
                type: "modal",
                label: "Vraag aan",
                onClick: toggleModal
              }}
              specifications={[
                "Op maat gemaakt plan",
                "Ondersteuning bij maken sportfolio",
                "Ondersteuning academische voorbereiding",
                "Promotie bij universiteiten",
                "Onderhandelingen sportbeurs",
                "Advies keuze universiteit sportbeurs",
                "Ondersteuning visumaanvraag",
                "Ondersteuning inschrijvingen",
                "Ondersteuning praktsiche zaken",
                "Studieboeken SAT/ACT",
                "Tweewekelijkse Zoom-call"

                
              ]}
            />
          </Grid>
        </Grid>
      </Container>
      <Container>
    <Grid item xs={12} style={{ marginBottom: '80px' }}>
      <MKTypography variant="body2" color="text">
        {/* Your text goes here */}
        *Voorheen bood ik het intakegesprek gratis aan. Helaas leidde dit tot veel afspraken met niet-serieuze belangstellenden, wat kostbaar was in tijd. Om de kwaliteit van mijn diensten te waarborgen en te zorgen dat gesprekken waardevol zijn voor beide partijen, vraag ik nu een kleine vergoeding van €14,99. Dit bedrag helpt om serieuze aanvragen te filteren, terwijl het gesprek vrijblijvend en gericht op uw specifieke behoeften blijft.



Ik waardeer uw begrip en kijk uit naar een productief en inzichtelijk gesprek met u.
      </MKTypography>
    </Grid>
  </Container>
    
    </section>
  );
}



export default Diensten;

/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sporten_Images/Waterpol1.jpeg";
import Image2 from "assets/images/Sporten_Images/Waterpolo2.jpeg";
import Image3 from "assets/images/Sporten_Images/Waterpolo3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import "../../../../H1asH3.css"


// Images

import { Link } from 'react-router-dom';


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Waterpolobeurzen in Amerika: Hoe je kunt studeren en spelen in het land van de sterren en strepen', isSubheader: false },

    { id: 'Hoofdstuk 1', title: '1. Geschiedenis en Ontwikkeling van Waterpolo in Amerika', isSubheader: false },
    { id: '1.1', title: '1.1 Vroege Jaren', isSubheader: true },
    { id: '1.2', title: '1.2 Groei en Professionalisering', isSubheader: true },

    { id: 'Hoofdstuk 2', title: '2. Universitair Waterpolo Vandaag de Dag', isSubheader: false },
    { id: '2.1', title: '2.1 Belangrijkste Competities en Conferenties', isSubheader: true },
    { id: '2.2', title: '2.2 Topprogramma’s en Prestigieuze Teams', isSubheader: true },

    { id: 'Hoofdstuk 3', title: '3: Van College naar de Profs', isSubheader: false },
    { id: '3.1', title: '3.1 Het Pad naar Professionalisme', isSubheader: true },
    { id: '3.2', title: '3.2 Professionele Competities', isSubheader: true },


    { id: 'Hoofdstuk 4', title: '4. Internationale Invloeden en Competities', isSubheader: false },
    { id: '4.1', title: '4.1 Buitenlandse Invloeden', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikanen in Internationale Competities', isSubheader: true },

    { id: 'Hoofdstuk 5', title: '5. Waterpolobeurzen en de Toekomst', isSubheader: false },
    { id: '5.1', title: '5.1 Het Belang van Beurzen', isSubheader: true },
    { id: '5.2', title: '5.2 Hoe Sportbeurs Amerika NL Kan Helpen', isSubheader: true },

  ];

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Inhoud
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Verberg</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Toon</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
            <MKBox id="intro" mb={3}>
            <MKTypography variant="h1" className="h1-as-h3" mb={2}>
              Waterpolobeurzen in Amerika: Hoe je kunt studeren en spelen in het land van de sterren en strepen

              </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
  <img
    src={Image1}
    alt="Stadion vol met fans in afwachting van een college waterpolowedstrijd."
    style={{
      maxWidth: "100%",
      border: "2px solid #fff",
      boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
      cursor: "pointer",
      outline: "none",
    }}
    onClick={() => openModal1(Image1)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
  Kalmte voorafgaand aan een wedstrijd
</figcaption>
</figure>
              Waterpolo is ontstaan in Europa in de late 19e eeuw als een vorm van rugby in het water. Het werd al snel populair in verschillende landen, waaronder Amerika. De eerste officiële wedstrijd in Amerika werd gespeeld in 1888 tussen twee clubs uit New York. Sindsdien heeft waterpolo zich enorm ontwikkeld in Amerika, zowel op professioneel als op universitair niveau.
<br/>
<br/>
Universitair waterpolo is een van de belangrijkste factoren voor de ontwikkeling van waterpolospelers in Amerika. Veel spelers beginnen hun carrière op de middelbare school en gaan dan verder naar de universiteit, waar ze de kans krijgen om te spelen tegen de beste teams en spelers van het land. Universitair waterpolo biedt ook een uitstekende academische opleiding, waardoor spelers hun passie voor de sport kunnen combineren met hun educatieve doelen.
<br/>
<br/>
Maar hoe kun je als Nederlander studeren en spelen in Amerika? Dat is waar Sportbeursamerika.nl je kan helpen. Sportbeursamerika.nl is een organisatie die gespecialiseerd is in het helpen van studenten om sportbeurzen te verkrijgen voor waterpolo in Amerika. Sportbeursamerika.nl heeft een team van experts die je kunnen begeleiden bij elke stap van het proces, van het vinden van de juiste universiteit tot het regelen van alle praktische zaken.
<br/>
<br/>
Als je geïnteresseerd bent in het verkrijgen van een waterpolobeurzen in Amerika, lees dan verder om meer te weten te komen over deze spannende mogelijkheid.
              </MKTypography>
              {/* Add bulleted list or other content as needed */}
            </MKBox>
             {/* Main chapters and subchapters with placeholder text */}
            <MKBox id="Hoofdstuk 1" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 1: Geschiedenis en Ontwikkeling van Waterpolo in Amerika
              </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              Waterpolo heeft een lange en rijke geschiedenis in Amerika. Het is een van de oudste Olympische sporten, die voor het eerst werd gespeeld in de Olympische Spelen van 1900 in Parijs, waar Amerika ook aan deelnam. Sindsdien heeft Amerika deelgenomen aan elke Olympische waterpolocompetitie, behalve in 1976 en 1980, toen het zich terugtrok uit protest tegen de politieke situatie in respectievelijk Zuid-Afrika en de Sovjet-Unie.
              </MKTypography>

              <MKBox id="1.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                1.1 Vroege Jaren                </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                De eerste waterpoloclubs in Amerika werden opgericht in New York en Boston in de late 19e eeuw. Ze speelden volgens de Engelse regels, die veel ruwer en gewelddadiger waren dan de moderne regels. De sport werd al snel populair onder de immigranten uit Europa, vooral uit Ierland en Schotland. De eerste nationale kampioenschap werd gehouden in 1890, en werd gewonnen door de Sydenham Swimmers Club.
<br/>
<br/>
De eerste universiteitsteams werden gevormd aan het begin van de 20e eeuw, vooral aan de oostkust. De Ivy League-universiteiten, zoals Harvard, Yale en Princeton, domineerden de sport tot de jaren 1920. De eerste intercollegiale competitie werd georganiseerd in 1912, en werd gewonnen door Princeton.
                </MKTypography>
              </MKBox>
              <MKBox id="1.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                1.2 Groei en Professionalisering
                </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                In de jaren 1920 begon waterpolo zich te verspreiden naar andere delen van het land, vooral naar Californië. Daar ontwikkelde zich een nieuwe stijl van spelen, die meer gericht was op snelheid, behendigheid en schieten dan op fysiek contact. De Californische teams werden al snel de beste van het land, en wonnen de meeste nationale en intercollegiale titels.
<br/>
<br/>
In de jaren 1930 ontstonden de eerste professionele competities in Amerika, zoals de American Water Polo League en de Pacific Coast Water Polo League. Deze competities trokken veel toeschouwers en media-aandacht, en verhoogden het niveau en de populariteit van de sport. Veel professionele spelers waren ook universitaire spelers of afgestudeerden, die hun carrière voortzetten na hun studie.
<br/>
<br/>
Een van de belangrijkste figuren in de geschiedenis van het Amerikaanse waterpolo was James “Jimmy” Smith, die wordt beschouwd als de “vader van het moderne waterpolo”. Hij was een speler, coach, scheidsrechter, organisator en promotor van de sport. Hij speelde voor verschillende clubs en universiteiten, waaronder UCLA, waar hij later ook coach werd. Hij leidde het Amerikaanse team naar twee Olympische bronzen medailles, in 1924 en 1932. Hij was ook een van de oprichters van de International Swimming Hall of Fame, waar hij werd ingewijd in 1965.
                </MKTypography>
              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 2" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 2: Universitair Waterpolo Vandaag de Dag
                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              Universitair waterpolo is een van de meest prestigieuze en competitieve sporten in Amerika. Het biedt een unieke kans voor studenten om hun academische en sportieve ambities te combineren, en om zich te meten met de beste spelers van het land. Universitair waterpolo is ook een belangrijke bron van talent voor het nationale team en de professionele competities.
              </MKTypography>

              <MKBox id="2.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                2.1 Belangrijkste Competities en Conferenties
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
  <img
    src={Image2}
    alt="Actie in het water: speelster schiet op doel tijdens een waterpolowedstrijd."
    style={{
      maxWidth: "100%",
      border: "2px solid #fff",
      boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
      cursor: "pointer",
      outline: "none",
    }}
    onClick={() => openModal2(Image2)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
  Speelsters in actie
</figcaption>
</figure>
                Universitair waterpolo wordt gereguleerd door de National Collegiate Athletic Association (NCAA), die de sport verdeelt in drie divisies op basis van het niveau en het aantal beurzen dat beschikbaar is. Divisie I is de hoogste divisie, die bestaat uit ongeveer 30 teams, voornamelijk uit Californië. Divisie II en III hebben elk ongeveer 20 teams, die meer verspreid zijn over het land.
<br/>
<br/>
Elk jaar wordt er een nationaal kampioenschap gehouden voor elke divisie, waarbij de beste teams uit elke conferentie strijden om de titel. Een conferentie is een groep van universiteiten die geografisch of historisch verbonden zijn, en die regelmatig tegen elkaar spelen. De belangrijkste conferenties voor waterpolo zijn:
                </MKTypography>
                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
De Mountain Pacific Sports Federation (MPSF), die bestaat uit acht teams uit Californië, waaronder UCLA, Stanford, USC en Berkeley. Deze conferentie wordt beschouwd als de sterkste en meest prestigieuze in het land, en heeft de meeste nationale titels gewonnen.
</li>
<li>
De Western Water Polo Association (WWPA), die bestaat uit negen teams uit Californië, Colorado, Utah en Hawaï. Deze conferentie is de tweede sterkste in het land, en heeft ook enkele nationale titels gewonnen.
</li>
<li>
De Collegiate Water Polo Association (CWPA), die bestaat uit 18 teams uit de oostkust, waaronder Harvard, Princeton, Brown en Navy. Deze conferentie is de derde sterkste in het land, en heeft af en toe een nationale titel gewonnen.
</li>
<li>
De Southern California Intercollegiate Athletic Conference (SCIAC), die bestaat uit negen teams uit Zuid-Californië, waaronder Pomona-Pitzer, Claremont-Mudd-Scripps en Occidental. Deze conferentie is de vierde sterkste in het land, en speelt voornamelijk in Divisie III.
</li>
</ul>
</MKTypography>


              </MKBox>
              <MKBox id="2.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                2.2 Topprogramma’s en Prestigieuze Teams
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Universitair waterpolo heeft een aantal toonaangevende programma’s die bekend staan om hun uitstekende prestaties, zowel op sportief als op academisch gebied. Deze programma’s trekken veel talentvolle spelers aan, die profiteren van de hoogwaardige faciliteiten, coaching en onderwijs die ze bieden. Sommige van deze programma’s zijn:
                </MKTypography>
                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
De Universiteit van Californië in Los Angeles (UCLA) is bekend om haar uitzonderlijke waterpoloprogramma, dat tot de meest succesvolle in de geschiedenis behoort. Met een indrukwekkend aantal nationale titels voor zowel mannen- als vrouwenteams, heeft het programma een prominente plek in de sport. De wedstrijden worden gespeeld in het Spieker Aquatics Center, met een capaciteit van 2.500 toeschouwers. Het UCLA-team is beroemd om zijn snelle en dynamische speelstijl en heeft een aanzienlijk aantal Olympische medaillewinnaars voortgebracht.

</li>
<li>
De Universiteit van Stanford beschikt ook over een prestigieus waterpoloprogramma met talrijke nationale titels voor zowel de mannen- als de vrouwenteams. De teams spelen hun thuiswedstrijden in het Avery Aquatic Center, dat ruimte biedt aan 2.530 toeschouwers. Stanford's waterpoloteams staan bekend om hun technische en tactische vaardigheden en hebben ook bijgedragen aan de vorming van vele Olympische medaillewinnaars.

</li>
<li>
De Universiteit van Zuid-Californië (USC) heeft eveneens een sterk waterpoloprogramma met meerdere nationale kampioenschappen voor zowel mannen als vrouwen. Hun thuisbasis is het Uytengsu Aquatics Center, met een capaciteit voor 2.500 toeschouwers. Het USC-team kenmerkt zich door een fysieke en agressieve speelstijl en heeft ook een significant aantal Olympische medaillewinnaars opgeleid.





</li>

</ul>
</MKTypography>



              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 3" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 3: Van College naar de Profs
                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              Universitair waterpolo is niet alleen een geweldige manier om te studeren en te spelen in Amerika, maar ook een mogelijke opstap naar een professionele carrière. Veel universitaire spelers gaan na hun afstuderen door naar de professionele competities, zowel in Amerika als in het buitenland. Sommigen van hen worden ook geselecteerd voor het nationale team, dat deelneemt aan internationale toernooien zoals de Olympische Spelen, de Wereldkampioenschappen en de World League.
              </MKTypography>

              <MKBox id="3.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                3.1 Het Pad naar Professionalisme
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Hoewel waterpolo een populaire en competitieve sport is in Amerika, is het nog steeds niet zo commercieel en lucratief als sommige andere sporten, zoals basketbal, voetbal of honkbal. Daarom zijn er niet veel professionele competities of teams in Amerika, en zijn de salarissen en prijzengelden relatief laag. De meeste professionele spelers hebben ook een andere baan of bron van inkomsten naast hun waterpolocarrière.
<br/>
<br/>
De belangrijkste professionele competitie in Amerika is de National Water Polo League (NWPL), die werd opgericht in 2018 als een opvolger van de American Water Polo League (AWPL). De NWPL bestaat uit acht teams, die elk bestaan uit 15 spelers. De teams spelen een regulier seizoen van 14 wedstrijden, gevolgd door een play-off toernooi om de kampioen te bepalen. De huidige kampioen is de Los Angeles Water Polo Club, die de titel won in 2022.
<br/>
<br/>
De meeste spelers in de NWPL zijn voormalige universitaire spelers of afgestudeerden, die hun carrière voortzetten na hun studie. Sommigen van hen zijn ook leden van het nationale team, dat regelmatig traint en speelt in het Olympic Training Center in Colorado Springs. Het nationale team wordt gecoacht door Dejan Udovicic, een voormalige Servische speler en coach, die sinds 2013 aan het roer staat.
<br/>
<br/>
Een van de meest succesvolle alumni van het universitaire waterpolo is Tony Azevedo, die wordt beschouwd als een van de beste spelers aller tijden. Hij speelde voor Stanford University, waar hij vier keer werd uitgeroepen tot NCAA Player of the Year. Hij speelde ook voor verschillende professionele teams in Europa en Amerika, waaronder Brescia (Italië), Olympiacos (Griekenland) en Long Beach Shore (Amerika). Hij speelde ook voor het nationale team in vijf Olympische Spelen, waar hij een zilveren medaille won in 2008. Hij wordt ook wel “The Savior” genoemd, omdat hij vaak beslissende doelpunten scoorde voor zijn team.
                </MKTypography>
              </MKBox>
              <MKBox id="3.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                3.2 Professionele Competities
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Naast de NWPL zijn er ook andere professionele competities in Amerika, zoals de Major League Water Polo (MLWP) en de Premier Water Polo League (PWPL). Deze competities zijn echter minder gevestigd en minder populair dan de NWPL, en hebben minder teams en spelers. Ze worden ook niet erkend door de USA Water Polo, de nationale federatie die verantwoordelijk is voor het organiseren en reguleren van waterpolo in Amerika.
<br/>
<br/>
Veel Amerikaanse spelers kiezen er daarom voor om hun professionele carrière voort te zetten in het buitenland, waar er meer mogelijkheden en betere voorwaarden zijn. Europa is de belangrijkste bestemming voor Amerikaanse waterpolospelers, omdat het continent beschikt over enkele van de sterkste en meest prestigieuze competities ter wereld. Sommige van deze competities zijn:
                </MKTypography>

                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
De LEN Champions League, die wordt beschouwd als de hoogste clubcompetitie in Europa. Het bestaat uit 16 teams uit verschillende landen, die strijden om de Europese titel. De huidige kampioen is Pro Recco (Italië), die de titel won in 2021.
</li>
<li>
De LEN Euro Cup, die wordt beschouwd als de tweede clubcompetitie in Europa. Het bestaat uit 32 teams uit verschillende landen, die strijden om een plaats in de Champions League. De huidige kampioen is Orvosegyetem SC (Hongarije), die de titel won in 2021.
</li>
<li>
De nationale competities, die worden georganiseerd door de respectievelijke federaties van elk land. Sommige van de sterkste en meest populaire nationale competities zijn de Serie A1 (Italië), de Liga Premaat (Spanje), de A1 Ethniki (Griekenland) en de OB I (Hongarije).
</li>

</ul>
</MKTypography>

              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 4" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 4: Internationale Invloeden en Competities
                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              Waterpolo is een wereldwijde sport, die wordt gespeeld en gevolgd door miljoenen mensen over de hele wereld. Het is ook een sport die voortdurend evolueert en innoveert, dankzij de invloed van verschillende culturen, stijlen en tradities. Amerikaans waterpolo is geen uitzondering, want het heeft veel geleerd en geprofiteerd van het internationale waterpolo, zowel op club- als op nationaal niveau.              </MKTypography>

              <MKBox id="4.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                4.1 Buitenlandse Invloeden
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
  <img
    src={Image3}
    alt="NCAA waterpolo kampioenschapswedstrijd"
    style={{
      maxWidth: "100%",
      border: "2px solid #fff",
      boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
      cursor: "pointer",
      outline: "none",
    }}
    onClick={() => openModal3(Image3)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
  Een wedstrijd om het NCAA kampioenschap
</figcaption>
</figure>
                Zoals we al hebben gezien, is waterpolo ontstaan in Europa, en heeft het zich van daaruit verspreid naar andere continenten, waaronder Amerika. Daarom heeft het Europese waterpolo een grote invloed gehad op het Amerikaanse waterpolo, vooral in termen van techniek, tactiek en strategie. Veel Amerikaanse spelers en coaches hebben hun vaardigheden en kennis verbeterd door te spelen of te trainen in Europa, of door te leren van Europese spelers en coaches.
<br/>
<br/>
Een van de meest invloedrijke Europese landen voor het Amerikaanse waterpolo is Hongarije, dat wordt beschouwd als de meest succesvolle waterpolonatie ter wereld. Hongarije heeft 15 Olympische gouden medailles gewonnen, meer dan elk ander land. Hongarije staat bekend om zijn creatieve en intelligente speelstijl, die gebaseerd is op een sterke balbehandeling, een snelle passing en een nauwkeurig schot. Veel Hongaarse spelers en coaches hebben hun stempel gedrukt op het Amerikaanse waterpolo, zoals Tibor Benedek, Zoltan Szecsi en Attila Banhidy.
<br/>
<br/>
Een ander invloedrijk Europees land voor het Amerikaanse waterpolo is Servië, dat ook een van de toonaangevende waterpolonaties ter wereld is. Servië heeft 7 Olympische gouden medailles gewonnen, waarvan de laatste in 2016. Servië staat bekend om zijn fysieke en agressieve speelstijl, die gebaseerd is op een sterke verdediging, een snelle tegenaanval en een krachtig schot. Veel Servische spelers en coaches hebben hun ervaring en expertise gedeeld met het Amerikaanse waterpolo, zoals Dejan Udovicic, Andrija Prlainovic en Filip Filipovic.
                </MKTypography>
              </MKBox>
              <MKBox id="4.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                4.2 Amerikanen in Internationale Competities
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Naast het leren van het internationale waterpolo, heeft het Amerikaanse waterpolo ook veel bijgedragen aan het internationale waterpolo, vooral op het gebied van prestaties, innovatie en promotie. Veel Amerikaanse spelers en coaches hebben deelgenomen aan internationale competities, zowel op club- als op nationaal niveau, waar ze hun talenten hebben laten zien en hun reputatie hebben versterkt.
<br/>
<br/>
Een van de belangrijkste internationale competities voor waterpolo is de Olympische Spelen, die om de vier jaar worden gehouden. Het Amerikaanse team heeft altijd meegedaan aan deze competitie, behalve in 1976 en 1980. Het Amerikaanse team heeft in totaal 12 Olympische medailles gewonnen: 3 goud, 4 zilver en 5 brons. De laatste medaille was een zilveren medaille voor de mannen in 2008. Het Amerikaanse team staat momenteel op de vierde plaats in de wereldranglijst.
<br/>
<br/>
Een andere belangrijke internationale competitie voor waterpolo is de Wereldkampioenschappen, die om de twee jaar worden gehouden. Het Amerikaanse team heeft ook altijd meegedaan aan deze competitie sinds de eerste editie in 1973. Het Amerikaanse team heeft in totaal 9 wereldkampioenschapsmedailles gewonnen: 2 goud, 3 zilver en 4 brons. De laatste medaille was een bronzen medaille voor de vrouwen in 2019. Het Amerikaanse team staat momenteel op de derde plaats in de wereldranglijst.
<br/>
<br/>
Een derde belangrijke internationale competitie voor waterpolo is de World League, die elk jaar wordt gehouden. Het Amerikaanse team heeft ook altijd meegedaan aan deze competitie sinds de eerste editie in 2002. Het Amerikaanse team heeft in totaal 10 World League-medailles gewonnen: 3 goud, 4 zilver en 3 brons. De laatste medaille was een gouden medaille voor de vrouwen in 2021. Het Amerikaanse team staat momenteel op de tweede plaats in de wereldranglijst.

                </MKTypography>
              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 5" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 5: Waterpolobeurzen en de Toekomst
                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              Waterpolo is een sport die veel vraagt van de spelers, zowel fysiek als mentaal. Het is ook een sport die veel kansen biedt voor de spelers, zowel op sportief als op academisch gebied. Een van de grootste kansen is het verkrijgen van een waterpolobeurzen om te studeren en te spelen aan een Amerikaanse universiteit. In dit hoofdstuk zullen we het belang van deze beurzen bespreken, en hoe Sportbeursamerika.nl je kan helpen om deze droom waar te maken.
              </MKTypography>

              <MKBox id="5.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                5.1 Het Belang van Beurzen 
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Een waterpolobeurzen is een financiële ondersteuning die een universiteit biedt aan een student-atleet om zijn of haar studie en sport te combineren. Een beurs kan variëren van een gedeeltelijke vergoeding van het collegegeld tot een volledige dekking van alle kosten, inclusief huisvesting, boeken, eten en reizen. Een beurs is dus niet alleen een financieel voordeel, maar ook een erkenning van je talent en potentieel.
<br/>
<br/>
Een waterpolobeurzen helpt je om jezelf te ontwikkelen als speler en als persoon. Je krijgt de kans om te trainen en te spelen op hoog niveau, onder begeleiding van professionele coaches en met toegang tot uitstekende faciliteiten. Je leert ook om te gaan met de uitdagingen en de druk die komen kijken bij het combineren van sport en studie. Je wordt onderdeel van een team en een gemeenschap die je steunen en motiveren. Je doet ook waardevolle ervaringen op in een andere cultuur en maakt internationale vrienden voor het leven.
<br/>
<br/>
Een waterpolobeurzen opent ook deuren voor je toekomst. Je kunt een diploma behalen dat internationaal erkend wordt en je kansen op de arbeidsmarkt vergroot. Je kunt ook doorgroeien naar een professionele carrière in waterpolo, of in aanmerking komen voor nationale of internationale selecties. Een waterpolobeurzen is dus niet alleen een investering in je huidige situatie, maar ook in je toekomstige mogelijkheden.
                </MKTypography>
              </MKBox>
              <MKBox id="5.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                5.2 Hoe Sportbeurs Amerika NL Kan Helpen 
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
  <img
    src={Image4}
    alt="Logo van Sportbeurs Amerika NL, bestaande uit een voetbal met het continent Noord-Amerika erop afgebeeld, met daarboven een afstudeerhoed (graduation cap) die bovenop de bal rust alsof het een hoofd is. De naam van het bedrijf is prominent weergegeven, wat de focus op sportbeurzen in Amerika voor Europese studenten benadrukt."
    style={{
      maxWidth: "100%",
      height: "auto",
      border: "2px solid #fff",
      boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)", // Apply shadow around the entire image
      cursor: "pointer",
      outline: "none",
      borderRadius: "50%", // Set border-radius to 50% for a circular image
      marginTop: "0px", // Add top margin to move the image up


    }}
    onClick={() => openModal4(Image4)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
  
</figcaption>
</figure>
Sportbeurs Amerika NL is een organisatie gespecialiseerd in het begeleiden van jonge sporttalenten naar sportbeurzen in Amerika.  Met een gepassioneerde en ervaren expert aan het roer, ondersteunt Sportbeurs Amerika NL de student-atleet bij iedere stap in het traject, vanaf de eerste oriëntatie tot aan de uiteindelijke plaatsing. Sportbeurs Amerika NL biedt onder andere de volgende diensten aan:
                </MKTypography>

                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
Een persoonlijke intakegesprek om de wensen, mogelijkheden en verwachtingen van de student-atleet te bespreken.
</li>
<li>
Een professionele evaluatie van het sportieve en academische niveau van de student-atleet.
</li>
<li>
Een uitgebreid netwerk van contacten met Amerikaanse coaches, universiteiten en colleges.
</li>
<li>
Een effectieve promotie van het profiel en de video’s van de student-atleet bij potentiële coaches.
</li>
<li>
Een deskundig advies over de beste opties voor de student-atleet op basis van zijn of haar voorkeuren, doelen en budget.
</li>
<li>
Een onderhandeling over de beste beursaanbiedingen namens de student-atleet.
</li>
<li>
Een hulp bij het aanvragen van de benodigde documenten, zoals het visum, het transcript, het diploma en de toelatingstesten.
</li>
<li>
Een voorbereiding op het leven in Amerika, zoals de cultuur, de taal, de regels en de tips.
</li>
<li>
Een nazorg en een follow-up tijdens het verblijf in Amerika.</li>

</ul>
</MKTypography>

<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Wil jij ook je passie voor American football combineren met een goede opleiding in Amerika? <Link to="/Diensten-Producten">Bekijk het volledige aanbod</Link> van Sportbeurs Amerika NL en maak een vrijblijvende afspraak om je te laten informeren over de mogelijkheden. 
      Ik sta voor je klaar om je te helpen bij het realiseren van je Amerikaanse droom.
    </MKTypography>


              </MKBox>
            </MKBox>

          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;
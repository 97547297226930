/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sporten_Images/Roeien1.jpg";
import Image2 from "assets/images/Sporten_Images/Roeien2.jpg";
import Image3 from "assets/images/Sporten_Images/Roeien3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";



// Images

import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Studeren en Roeien in Amerika: Een Unieke Kans voor Jonge Roeiers', isSubheader: false },

    { id: 'Hoofdstuk 1', title: '1. De Geschiedenis en Ontwikkeling van Roeien in Amerika', isSubheader: false },
    { id: '1.1', title: '1.1 Vroege Jaren en de Rol van Colleges', isSubheader: true },
    { id: '1.2', title: '1.2 Groei en Tradities', isSubheader: true },

    { id: 'Hoofdstuk 2', title: '2. Roeien op Collegenniveau Vandaag de Dag', isSubheader: false },
    { id: '2.1', title: '2.1 Belangrijke Competities en Conferenties', isSubheader: true },
    { id: '2.2', title: '2.2 Toonaangevende Programma’s en Teams', isSubheader: true },

    { id: 'Hoofdstuk 3', title: '3: Van College naar de Elite', isSubheader: false },
    { id: '3.1', title: '3.1 Het Pad naar de Top', isSubheader: true },
    { id: '3.2', title: '3.2 Professionele en Internationale Kansen', isSubheader: true },


    { id: 'Hoofdstuk 4', title: '4. Internationale Invloeden en Diversiteit', isSubheader: false },
    { id: '4.1', title: '4.1 Internationale Atleten in het College Roeien', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikaanse Roeiers in het Buitenland', isSubheader: true },

    { id: 'Hoofdstuk 5', title: '5. Roeibeurzen en Toekomstige Kansen', isSubheader: false },
    { id: '5.1', title: '5.1 Het Belang van Sportbeurzen', isSubheader: true },
    { id: '5.2', title: '5.2 Hoe Sportbeurs Amerika NL Kan Helpen', isSubheader: true },

  ];

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };


  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Inhoud
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Verberg</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Toon</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
            <MKBox id="intro" mb={3}>
            <MKTypography variant="h1" className="h1-as-h3" mb={2}>
              Studeren en Roeien in Amerika: Een Unieke Kans voor Jonge Roeiers
              </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
  <img
    src={Image1}
    alt="Roeisters in actie tijdens het nationale college roeikampioenschap."
    style={{
      maxWidth: "100%",
      border: "2px solid #fff",
      boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
      cursor: "pointer",
      outline: "none",
    }}
    onClick={() => openModal1(Image1)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
  Roeisters in actie tijdens het nationale kampioenschap
</figcaption>
</figure>
              Roeien is een van de oudste en meest prestigieuze sporten in de Verenigde Staten. Al sinds de 19e eeuw wordt er op hoog niveau geroeid op Amerikaanse universiteiten, waar roeiers niet alleen hun fysieke en mentale kracht ontwikkelen, maar ook hun academische en sociale vaardigheden. Studeren en roeien in Amerika is een unieke kans voor jonge roeiers die hun sportieve dromen willen waarmaken, maar ook een waardevolle opleiding willen volgen en een onvergetelijke ervaring willen beleven. In dit artikel vertellen we je alles wat je moet weten over roeien in Amerika, van de geschiedenis en tradities tot de kansen en uitdagingen. Ook leggen we uit hoe Sportbeursamerika.nl je kan helpen om een roeibeurs te bemachtigen en je voor te bereiden op je avontuur.
              </MKTypography>
              {/* Add bulleted list or other content as needed */}
            </MKBox>
             {/* Main chapters and subchapters with placeholder text */}
            <MKBox id="Hoofdstuk 1" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 1: De Geschiedenis en Ontwikkeling van Roeien in Amerika
              </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              In dit hoofdstuk duiken we in de fascinerende geschiedenis van roeien in de Verenigde Staten. We verkennen hoe deze sport zich vanuit Engeland over de oceaan verspreidde en uitgroeide tot een integraal onderdeel van de Amerikaanse universitaire cultuur. Van de eerste roeiwedstrijden op Lake Winnipesaukee tot de iconische rivaliteiten tussen prestigieuze universiteiten, we onderzoeken de ontwikkeling van roeien als een sport die zowel fysieke behendigheid als academische trots belichaamt.

</MKTypography>

              <MKBox id="1.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                1.1 De Vroege Jaren en Rol van Colleges
                </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Roeien als sport ontstond in Engeland in de 18e eeuw, toen er wedstrijden werden gehouden tussen professionele roeiers op de Theems. Deze wedstrijden trokken veel publiek en weddenschappen, waardoor roeien al snel populair werd. In de 18e en 19e eeuw verspreidde roeien zich naar andere landen, waaronder de Verenigde Staten. De eerste Amerikaanse roeiwedstrijd vond plaats in 1843 op Lake Winnipesaukee in New Hampshire1. In 1852 werd de eerste intercollegiate roeiwedstrijd gehouden tussen Harvard en Yale op Lake Winnipesaukee. Dit was tevens de eerste intercollegiate sportwedstrijd in de VS. Sindsdien is roeien een belangrijk onderdeel geworden van het Amerikaanse college sportlandschap.
<br/>
<br/>
De rol van colleges bij het bevorderen van roeien was cruciaal. Colleges zagen roeien als een manier om hun studenten discipline, teamwork en leiderschap bij te brengen, maar ook om hun prestige en reputatie te vergroten. Colleges investeerden daarom veel geld en middelen in het bouwen van botenhuizen, het aanschaffen van boten en het aantrekken van coaches. Ook organiseerden ze regelmatig wedstrijden tegen andere colleges, zowel regionaal als nationaal. Deze wedstrijden werden vaak druk bezocht door studenten, alumni, media en sponsors, waardoor er een grote belangstelling en betrokkenheid ontstond voor de sport.
                </MKTypography>
              </MKBox>
              <MKBox id="1.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                1.2 Groei en Tradities
                </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Roeien groeide gestaag in populariteit en prestige in de tweede helft van de 19e eeuw en de eerste helft van de 20e eeuw. Er ontstonden verschillende regionale en nationale competities, zoals de Eastern Sprints, de IRA National Championship Regatta, de Dad Vail Regatta en de Head of the Charles Regatta3. Ook namen Amerikaanse roeiers deel aan internationale evenementen, zoals de Henley Royal Regatta in Engeland en de Olympische Spelen. De Amerikaanse roeiers behaalden vaak indrukwekkende resultaten, zoals het winnen van goud op acht opeenvolgende Olympische Spelen tussen 1920 en 19564.
<br/>
<br/>
Roeien ontwikkelde ook een rijke cultuur en traditie op Amerikaanse colleges. Elke universiteit had zijn eigen kleuren, mascotte, liederen en rituelen die verbonden waren met het roeien. Sommige tradities waren ludiek of symbolisch, zoals het gooien van de stuurman in het water na een overwinning of het dragen van een speciale stropdas of daspeld. Andere tradities waren serieus of ceremonieel, zoals het zingen van het alma mater lied of het houden van een herdenking voor overleden roeiers. Deze tradities droegen bij aan het gevoel van trots, saamhorigheid en identiteit onder de roeiers.
                </MKTypography>
              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 2" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 2: Roeien op Collegenniveau Vandaag de Dag
                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              In dit hoofdstuk richten we ons op het hedendaagse roeien op universitair niveau in Amerika. We bekijken hoe de sport zich heeft ontwikkeld tot een hoog competitief en prestigieus onderdeel van het universitaire sportlandschap. Hier bespreken we de belangrijkste competities en conferenties, de structuur van de NCAA-divisies, en werpen we een blik op enkele van de meest toonaangevende universitaire roeiprogramma's. Dit deel belicht de huidige staat van roeien op Amerikaanse universiteiten en de unieke kansen die het biedt aan jonge atleten.




</MKTypography>

              <MKBox id="2.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                2.1 Belangrijke Competities en Conferenties
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
  <img
    src={Image2}
    alt="Stanford roeiteam viert het winnen van het nationale kampioenschap."
    style={{
      maxWidth: "100%",
      border: "2px solid #fff",
      boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
      cursor: "pointer",
      outline: "none",
    }}
    onClick={() => openModal2(Image2)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
  Roeisters van Stanford vieren het nationale kampioenschap
</figcaption>
</figure>
                Roeien op collegenniveau is vandaag de dag nog steeds een zeer competitieve en prestigieuze sport in de VS. Er zijn meer dan 300 universiteiten die roeiprogramma’s aanbieden, waarvan ongeveer 150 deelnemen aan de NCAA (National Collegiate Athletic Association), de overkoepelende organisatie voor college sporten. De NCAA verdeelt de universiteiten in drie divisies, gebaseerd op het aantal studenten, het budget en het niveau van de sport. Divisie I is de hoogste divisie, met de meeste en sterkste roeiprogramma’s. Divisie II en III hebben minder en kleinere roeiprogramma’s, maar zijn nog steeds zeer competitief.
<br/>
<br/>
Binnen elke divisie zijn er verschillende conferenties, die groepen van universiteiten zijn die geografisch of historisch verbonden zijn. Elke conferentie organiseert zijn eigen kampioenschap, waar de beste teams zich kwalificeren voor de nationale kampioenschappen. De belangrijkste conferenties voor roeien zijn onder andere de Big Ten, de Pac-12, de Ivy League, de ACC en de Big 12. Deze conferenties hebben vaak de meest succesvolle en invloedrijke roeiprogramma’s in het land.
<br/>
<br/>
Naast de conferentiekampioenschappen zijn er ook andere belangrijke nationale regatta’s waar universiteiten aan kunnen deelnemen. De meest prestigieuze is de NCAA Women’s Rowing Championship, die sinds 1997 wordt gehouden voor vrouwelijke roeiers in alle drie de divisies. Voor mannelijke roeiers is er geen equivalent NCAA kampioenschap, maar wel de IRA National Championship Regatta, die sinds 1895 wordt gehouden voor zowel varsity als lightweight teams. Andere bekende nationale regatta’s zijn onder andere de Dad Vail Regatta, de San Diego Crew Classic, de ACRA National Championship Regatta en de CRASH-B Sprints.
                </MKTypography>
              </MKBox>
              <MKBox id="2.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                2.2 Toonaangevende Programma’s en Teams
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Roeien op collegenniveau kent een lange geschiedenis van rivaliteit, excellentie en innovatie. Er zijn veel universiteiten die zich hebben onderscheiden door hun roeiprogramma’s, zowel op het gebied van prestaties als van ontwikkeling. Sommige van deze universiteiten zijn:
                </MKTypography>
                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
University of Washington: De Huskies staan bekend om hun krachtige en gerespecteerde roeiprogramma. Met meerdere nationale kampioenschappen in mannenroeien en een geschiedenis van het voortbrengen van Olympische medaillewinnaars, inclusief de legendarische “Boys in the Boat” die in 1936 goud wonnen. De Huskies worden gevierd voor hun rigoureuze training, sterke teamgeest en invloedrijke coaches zoals Al Ulbrickson.


</li>
<li>
Yale University: Als een van de pioniers van het intercollegiate roeien, hebben de Bulldogs van Yale een oud en prestigieus roeiprogramma. Hun geschiedenis omvat meerdere nationale titels en een aanzienlijke bijdrage aan het Amerikaanse roeien op Olympisch niveau. Yale is ook bekend om zijn academische excellentie en historische rivaliteit met Harvard.

</li>
<li>
University of California, Berkeley: De Golden Bears hebben een succesvol en veelzijdig roeiprogramma dat zich onderscheidt door hun nationale titels en Olympische deelnemers. Het programma staat bekend om zijn diversiteit, maatschappelijke betrokkenheid en toegewijde coaching."

</li>
</ul>
</MKTypography>



              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 3" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 3: Van College naar de Elite
                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>

              In dit hoofdstuk verkennen we de reis van college roeiers naar de elite van de roeiwereld. We onderzoeken hoe de ervaringen en vaardigheden die roeiers opdoen op universitair niveau hen voorbereiden op hogere niveaus van competitie, inclusief deelname aan nationale teams en de Olympische Spelen. Dit hoofdstuk belicht de overgang van het roeien op collegenniveau naar professionele en internationale podia, met aandacht voor de trajecten die atleten volgen, de uitdagingen die ze tegenkomen, en de succesverhalen van hen die de top bereiken. We bekijken ook hoe universitaire roeiprogramma's talenten identificeren en ontwikkelen, en hoe ze de weg banen voor toekomstige Olympiërs en wereldkampioenen.





</MKTypography>

              <MKBox id="3.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                3.1 Het Pad naar de Top
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Roeien op collegenniveau is niet alleen een sportieve uitdaging, maar ook een mogelijke opstap naar de elite van de roeiwereld. Veel roeiers die op college niveau hebben geroeid, maken de overstap naar nationale teams en Olympisch roeien. Dit komt omdat roeien op collegenniveau een uitstekende voorbereiding biedt op het gebied van techniek, tactiek, fysiek, mentaal en teamwork. Bovendien biedt roeien op collegenniveau de kans om op te vallen bij scouts en coaches van nationale bonden en selecties.
<br/>
<br/>
Er zijn talloze voorbeelden van roeiers die van college naar de elite zijn gegaan. Een recent voorbeeld van een roeier die na zijn collegecarrière een Olympische medaille heeft behaald, is Madison Mailey. Mailey, een All-American roeier aan Northeastern University, die in 2018 afstudeerde, won een gouden medaille op de Olympische Spelen in Tokyo in de vrouwen acht voor Canada. Ze en haar teamgenoten namen een vroege voorsprong en hielden een late inhaalpoging van Nieuw-Zeeland af, die net te kort kwam met 0,91 seconden​​.


                </MKTypography>
              </MKBox>
              <MKBox id="3.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                3.2 Professionele en Internationale Kansen
                  </MKTypography>
                  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                  Roeien op collegenniveau opent de deur naar professionele en internationale kansen voor roeiers. Naast het potentieel voor Olympische deelname, kunnen roeiers van universiteiten hun vaardigheden gebruiken om deel te nemen aan prestigieuze internationale regatta's zoals de Henley Royal Regatta of de World Rowing Cup. Deze evenementen bieden een platform voor roeiers om zich te meten met de wereldtop en ervaring op te doen in een internationale context.

<br/>
<br/>
Verder is er een pad naar professioneel roeien, waar afgestudeerde roeiers zich kunnen aansluiten bij professionele teams en deelnemen aan wereldwijde competities. Dit biedt een fulltime roeicarrière met intensieve training en de kans om te concurreren op het hoogste niveau.
<br/>
<br/>
Voor degenen die geïnteresseerd zijn in een carrière in coaching, biedt het roeien op collegenniveau een solide basis. Veel succesvolle roeicoaches zijn voormalige universitaire atleten, die hun ervaring en expertise gebruiken om nieuwe generaties roeiers te begeleiden. Deze carrièremogelijkheid kan leiden tot posities bij nationale teams en roeiclubs over de hele wereld.
<br/>
<br/>
Kortom, de ervaringen opgedaan in het universitaire roeien kunnen leiden tot een reeks van professionele en internationale kansen, van atleten die een professionele carrière najagen tot degenen die de sport in een coachende rol willen voortzetten.






                </MKTypography>
              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 4" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 4: Internationale Invloeden en Diversiteit
                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>

              Dit hoofdstuk belicht de internationale dynamiek en diversiteit binnen het universitaire roeien in de Verenigde Staten. We onderzoeken hoe internationale student-atleten de sport verrijken met hun unieke achtergronden en perspectieven, en hoe Amerikaanse roeiers kansen vinden om in het buitenland te roeien en zich verder te ontwikkelen. Deze interacties dragen bij aan een rijkere culturele ervaring en verbreden de horizon van zowel Amerikaanse als internationale roeiers. We verkennen de verschillende paden die internationale studenten bewandelen om te roeien op collegenniveau in de VS, de voordelen van deze ervaringen, en de impact die ze hebben op de sport en de deelnemers. Daarnaast richten we ons op de mogelijkheden voor Amerikaanse roeiers om in het buitenland ervaring op te doen, inclusief studie-uitwisselingsprogramma's, internationale trainingskampen en coachingmogelijkheden. Dit hoofdstuk benadrukt het belang van internationale invloeden in het verrijken van de roeisport op collegenniveau en het bevorderen van culturele uitwisseling en begrip.





</MKTypography>

              <MKBox id="4.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                4.1 Internationale Atleten in het College Roeien
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
  <img
    src={Image3}
    alt="Roeiboten opgesteld voor de start van een college roeiwedstrijd."
    style={{
      maxWidth: "100%",
      border: "2px solid #fff",
      boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
      cursor: "pointer",
      outline: "none",
    }}
    onClick={() => openModal3(Image3)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
  Boten liggen klaar voor de start van een wedstrijd
</figcaption>
</figure>
                Roeien op collegenniveau is niet alleen een Amerikaanse aangelegenheid, maar ook een internationale. Er zijn veel internationale studenten die naar de VS komen om te studeren en te roeien op Amerikaanse universiteiten. Deze studenten brengen hun eigen cultuur, achtergrond en perspectief mee, waardoor ze een waardevolle bijdrage leveren aan de diversiteit en kwaliteit van de teams. Bovendien bieden ze een kans voor culturele uitwisseling en vriendschap tussen roeiers uit verschillende landen.
<br/>
<br/>
Er zijn verschillende redenen waarom internationale studenten kiezen voor roeien op collegenniveau in de VS. Sommige van deze redenen zijn:
                </MKTypography>
                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
Roeibeurzen: Veel Amerikaanse universiteiten bieden roeibeurzen aan voor getalenteerde roeiers, waardoor ze hun studiekosten kunnen verlagen of zelfs elimineren. Dit is een grote stimulans voor internationale studenten die anders misschien niet in staat zouden zijn om te studeren in de VS.

</li>
<li>
Academische kwaliteit: Veel Amerikaanse universiteiten staan bekend om hun hoge academische standaarden en reputatie. Dit trekt internationale studenten aan die een uitstekende opleiding willen volgen en een diploma willen behalen dat wereldwijd erkend wordt.
</li>
<li>
Sportieve uitdaging: Veel internationale studenten zien roeien op collegenniveau als een sportieve uitdaging en een kans om zich te meten met de beste roeiers ter wereld. Ze willen hun vaardigheden verbeteren, hun grenzen verleggen en hun potentieel waarmaken.
</li>
<li>
Persoonlijke ontwikkeling: Veel internationale studenten zien roeien op collegenniveau als een persoonlijke ontwikkeling en een kans om te groeien als mens. Ze willen nieuwe ervaringen opdoen, nieuwe mensen ontmoeten, nieuwe culturen leren kennen en hun horizon verbreden.
</li>
</ul>
</MKTypography>
<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
"Niki van Sprang is een voorbeeld van een internationale student die op collegiaal niveau in de Verenigde Staten heeft geroeid. Van Sprang, afkomstig uit Nederland waar hij meerdere nationale titels won, kreeg een roeibeurs van de University of California, Berkeley. Daar sloot hij zich aan bij het varsity-team en behaalde aanzienlijke successen. Na zijn afstuderen in 2017 keerde Van Sprang terug naar Nederland en werd lid van het nationale roeiteam. Hij nam deel aan de Olympische Spelen van 2020 in Tokyo, waar hij samen met Guillaume Krommenhoek in de mannen twee-zonder stuurman de 7e plaats behaalde. Bovendien won hij zilver op de Wereldkampioenschappen in 2022 in Račice en op de Europese Kampioenschappen in 2022 in München, en een bronzen medaille op de Europese Kampioenschappen in 2023 in Bled, allen in de mannen acht."






                </MKTypography>



              </MKBox>
              <MKBox id="4.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                4.2 Amerikaanse Roeiers in het Buitenland
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Roeien op collegenniveau kan ook leiden tot internationale kansen voor Amerikaanse student-atleten die hun sportieve horizon willen verbreden of veranderen. Er zijn verschillende mogelijkheden voor Amerikaanse roeiers om in het buitenland te roeien en ervaring op te doen. Sommige van deze mogelijkheden zijn:
                </MKTypography>
                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
Studie-uitwisseling: Sommige Amerikaanse universiteiten hebben uitwisselingsprogramma’s met buitenlandse universiteiten, waardoor studenten een semester of een jaar kunnen studeren en roeien in een ander land. Dit geeft hen de kans om een andere cultuur, taal en onderwijssysteem te leren kennen, maar ook om te roeien met andere teams en coaches.

</li>
<li>
Trainingskamp: Sommige Amerikaanse universiteiten organiseren trainingskampen in het buitenland, meestal tijdens de winter- of zomerstop. Dit geeft hen de kans om te trainen in een andere omgeving, klimaat en waterconditie, maar ook om te concurreren tegen andere teams of deel te nemen aan lokale regatta’s.
</li>
<li>
Coaching: Sommige Amerikaanse roeiers besluiten om na hun afstuderen te gaan coachen in het buitenland, meestal bij scholen, clubs of nationale teams. Dit geeft hen de kans om hun kennis en ervaring over te dragen, maar ook om te leren van andere coaches en culturen.
</li>

</ul>
</MKTypography>
              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 5" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 5: Roeibeurzen en Toekomstige Kansen
                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              Roeien is een sport die veel discipline, doorzettingsvermogen en teamwork vereist. Het is ook een sport die veel kansen biedt voor jonge atleten die hun passie willen combineren met een goede opleiding. In dit hoofdstuk zullen we het belang van roeibeurzen bespreken, en hoe Sportbeursamerika.nl je kan helpen om je droom waar te maken.
              </MKTypography>

              <MKBox id="5.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                5.1 Het Belang van Sportbeurzen
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Een sportbeurs is een financiële ondersteuning die een universiteit biedt aan een student-atleet om de kosten van zijn of haar studie en verblijf te dekken. Een sportbeurs kan variëren van een gedeeltelijke vergoeding tot een volledige dekking van alle kosten. Een sportbeurs is niet alleen een financieel voordeel, maar ook een erkenning van je talent en inzet.
<br/>
<br/>
Een roeibeur is een specifiek type sportbeurs dat gericht is op roeiers. Roeien is een van de oudste en meest prestigieuze sporten in het Amerikaanse college systeem, en er zijn veel universiteiten die roeibeurzen aanbieden. Roeien is ook een van de weinige sporten waarbij vrouwen evenveel kansen hebben als mannen om een beurs te krijgen. Dit komt omdat roeien onder de Title IX wet valt, die gelijke kansen voor beide geslachten garandeert in het onderwijs.
<br/>
<br/>
Een roeibeur kan je helpen om je potentieel als roeier te bereiken, door je toegang te geven tot de beste faciliteiten, coaches en competities in de wereld. Je kunt jezelf meten met de top van je leeftijdsgroep, en je vaardigheden en techniek verbeteren. Je kunt ook profiteren van de academische kwaliteit en diversiteit van de Amerikaanse universiteiten, en een diploma behalen dat internationaal erkend wordt.
<br/>
<br/>
Een roeibeur kan ook je toekomstperspectieven vergroten, door je in contact te brengen met een groot netwerk van alumni, coaches en professionals. Je kunt waardevolle ervaringen opdoen, zowel op sportief als op persoonlijk vlak, die je kunnen helpen bij je verdere carrière. Je kunt ook doorgroeien naar hogere niveaus van roeien, zoals nationale teams of Olympisch roeien.
                </MKTypography>
              </MKBox>
              <MKBox id="5.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                5.2 Hoe Sportbeurs Amerika NL Kan Helpen 
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
  <img
    src={Image4}
    alt="Logo van Sportbeurs Amerika NL, bestaande uit een voetbal met het continent Noord-Amerika erop afgebeeld, met daarboven een afstudeerhoed (graduation cap) die bovenop de bal rust alsof het een hoofd is. De naam van het bedrijf is prominent weergegeven, wat de focus op sportbeurzen in Amerika voor Europese studenten benadrukt."
    style={{
      maxWidth: "100%",
      height: "auto",
      border: "2px solid #fff",
      boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)", // Apply shadow around the entire image
      cursor: "pointer",
      outline: "none",
      borderRadius: "50%", // Set border-radius to 50% for a circular image
      marginTop: "0px", // Add top margin to move the image up


    }}
    onClick={() => openModal4(Image4)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
  
</figcaption>
</figure>
Sportbeurs Amerika NL is een organisatie gespecialiseerd in het begeleiden van jonge sporttalenten naar sportbeurzen in Amerika.  Met een gepassioneerde en ervaren expert aan het roer, ondersteunt Sportbeurs Amerika NL de student-atleet bij iedere stap in het traject, vanaf de eerste oriëntatie tot aan de uiteindelijke plaatsing. Sportbeurs Amerika NL biedt onder andere de volgende diensten aan:
                </MKTypography>

                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
Een persoonlijke intakegesprek om de wensen, mogelijkheden en verwachtingen van de student-atleet te bespreken.
</li>
<li>
Een professionele evaluatie van het sportieve en academische niveau van de student-atleet.
</li>
<li>
Een uitgebreid netwerk van contacten met Amerikaanse coaches, universiteiten en colleges.
</li>
<li>
Een effectieve promotie van het profiel en de video’s van de student-atleet bij potentiële coaches.
</li>
<li>
Een deskundig advies over de beste opties voor de student-atleet op basis van zijn of haar voorkeuren, doelen en budget.
</li>
<li>
Een onderhandeling over de beste beursaanbiedingen namens de student-atleet.
</li>
<li>
Een hulp bij het aanvragen van de benodigde documenten, zoals het visum, het transcript, het diploma en de toelatingstesten.
</li>
<li>
Een voorbereiding op het leven in Amerika, zoals de cultuur, de taal, de regels en de tips.
</li>
<li>
Een nazorg en een follow-up tijdens het verblijf in Amerika.</li>

</ul>
</MKTypography>

<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Wil jij ook je passie voor American football combineren met een goede opleiding in Amerika? <Link to="/Diensten-Producten">Bekijk het volledige aanbod</Link> van Sportbeurs Amerika NL en maak een vrijblijvende afspraak om je te laten informeren over de mogelijkheden. 
      Ik sta voor je klaar om je te helpen bij het realiseren van je Amerikaanse droom.
    </MKTypography>


              </MKBox>
            </MKBox>

          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;
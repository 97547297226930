


import React from "react";


// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

import { Helmet } from 'react-helmet';

// Coworking page sections
import ContactUsTwo from "layouts/sections/input-areas/contact-sections/components/ContactUsTwo";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import DetailedFooter from "examples/Footers/DetailedFooter";

function Contactformulier() {
  return (
    <>
<Helmet>
    <title>Contacteer Mij - Sportbeurs Amerika</title>
    <meta name="description" content="Heeft u vragen of wilt u meer informatie over sportbeurzen in Amerika? Gebruik het contactformulier om direct met mij in contact te komen." />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta charset="UTF-8" />
    <meta name="robots" content="index, follow" />
    
    {/* Open Graph Tags */}
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://sportbeursamerika.nl/Contactformulier" />
    <meta property="og:title" content="Contacteer Mij - Sportbeurs Amerika" />
    <meta property="og:description" content="Neem contact op met mij voor al uw vragen over studeren en sporten in de VS. Ik sta klaar om u te helpen met persoonlijke begeleiding en advies." />
    <meta property="og:image" content="https://sportbeursamerika.nl/path/to/contact-page-image.jpg" />
    {/* Favicon */}
    <link rel="icon" href="https://sportbeursamerika.nl/32x32Sportbeurs.png" />
</Helmet>

  <MKBox pt={0}>  {/* Apply padding here */}
  <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://calendly.com/sportbeurs-amerika/intake",
          label: "MAAK AFSPRAAK",
          color: "info",
        }}
      
         
      />
  </MKBox>

     
     <MKBox pt={10}>  {/* Adjust the value as needed */}
  <ContactUsTwo />
</MKBox>

<MKBox pt={0} px={1} mt={-10}>
 <DetailedFooter content={footerRoutes} />
</MKBox>
    </>
  );
}

export default Contactformulier;

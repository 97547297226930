/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sporten_Images/Turnen1.jpeg";
import Image2 from "assets/images/Sporten_Images/Turnen2.jpg";
import Image3 from "assets/images/Sporten_Images/Turnen3.webp";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import "../../../../H1asH3.css"


// Images

import { Link } from 'react-router-dom';


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Turnen en Studeren in Amerika: Een Unieke Kans voor Jonge Gymnasten', isSubheader: false },

    { id: 'Hoofdstuk 1', title: '1. Geschiedenis en Ontwikkeling van Turnen in Amerika', isSubheader: false },
    { id: '1.1', title: '1.1 Vroege Jaren en de Rol van Universiteiten', isSubheader: true },
    { id: '1.2', title: '1.2 Groei en Professionalisering', isSubheader: true },

    { id: 'Hoofdstuk 2', title: '2. Universitair Turnen Vandaag de Dag', isSubheader: false },
    { id: '2.1', title: '2.1 Belangrijke Competities en Conferenties', isSubheader: true },
    { id: '2.2', title: '2.2 Topprogramma’s en Prestigieuze Teams', isSubheader: true },

    { id: 'Hoofdstuk 3', title: '3: Van College naar de Profs', isSubheader: false },
    { id: '3.1', title: '3.1 Het Pad naar Professionalisme', isSubheader: true },
    { id: '3.2', title: '3.2 Professionele Carrières en Kansen', isSubheader: true },


    { id: 'Hoofdstuk 4', title: '4. Internationale Invloeden', isSubheader: false },
    { id: '4.1', title: '4.1 Buitenlandse Invloeden en Uitwisseling', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikanen in het Buitenland', isSubheader: true },

    { id: 'Hoofdstuk 5', title: '5. Turnbeurzen en de Toekomst', isSubheader: false },
    { id: '5.1', title: '5.1 Het Belang van Sportbeurzen', isSubheader: true },
    { id: '5.2', title: '5.2 Hoe Sportbeurs Amerika NL Kan Helpen', isSubheader: true },

  ];

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Inhoud
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Verberg</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Toon</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
            <MKBox id="intro" mb={3}>
            <MKTypography variant="h1" className="h1-as-h3" mb={2}>
              Turnen en Studeren in Amerika: Een Unieke Kans voor Jonge Gymnasten
              </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
  <img
    src={Image1}
    alt="Zicht vanaf de tribune op een college turnwedstrijd in een groot stadion."
    style={{
      maxWidth: "100%",
      border: "2px solid #fff",
      boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
      cursor: "pointer",
      outline: "none",
    }}
    onClick={() => openModal1(Image1)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
  Een grote arena tijdens een NCAA turnwedstrijd 
</figcaption>
</figure>
              Turnen heeft een lange en rijke geschiedenis in de Verenigde Staten. Al sinds de 19e eeuw wordt er geturnd op universiteiten, waar turners hun vaardigheden ontwikkelen en hun talent tonen. Turnen is niet alleen een sport, maar ook een kunstvorm, een wetenschap en een levensstijl. Turners leren discipline, doorzettingsvermogen, creativiteit en teamwork. Ze trainen hard, maar hebben ook veel plezier.
<br/>
<br/>
Turnen op collegiaal niveau is een unieke kans voor jonge gymnasten om hun dromen waar te maken. Door een sportbeurs te krijgen, kun je studeren aan een prestigieuze universiteit en tegelijkertijd deelnemen aan spannende wedstrijden tegen andere topturners. Je maakt deel uit van een hechte gemeenschap van student-atleten, die elkaar steunen en motiveren. Je krijgt toegang tot de beste faciliteiten, coaches en begeleiders, die je helpen om je potentieel te bereiken. En je doet een onvergetelijke ervaring op, die je voorbereidt op je toekomstige carrière.
<br/>
<br/>
Maar hoe kom je in aanmerking voor een sportbeurs? Wat zijn de eisen en de voordelen? Hoe vind je de juiste universiteit voor jou? En hoe ziet het leven van een student-turner eruit? Dat zijn allemaal vragen die Sportbeursamerika.nl voor je kan beantwoorden. Sportbeursamerika.nl is een platform dat jonge gymnasten helpt om hun weg te vinden naar Amerika. Ik bied persoonlijke begeleiding, advies en ondersteuning bij het hele proces van aanmelding, selectie, voorbereiding en vertrek. Ik heb contact met honderden universiteiten en coaches, die op zoek zijn naar turntalent. Ik ken de ins en outs van het Amerikaanse onderwijssysteem en het universitaire turnen. En ik heb zelf ervaring met sporten en studeren in Amerika.
<br/>
<br/>
Ben je benieuwd naar de mogelijkheden om te turnen en te studeren in Amerika? Lees dan verder en ontdek alles wat je moet weten over deze unieke kans.
              </MKTypography>
              {/* Add bulleted list or other content as needed */}
            </MKBox>
             {/* Main chapters and subchapters with placeholder text */}
            <MKBox id="Hoofdstuk 1" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 1: Geschiedenis en Ontwikkeling van Turnen in Amerika
              </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              Turnen is een van de oudste sporten ter wereld, die zijn oorsprong vindt in de oude beschavingen van Griekenland, Rome en China. Turnen werd gebruikt als een manier om het lichaam te trainen, de geest te scherpen en de ziel te verheffen. Turnen werd ook gezien als een vorm van expressie, kunst en schoonheid.
<br/>
<br/>
In de Verenigde Staten begon turnen zich te ontwikkelen in de 19e eeuw, toen Europese immigranten hun kennis en vaardigheden meebrachten. Turnen werd al snel populair op scholen, clubs en verenigingen, waar het werd gezien als een middel om de gezondheid, het karakter en het burgerschap te bevorderen. Turnen werd ook een bron van nationale trots, vooral na de onafhankelijkheidsoorlog.
              </MKTypography>

              <MKBox id="1.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                1.1 Vroege Jaren en de Rol van Universiteiten
                </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                De eerste universitaire turnteams werden gevormd in de late 19e eeuw, toen studenten hun eigen clubs oprichtten en onderlinge wedstrijden organiseerden. De eerste officiële universitaire turncompetitie vond plaats in 1897, toen Yale University het opnam tegen Springfield College. Springfield College wordt beschouwd als de bakermat van het Amerikaanse turnen, omdat het de eerste school was die turnen aanbood als een academisch vak. Veel bekende turncoaches en leiders studeerden of doceerden aan Springfield College.
<br/>
<br/>
In de vroege 20e eeuw groeide het universitaire turnen verder, met de oprichting van regionale en nationale organisaties, zoals de Eastern Intercollegiate Gymnastics League (EIGL) in 1902 en de National Collegiate Athletic Association (NCAA) in 1906. De NCAA begon met het organiseren van nationale kampioenschappen voor mannen in 1938 en voor vrouwen in 1982. De NCAA is nog steeds de belangrijkste organisatie voor universitair turnen in Amerika.
                </MKTypography>
              </MKBox>
              <MKBox id="1.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                1.2 Groei en Professionalisering
                </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                De populariteit en het niveau van het universitaire turnen namen toe na de Tweede Wereldoorlog, toen veel veteranen terugkeerden naar de universiteiten en hun sportieve ambities nastreefden. Ook de invloed van Europese coaches en atleten, die naar Amerika kwamen om te ontsnappen aan het communisme of het nazisme, droeg bij aan de ontwikkeling van het universitaire turnen. Een van hen was George Gulack, een Hongaarse turner die goud won op de Olympische Spelen van 1936 in Berlijn. Gulack had een belangrijke rol bij de Olympische commissie en Amerikaanse en internationale gymnastiekorganisaties. Hij was lid van het Amerikaanse Olympische Comité van 1934 tot 1958.
<br/>
<br/>
In de jaren 50 en 60 begon het professionele turnen zich te onderscheiden van het universitaire turnen, met de opkomst van internationale sterren zoals Larisa Latynina, Olga Korbut en Nadia Comaneci. Deze turners inspireerden veel jonge Amerikanen om zich aan te sluiten bij privéclubs en zich te richten op individuele prestaties. Een van hen was Cathy Rigby, die in 1970 als eerste Amerikaanse vrouw ooit een medaille won op een wereldkampioenschap. Zij werd niet veel later een zeer succesvolle actrice.
<br/>
<br/>
Het professionele turnen bereikte zijn hoogtepunt in 1978, toen de Amerikaanse turner Kurt Thomas de eerste man werd die een gouden medaille won op een wereldkampioenschap. Hij studeerde aan Indiana State University en werd later een acteur. In datzelfde jaar maakte ook Mary Lou Retton haar debuut op het nationale toneel. Zij werd in 1984 de eerste Amerikaanse vrouw die goud won op de meerkamp bij een Olympische Spelen. 
<br/>
<br/>
Het universitaire turnen bleef echter ook relevant en invloedrijk, vooral voor vrouwen. Veel vrouwelijke turners kozen ervoor om hun carrière voort te zetten op universitair niveau, omdat ze daar meer kansen en voordelen kregen dan in het professionele circuit. 
<br/>
<br/>
Het universitaire turnen heeft dus een belangrijke rol gespeeld in de geschiedenis en ontwikkeling van turnen in Amerika. Het heeft veel atleten voortgebracht die niet alleen uitblonken in hun sport, maar ook in hun studie en hun latere carrière. Het heeft ook bijgedragen aan de verspreiding en promotie van turnen als een sport voor iedereen, ongeacht leeftijd, geslacht of achtergrond. Het universitaire turnen is nog steeds een levendige en dynamische sport, die elk jaar duizenden fans en toeschouwers trekt.
                </MKTypography>
              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 2" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 2: Universitair Turnen Vandaag de Dag
                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              Turnen is een van de meest populaire en competitieve sporten op universitair niveau in Amerika. Elk jaar strijden honderden teams en duizenden atleten om de felbegeerde titels en trofeeën. Het universitaire turnen biedt een unieke combinatie van sportiviteit, spektakel en academische uitmuntendheid. Het is een sport die veel vraagt, maar ook veel geeft.              </MKTypography>

              <MKBox id="2.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                2.1 Belangrijke Competities en Conferenties
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
  <img
    src={Image2}
    alt="Auburn turnster voert een vloeroefening uit tijdens een college competitie."
    style={{
      maxWidth: "100%",
      border: "2px solid #fff",
      boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
      cursor: "pointer",
      outline: "none",
    }}
    onClick={() => openModal2(Image2)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
  Een turnster van Auburn in actie
</figcaption>
</figure>
                Het universitaire turnen wordt gereguleerd door de NCAA, die de regels, de kwalificaties en de kampioenschappen bepaalt. De NCAA verdeelt het universitaire turnen in drie divisies, afhankelijk van het aantal beurzen, het budget en het niveau van de teams. Divisie I is de hoogste divisie, waar de meeste topturners en -teams aan deelnemen. Divisie II en III zijn lagere divisies, waar minder beurzen worden aangeboden en meer nadruk wordt gelegd op de academische prestaties van de atleten.
<br/>
<br/>
Binnen elke divisie zijn er verschillende conferenties, die regionale of thematische groepen van universiteiten vormen. De conferenties organiseren hun eigen competities en kampioenschappen, die dienen als voorrondes voor de nationale kampioenschappen. De belangrijkste conferenties voor het universitaire turnen zijn:
                </MKTypography>

                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
De Big Ten Conference, die bestaat uit 14 universiteiten in het middenwesten en het noordoosten van Amerika. De Big Ten is een van de oudste en meest prestigieuze conferenties, die bekend staat om zijn academische en atletische excellentie. De Big Ten heeft een aantal van de beste turnprogramma’s voor mannen en vrouwen, zoals Michigan, Minnesota, Nebraska en Penn State.

</li>
<li>
De Southeastern Conference (SEC), die bestaat uit 14 universiteiten in het zuidoosten van Amerika. De SEC is een van de meest dominante en invloedrijke conferenties, die bekend staat om zijn fanatieke en loyale fans. De SEC heeft een aantal van de beste turnprogramma’s voor vrouwen, zoals Alabama, Florida, Georgia en LSU.

</li>
<li>
De Pac-12 Conference, die bestaat uit 12 universiteiten in het westen van Amerika. De Pac-12 is een van de meest innovatieve en diverse conferenties, die bekend staat om zijn creativiteit en cultuur. De Pac-12 heeft een aantal van de beste turnprogramma’s voor mannen en vrouwen, zoals California, Stanford, UCLA en Utah.

</li>
</ul>
</MKTypography>
<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
De nationale kampioenschappen zijn het hoogtepunt van het universitaire turnseizoen, waar de beste teams en individuen strijden om de eer en de glorie. De nationale kampioenschappen worden elk jaar gehouden in april, op verschillende locaties in het land. De nationale kampioenschappen bestaan uit twee rondes: de regionale kampioenschappen en de nationale finales. De regionale kampioenschappen zijn de kwalificatierondes, waar 36 teams voor mannen en 36 teams voor vrouwen worden verdeeld over zes regio’s. De beste twee teams uit elke regio gaan door naar de nationale finales. De nationale finales zijn de beslissende rondes, waar 12 teams voor mannen en 12 teams voor vrouwen worden verdeeld over twee sessies. De beste vier teams uit elke sessie gaan door naar de superfinale (Super Six), waar ze strijden om de nationale titel voor het teamklassement. Daarnaast worden ook individuele titels toegekend voor het allroundklassement en de toestelfinales.                </MKTypography>

              </MKBox>
              <MKBox id="2.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                2.2 Topprogramma’s en Prestigieuze Teams
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Het universitaire turnen heeft een aantal topprogramma’s voortgebracht, die zich onderscheiden door hun consistentie, hun kwaliteit en hun traditie. Deze programma’s hebben meerdere nationale titels gewonnen, hebben veel talentvolle atleten opgeleid en hebben veel fans aangetrokken. Enkele van deze programma’s zijn:
                </MKTypography>

                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
UCLA Bruins: Het UCLA-turnprogramma is een van de meest succesvolle en gerespecteerde programma’s in het land. Het UCLA-turnprogramma heeft meer dan 20 nationale titels gewonnen: meerdere voor mannen en de meeste voor vrouwen. Het UCLA-turnprogramma heeft ook veel Olympische en wereldkampioenen voortgebracht, zoals Peter Vidmar, Tim Daggett, Jamie Dantzscher, Mohini Bhardwaj, Tasha Schwikert, Samantha Peszek en Kyla Ross. </li>
<li>
Oklahoma Sooners: Het Oklahoma-turnprogramma is een van de meest dominante en indrukwekkende programma’s in het land. Het Oklahoma-turnprogramma heeft meer dan 10 nationale titels gewonnen: meerdere voor mannen en bijna evenveel voor vrouwen. Het Oklahoma-turnprogramma heeft ook veel NCAA-kampioenen voortgebracht, zoals Jonathan Horton, Jake Dalton, Maggie Nichols, Brenna Dowell en Anastasia Webb. 
</li>
<li>
Utah Utes: Het Utah-turnprogramma is een van de meest historische en loyale programma’s in het land. Het Utah-turnprogramma heeft meer dan 10 nationale titels gewonnen: allemaal voor vrouwen. Het Utah-turnprogramma heeft ook veel legendarische coaches en atleten voortgebracht, zoals Greg Marsden, Megan Marsden, Missy Marlowe, Theresa Kulikowski, Ashley Postell en MyKayla Skinner.
</li>
</ul>
</MKTypography>

<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
Het universitaire turnen biedt dus een unieke kans voor jonge gymnasten om hun sport te beoefenen op het hoogste niveau, terwijl ze ook een waardevolle opleiding krijgen. Het universitaire turnen is een sport die veel uitdagingen en beloningen biedt, zowel op als naast de vloer. Het universitaire turnen is een sport die je leven kan veranderen.
                </MKTypography>
              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 3" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 3: Van College naar de Profs
                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              Turnen is een sport die veel talent, toewijding en doorzettingsvermogen vereist. Turners beginnen vaak al op jonge leeftijd met trainen, en dromen van een professionele carrière. Maar hoe maak je de overstap van het universitaire turnen naar het professionele turnen? En wat zijn de mogelijkheden en uitdagingen die je te wachten staan?              </MKTypography>

              <MKBox id="3.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                3.1 Het Pad naar Professionalisme
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Het universitaire turnen is een uitstekende voorbereiding op het professionele turnen, omdat het je de kans geeft om je vaardigheden te verbeteren, je ervaring op te doen en je netwerk uit te breiden. Veel universitaire turners hebben deelgenomen aan professionele competities, zoals de Olympische Spelen, de wereldkampioenschappen en de World Cup. Sommige van hen hebben zelfs medailles gewonnen.
                <br/>
<br/>
Om deel te kunnen nemen aan professionele competities, moeten universitaire turners zich echter houden aan bepaalde regels en voorwaarden. Zo moeten ze hun amateurstatus behouden, wat betekent dat ze geen geld mogen verdienen met hun sport. Ze mogen ook geen sponsors of agenten hebben, tenzij ze toestemming krijgen van de NCAA. Ze moeten ook voldoen aan de academische eisen van hun universiteit, wat soms moeilijk te combineren is met hun trainingsschema.
<br/>
<br/>
Daarnaast moeten universitaire turners ook rekening houden met hun leeftijd en hun lichamelijke conditie. Turnen is een sport die veel vergt van het lichaam, en die vaak blessures veroorzaakt. Veel turners bereiken hun piek rond hun twintigste jaar, en hebben daarna moeite om hun niveau te handhaven. Sommige turners kiezen er dan ook voor om hun professionele ambities op te geven, en zich te concentreren op hun studie of hun carrière buiten de sport.
                </MKTypography>


              </MKBox>
              <MKBox id="3.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                3.2 Professionele Carrières en Kansen
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Voor degenen die wel besluiten om door te gaan met het professionele turnen, zijn er verschillende mogelijkheden en kansen om hun passie voort te zetten. Een van de meest prestigieuze en lucratieve opties is om mee te doen aan de Olympische Spelen, die elke vier jaar worden gehouden. De Olympische Spelen zijn het hoogtepunt van elke sportcarrière, en bieden de kans om je land te vertegenwoordigen, jezelf te meten met de beste turners ter wereld, en een medaille te winnen. De Olympische Spelen zijn ook een bron van roem en erkenning, die kunnen leiden tot sponsorcontracten, media-aandacht en andere voordelen.
<br/>
<br/>
Een andere optie is om mee te doen aan de wereldkampioenschappen, die elk jaar worden gehouden (behalve in een Olympisch jaar). De wereldkampioenschappen zijn een belangrijke graadmeter voor het niveau en de ontwikkeling van het turnen in de wereld. De wereldkampioenschappen bieden ook de kans om je te kwalificeren voor de Olympische Spelen, of om een wildcard te krijgen. De wereldkampioenschappen zijn ook een bron van trots en prestige, die kunnen leiden tot prijzengeld, beurzen en andere beloningen.
<br/>
<br/>
Een derde optie is om mee te doen aan de World Cup, die bestaat uit een reeks van individuele wedstrijden die over de hele wereld worden gehouden. De World Cup is een aantrekkelijke optie voor turners die meer flexibiliteit en variatie willen in hun sportcarrière. De World Cup biedt ook de kans om verschillende landen en culturen te ontdekken, nieuwe vrienden te maken en je horizon te verbreden. De World Cup is ook een bron van plezier en avontuur, die kunnen leiden tot reiskostenvergoedingen, bonussen en andere incentives.
<br/>
<br/>
Naast deze internationale competities, zijn er ook nog andere professionele mogelijkheden voor turners, zoals:
                </MKTypography>
                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
Het geven van clinics, workshops of demonstraties, waarbij je je kennis en ervaring deelt met andere turners, coaches of fans.
</li>
<li>
Het werken als coach, trainer of adviseur, waarbij je andere turners helpt om hun doelen te bereiken, hun techniek te verbeteren of hun problemen op te lossen.
</li>
<li>
Het werken als commentator, analist of journalist, waarbij je je mening en inzicht geeft over het turnen, de wedstrijden of de actualiteit.
</li>
<li>
Het werken als model, acteur of influencer, waarbij je je uiterlijk, je persoonlijkheid of je populariteit gebruikt om producten, diensten of merken te promoten.
</li>
</ul>
</MKTypography>

<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
Het professionele turnen is dus een spannende en uitdagende carrière, die veel mogelijkheden en kansen biedt. Het professionele turnen is echter ook een veeleisende en onzekere carrière, die veel risico’s en obstakels met zich meebrengt. Het professionele turnen is een carrière die je moet kiezen met je hart, maar ook met je hoofd.                </MKTypography>



              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 4" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 4: Internationale Invloeden
                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              Turnen is een sport die geen grenzen kent. Turnen is een sport die mensen van over de hele wereld samenbrengt, inspireert en verrijkt. Turnen is een sport die wordt beïnvloed door verschillende culturen, stijlen en tradities. In dit hoofdstuk zullen we kijken naar de internationale invloeden op het universitaire turnen in Amerika, en hoe deze invloeden het universitaire turnen hebben gevormd en veranderd.
              </MKTypography>

              <MKBox id="4.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                4.1 Buitenlandse Invloeden en Uitwisseling
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
  <img
    src={Image3}
    alt="Stanford turner in actie op het paardvoltige bij een college wedstrijd."
    style={{
      maxWidth: "100%",
      border: "2px solid #fff",
      boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
      cursor: "pointer",
      outline: "none",
    }}
    onClick={() => openModal3(Image3)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
  Een turner van Stanford in actie
</figcaption>
</figure>
In de fascinerende wereld van universitair turnen in Amerika speelt de internationale uitwisseling een cruciale rol. Wist je dat buitenlandse turners een unieke dynamiek en technieken inbrengen die het niveau van de competitie verhogen? Deze atleten brengen vaak vernieuwende stijlen en methoden mee die hun Amerikaanse tegenhangers inspireren en uitdagen. Hierdoor wordt de sport niet alleen gevarieerder, maar ook competitiever.

<br/>
<br/>
Een voorbeeld hiervan is de impact van Oost-Europese turners in de jaren '80 en '90. Hun komst naar de VS bracht nieuwe technieken en trainingsmethoden, wat leidde tot een significante evolutie in het Amerikaanse turnen. Deze invloeden zijn vandaag de dag nog steeds zichtbaar in de stijl en techniek van veel topgymnasten.

<br/>
<br/>
De diversiteit die internationale gymnasten brengen, verrijkt de sportcultuur op de universiteiten. Het zorgt voor een smeltkroes van culturen en ideeën, wat de sport alleen maar ten goede komt. Daarnaast biedt het Amerikaanse studenten en coaches de kans om van verschillende culturele perspectieven te leren en hun eigen vaardigheden en tactieken aan te scherpen.


                </MKTypography>
              </MKBox>
              <MKBox id="4.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                4.2 Amerikanen in het Buitenland
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Aan de andere kant hebben Amerikaanse gymnasten ook een grote impact gehad op de internationale turnwereld. Tal van Amerikaanse student-atleten hebben na hun universitaire carrière internationaal succes geboekt. Hun prestaties op wereldkampioenschappen en Olympische Spelen zijn vaak het resultaat van de training en ervaring die ze opgedaan hebben in het universitaire circuit.


<br/>
<br/>
Deze gymnasten zijn ambassadeurs van de Amerikaanse turncultuur. Hun successen inspireren jonge turners wereldwijd om te streven naar een plek in een Amerikaans universiteitsteam. Dit creëert een positieve cyclus waarbij talent en kennis over de grenzen heen worden uitgewisseld.
<br/>
<br/>
Het pad dat deze gymnasten hebben bewandeld, toont aan hoe universitair turnen een uitstekende basis kan zijn voor een internationale carrière. De ervaringen die ze opdoen tijdens hun studie - van het deelnemen aan hoogstaande competities tot het trainen onder topcoaches - bereiden hen voor op de uitdagingen van de professionele sportwereld.


                </MKTypography>
              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 5" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 5: Turnbeurzen en de Toekomst
                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              Turnbeurzen spelen een sleutelrol in de ontwikkeling van jonge gymnasten. Ze bieden niet alleen financiële steun, maar ook toegang tot topfaciliteiten, coaching en competitieniveaus die cruciaal zijn voor hun groei als atleten. In dit hoofdstuk gaan we dieper in op de impact van deze beurzen en hoe platforms zoals Sportbeursamerika.nl jonge turners kunnen helpen hun dromen waar te maken.

</MKTypography>

              <MKBox id="5.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                5.1 Het Belang van Sportbeurzen
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Turnen is een van de meest populaire en competitieve sporten in de Verenigde Staten. Elk jaar strijden duizenden jonge gymnasten om een plekje in een van de prestigieuze universitaire turnprogramma’s. Maar hoe kom je daar? En wat zijn de voordelen van een turnbeurs?
<br/>
<br/>
Een turnbeurs is een financiële ondersteuning die wordt aangeboden door een universiteit aan een student-atleet die uitblinkt in turnen. Een turnbeurs kan de kosten van het collegegeld, de boeken, de huisvesting en andere uitgaven dekken. Een turnbeurs kan ook toegang geven tot hoogwaardige faciliteiten, coaching, medische zorg en academische begeleiding.
<br/>
<br/>
Een turnbeurs is niet alleen een financieel voordeel, maar ook een persoonlijk en professioneel voordeel. Een turnbeurs stelt je in staat om te studeren aan een gerenommeerde universiteit, waar je een waardevol diploma kunt behalen en je kennis en vaardigheden kunt vergroten. Een turnbeurs geeft je ook de kans om te trainen en te concurreren op het hoogste niveau, waar je je talent en passie voor turnen kunt laten zien. Een turnbeurs kan je helpen om je dromen waar te maken, of dat nu het worden van een professionele turner, een Olympische atleet, of een succesvolle carrière buiten de sport is.
<br/>
<br/>
Een turnbeurs is echter niet gemakkelijk te krijgen. De concurrentie is enorm en de eisen zijn hoog. Je moet niet alleen uitblinken in turnen, maar ook in academische prestaties, leiderschap, karakter en motivatie. Je moet ook weten hoe je jezelf kunt promoten bij de universiteiten die je interesseren, hoe je contact kunt leggen met coaches en recruiters, en hoe je je kunt voorbereiden op het aanmeldings- en selectieproces.
                </MKTypography>
              </MKBox>
              <MKBox id="5.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                5.2 Hoe Sportbeurs Amerika NL Kan Helpen 
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
  <img
    src={Image4}
    alt="Logo van Sportbeurs Amerika NL, bestaande uit een voetbal met het continent Noord-Amerika erop afgebeeld, met daarboven een afstudeerhoed (graduation cap) die bovenop de bal rust alsof het een hoofd is. De naam van het bedrijf is prominent weergegeven, wat de focus op sportbeurzen in Amerika voor Europese studenten benadrukt."
    style={{
      maxWidth: "100%",
      height: "auto",
      border: "2px solid #fff",
      boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)", // Apply shadow around the entire image
      cursor: "pointer",
      outline: "none",
      borderRadius: "50%", // Set border-radius to 50% for a circular image
      marginTop: "0px", // Add top margin to move the image up


    }}
    onClick={() => openModal4(Image4)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
  
</figcaption>
</figure>
Sportbeurs Amerika NL is een organisatie gespecialiseerd in het begeleiden van jonge sporttalenten naar sportbeurzen in Amerika.  Met een gepassioneerde en ervaren expert aan het roer, ondersteunt Sportbeurs Amerika NL de student-atleet bij iedere stap in het traject, vanaf de eerste oriëntatie tot aan de uiteindelijke plaatsing. Sportbeurs Amerika NL biedt onder andere de volgende diensten aan:
                </MKTypography>

                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
Een persoonlijke intakegesprek om de wensen, mogelijkheden en verwachtingen van de student-atleet te bespreken.
</li>
<li>
Een professionele evaluatie van het sportieve en academische niveau van de student-atleet.
</li>
<li>
Een uitgebreid netwerk van contacten met Amerikaanse coaches, universiteiten en colleges.
</li>
<li>
Een effectieve promotie van het profiel en de video’s van de student-atleet bij potentiële coaches.
</li>
<li>
Een deskundig advies over de beste opties voor de student-atleet op basis van zijn of haar voorkeuren, doelen en budget.
</li>
<li>
Een onderhandeling over de beste beursaanbiedingen namens de student-atleet.
</li>
<li>
Een hulp bij het aanvragen van de benodigde documenten, zoals het visum, het transcript, het diploma en de toelatingstesten.
</li>
<li>
Een voorbereiding op het leven in Amerika, zoals de cultuur, de taal, de regels en de tips.
</li>
<li>
Een nazorg en een follow-up tijdens het verblijf in Amerika.</li>

</ul>
</MKTypography>

<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Wil jij ook je passie voor American football combineren met een goede opleiding in Amerika? <Link to="/Diensten-Producten">Bekijk het volledige aanbod</Link> van Sportbeurs Amerika NL en maak een vrijblijvende afspraak om je te laten informeren over de mogelijkheden. 
      Ik sta voor je klaar om je te helpen bij het realiseren van je Amerikaanse droom.
    </MKTypography>


              </MKBox>
            </MKBox>

          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;
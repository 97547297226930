
import { LinkedIn } from "@mui/icons-material";
import { Instagram } from "@mui/icons-material";
import { Twitter } from "@mui/icons-material";


// Material Kit 2 PRO React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images//Logos/Sportbeurs.png";


const date = new Date().getFullYear();

export default {
  brand: {
    name: "Sportbeurs Amerika NL",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <LinkedIn />,
      link: "https://www.linkedin.com/company/sportbeurs-amerika-nl",
    },
    {
      icon: <Instagram />,
      link: "",
    },
    {
      icon: <Twitter />,
      link: "",
    },
  
  ],
  menus: [
    {
      name: "Bedrijf",
      items: [
        { name: "Over Mij", route: "/Over-Mij" },
        { name: "Over Sportbeurs Amerika", route: "/Over-Sportbeurs-Amerika" },
        { name: "Diensten & Producten", route: "/Diensten-Producten" },
      ],
    },
    {
      name: "Bronnen",
      items: [
        { name: "Podcast", route: "/Podcast" },
        { name: "Kenniscentrum", route: "/Kenniscentrum" },
      ],
    },
    {
      name: "Hulp & Ondersteuning",
      items: [
        { name: "Contactformulier", route: "/Contactformulier" },
        { name: "FAQ's", route: "/FAQs" },
      ],
    },
    {
      name: "Juridisch",
      items: [
        { name: "Privacyverklaring", route: "/Privacyverklaring" },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      Alle rechten voorbehouden. Auteursrecht &copy; {date} Sportbeurs Amerika NL
    </MKTypography>
  ),
};
import { useEffect, useRef } from "react";
import { Helmet } from 'react-helmet';

// rellax
import Rellax from "rellax";

// typed-js
import Typed from "typed.js";



// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DetailedFooter from "examples/Footers/DetailedFooter";


// Coworking page sections
import DesignBlocks from "./sections/DesignBlocks"

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import Breadcrumbs from "examples/Breadcrumbs";

// Images
import bgImage from "assets/images/Achtergronden/Home_BG.png";

function AllSports() {
  const headerRef = useRef(null);
  const typedJSRef = useRef(null);

  // Setting up rellax
  useEffect(() => {
    const parallax = new Rellax(headerRef.current, {
      speed: -6,
    });

    return () => parallax.destroy();
  }, []);

  // Setting up typedJS
  useEffect(() => {
    const typedJS = new Typed(typedJSRef.current, {
      strings: ["american football", "atletiek", "basketbal", "golf", "hockey", "honkbal", "softbal", "roeien", "tennis", "turnen", "voetbal", "volleybal", "waterpolo", "worstelen", "zwemmen",],
      typeSpeed: 90,
      backSpeed: 90,
      backDelay: 200,
      startDelay: 500,
      loop: true,
    });

    return () => typedJS.destroy();
  }, []);

  return (
    <>
    <Helmet>
    <title>Overzicht van College Sporten in de VS - Sportbeurs Amerika</title>
    <meta name="description" content="Een volledig overzicht van alle college sporten in Amerika. Ontdek mogelijkheden in sporten zoals voetbal, hockey en tennis voor sportbeurzen in de VS." />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta charset="UTF-8" />
    <meta name="robots" content="index, follow" />
    
    {/* Open Graph Tags */}
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://sportbeursamerika.nl/Alle-Sporten" />
    <meta property="og:title" content="Overzicht van College Sporten in de VS - Sportbeurs Amerika" />
    <meta property="og:description" content="Ontdek een breed scala aan college sporten in Amerika. Van American Football tot Zwemmen, alle informatie voor aspirant-student-atleten." />
    <meta property="og:image" content="https://sportbeursamerika.nl/path/to/overview-sports-image.jpg" />
 
    {/* Favicon */}
    <link rel="icon" href="https://sportbeursamerika.nl/32x32Sportbeurs.png" />
</Helmet>

      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://calendly.com/sportbeurs-amerika/intake",
          label: "MAAK AFSPRAAK",
          color: "info",
        }}
        transparent
        light
         
      />
      <MKBox
        ref={headerRef}
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Alle informatie die je nodig hebt over <span ref={typedJSRef} /> 
            </MKTypography>
           
           
          
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
             <MKBox
  width={{ xs: "80%", md: "50%", lg: "25%" }}
  mb={1}
  sx={{
    backgroundColor: "transparent", // Transparent background color
  }}
>
  <Breadcrumbs
    routes={[
      { label: "Home", route: "/" },
      { label: "Alle Sporten" },
    ]}
  />
</MKBox>  
        <DesignBlocks />
        
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DetailedFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default AllSports;

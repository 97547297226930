/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React components
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Images
import post1 from "assets/images/Jeugd.png";
import post2 from "assets/images/TijdUS.png";
import post3 from "assets/images/Deal.png";

function Post2() {
  return (
    <MKBox component="section" py={0}>
      <Container>
        <Grid container item xs={12} lg={4}>
          <MKTypography variant="h3" mb={6}>
            Lees hier mijn verhaal
          </MKTypography>
        </Grid>
        <Grid container spacing={3}>
        <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }}>
            <TransparentBlogCard
              image={post1}
              title="Deel 1: Mijn Jeugd in Nederland "
              description="In de betoverende omgeving van Nijmegen begon mijn levenslange liefdesaffaire met voetbal. Als kind ..."
              action={{
                type: "internal",
                route: "/Over-Mij/Jeugd-Voetbal",
                color: "info",
                label: "lees meer",
              }}
            />
          </Grid>
          <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }}>
            <TransparentBlogCard
              image={post2}
              title="Deel 2: Mijn Tijd in Amerika "
              description="Mijn tijd in Amerika was als een adembenemende reis, vol met onvergetelijke ervaringen en persoonlijke groei. ..."
              action={{
                type: "internal",
                route: "/Over-Mij/Tijd-in-Amerika",
                color: "info",
                label: "lees meer",
              }}
            />
          </Grid>
          <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }}>
            <TransparentBlogCard
              image={post3}
              title="Deel 3: Terug in Nederland "
              description="Bij mijn terugkomst in Nederland voelde ik een mengeling van nostalgie en vastberadenheid. De passie voor ..."
              action={{
                type: "internal",
                route: "/Over-Mij/Terug-in-Nederland",
                color: "info",
                label: "lees meer",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Post2;


/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Helmet } from 'react-helmet';

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import DetailedFooter from "examples/Footers/DetailedFooter";
import "../../H1asH3.css"

function Privacy() {
  return (
    <>
    <Helmet>
    <title>Privacyverklaring - Sportbeurs Amerika NL</title>
    <meta name="description" content="Lees onze privacyverklaring om te begrijpen hoe we persoonlijke informatie verzamelen, gebruiken en beschermen op Sportbeurs Amerika NL." />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta charset="UTF-8" />
    <meta name="robots" content="index, follow" />
    
    {/* Open Graph Tags */}
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://sportbeursamerika.nl/Privacyverklaring" />
    <meta property="og:title" content="Privacyverklaring - Sportbeurs Amerika NL" />
    <meta property="og:description" content="Bekijk onze privacyverklaring om te begrijpen hoe we persoonlijke informatie verzamelen, gebruiken en beschermen op Sportbeurs Amerika NL." />
    <meta property="og:image" content="https://sportbeursamerika.nl/path/to/privacy-policy-image.jpg" />

    {/* Favicon */}
    <link rel="icon" href="https://sportbeursamerika.nl/32x32Sportbeurs.png" />
</Helmet>

     <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://calendly.com/sportbeurs-amerika/intake",
          label: "MAAK AFSPRAAK",
          color: "info",
        }}
      
         
      />
      <MKBox component="section" pt={20} pb={12}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Card>
                <MKBox
                  variant="gradient"
                  bgColor="dark"
                  borderRadius="lg"
                  coloredShadow="dark"
                  p={3}
                  mt={-3}
                  mx={2}
                >
                  <MKTypography variant="h1" className="h1-as-h3" color="white">
Privacyverklaring                  </MKTypography>
                  <MKTypography variant="body2" color="white" opacity={0.8}>
                  Laatst gewijzigd: 6 november 2023




</MKTypography>
                </MKBox>
                <MKBox pb={6} px={6}>
                  <MKTypography variant="h5" mt={6} mb={2}>
                  Wie is verantwoordelijk voor de verwerking van mijn persoonsgegevens?
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                  Sportbeurs Amerika NL, gevestigd aan Van Woustraat 64-1
1073LN, Amsterdam, is verantwoordelijk voor de verwerking van persoonsgegevens zoals weergegeven in deze privacyverklaring.                   
 <MKTypography>
 <MKTypography variant="h5" mt={4} mb={2}>
 Contactgegevens:
                  </MKTypography>
                    </MKTypography>
                    https://www.sportbeursamerika.nl
                    <br/>
Van Woustraat 64-1
<br/>
1073LN, Amsterdam
<br/>
+31 626007445
                    <MKTypography  >
                    </MKTypography>
                  </MKTypography>
                  <MKTypography variant="h5" mt={4} mb={2}>
                  Persoonsgegevens die wij verwerken
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                  Sportbeurs Amerika NL verwerkt uw persoonsgegevens doordat u gebruik maakt van onze diensten en/of omdat u deze zelf aan ons verstrekt.

                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                  Hieronder vindt u een overzicht van de persoonsgegevens die wij verwerken:
                  </MKTypography>
                  <MKBox component="ul" my={3} ml={6}>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                      Voor- en achternaam
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                      Geslacht
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                      Geboortedatum
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                      Adresgegevens
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                      E-mailadres
                      </MKTypography>
                    </MKBox>
                   
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                      IP-adres
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                      Overige persoonsgegevens die u actief verstrekt bijvoorbeeld door een profiel op deze website aan te maken, in correspondentie en telefonisch
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                      Gegevens over uw activiteiten op onze website
                      </MKTypography>
                    </MKBox>
                    
                  </MKBox>
                
                  <MKTypography variant="h5" mt={4} mb={2}>
                  Bijzondere en/of gevoelige persoonsgegevens die wij verwerken
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Onze website en/of dienst heeft niet de intentie gegevens te verzamelen over websitebezoekers die jonger zijn dan 16 jaar. Tenzij ze toestemming hebben van ouders of voogd. We kunnen echter niet controleren of een bezoeker ouder dan 16 is. Wij raden ouders dan ook aan betrokken te zijn bij de online activiteiten van hun kinderen, om zo te voorkomen dat er gegevens over kinderen verzameld worden zonder ouderlijke toestemming. Als u er van overtuigd bent dat wij zonder die toestemming persoonlijke gegevens hebben verzameld over een minderjarige, neem dan contact met ons op via info@sportbeursamerika.nl, dan verwijderen wij deze informatie.

                  </MKTypography>
                  <MKTypography variant="h5" mt={4} mb={2}>
                  Met welk doel en op basis van welke grondslag wij persoonsgegevens verwerken
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                  Sportbeurs Amerika NL verwerkt uw persoonsgegevens voor de volgende doelen:

                  </MKTypography>
                  
                  <MKBox component="ul" my={3} ml={6}>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                      Verzenden van onze nieuwsbrief en/of reclamefolder
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                      U te kunnen bellen of e-mailen indien dit nodig is om onze dienstverlening uit te kunnen voeren
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                      U te informeren over wijzigingen van onze diensten en producten
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                      Om goederen en diensten bij u af te leveren
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                      Sportbeurs Amerika NL analyseert uw gedrag op de website om daarmee de website te verbeteren en het aanbod van producten en diensten af te stemmen op uw voorkeuren. 
                      </MKTypography>
                    </MKBox>
                   
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                      Sportbeurs Amerika NL verwerkt ook persoonsgegevens als wij hier wettelijk toe verplicht zijn, zoals gegevens die wij nodig hebben voor onze belastingaangifte. 
                      </MKTypography>
                    </MKBox>
                                  
                    
                  </MKBox>
                  <MKTypography variant="h5" mt={4} mb={2}>
                    Geautomatiseerde besluitvorming
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Sportbeurs Amerika NL neemt #responsibility op basis van geautomatiseerde verwerkingen besluiten over zaken die (aanzienlijke) gevolgen kunnen hebben voor personen. Het gaat hier om besluiten die worden genomen door computerprogramma's of -systemen, zonder dat daar een mens (bijvoorbeeld een medewerker van Sportbeurs Amerika NL) tussen zit. Sportbeurs Amerika NL gebruikt de volgende computerprogramma's of -systemen:

                  </MKTypography>
                  <MKTypography variant="h5" mt={4} mb={2}>
                  Hoe lang we persoonsgegevens bewaren
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Sportbeurs Amerika NL bewaart uw persoonsgegevens niet langer dan strikt nodig is om de doelen te realiseren waarvoor uw gegevens worden verzameld. Wij hanteren de volgende bewaartermijnen voor de volgende (categorieën) van persoonsgegevens:

                  </MKTypography>
                  <MKTypography variant="h5" mt={4} mb={2}>
                  Delen van persoonsgegevens met derden                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Sportbeurs Amerika NL deelt uw persoonsgegevens met verschillende derden als dit noodzakelijk is voor het uitvoeren van de overeenkomst en om te voldoen aan een eventuele wettelijke verplichting. Met bedrijven die u gegevens verwerken in onze opdracht, sluiten wij een verwerkersovereenkomst om te zorgen voor eenzelfde niveau van beveiliging en vertrouwelijkheid van uw gegevens. Sportbeurs Amerika NL blijft verantwoordelijk voor deze verwerkingen. Daarnaast verstrekt Sportbeurs Amerika NL uw persoonsgegevens aan andere derden. Dit doen wij alleen met uw nadrukkelijke toestemming. [voeg hier een tabel toe met: de categorie waar derde toe behoort, naam en jurisdictie, doel en welke gegevens.]
                  </MKTypography>
                  <MKTypography variant="h5" mt={4} mb={2}>
                  Cookies, of vergelijkbare technieken, die wij gebruiken                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Sportbeurs Amerika NL gebruikt functionele, analytische en tracking cookies. Een cookie is een klein tekstbestand dat bij het eerste bezoek aan deze website wordt opgeslagen in de browser van uw computer, tablet of smartphone. Sportbeurs Amerika NL gebruikt cookies met een puur technische functionaliteit. Deze zorgen ervoor dat de website naar behoren werkt en dat bijvoorbeeld uw voorkeursinstellingen onthouden worden. Deze cookies worden ook gebruikt om de website goed te laten werken en deze te kunnen optimaliseren. Daarnaast plaatsen we cookies die uw surfgedrag bijhouden zodat we op maat gemaakte content en advertenties kunnen aanbieden. Bij uw eerste bezoek aan onze website hebben wij u al geïnformeerd over deze cookies en toestemming gevraagd voor het plaatsen ervan. U kunt zich afmelden voor cookies door uw internetbrowser zo in te stellen dat deze geen cookies meer opslaat. Daarnaast kunt u ook alle informatie die eerder is opgeslagen via de instellingen van uw browser verwijderen. Zie voor een toelichting: https://veiliginternetten.nl/themes/situatie/cookies-wat-zijn-het-en-wat-doe-ik-ermee/
                  </MKTypography>
                  <MKTypography variant="h5" mt={4} mb={2}>
                  Gegevens inzien, aanpassen of verwijderen                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  U heeft het recht om uw persoonsgegevens in te zien, te corrigeren of te verwijderen. Daarnaast heeft u het recht om uw eventuele toestemming voor de gegevensverwerking in te trekken of bezwaar te maken tegen de verwerking van uw persoonsgegevens door Sportbeurs Amerika NL en heeft u het recht op gegevensoverdraagbaarheid. Dat betekent dat u bij ons een verzoek kunt indienen om de persoonsgegevens die wij van u beschikken in een computerbestand naar u of een ander, door u genoemde organisatie, te sturen. U kunt een verzoek tot inzage, correctie, verwijdering, gegevensoverdraging van uw persoonsgegevens of verzoek tot intrekking van uw toestemming of bezwaar op de verwerking van uw persoonsgegevens sturen naar persoonsgegevens@sportbeursamerika.nl. Om er zeker van te zijn dat het verzoek tot inzage door u is gedaan, vragen wij u een kopie van uw identiteitsbewijs met het verzoek mee te sturen. Maak in deze kopie uw pasfoto, MRZ (machine readable zone, de strook met nummers onderaan het paspoort), paspoortnummer en Burgerservicenummer (BSN) zwart. Dit ter bescherming van uw privacy. We reageren zo snel mogelijk, maar binnen vier weken, op uw verzoek. Sportbeurs Amerika NL wil u er tevens op wijzen dat u de mogelijkheid heeft om een klacht in te dienen bij de nationale toezichthouder, de Autoriteit Persoonsgegevens. Dat kan via de volgende link: https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons
                  </MKTypography>
                  <MKTypography variant="h5" mt={4} mb={2}>
                  Hoe wij persoonsgegevens beveiligen                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Sportbeurs Amerika NL neemt de bescherming van uw gegevens serieus en neemt passende maatregelen om misbruik, verlies, onbevoegde toegang, ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan. Als u de indruk heeft dat uw gegevens niet goed beveiligd zijn of er aanwijzingen zijn van misbruik, neem dan contact op met onze klantenservice of via persoonsgegevens@sportbeursamerika.nl
                  </MKTypography>
                  
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DetailedFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Privacy;

/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/


// @mui material components
import React, { useState } from 'react'; // Step 1: Import useState


import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Images
import post1 from "assets/images/Logos/NJCAA.png";
import post3 from "assets/images/Logos/NAIA.png";



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {

 const articleStructure = [
    { id: 'intro', title: 'De NCAA: Het Hart van College Sport in Amerika', isSubheader: false },

    { id: 'Hoofdstuk 1', title: 'De Wortels en Groei van de NCAA', isSubheader: true },
    { id: '1.1', title: 'De Drie Gezichten van de NCAA: Divisies I, II en III', isSubheader: true },
    { id: '1.2', title: 'De Rol van Academici', isSubheader: true },

    { id: 'Hoofdstuk 2', title: 'Financiën, Media, en Marketing', isSubheader: true },
    { id: '2.1', title: 'Uitdagingen en Toekomst', isSubheader: true },
    { id: '2.2', title: 'Tot Slot', isSubheader: true },


  
  ];

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };


  return (
     <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Inhoud
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Verberg</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Toon</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
            <MKBox id="intro" mb={3}>


              
                  <MKTypography variant="h1" className="h1-as-h3" mb={{ xs: 1, sm: 1, md: 0 }}>De NCAA: Het Hart van College Sport in Amerika</MKTypography>
              </MKBox>
              <MKBox id="Hoofdstuk 1" mb={3}>

              <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              De Wortels en Groei van de NCAA
              </MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
                De National Collegiate Athletic Association, beter bekend als de NCAA, is al meer dan een eeuw de spil in het Amerikaanse universitaire sportlandschap. Opgericht in 1906, heeft deze organisatie zich ontwikkeld van een kleine, regulerende entiteit tot een machtige en invloedrijke organisatie die het leven van duizenden student-atleten, coaches, en sportliefhebbers raakt.
                <br />
                <br />
                In de vroege jaren van de NCAA lag de focus voornamelijk op het vaststellen van spelregels en het waarborgen van de veiligheid in de sport. Naarmate de tijd vorderde, breidde de rol van de NCAA zich echter uit, waarbij ze steeds meer betrokken raakte bij het reguleren van elk aspect van universitaire atletiek, van academische eisen tot financiële kwesties.
                </MKTypography>

                <MKBox id="1.1" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
                <br />
                De Drie Gezichten van de NCAA: Divisies I, II en III
                </MKTypography>
                </MKBox>

                <MKTypography variant="body1" fontWeight="light" color="text"> 
  De NCAA is opgedeeld in drie divisies, elk met hun eigen unieke karakter en competitieniveau:
  <br /><br />
  <MKTypography component="span" variant="body1" fontWeight="bold">
    Divisie I:{' '}
  </MKTypography>
  staat bekend als het hoogtepunt van universitaire sport, waar de scholen met de grootste budgetten, de beste faciliteiten, en de meest getalenteerde atleten samenkomen. De universiteiten in deze divisie zijn vaak groot en hebben aanzienlijke middelen tot hun beschikking. Ze bieden volledige en gedeeltelijke atletiekbeurzen aan en trekken topatleten aan uit het hele land en de wereld. De competitie is intens, en de druk om te presteren is hoog. Toch is het ook een podium waar sterren worden geboren en waar de dromen van jonge atleten werkelijkheid kunnen worden. De divisie is onderverdeeld in drie categorieën: de Football Bowl Subdivision (FBS), de Football Championship Subdivision (FCS), en scholen zonder football op Divisie I-niveau.  <br /><br />
  <MKTypography component="span" variant="body1" fontWeight="bold">
    Divisie II:{' '}
  </MKTypography>
  vertegenwoordigt een evenwichtiger benadering van universitaire sport, waarbij atletiek, academische prestaties, en campusbetrokkenheid hand in hand gaan. De universiteiten in deze divisie zijn vaak kleiner dan die in Divisie I, maar ze bieden nog steeds een hoog niveau van competitie. Atleten in Divisie II kunnen rekenen op ondersteuning zowel op het veld als in de klas, met het doel een volledige universitaire ervaring te bieden.  <br /><br />
  <MKTypography component="span" variant="body1" fontWeight="bold">
    Divisie III:{' '}
  </MKTypography>
  tenslotte, legt de nadruk op de algehele ontwikkeling van de student-atleet. Sport is belangrijk, maar het is slechts een deel van de bredere universitaire ervaring. Er worden geen atletiekbeurzen aangeboden in Divisie III, maar atleten hebben de mogelijkheid om deel te nemen aan competitieve sporten terwijl ze ook volledig deel uitmaken van het academische en sociale leven op de campus.</MKTypography>
  
  <MKBox id="1.2" mb={3}>

  <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              De Rol van Academici
              </MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              De NCAA neemt de academische prestaties van haar student-atleten zeer serieus. Alle atleten moeten voldoen aan strenge academische eisen om in aanmerking te komen voor competitie, en de universiteiten zelf zijn verantwoordelijk voor het bieden van de nodige ondersteuning om ervoor te zorgen dat atleten slagen in de klas. Dit omvat academische adviseurs, tutors, en speciale studieprogramma's, allemaal ontworpen om student-atleten te helpen het evenwicht te bewaren tussen hun sportieve en academische verplichtingen.                <br />
                <br />
                </MKTypography>

                <MKBox id="Hoofdstuk 2" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Financiën, Media, en Marketing
              </MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              De NCAA is ook een financieel krachtpatser, met miljarden dollars die jaarlijks door de organisatie stromen dankzij televisiecontracten, sponsoren, en merchandise. Deze inkomsten worden gebruikt om beurzen te financieren, faciliteiten te verbeteren, en de algehele atletiekervaring voor student-atleten te verbeteren. Tegelijkertijd heeft de populariteit van universitaire sport geleid tot lucratieve marketing- en mediadeals, waardoor universitaire atletiek een vast onderdeel is geworden van de Amerikaanse sportcultuur.

<br />
                </MKTypography>

                <MKBox id="2.1" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Uitdagingen en Toekomst
              </MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              Ondanks haar succes, staat de NCAA ook voor aanzienlijke uitdagingen. Kwesties zoals de amateurstatus van student-atleten, de mentale gezondheid van atleten, en het streven naar gelijkheid in sport zijn slechts enkele van de onderwerpen die de organisatie moet aanpakken in haar streven naar een betere toekomst voor universitaire atletiek.
<br />
<br />
De NCAA blijft een cruciale speler in het Amerikaanse onderwijssysteem, en haar impact op het leven van jonge atleten en de bredere sportwereld is onmiskenbaar. Terwijl ze vooruitkijkt naar de toekomst, is het duidelijk dat de organisatie een sleutelrol zal blijven spelen in de vorming van het universitaire sportlandschap in Amerika.


                </MKTypography>

                <MKBox id="2.2" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Tot Slot
              </MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              De National Collegiate Athletic Association is meer dan alleen een sportorganisatie; het is een cruciaal onderdeel van het Amerikaanse onderwijs- en sportlandschap. Met haar rijke geschiedenis, complexe structuur, en diepe impact op het leven van ontelbare individuen, blijft de NCAA een fascinerend en vitaal onderdeel van de Amerikaanse cultuur. Haar toekomst zal ongetwijfeld gevuld zijn met uitdagingen en kansen, maar één ding is zeker: de NCAA zal blijven streven naar excellentie, zowel op het veld als in de klas.




<br />


                </MKTypography>

              <hr style={{ margin: '20px 0'}} />
              
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post1}
                    title="NJCAA"
                    description="De Springplank naar Grote Colleges en de NCAA"
                    action={{
                      type: "internal",
                      route: "/NJCAA",
                      color: "info",
                      label: "lees meer",
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post3}
                    title="NAIA"
                    description="Een Alternatieve Route in College Sport in Amerika"
                    action={{
                      type: "internal",
                      route: "/NAIA",
                      color: "info",
                      label: "lees meer",
                    }}
                    />
                  </Grid>
                </Grid>
  
              </Grid>
          </Grid>
        </Container>
      </MKBox>
    );
  }
  
  export default Beschrijving;
/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
// import Stack from "@mui/material/Stack";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import myImage from "assets/images/VoetbalfotoKayDuit1.png";
import secondimage from "assets/images/VoetbalfotoKayDuit2.png"



function Testimonials() {
  const [currentImage, setCurrentImage] = useState(myImage);
  const [opacity, setOpacity] = useState(1);

  const myImageAlt = "Kay Duit in actie tijdens een voetbalwedstrijd, spelend voor de University of Tulsa, dynamisch en gefocust op de bal"; // Replace with your actual alt text
  const secondImageAlt = "Kay Duit als voetballer bij de University of Tulsa, actief bezig met een speelactie op het voetbalveld."; // Replace with your actual alt text

  useEffect(() => {
    const switchImage = () => {
      setOpacity(0); // start fading out
      setTimeout(() => {
        setCurrentImage(prev => prev === myImage ? secondimage : myImage); // switch image after half duration
        setOpacity(1); // start fading in
      }, 500); // this is half the duration for fade
    };

    const interval = setInterval(switchImage, 10000); // switch every 10 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <MKBox component="section" position="relative" py={8}>
        <Container>
          <Grid container justifyContent="center" sx={{ pt: 0, pb: 0 }}>
            <Grid item xs={12} md={7} sx={{ textAlign: "center" }}>
            <MKTypography variant="h2" align="center" fontWeight="bold" gutterBottom mb={3}>
            Mijn Verhaal: Van Passie tot Profession
                        </MKTypography>
            <MKTypography color="text" variant="body2" align="center" gutterBottom mb={14}>
            Hoe mijn ervaring in de Amerikaanse sportwereld nu de sleutel vormt om jonge talenten te begeleiden naar successen.        </MKTypography>

              
            </Grid>
          </Grid>
        </Container>
     
      <Container sx={{ maxWidth: "100% !important" }}>
      
      <Grid container item xs={12} sm={10} sx={{ mx: "auto" }}>
          
          <MKBox variant="gradient" bgColor="dark" borderRadius="lg" width="100%" py={6}>
            <Grid container>
              
              <Grid item xs={12} md={6} xl={4} sx={{ position: "relative", px: 6 }}>
              <MKBox
                component="img"
                src={currentImage}
                alt={currentImage === myImage ? myImageAlt : secondImageAlt}
                borderRadius="md"
                maxWidth="300px"
                width="100%"
                position="relative"
                mt={-12}
                style={{ transition: 'opacity 1s', opacity: opacity }} // inline style for transition
                    />
              </Grid>
              <Grid
                item
                xs={12}
                md={5}
                xl={4}
                sx={{
                  position: "relative",
                  px: { xs: 6, md: 2 },
                  mt: { xs: 3, md: 0 },
                  my: { xs: 0, md: "auto" },
                  color: ({ palette: { white } }) => white.main,
                }}
              >
                <Icon fontSize="large">format_quote</Icon>
                <MKTypography
                  variant="body2"
                  color="white"
                  fontWeight="light"
                  mb={2}
                  sx={{ fontSize: "1.125rem" }}
                >
                Als oud-voetballer van de University of Tulsa heb ik vier onvergetelijke jaren mogen ervaren in het hart van de Amerikaanse studentencultuur. Die passie en ervaring drijven mij nu om Nederlandse studenten te begeleiden op hun eigen sportieve reis in de VS. Samen maken we jouw Amerikaanse sportdroom werkelijkheid.

                </MKTypography>
                <MKTypography variant="button" fontWeight="bold" color="white">
                  Kay Duit -{" "}
                  <MKTypography variant="caption" color="white">
                    Eigenaar
                  </MKTypography>
                </MKTypography>
              </Grid>
              <Grid item xs={1} />
              <Grid
                item
                xs={12}
                xl={2}
                sx={{ px: { xs: 6, xl: 0 }, my: { xs: 0, xl: "auto" }, lineHeight: 1 }}
              >
                <MKTypography variant="h5" color="white" mt={{ xs: 6, xl: 0 }} mb={1}>
                4-year letterwinner
                <br />
                3x AAC Champion
                              
                </MKTypography>
                
                <MKTypography
                  component="a"
                  href="/Over-Mij"
                  variant="button"
                  color="white"
                  fontWeight="regular"
                  sx={{
                    width: "max-content",
                    display: "flex",
                    alignItems: "center",


                    "& .material-icons-round": {
                      fontSize: "1.125rem",
                      transform: `translateX(3px)`,
                      transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                    },

                    "&:hover .material-icons-round, &:focus .material-icons-round": {
                      transform: `translateX(6px)`,
                    },
                  }}
                >
                  Lees meer
                  <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                </MKTypography>
              </Grid>
            </Grid>
          </MKBox>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Testimonials;


// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKTypography from "components/MKTypography";
import MKSocialButton from "components/MKSocialButton";
import "../../../../H1asH3.css"

// Images
import profilePicture from "assets/images/Profielfoto_Kay_Duit.jpeg";

function Profile() {
  return (
    <MKBox component="section" pt={{ xs: 20, sm: 20,  md: 6}} pb={{ xs: 6, sm: 0}}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -16, md: -20 }} textAlign="center">
            <MKAvatar src={profilePicture} alt="Profielfoto van Kay Duit, oprichter en eigenaar" size="xxl" shadow="xl" />
          </MKBox>
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox 
                display="flex" 
                flexDirection={{ xs: "column", sm: "column", md: "column", lg: "row" }} 
                justifyContent="space-between" 
                alignItems="center" 
                mb={1}
              >
                <MKTypography variant="h1" className="h1-as-h3" mb={{ xs: 1, sm: 1, md: 0 }}>Kay Duit</MKTypography>
                <MKBox display="flex" flexDirection={{ xs: "column", sm: "column", md: "column", lg: "row" }} gap={1}>
                <a href="https://www.linkedin.com/in/kayduit/" target="_blank" rel="noopener noreferrer">
                <MKSocialButton color="linkedin" style={{ width: '140px' }}>
                    <MKBox component="i" color="inherit" mr={0.5} className="fab fa-linkedin" />
                    linkedin
                  </MKSocialButton>
                  </a>
                  <a href="https://www.instagram.com/kayduit/" target="_blank" rel="noopener noreferrer">
                  <MKSocialButton color="instagram" style={{ width: '140px' }}>
                    <MKBox component="i" color="inherit" mr={0.5} className="fab fa-instagram" />
                    instagram
                  </MKSocialButton>
                  </a>
                  <a href="/Podcast">
                  <MKSocialButton color="pinterest" style={{ width: '140px' }}>
                    <MKBox component="i" color="inherit" mr={0.5} className="fas fa-podcast" />
                    podcast
                  </MKSocialButton>
                  </a>
                </MKBox>
              </MKBox>
              <br />
              <MKTypography variant="body1" fontWeight="light" color="text">
                Op een zwoele zomeravond in Amerika stond ik aan de zijlijn van het voetbalveld, 
                wachtend op het fluitsignaal om het veld op te stormen. Mijn hart klopte in mijn keel, 
                niet vanwege de aanstaande wedstrijd, maar vanwege het besef van hoe ver ik was gekomen. 
                Drie jaar eerder had ik het vliegtuig genomen vanuit Nederland naar de VS, een sprong in het diepe, 
                op zoek naar avontuur en de kans om zowel mijn sportieve als academische dromen te realiseren.
                <br /><br />  
                Gedurende die vier jaar had ik zoveel meer ervaren dan alleen de voetbalwedstrijden en de studie. 
                Er waren de late avonddiners na een grote overwinning, de vliegreizen naar uitwedstrijden in verre staten, 
                de vriendschappen die ik had opgebouwd, en de ontelbare keren dat ik me realiseerde hoeveel groter de wereld was dan het stukje Nederland waar ik vandaan kwam.
                <br /><br />  
                Die avond, terwijl het volkslied speelde en de tribunes gevuld waren met duizenden juichende fans, 
                dacht ik aan alle jonge studenten in Nederland die net als ik droomden van zulke kansen. En ik nam me voor dat, 
                wanneer mijn tijd in Amerika voorbij was, ik hen zou helpen diezelfde dromen te realiseren. 
              </MKTypography>
              <MKTypography
                component="a"
                href="#"
                variant="body1"
                fontWeight="light"
                color="info"
                mt={3}
                sx={{
                  width: "max-content",
                  display: "flex",
                  alignItems: "center",
                  "& .material-icons-round": {
                    transform: `translateX(3px)`,
                    transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                  },
                  "&:hover .material-icons-round, &:focus .material-icons-round": {
                    transform: `translateX(6px)`,
                  },
                }}
              >
              </MKTypography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Profile;

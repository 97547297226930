/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Images
import post1 from "assets/images/Jeugd.png";
import post3 from "assets/images/Deal.png";
import "../../../../H1asH3.css"


function Profile() {
  return (
    <MKBox component="section" py={{ xs: 0, sm: 0 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -16, md: -20 }} textAlign="center"></MKBox>
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox 
                  display="flex" 
                  flexDirection={{ xs: "column", sm: "column", md: "column", lg: "row" }} 
                  justifyContent="space-between" 
                  alignItems="center" 
                  mb={1}
              >
                  <MKTypography variant="h1" className="h1-as-h3" mb={{ xs: 1, sm: 1, md: 0 }}>Deel 2: Mijn Tijd in Amerika</MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text">
                  
                <br />
                  Mijn tijd in Amerika was als een adembenemende reis, vol met onvergetelijke ervaringen en persoonlijke groei. 
                  Dankzij voetbal reisde ik van kust naar kust, vergezeld door een groep teamgenoten die al snel uitgroeiden tot enkele van mijn beste vrienden. 
                  Onze dagen begonnen vroeg, met intensieve trainingssessies in het krachthonk, iets wat ik in Nederland niet gewend was. 
                  Daarna snel naar de universiteit voor de lessen, en 's middags weer trainen. Het was een dagelijkse toewijding die me zowel fysiek als mentaal uitdaagde.
                <br />
                <br />
                  De wedstrijden waren hoogtepunten waar duizenden fans op afkwamen, en elke keer voelde het alsof we speelden voor iets groters dan onszelf. 
                  De adrenaline van het veld betreden voor zo'n gepassioneerd publiek is onbeschrijfelijk. Bovendien waren de reizen die we maakten voor wedstrijden onvergetelijk. 
                  In het vliegtuig brachten we vaak uren door met kaarten, en in de hotellobby's deelden we lachwekkende momenten en spannende gesprekken. 
                  Restaurants met heerlijke gerechten werden onze tijdelijke eetplekken, en deze culinaire avonturen voegden een extra dimensie toe aan onze reizen. 
                  Alles was tot in de puntjes geregeld, van de kaarten in het vliegtuig tot de restaurants waar we dineerden. 
                  Deze ervaringen lieten me een glimp opvangen van de grootsheid van Amerika.
                <br />
                <br />
                  Daarnaast had ik de kans om stages te lopen bij vooraanstaande profclubs, waaronder Seattle Sounders. 
                  Het delen van het veld met spelers als Amerika's topscorer aller tijden Clint Dempsey was niet minder dan surreëel, een hoogtepunt dat ik altijd zal koesteren. 
                  Maar zoals elk avontuur had ook deze reis zijn uitdagingen. 
                  In mijn laatste jaar werd ik geconfronteerd met een zware hamstringblessure, waardoor mijn voetbalambities tijdelijk werden onderbroken. 
                  Dit was een periode van persoonlijke reflectie en veerkracht, waar ik uiteindelijk sterker uitkwam.
                <br />
                <br />
                  Daarnaast was mijn academische reis in Amerika even belangrijk. Ondanks het intensieve voetbaltrainingsschema en het vele reizen, 
                  kreeg ik perfecte begeleiding om mijn studie voort te zetten. Het was geen eenvoudige taak, omdat ik vaak lessen miste vanwege mijn betrokkenheid bij het voetbalteam. 
                  Desondanks was ik vastbesloten om te slagen, en dat deed ik met trots. Ik behaalde mijn bachelor met de hoogste eer, magna cum laude. 
                  Dit academische succes was een testament van mijn toewijding aan zowel mijn sport als mijn onderwijs.
                <br />
                <br />
                  Tijdens mijn avontuur in Amerika maakte ik kennis met typisch Amerikaanse feesten die rechtstreeks uit een film leken te komen. 
                  Deze bruisende sociale bijeenkomsten waren gevuld met muziek, dans en luid gelach, en ze boden me de kans om te groeien, niet alleen als atleet en student, 
                  maar ook als sociale levensgenieter. Deze feesten voegden een extra dimensie toe aan mijn studententijd en verrijkten mijn sociale leven.
                <br />
                <br />
                  Amerika betekende voor mij echter meer dan alleen voetbal, wedstrijden, sociale evenementen en academische prestaties. 
                  Het was een kans om mijn sociale kring uit te breiden en vriendschappen voor het leven te sluiten. 
                  Dankzij deze vriendschappen kon ik het land op nieuwe manieren ontdekken. 
                  Van spring break in Mexico, waar we genoten van zon, zee en strand, tot het vieren van Thanksgiving met de familie van vrienden, 
                  die ik inmiddels net zo goed mijn Amerikaanse familie kan noemen. Hun warme ontvangst maakte me echt deel van hun leven, 
                  en deze ervaringen hebben mijn tijd in Amerika nog rijker gemaakt.
                <br />
                <br />
                Deze periode van mijn leven heeft mijn liefde voor zowel voetbal als onderwijs verdiept, 
                en het heeft me geleid naar het pad dat ik nu volg om anderen te helpen hun dromen in Amerika waar te maken. 
                Blijf lezen, want in Deel 3 zal ik vertellen hoe mijn terugkeer naar Nederland en het oprichten van mijn bedrijf mijn leven een nieuwe wending gaven.           
      
               
                </MKTypography>
              <hr style={{ margin: '20px 0'}} />
              
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post1}
                    title="Deel 1: Mijn Jeugd in Nederland "
                    action={{
                      type: "internal",
                      route: "/Over-Mij/Jeugd-Voetbal",
                      color: "info",
                      label: "lees meer",
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post3}
                    title="Deel 3: Terug in Nederland "
                    action={{
                      type: "internal",
                      route: "/Over-Mij/Terug-in-Nederland",           
                      color: "info",
                      label: "lees meer",
                    }}
                  />
                </Grid>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Profile;
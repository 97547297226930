/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";


// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MuiLink from "@mui/material/Link";


// Material Kit 2 PRO React components
import TransparentGradientBlogCard from "examples/Cards/BlogCards/TransparentGradientBlogCard";


// Images
import post1 from "assets/images/Achtergronden/NCAA_Small.png";
import post2 from "assets/images/Achtergronden/NAIA_Small.png";
import post3 from "assets/images/Achtergronden/NJCAA_Small.png";
import post4 from "assets/images/Achtergronden/Staten_Small.png";
import "../../../H1asH3.css"


function Places() {
  return (
    <MKBox component="section" py={4}>
      <Container>
        <Grid container item xs={12} lg={6}>
          <MKTypography variant="h2" className="h1-as-h3" mb={6}>
            Artikelen van Sportbeurs Amerika NL 
          </MKTypography>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentGradientBlogCard
              image={post1}
              title="NCAA"
              action={{
                type: "internal",
                route: "/NCAA",
                color: "info",
                label: "Lees verder",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentGradientBlogCard
              image={post2}
              title="NAIA"
              action={{
                type: "internal",
                route: "/NAIA",
                color: "info",
                label: "Lees verder",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentGradientBlogCard
              image={post3}
              title="NJCAA"
              action={{
                type: "internal",
                route: "/NJCAA",
                color: "info",
                label: "Lees verder",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentGradientBlogCard
              image={post4}
              title="Staten van Amerika"
              action={{
                type: "internal",
                route: "/Staten",
                color: "info",
                label: "Lees verder",
              }}
            />
            
          </Grid>
             {/* Handige Externe Links title */}
             <Grid item xs={12}>
            <MKTypography variant="h2" className="h1-as-h3" mb={1}>
              Handige Interne Links 
            </MKTypography>
          </Grid>
        {/* Hello text in its own Grid item */}
      
          <Grid item xs={12}> {/* Adjust the 'xs' value as needed */}
        <MKTypography
            component={MuiLink}
            href="/Contactformulier"
            rel="noreferrer"
            variant="body2"
            fontWeight="regular"
            color="info"
            textTransform="capitalize"
            sx={{
              display: "flex",
              alignItems: "center",
              width: "max-content",
              textAlign: "left",
          
              "& .material-icons, .material-icons-round,": {
                transform: `translateX(2px)`,
                transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
              },
          
              "&:hover .material-icons, &:focus .material-icons, &:hover .material-icons-round, &:focus .material-icons-round":
                {
                  transform: `translateX(6px)`,
                },
            }}
          >
            Contactformulier
            <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
          </MKTypography>
          </Grid>
          <Grid item xs={12}> {/* Adjust the 'xs' value as needed */}
        <MKTypography
            component={MuiLink}
            href="/FAQs"
            rel="noreferrer"
            variant="body2"
            fontWeight="regular"
            color="info"
            textTransform="capitalize"
            sx={{
              display: "flex",
              alignItems: "center",
              width: "max-content",
              textAlign: "left",
          
              "& .material-icons, .material-icons-round,": {
                transform: `translateX(2px)`,
                transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
              },
          
              "&:hover .material-icons, &:focus .material-icons, &:hover .material-icons-round, &:focus .material-icons-round":
                {
                  transform: `translateX(6px)`,
                },
            }}
          >
            FAQ's
            <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
          </MKTypography>
          </Grid>
          <Grid item xs={12}> {/* Adjust the 'xs' value as needed */}
        <MKTypography
            component={MuiLink}
            href="/Privacyverklaring"
            rel="noreferrer"
            variant="body2"
            fontWeight="regular"
            color="info"
            textTransform="capitalize"
            sx={{
              display: "flex",
              alignItems: "center",
              width: "max-content",
              textAlign: "left",
          
              "& .material-icons, .material-icons-round,": {
                transform: `translateX(2px)`,
                transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
              },
          
              "&:hover .material-icons, &:focus .material-icons, &:hover .material-icons-round, &:focus .material-icons-round":
                {
                  transform: `translateX(6px)`,
                },
            }}
          >
            Privacybeleid
            <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
          </MKTypography>
          </Grid>

             {/* Handige Externe Links title */}
             <Grid item xs={12}>
            <MKTypography variant="h2" className="h1-as-h3" mb={1}>
              Handige Externe Links 
            </MKTypography>
          </Grid>
        {/* Hello text in its own Grid item */}
        <Grid item xs={12}> {/* Adjust the 'xs' value as needed */}
        <MKTypography
            component={MuiLink}
            href="https://www.ncaa.com/"
            target="_blank"
            rel="noreferrer"
            variant="body2"
            fontWeight="regular"
            color="info"
            textTransform="capitalize"
            sx={{
              display: "flex",
              alignItems: "center",
              width: "max-content",
              textAlign: "left",
          
              "& .material-icons, .material-icons-round,": {
                transform: `translateX(2px)`,
                transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
              },
          
              "&:hover .material-icons, &:focus .material-icons, &:hover .material-icons-round, &:focus .material-icons-round":
                {
                  transform: `translateX(6px)`,
                },
            }}
          >
            NCAA: Officiële Website
            <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
          </MKTypography>
          </Grid>
          <Grid item xs={12}> {/* Adjust the 'xs' value as needed */}
        <MKTypography
            component={MuiLink}
            href="https://www.ncaa.org/sports/2017/6/14/international-student-athletes.aspx#:~:text=%23%20%E3%80%904%E2%80%A0Become%20an%20International%20Student,learning%20environment%20within%20intercollegiate%20athletics"
            target="_blank"
            rel="noreferrer"
            variant="body2"
            fontWeight="regular"
            color="info"
            textTransform="capitalize"
            sx={{
              display: "flex",
              alignItems: "center",
              width: "max-content",
              textAlign: "left",
              ml: 2, // Adjust the margin-left value as needed for indentation

          
              "& .material-icons, .material-icons-round,": {
                transform: `translateX(2px)`,
                transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
              },
          
              "&:hover .material-icons, &:focus .material-icons, &:hover .material-icons-round, &:focus .material-icons-round":
                {
                  transform: `translateX(6px)`,
                },
            }}
          >
            NCAA: Become an International Student-Athlete

            <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
          </MKTypography>
          </Grid>
          <Grid item xs={12}> {/* Adjust the 'xs' value as needed */}
        <MKTypography
            component={MuiLink}
            href="https://www.naia.org/landing/index"
            target="_blank"
            rel="noreferrer"
            variant="body2"
            fontWeight="regular"
            color="info"
            textTransform="capitalize"
            sx={{
              display: "flex",
              alignItems: "center",
              width: "max-content",
              textAlign: "left",
          
              "& .material-icons, .material-icons-round,": {
                transform: `translateX(2px)`,
                transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
              },
          
              "&:hover .material-icons, &:focus .material-icons, &:hover .material-icons-round, &:focus .material-icons-round":
                {
                  transform: `translateX(6px)`,
                },
            }}
          >
            NAIA: Officiële Website
            <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
          </MKTypography>
          </Grid>
          <Grid item xs={12}> {/* Adjust the 'xs' value as needed */}
        <MKTypography
            component={MuiLink}
            href="https://www.naia.org/findyourpath/international/index"
            target="_blank"
            rel="noreferrer"
            variant="body2"
            fontWeight="regular"
            color="info"
            textTransform="capitalize"
            sx={{
              display: "flex",
              alignItems: "center",
              width: "max-content",
              textAlign: "left",
              ml: 2,

          
              "& .material-icons, .material-icons-round,": {
                transform: `translateX(2px)`,
                transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
              },
          
              "&:hover .material-icons, &:focus .material-icons, &:hover .material-icons-round, &:focus .material-icons-round":
                {
                  transform: `translateX(6px)`,
                },
            }}
          >
            NAIA: International Students
            <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
          </MKTypography>
          </Grid>
          <Grid item xs={12}> {/* Adjust the 'xs' value as needed */}
        <MKTypography
            component={MuiLink}
            href="https://www.njcaa.org/landing/index"
            target="_blank"
            rel="noreferrer"
            variant="body2"
            fontWeight="regular"
            color="info"
            textTransform="capitalize"
            sx={{
              display: "flex",
              alignItems: "center",
              width: "max-content",
              textAlign: "left",
          
              "& .material-icons, .material-icons-round,": {
                transform: `translateX(2px)`,
                transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
              },
          
              "&:hover .material-icons, &:focus .material-icons, &:hover .material-icons-round, &:focus .material-icons-round":
                {
                  transform: `translateX(6px)`,
                },
            }}
          >
            NJCAA: Officiële Website
            <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
          </MKTypography>
          </Grid>
          <Grid item xs={12}> {/* Adjust the 'xs' value as needed */}
        <MKTypography
            component={MuiLink}
            href="https://www.usnews.com/education/best-global-universities/articles/tips-for-international-student-athletes"
            target="_blank"
            rel="noreferrer"
            variant="body2"
            fontWeight="regular"
            color="info"
            textTransform="capitalize"
            sx={{
              display: "flex",
              alignItems: "center",
              width: "max-content",
              textAlign: "left",
          
              "& .material-icons, .material-icons-round,": {
                transform: `translateX(2px)`,
                transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
              },
          
              "&:hover .material-icons, &:focus .material-icons, &:hover .material-icons-round, &:focus .material-icons-round":
                {
                  transform: `translateX(6px)`,
                },
            }}
          >
            USNews: Artikel met Tips
            <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
          </MKTypography>
          </Grid>
          <Grid item xs={12}> {/* Adjust the 'xs' value as needed */}
        <MKTypography
            component={MuiLink}
            href="https://travel.state.gov/content/travel/en/us-visas/study/student-visa.h"
            target="_blank"
            rel="noreferrer"
            variant="body2"
            fontWeight="regular"
            color="info"
            textTransform="capitalize"
            sx={{
              display: "flex",
              alignItems: "center",
              width: "max-content",
              textAlign: "left",
          
              "& .material-icons, .material-icons-round,": {
                transform: `translateX(2px)`,
                transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
              },
          
              "&:hover .material-icons, &:focus .material-icons, &:hover .material-icons-round, &:focus .material-icons-round":
                {
                  transform: `translateX(6px)`,
                },
            }}
          >
            US Government: Student Visa
            <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
          </MKTypography>
          </Grid>
          <Grid item xs={12}>
            <MKTypography variant="h2" className="h1-as-h3" mb={1}>
Talen            </MKTypography>
          </Grid>
          <Grid item xs={12}> {/* Adjust the 'xs' value as needed */}
        <MKTypography
            component={MuiLink}
            href="https://sportscholarshipsamerica.com/da"
            target="_blank"
            rel="noreferrer"
            variant="body2"
            fontWeight="regular"
            color="info"
            textTransform="capitalize"
            sx={{
              display: "flex",
              alignItems: "center",
              width: "max-content",
              textAlign: "left",
          
              "& .material-icons, .material-icons-round,": {
                transform: `translateX(2px)`,
                transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
              },
          
              "&:hover .material-icons, &:focus .material-icons, &:hover .material-icons-round, &:focus .material-icons-round":
                {
                  transform: `translateX(6px)`,
                },
            }}
          >
Dansk            <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
          </MKTypography>
          </Grid>
          <Grid item xs={12}> {/* Adjust the 'xs' value as needed */}
        <MKTypography
            component={MuiLink}
            href="https://sportscholarshipsamerica.com/de"
            target="_blank"
            rel="noreferrer"
            variant="body2"
            fontWeight="regular"
            color="info"
            textTransform="capitalize"
            sx={{
              display: "flex",
              alignItems: "center",
              width: "max-content",
              textAlign: "left",
          
              "& .material-icons, .material-icons-round,": {
                transform: `translateX(2px)`,
                transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
              },
          
              "&:hover .material-icons, &:focus .material-icons, &:hover .material-icons-round, &:focus .material-icons-round":
                {
                  transform: `translateX(6px)`,
                },
            }}
          >
Deutsch            <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
          </MKTypography>
          </Grid>
          <Grid item xs={12}> {/* Adjust the 'xs' value as needed */}
        <MKTypography
            component={MuiLink}
            href="https://sportscholarshipsamerica.com/"
            target="_blank"
            rel="noreferrer"
            variant="body2"
            fontWeight="regular"
            color="info"
            textTransform="capitalize"
            sx={{
              display: "flex",
              alignItems: "center",
              width: "max-content",
              textAlign: "left",
          
              "& .material-icons, .material-icons-round,": {
                transform: `translateX(2px)`,
                transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
              },
          
              "&:hover .material-icons, &:focus .material-icons, &:hover .material-icons-round, &:focus .material-icons-round":
                {
                  transform: `translateX(6px)`,
                },
            }}
          >
English            <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
          </MKTypography>
          </Grid>
          <Grid item xs={12}> {/* Adjust the 'xs' value as needed */}
        <MKTypography
            component={MuiLink}
            href="https://sportscholarshipsamerica.com/es"
            target="_blank"
            rel="noreferrer"
            variant="body2"
            fontWeight="regular"
            color="info"
            textTransform="capitalize"
            sx={{
              display: "flex",
              alignItems: "center",
              width: "max-content",
              textAlign: "left",
          
              "& .material-icons, .material-icons-round,": {
                transform: `translateX(2px)`,
                transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
              },
          
              "&:hover .material-icons, &:focus .material-icons, &:hover .material-icons-round, &:focus .material-icons-round":
                {
                  transform: `translateX(6px)`,
                },
            }}
          >
Español            <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
          </MKTypography>
          </Grid>
          <Grid item xs={12}> {/* Adjust the 'xs' value as needed */}
        <MKTypography
            component={MuiLink}
            href="https://sportscholarshipsamerica.com/fr"
            target="_blank"
            rel="noreferrer"
            variant="body2"
            fontWeight="regular"
            color="info"
            textTransform="capitalize"
            sx={{
              display: "flex",
              alignItems: "center",
              width: "max-content",
              textAlign: "left",
          
              "& .material-icons, .material-icons-round,": {
                transform: `translateX(2px)`,
                transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
              },
          
              "&:hover .material-icons, &:focus .material-icons, &:hover .material-icons-round, &:focus .material-icons-round":
                {
                  transform: `translateX(6px)`,
                },
            }}
          >
Français            <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
          </MKTypography>
          </Grid>
          <Grid item xs={12}> {/* Adjust the 'xs' value as needed */}
        <MKTypography
            component={MuiLink}
            href="https://sportscholarshipsamerica.com/it"
            target="_blank"
            rel="noreferrer"
            variant="body2"
            fontWeight="regular"
            color="info"
            textTransform="capitalize"
            sx={{
              display: "flex",
              alignItems: "center",
              width: "max-content",
              textAlign: "left",
          
              "& .material-icons, .material-icons-round,": {
                transform: `translateX(2px)`,
                transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
              },
          
              "&:hover .material-icons, &:focus .material-icons, &:hover .material-icons-round, &:focus .material-icons-round":
                {
                  transform: `translateX(6px)`,
                },
            }}
          >
Italiano            <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
          </MKTypography>
          </Grid>
          <Grid item xs={12}> {/* Adjust the 'xs' value as needed */}
        <MKTypography
            component={MuiLink}
            href="https://sportscholarshipsamerica.com/no"
            target="_blank"
            rel="noreferrer"
            variant="body2"
            fontWeight="regular"
            color="info"
            textTransform="capitalize"
            sx={{
              display: "flex",
              alignItems: "center",
              width: "max-content",
              textAlign: "left",
          
              "& .material-icons, .material-icons-round,": {
                transform: `translateX(2px)`,
                transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
              },
          
              "&:hover .material-icons, &:focus .material-icons, &:hover .material-icons-round, &:focus .material-icons-round":
                {
                  transform: `translateX(6px)`,
                },
            }}
          >
Norsk            <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
          </MKTypography>
          </Grid>
          <Grid item xs={12}> {/* Adjust the 'xs' value as needed */}
        <MKTypography
            component={MuiLink}
            href="https://sportscholarshipsamerica.com/pt"
            target="_blank"
            rel="noreferrer"
            variant="body2"
            fontWeight="regular"
            color="info"
            textTransform="capitalize"
            sx={{
              display: "flex",
              alignItems: "center",
              width: "max-content",
              textAlign: "left",
          
              "& .material-icons, .material-icons-round,": {
                transform: `translateX(2px)`,
                transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
              },
          
              "&:hover .material-icons, &:focus .material-icons, &:hover .material-icons-round, &:focus .material-icons-round":
                {
                  transform: `translateX(6px)`,
                },
            }}
          >
Português            <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
          </MKTypography>
          </Grid>
          <Grid item xs={12}> {/* Adjust the 'xs' value as needed */}
        <MKTypography
            component={MuiLink}
            href="https://sportscholarshipsamerica.com/sv"
            target="_blank"
            rel="noreferrer"
            variant="body2"
            fontWeight="regular"
            color="info"
            textTransform="capitalize"
            sx={{
              display: "flex",
              alignItems: "center",
              width: "max-content",
              textAlign: "left",
          
              "& .material-icons, .material-icons-round,": {
                transform: `translateX(2px)`,
                transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
              },
          
              "&:hover .material-icons, &:focus .material-icons, &:hover .material-icons-round, &:focus .material-icons-round":
                {
                  transform: `translateX(6px)`,
                },
            }}
          >
Svenska            <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
          </MKTypography>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Places;

/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sporten_Images/Worstelen1.jpeg";
import Image2 from "assets/images/Sporten_Images/Worstelen2.webp";
import Image3 from "assets/images/Sporten_Images/Worstelen3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import "../../../../H1asH3.css"


// Images

import { Link } from 'react-router-dom';


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Worstelbeurs USA: Hoe Studeren en Worstelen in Amerika Je Droom Kan Waarmaken', isSubheader: false },

    { id: 'Hoofdstuk 1', title: '1. De Geschiedenis en Ontwikkeling van Worstelen in Amerika', isSubheader: false },
    { id: '1.1', title: '1.1 Vroege Jaren', isSubheader: true },
    { id: '1.2', title: '1.2 Groei en Evolutie', isSubheader: true },

    { id: 'Hoofdstuk 2', title: 'Collegeworstelen Vandaag de Dag', isSubheader: false },
    { id: '2.1', title: '2.1 Belangrijke Competities en Conferenties', isSubheader: true },
    { id: '2.2', title: '2.2 Topprogramma’s en Prestigieuze Teams', isSubheader: true },

    { id: 'Hoofdstuk 3', title: '3: Van College naar Olympisch Niveau en Profcompetities', isSubheader: false },
    { id: '3.1', title: '3.1 Het Pad naar de Top', isSubheader: true },
    { id: '3.2', title: '3.2 Professioneel Worstelen en Olympische Spelen', isSubheader: true },


    { id: 'Hoofdstuk 4', title: '4. Internationale Invloeden en Competities', isSubheader: false },
    { id: '4.1', title: '4.1 Buitenlandse Worstelstijlen en -Tradities', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikanen op het Wereldtoneel', isSubheader: true },

    { id: 'Hoofdstuk 5', title: '5. Worstelbeurzen en de Toekomst', isSubheader: false },
    { id: '5.1', title: '5.1 Het Belang van Beurzen', isSubheader: true },
    { id: '5.2', title: '5.2 Hoe Sportbeurs Amerika NL Kan Helpen', isSubheader: true },

  ];

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };

  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Inhoud
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Verberg</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Toon</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
            <MKBox id="intro" mb={3}>
            <MKTypography variant="h1" className="h1-as-h3" mb={2}>
              Worstelbeurs USA: Hoe Studeren en Worstelen in Amerika Je Droom Kan Waarmaken
              </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
  <img
    src={Image1}
    alt="Luchtfoto van een vol stadion bij een college worstelwedstrijd."
    style={{
      maxWidth: "100%",
      border: "2px solid #fff",
      boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
      cursor: "pointer",
      outline: "none",
    }}
    onClick={() => openModal1(Image1)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
  Het nationale kampioenschap
</figcaption>
</figure>
              Worstelen is een sport die al duizenden jaren wordt beoefend over de hele wereld. Het is een vorm van lichamelijk gevecht waarbij twee tegenstanders elkaar proberen te domineren door middel van grepen, worpen, klemmen, en andere technieken. Worstelen vereist niet alleen fysieke kracht, snelheid, uithoudingsvermogen, en flexibiliteit, maar ook mentale scherpte, strategie, discipline, en doorzettingsvermogen.
<br/>
<br/>
In de Verenigde Staten is worstelen een van de meest beoefende en bekeken sporten. Het heeft een lange en boeiende geschiedenis, die teruggaat tot de koloniale tijd. Sindsdien heeft het zich ontwikkeld tot een nationaal fenomeen, met miljoenen fans en beoefenaars. Het Amerikaanse collegeworstelen is het hoogtepunt van deze ontwikkeling. Het is het niveau waar de beste worstelaars van het land elkaar ontmoeten in spannende competities, die vaak als opstap dienen naar professionele of olympische carrières.
<br/>
<br/>
Het belang van worstelen op collegeniveau voor de ontwikkeling van atleten kan niet worden onderschat. Collegeworstelaars krijgen niet alleen de kans om hun sportieve talenten te tonen en te verbeteren, maar ook om te studeren aan gerenommeerde universiteiten, waar ze waardevolle academische vaardigheden en kennis kunnen opdoen. Bovendien kunnen ze profiteren van beurzen, die hun studiekosten grotendeels of volledig kunnen dekken.
<br/>
<br/>
Het verkrijgen van een worstelbeurs is echter geen gemakkelijke taak. Het vergt veel voorbereiding, toewijding, en begeleiding. Gelukkig hoef je dat niet alleen te doen. Sportbeursamerika.nl is een organisatie die gespecialiseerd is in het helpen van jonge worsteltalenten om hun droom van studeren en worstelen in Amerika waar te maken. Ik bied je professioneel advies, persoonlijke ondersteuning, en toegang tot mijn uitgebreide netwerk van contacten in de Amerikaanse worstelwereld.
Wil je meer weten over hoe ik je kan helpen om een worstelbeurs USA te bemachtigen? Lees dan verder! In dit artikel zal ik je meer vertellen over:
              </MKTypography>
              {/* Add bulleted list or other content as needed */}
            </MKBox>
             {/* Main chapters and subchapters with placeholder text */}
            <MKBox id="Hoofdstuk 1" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 1: De Geschiedenis en Ontwikkeling van Worstelen in Amerika
              </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              Worstelen is een sport die al sinds de oudheid wordt beoefend in verschillende culturen. Het is een van de oudste vormen van georganiseerde competitie, die al werd vermeld in de Griekse mythologie en de Bijbel. Het was ook een van de eerste sporten die werd opgenomen in de Olympische Spelen van de oudheid.
<br/>
<br/>
In de Verenigde Staten heeft worstelen een eigen geschiedenis en evolutie doorgemaakt, die nauw verbonden is met de sociale en politieke context van het land. In dit hoofdstuk zullen we een overzicht geven van de belangrijkste fasen en gebeurtenissen in de Amerikaanse worstelgeschiedenis, en hoe ze hebben bijgedragen aan de vorming van het huidige collegeworstelen.
              </MKTypography>

              <MKBox id="1.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                1.1 Vroege Jaren
                </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Het begin van het worstelen in Amerika kan worden teruggevoerd tot de koloniale tijd, toen Europese kolonisten hun eigen worstelstijlen en -tradities meebrachten naar het nieuwe continent. Deze stijlen waren vooral gebaseerd op het Engelse catch-as-catch-can worstelen, dat zich kenmerkte door het toestaan van grepen op elk deel van het lichaam, behalve het hoofd. Het was een populaire vorm van vermaak en uitdaging onder de kolonisten, die vaak wedstrijden organiseerden op feestdagen of marktdagen.
<br/>
<br/>
Een van de eerste bekende worstelaars in Amerika was George Washington, die als jongeman bekend stond om zijn worstelvaardigheden. Hij versloeg ooit zeven tegenstanders op één dag tijdens een wedstrijd in Virginia. Een andere beroemde worstelaar uit deze periode was Abraham Lincoln, die als tiener ook meerdere wedstrijden won in Illinois.
<br/>
<br/>
Het eerste college worstelteam werd gevormd in 1780 aan de Yale University, maar het duurde nog meer dan een eeuw voordat het collegeworstelen echt van de grond kwam. De eerste officiële intercollegiale wedstrijd vond plaats in 1889 tussen Yale en Columbia University, en werd gewonnen door Yale met 5-0. In 1892 werd de eerste nationale kampioenschap gehouden in New York City, met acht teams uit verschillende staten. De winnaar was Cornell University, dat alle zeven wedstrijden won.
                </MKTypography>
              </MKBox>
              <MKBox id="1.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                1.2 Groei en Evolutie
                </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                In de late 19e en vroege 20e eeuw begon het worstelen zich te verspreiden en te diversifiëren in Amerika. Er ontstonden verschillende regionale stijlen, zoals het Iowa-stijl worstelen, dat zich richtte op snelheid en behendigheid, of het Oklahoma-stijl worstelen, dat zich richtte op kracht en controle. Er werden ook nieuwe regels ingevoerd om het worstelen veiliger en eerlijker te maken, zoals het verbieden van gevaarlijke grepen of het invoeren van tijdslimieten.
<br/>
<br/>
Het collegeworstelen groeide ook in populariteit en prestige, met meer teams, competities, en fans. In 1928 werd de National Collegiate Athletic Association (NCAA) opgericht om het collegeworstelen te reguleren en te promoten. De NCAA organiseerde jaarlijks nationale kampioenschappen voor verschillende divisies, gebaseerd op de grootte en het niveau van de universiteiten. De meest prestigieuze divisie was Division I, die bestond uit ongeveer 80 teams.
<br/>
<br/>
Het collegeworstelen produceerde ook vele sterren en legendes, die zowel nationaal als internationaal succes behaalden. Enkele voorbeelden zijn:
                </MKTypography>

                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
Dan Gable: Een van de beste worstelaars aller tijden, die zowel als atleet als coach domineerde. Hij won drie NCAA-titels voor Iowa State University, een gouden medaille op de Olympische Spelen van 1972 zonder een punt te verliezen, en 15 NCAA-titels als coach voor de University of Iowa.
</li>
<li>
John Smith: Een zesvoudig wereldkampioen en tweevoudig olympisch kampioen, die bekend stond om zijn innovatieve technieken en tactieken. Hij won twee NCAA-titels voor Oklahoma State University, en werd later de hoofdcoach van hetzelfde team.
</li>
<li>
Cael Sanderson: De enige worstelaar die vier NCAA-titels won zonder een enkele wedstrijd te verliezen. Hij had een perfecte record van 159-0 voor Iowa State University, en won ook een gouden medaille op de Olympische Spelen van 2004. Hij is momenteel de hoofdcoach van Penn State University, waar hij acht NCAA-titels heeft gewonnen.
</li>
</ul>
</MKTypography>

<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
Dit zijn slechts enkele van de vele voorbeelden van de groei en evolutie van het worstelen in Amerika, die hebben geleid tot het huidige collegeworstelen, dat wordt beschouwd als een van de meest competitieve en spannende sporten in het land.
                </MKTypography>


              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 2" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 2: Collegeworstelen Vandaag de Dag
                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              Het collegeworstelen is vandaag de dag een van de meest populaire en prestigieuze sporten in Amerika. Het trekt miljoenen fans en toeschouwers, die de spannende wedstrijden volgen via televisie, internet, of live in de arena’s. Het collegeworstelen biedt ook tal van mogelijkheden voor student-atleten, die kunnen profiteren van beurzen, academische ondersteuning, en professionele begeleiding.
<br/>
<br/>
In dit hoofdstuk zullen we je meer vertellen over de belangrijkste competities en conferenties in het collegeworstelen, en de topprogramma’s en prestigieuze teams die de sport domineren.
              </MKTypography>

              <MKBox id="2.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                2.1 Belangrijke Competities en Conferenties
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
  <img
    src={Image2}
    alt="Twee college worstelaars in een intens duel op de mat."
    style={{
      maxWidth: "100%",
      border: "2px solid #fff",
      boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
      cursor: "pointer",
      outline: "none",
    }}
    onClick={() => openModal2(Image2)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
  Twee worstelaars in actie
</figcaption>
</figure>
                Het collegeworstelen wordt georganiseerd door de NCAA, die drie divisies heeft: Division I, Division II, en Division III. Elke divisie heeft zijn eigen regels, criteria, en kampioenschappen. De meest competitieve en bekende divisie is Division I, die bestaat uit ongeveer 80 teams uit verschillende staten.
<br/>
<br/>
De teams in Division I zijn verdeeld over verschillende conferenties, die regionale of thematische groepen vormen. De conferenties organiseren hun eigen competities en kampioenschappen, die dienen als kwalificatie voor het nationale kampioenschap. De meest prominente conferenties in het collegeworstelen zijn:
                </MKTypography>
                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
Big Ten Conference: De grootste en sterkste conferentie in het collegeworstelen, die bestaat uit 14 teams uit het Midwesten en het Noordoosten van het land. De Big Ten heeft de meeste nationale kampioenen voortgebracht, waaronder Penn State, Iowa, Ohio State, Michigan, en Minnesota.
</li>
<li>
Big 12 Conference: De tweede grootste en sterkste conferentie in het collegeworstelen, die bestaat uit 12 teams uit het Zuiden en het Westen van het land. De Big 12 heeft ook vele nationale kampioenen voortgebracht, waaronder Oklahoma State, Oklahoma, Iowa State, en Missouri.
</li>
<li>
Atlantic Coast Conference (ACC): Een opkomende conferentie in het collegeworstelen, die bestaat uit zes teams uit het Zuidoosten van het land. De ACC heeft recentelijk meer aandacht gekregen door de prestaties van teams als Virginia Tech, North Carolina State, en North Carolina.
</li>
<li>
Eastern Intercollegiate Wrestling Association (EIWA): Een historische conferentie in het collegeworstelen, die bestaat uit 16 teams uit het Oosten van het land. De EIWA is de oudste worstelconferentie in Amerika, die werd opgericht in 1905. De EIWA heeft ook enkele nationale kampioenen voortgebracht, waaronder Cornell, Lehigh, Princeton, en Navy.
</li>
</ul>
</MKTypography>

<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
Dit zijn slechts enkele van de vele conferenties in het collegeworstelen, die elk hun eigen rivaliteiten, tradities, en fans hebben. De conferenties zijn echter niet de enige factor die bepaalt hoe goed een team is. Er zijn ook andere aspecten die een rol spelen in het succes van een worstelprogramma.
                </MKTypography>


              </MKBox>
              <MKBox id="2.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                2.2 Topprogramma’s en Prestigieuze Teams
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Een worstelprogramma is een term die wordt gebruikt om te verwijzen naar de organisatie en structuur van een universitair worstelteam. Een worstelprogramma omvat onder andere de coach, de staf, de faciliteiten, de rekrutering, de training, de financiering, en de cultuur van een team.
<br/>
<br/>
Een goed worstelprogramma kan een groot verschil maken voor de ontwikkeling van student-atleten. Een goed worstelprogramma kan student-atleten voorzien van:
                </MKTypography>
                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
Een ervaren en gekwalificeerde coach, die hen kan leren hoe ze hun techniek, tactiek, conditie, en mentaliteit kunnen verbeteren.
</li>
<li>
Een toegewijde en professionele staf, die hen kan ondersteunen op gebieden zoals voeding, blessurepreventie, herstel, academische begeleiding, en carrièreplanning.
</li>
<li>
Een moderne en uitgeruste faciliteit, waar ze kunnen trainen op hoog niveau met geschikte apparatuur en materialen.
</li>
<li>
Een effectieve en ethische rekruteringstrategie, die hen kan helpen om talentvolle en gemotiveerde worstelaars aan te trekken en te behouden.
</li>
<li>
Een intensieve en gevarieerde trainingsschema, die hen kan uitdagen en voorbereiden op de verschillende wedstrijden en situaties.
</li>
<li>
Een voldoende en stabiele financiering, die hen kan helpen om de kosten van het worstelen te dekken, zoals reizen, uitrusting, beurzen, en andere uitgaven.
</li>
<li>
Een positieve en inspirerende cultuur, die hen kan motiveren en stimuleren om hun potentieel te bereiken, hun doelen na te streven, en hun passie voor de sport te delen.
</li>
</ul>
</MKTypography>
<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
Er zijn vele universiteiten in Amerika die uitstekende worstelprogramma’s hebben, die al jarenlang succesvol zijn in het collegeworstelen. Sommige van deze programma’s zijn:
                </MKTypography>


<MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
Penn State: Het huidige topteam in het collegeworstelen, dat al acht keer het nationale kampioenschap heeft gewonnen sinds 2011. Penn State staat bekend om zijn aanvallende en spectaculaire worstelstijl, die wordt geleid door coach Cael Sanderson, een van de beste worstelaars aller tijden.
</li>
<li>
Iowa: Het meest succesvolle team in de geschiedenis van het collegeworstelen, dat al meer dan 20 keer het nationale kampioenschap heeft gewonnen sinds 1975. Iowa staat bekend om zijn agressieve en dominante worstelstijl, die wordt geleid door coach Tom Brands, een voormalig olympisch kampioen.
</li>
<li>
Oklahoma State: Het op één na meest succesvolle team in de geschiedenis van het collegeworstelen, dat al meer dan 20 keer het nationale kampioenschap heeft gewonnen. Oklahoma State staat bekend om zijn technische en tactische worstelstijl, die wordt geleid door coach John Smith, een van de beste worstelaars aller tijden.
</li>

</ul>
</MKTypography>
<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
Dit zijn slechts enkele van de vele topprogramma’s en prestigieuze teams in het collegeworstelen, die elk hun eigen kenmerken, prestaties, en fans hebben. Het is echter niet gemakkelijk om deel uit te maken van deze programma’s en teams. Het vergt veel talent, hard werk, en toewijding om een worstelbeurs te bemachtigen.
                </MKTypography>


              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 3" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 3: Van College naar Olympisch Niveau en Profcompetities
                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              Het collegeworstelen is niet het eindpunt voor veel student-atleten die dromen van een carrière in de sport. Het is eerder een springplank naar hogere niveaus, waar ze zich kunnen meten met de beste worstelaars ter wereld. In dit hoofdstuk zullen we je meer vertellen over het pad naar de top in het professionele of olympische worstelen, en de successen en uitdagingen van Amerikaanse worstelaars op het wereldtoneel.
              </MKTypography>

              <MKBox id="3.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                3.1 Het Pad naar de Top
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Het collegeworstelen is een van de meest competitieve en veeleisende sporten in Amerika. Het vereist veel talent, hard werk, en toewijding om een worstelbeurs te bemachtigen, en nog meer om te schitteren in de nationale competities. Maar zelfs als je een van de beste collegeworstelaars bent, is dat geen garantie voor succes op het professionele of olympische niveau.
<br/>
<br/>
Er zijn namelijk verschillende factoren die het moeilijk maken om de overstap te maken van het collegeworstelen naar het professionele of olympische worstelen. Enkele van deze factoren zijn:
                </MKTypography>

                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
De stijlverschillen: Het collegeworstelen wordt beoefend volgens de folkstyle regels, die uniek zijn voor Amerika. Deze regels zijn gericht op het controleren en domineren van de tegenstander, door middel van punten voor rijden, ontsnappen, omkeren, en bijna-vallen. Het professionele of olympische worstelen wordt echter beoefend volgens de freestyle of Greco-Romeinse regels, die internationaal worden gebruikt. Deze regels zijn gericht op het scoren van punten door middel van worpen, kantelingen, blootstellingen, en vallen. Dit betekent dat collegeworstelaars hun techniek, tactiek, en strategie moeten aanpassen om te kunnen concurreren met worstelaars uit andere landen, die meer ervaring hebben met deze stijlen.
</li>
<li>
De leeftijdsverschillen: Het collegeworstelen is een sport voor jonge atleten, die meestal tussen de 18 en 23 jaar oud zijn. Het professionele of olympische worstelen is echter een sport voor volwassen atleten, die meestal tussen de 25 en 35 jaar oud zijn. Dit betekent dat collegeworstelaars moeten concurreren met oudere en meer ervaren worstelaars, die meer fysieke kracht, mentale volwassenheid, en technische verfijning hebben.
</li>
<li>
De financiële verschillen: Het collegeworstelen is een sport die wordt ondersteund door de universiteiten, die de student-atleten voorzien van beurzen, faciliteiten, coaching, en andere voordelen. Het professionele of olympische worstelen is echter een sport die weinig financiële steun krijgt van de overheid of sponsors. Dit betekent dat collegeworstelaars moeten zoeken naar andere bronnen van inkomsten om hun training, reizen, uitrusting, en levensonderhoud te bekostigen.
</li>

</ul>
</MKTypography>
<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
Dit zijn slechts enkele van de vele uitdagingen die collegeworstelaars moeten overwinnen om hun droom van een professionele of olympische carrière na te streven. Maar ondanks deze uitdagingen zijn er ook vele successen en inspirerende verhalen van Amerikaanse worstelaars die het hebben gemaakt op het hoogste niveau.
                </MKTypography>


              </MKBox>
              <MKBox id="3.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                3.2 Professioneel Worstelen en Olympische Spelen                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Het professionele of olympische worstelen is het hoogtepunt van de sport, waar de beste worstelaars ter wereld elkaar ontmoeten in prestigieuze competities en evenementen. Er zijn twee belangrijke vormen van professioneel of olympisch worstelen: freestyle en Greco-Romeins.
<br/>
<br/>
Freestyle worstelen is een vorm van worstelen waarbij alle soorten grepen op het hele lichaam zijn toegestaan, zolang ze niet gevaarlijk of ongepast zijn. Freestyle worstelen wordt beoefend door zowel mannen als vrouwen, in verschillende gewichtsklassen. Freestyle worstelen is een van de oudste sporten op de Olympische Spelen, die al sinds 1904 wordt gehouden voor mannen en sinds 2004 voor vrouwen.
<br/>
<br/>
Greco-Romeins worstelen is een vorm van worstelen waarbij alleen grepen boven de taille zijn toegestaan, en waarbij het gebruik van de benen om de tegenstander te grijpen of te werpen verboden is. Greco-Romeins worstelen wordt alleen beoefend door mannen, in verschillende gewichtsklassen. Greco-Romeins worstelen is de oudste vorm van worstelen op de Olympische Spelen, die al sinds 1896 wordt gehouden.
<br/>
<br/>
Amerikaanse worstelaars hebben vele successen behaald in zowel freestyle als Greco-Romeins worstelen, zowel op de Olympische Spelen als op andere internationale competities, zoals de wereldkampioenschappen, de Pan-Amerikaanse Spelen, of de World Cup. Enkele van de meest succesvolle en bekende Amerikaanse worstelaars zijn:
                </MKTypography>

                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
Jordan Burroughs: Een vijfvoudig wereldkampioen en olympisch kampioen in freestyle worstelen, die bekend staat om zijn explosieve en dynamische stijl. Hij won een gouden medaille op de Olympische Spelen van 2012 in Londen, en vier wereldtitels in 2011, 2013, 2015, en 2017. Hij won ook twee NCAA-titels voor de University of Nebraska.
</li>
<li>
Kyle Snyder: Een drievoudig wereldkampioen en olympisch kampioen in freestyle worstelen, die bekend staat om zijn kracht en techniek. Hij won een gouden medaille op de Olympische Spelen van 2016 in Rio de Janeiro, en drie wereldtitels in 2015, 2017, en 2019. Hij won ook drie NCAA-titels voor Ohio State University.
</li>


</ul>
</MKTypography>
<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
Dit zijn slechts enkele van de vele successen en inspirerende verhalen van Amerikaanse worstelaars op het professionele of olympische niveau. Ze laten zien dat het mogelijk is om je droom waar te maken, als je bereid bent om hard te werken, te leren, en te groeien.
                </MKTypography>



              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 4" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 4: Internationale Invloeden en Competities
                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              Het worstelen is een universele sport, die wordt beoefend en gewaardeerd in vele landen en culturen. Het worstelen kent ook vele stijlen en tradities, die zijn ontstaan en ontwikkeld in verschillende regio’s en periodes. In dit hoofdstuk zullen we je meer vertellen over de internationale invloeden en competities in het worstelen, en hoe ze de Amerikaanse worstelaars beïnvloeden en uitdagen.
              </MKTypography>

              <MKBox id="4.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                4.1 Buitenlandse Worstelstijlen en -Tradities
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
  <img
    src={Image3}
    alt="Luchtopname van een NCAA worstelwedstrijd in een vol stadion."
    style={{
      maxWidth: "100%",
      border: "2px solid #fff",
      boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
      cursor: "pointer",
      outline: "none",
    }}
    onClick={() => openModal3(Image3)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
  Een wedstrijd in het NCAA tournament
</figcaption>
</figure>
                Het worstelen is een sport die al sinds de oudheid wordt beoefend in verschillende delen van de wereld. Het is een van de oudste vormen van lichamelijke expressie, die vaak verbonden is met religieuze, culturele, of sociale betekenissen. Er zijn vele soorten worstelen, die elk hun eigen kenmerken, regels, en technieken hebben. Enkele voorbeelden zijn:
                </MKTypography>
                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
Sumo: Een traditionele Japanse worstelstijl, die wordt beschouwd als een nationale sport en een heilige ritueel. Sumo wordt beoefend door zware en sterke worstelaars, die elkaar proberen uit een cirkelvormige ring te duwen of op de grond te werpen.
</li>
<li>
Mongools worstelen: Een traditionele Mongoolse worstelstijl, die wordt beschouwd als een symbool van moed en kracht. Mongools worstelen wordt beoefend door gespierde en lenige worstelaars, die elkaar proberen op de grond te krijgen door middel van grepen op de bovenlichaam of de benen.
</li>
<li>
Turkse olieworstelen: Een traditionele Turkse worstelstijl, die wordt beschouwd als een erfgoed en een kunst. Turkse olieworstelen wordt beoefend door gesmeerde en gespierde worstelaars, die elkaar proberen te verslaan door middel van grepen op de broek of de riem.
</li>
<li>
Senegalees worstelen: Een traditionele Senegalese worstelstijl, die wordt beschouwd als een populaire en lucratieve sport. Senegalees worstelen wordt beoefend door atletische en kleurrijke worstelaars, die elkaar proberen te vloeren door middel van worpen, stoten, of trappen.
</li>


</ul>
</MKTypography>
<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
Dit zijn slechts enkele van de vele buitenlandse worstelstijlen en -tradities, die elk hun eigen geschiedenis, cultuur, en fans hebben. Deze stijlen hebben ook invloed gehad op het Amerikaanse collegeworstelen, door het introduceren van nieuwe technieken, tactieken, of uitdagingen. Veel Amerikaanse collegeworstelaars hebben gereisd of getraind in andere landen om hun horizon te verbreden en hun vaardigheden te verbeteren.
                </MKTypography>


              </MKBox>
              <MKBox id="4.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                4.2 Amerikanen op het Wereldtoneel
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Het Amerikaanse collegeworstelen is niet alleen gericht op de nationale competities, maar ook op de internationale competities. Er zijn vele internationale evenementen waar Amerikaanse collegeworstelaars kunnen deelnemen om zich te meten met de beste worstelaars ter wereld. Enkele van deze evenementen zijn:
                                </MKTypography>

                                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
De Olympische Spelen: Het grootste en meest prestigieuze evenement in het professionele of olympische worstelen, dat om de vier jaar wordt gehouden. De Olympische Spelen zijn het ultieme doel voor veel collegeworstelaars, die dromen van het winnen van een gouden medaille voor hun land.</li>
<li>
De Wereldkampioenschappen: Het tweede grootste en meest prestigieuze evenement in het professionele of olympische worstelen, dat elk jaar wordt gehouden. De Wereldkampioenschappen zijn een belangrijke kans voor collegeworstelaars om zich te kwalificeren voor de Olympische Spelen, of om hun reputatie te vestigen op het wereldtoneel.
</li>
<li>
De World Cup: Het tweede grootste en meest prestigieuze evenement in het regionale worstelen, dat elk jaar wordt gehouden. De World Cup is een belangrijke kans voor collegeworstelaars om deel uit te maken van een nationaal team, dat strijdt tegen andere nationale teams in een teamformaat.
</li>

</ul>
</MKTypography>
<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
Dit zijn slechts enkele van de vele internationale evenementen waar Amerikaanse collegeworstelaars kunnen deelnemen om hun talenten te tonen en hun dromen na te streven. Deze evenementen zijn echter niet alleen een bron van eer en glorie, maar ook een bron van uitdaging en moeilijkheid. Collegeworstelaars moeten concurreren met worstelaars uit andere landen, die vaak meer ervaring, stijl, of motivatie hebben.
                                </MKTypography>

              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 5" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 5: Worstelbeurzen en de Toekomst                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              Worstelen is een sport die veel inzet, discipline en doorzettingsvermogen vereist. Het is ook een sport die veel kansen biedt voor jonge atleten die hun talent willen combineren met een goede opleiding. In dit hoofdstuk bespreken we het belang van worstelbeurzen voor student-atleten en hoe Sportbeursamerika.nl hen kan helpen om hun droom waar te maken.
              </MKTypography>

              <MKBox id="5.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                5.1 Het Belang van Beurzen 
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Een beurs is een financiële ondersteuning die een universiteit of college biedt aan een student-atleet om zijn of haar studie- en sportkosten te dekken. Een beurs kan variëren van een gedeeltelijke vergoeding tot een volledige dekking van het collegegeld, de boeken, de huisvesting, de maaltijden en de reiskosten. Een beurs is een geweldige kans voor student-atleten om hun academische en sportieve ambities na te streven zonder zich zorgen te hoeven maken over de financiële last.
<br/>
<br/>
Een worstelbeurs is echter niet gemakkelijk te verkrijgen. Er zijn veel factoren die meespelen bij het toekennen van een beurs, zoals het niveau van de atleet, de academische prestaties, de beschikbaarheid van beurzen bij de universiteit, de behoeften van het team, de concurrentie van andere atleten en de regels van de NCAA. Bovendien zijn er verschillende soorten beurzen voor verschillende divisies en conferenties. Zo zijn er bijvoorbeeld in de NCAA Division I 79 worstelprogramma’s voor mannen die elk maximaal 9,9 beurzen mogen verdelen over hun teamleden. In Division II zijn er 62 programma’s met elk maximaal 9 beurzen. In Division III zijn er geen beurzen op basis van sportieve verdiensten, maar wel op basis van academische verdiensten of financiële behoeften. Voor vrouwen zijn er momenteel 23 worstelprogramma’s in Division I, II en III die onderdeel zijn van de Women’s Collegiate Wrestling Association (WCWA) en die elk maximaal 10 beurzen mogen aanbieden.
<br/>
<br/>
Het verkrijgen van een worstelbeurs kan dus een complex en uitdagend proces zijn, maar ook zeer lonend. Een worstelbeurs kan student-atleten niet alleen helpen om hun studie te financieren, maar ook om zich te ontwikkelen als atleten en als personen. Door te worstelen op collegiaal niveau kunnen student-atleten profiteren van hoogwaardige faciliteiten, coaching, training, wedstrijden, reizen en netwerken. Ze kunnen ook leren om te gaan met druk, verantwoordelijkheid, teamwork, leiderschap en diversiteit. Bovendien kunnen ze een waardevol diploma behalen dat hen kan helpen bij hun toekomstige carrière.
                </MKTypography>
              </MKBox>
              <MKBox id="5.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                5.2 Hoe Sportbeurs Amerika NL Kan Helpen 
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
  <img
    src={Image4}
    alt="Logo van Sportbeurs Amerika NL, bestaande uit een voetbal met het continent Noord-Amerika erop afgebeeld, met daarboven een afstudeerhoed (graduation cap) die bovenop de bal rust alsof het een hoofd is. De naam van het bedrijf is prominent weergegeven, wat de focus op sportbeurzen in Amerika voor Europese studenten benadrukt."
    style={{
      maxWidth: "100%",
      height: "auto",
      border: "2px solid #fff",
      boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)", // Apply shadow around the entire image
      cursor: "pointer",
      outline: "none",
      borderRadius: "50%", // Set border-radius to 50% for a circular image
      marginTop: "0px", // Add top margin to move the image up


    }}
    onClick={() => openModal4(Image4)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
  
</figcaption>
</figure>
Sportbeurs Amerika NL is een organisatie gespecialiseerd in het begeleiden van jonge sporttalenten naar sportbeurzen in Amerika.  Met een gepassioneerde en ervaren expert aan het roer, ondersteunt Sportbeurs Amerika NL de student-atleet bij iedere stap in het traject, vanaf de eerste oriëntatie tot aan de uiteindelijke plaatsing. Sportbeurs Amerika NL biedt onder andere de volgende diensten aan:
                </MKTypography>

                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
Een persoonlijke intakegesprek om de wensen, mogelijkheden en verwachtingen van de student-atleet te bespreken.
</li>
<li>
Een professionele evaluatie van het sportieve en academische niveau van de student-atleet.
</li>
<li>
Een uitgebreid netwerk van contacten met Amerikaanse coaches, universiteiten en colleges.
</li>
<li>
Een effectieve promotie van het profiel en de video’s van de student-atleet bij potentiële coaches.
</li>
<li>
Een deskundig advies over de beste opties voor de student-atleet op basis van zijn of haar voorkeuren, doelen en budget.
</li>
<li>
Een onderhandeling over de beste beursaanbiedingen namens de student-atleet.
</li>
<li>
Een hulp bij het aanvragen van de benodigde documenten, zoals het visum, het transcript, het diploma en de toelatingstesten.
</li>
<li>
Een voorbereiding op het leven in Amerika, zoals de cultuur, de taal, de regels en de tips.
</li>
<li>
Een nazorg en een follow-up tijdens het verblijf in Amerika.</li>

</ul>
</MKTypography>

<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Wil jij ook je passie voor American football combineren met een goede opleiding in Amerika? <Link to="/Diensten-Producten">Bekijk het volledige aanbod</Link> van Sportbeurs Amerika NL en maak een vrijblijvende afspraak om je te laten informeren over de mogelijkheden. 
      Ik sta voor je klaar om je te helpen bij het realiseren van je Amerikaanse droom.
    </MKTypography>


              </MKBox>
            </MKBox>

          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;
/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import React, { useState } from 'react'; // Step 1: Import useState


import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";


// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Images
import post1 from "assets/images/Logos/NCAA.png";
import post3 from "assets/images/Logos/NJCAA.png";



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {

 const articleStructure = [
    { id: 'intro', title: 'De NAIA: Een Alternatieve Route in College Sport in Amerika', isSubheader: false },

    { id: 'Hoofdstuk 1', title: 'De Oorsprong en Ontwikkeling van de NAIA', isSubheader: true },
    { id: '1.1', title: 'De NAIA en haar Unieke Divisies', isSubheader: true },
    { id: '1.2', title: 'De Academische Missie', isSubheader: true },

    { id: 'Hoofdstuk 2', title: 'Financiën, Media, en Zichtbaarheid', isSubheader: true },
    { id: '2.1', title: 'Uitdagingen en de Weg Vooruit', isSubheader: true },
    { id: '2.2', title: 'Tot Slot', isSubheader: true },


  
  ];

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };


  return (
     <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Inhoud
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Verberg</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Toon</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
            <MKBox id="intro" mb={3}>
              <MKTypography variant="h1" className="h1-as-h3" mb={2}>De NAIA: Een Alternatieve Route in College Sport in Amerika

</MKTypography>
              </MKBox>
              <MKBox id="Hoofdstuk 1" mb={3}>

              <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              De Oorsprong en Ontwikkeling van de NAIA
              </MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              De National Association of Intercollegiate Athletics, ofwel de NAIA, is een cruciale entiteit in het Amerikaanse college sportlandschap, zij het minder bekend dan de NCAA. Opgericht in 1937, heeft de NAIA zich ontwikkeld tot een gerenommeerde organisatie die student-atleten een unieke kans biedt om hun sportieve en academische aspiraties te verwezenlijken.

<br />
                <br />
                Vanaf het begin heeft de NAIA zich gefocust op het bieden van mogelijkheden aan kleinere onderwijsinstellingen, waarbij de nadruk ligt op het creëren van een evenwichtige ervaring voor student-atleten. De organisatie bevordert zowel competitieve sporten als academische excellentie, met de overtuiging dat de twee hand in hand kunnen gaan.

</MKTypography>
<MKBox id="1.1" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
                <br />
                De NAIA en haar Unieke Divisies
                </MKTypography>
                </MKBox>

                <MKTypography variant="body1" fontWeight="light" color="text"> 
                In tegenstelling tot de NCAA is de NAIA compacter georganiseerd, met een indeling in twee divisies die beide een rijke mix van sport en onderwijs aanbieden:

<br /><br />
  <MKTypography component="span" variant="body1" fontWeight="bold">
    Divisie I:{' '}
  </MKTypography>
  In deze divisie vinden we scholen die een hoger niveau van atletische beurzen aanbieden en waar de sportcompetitie zeer competitief is. Ondanks de kleinere omvang van de scholen in vergelijking met de NCAA Divisie I-instellingen, biedt de NAIA Divisie I een podium voor topatleten om hun vaardigheden te tonen en zich te ontwikkelen.  
  <br />
  <br />
  <MKTypography component="span" variant="body1" fontWeight="bold">
    Divisie II:{' '}
  </MKTypography>
  Deze divisie legt nog meer de nadruk op de balans tussen sport, academici, en campusbetrokkenheid. De atletische beurzen zijn beperkter, maar de mogelijkheden voor student-atleten om te excelleren in zowel hun sport als hun studie zijn volop aanwezig. 
  </MKTypography>

  <MKBox id="1.2" mb={3}>

  <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              De Academische Missie
              </MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              De NAIA hecht grote waarde aan de academische prestaties van haar student-atleten. Er zijn strikte academische normen vastgesteld om deelname aan sporten te waarborgen, en de aangesloten scholen zijn toegewijd aan het bieden van de nodige academische ondersteuning. Dit resulteert in een omgeving waar student-atleten worden aangemoedigd om te streven naar uitmuntendheid in elk aspect van hun leven.

                <br />
                </MKTypography>
                <MKBox id="Hoofdstuk 2" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Financiën, Media, en Zichtbaarheid
              </MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              Hoewel de NAIA financieel gezien misschien niet kan concurreren met de gigantische inkomsten van de NCAA, speelt het nog steeds een belangrijke rol in het financieren van atletiekbeurzen en het verbeteren van faciliteiten. Bovendien wint de NAIA aan zichtbaarheid dankzij streamingdiensten en sociale media, wat helpt om de boodschap van evenwichtige atletiek en academici te verspreiden.


<br />
                </MKTypography>
                <MKBox id="2.1" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Uitdagingen en de Weg Vooruit
              </MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              Net als de NCAA, staat de NAIA voor uitdagingen, waaronder het navigeren door de complexiteit van de amateurstatus van student-atleten en het waarborgen van gelijkheid in sport. Echter, met haar unieke positie en toewijding aan de ontwikkeling van student-atleten, is de NAIA goed gepositioneerd om een positieve impact te blijven maken op de levens van vele jonge atleten.

                </MKTypography>
                <MKBox id="2.2" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Tot Slot
              </MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              De National Association of Intercollegiate Athletics biedt een waardevol en uniek alternatief in de wereld van het Amerikaanse college sport. Met haar nadruk op balans, academische excellentie, en atletische competitie, blijft de NAIA een essentiële kracht in het vormgeven van de toekomst van student-atleten. Terwijl ze vooruitkijkt en blijft evolueren, blijft haar toewijding aan de ontwikkeling van jonge mensen onwankelbaar, waardoor ze een blijvende en positieve impact heeft op de Amerikaanse sport- en onderwijswereld.
              <br />


                </MKTypography>

              <hr style={{ margin: '20px 0'}} />
              
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post1}
                    title="NCAA"
                    description="Het Hart van College Sport in Amerika "
                    action={{
                      type: "internal",
                      route: "/NCAA",
                      color: "info",
                      label: "lees meer",
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post3}
                    title="NJCAA"
                    description="De Springplank naar Grote Colleges en de NCAA "
                    action={{
                      type: "internal",
                      route: "/NJCAA",
                      color: "info",
                      label: "lees meer",
                    }}
                  />
                </Grid>
              </Grid>

            </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;
/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sporten_Images/Zwemmen1.jpg";
import Image2 from "assets/images/Sporten_Images/Zwemmen2.jpeg";
import Image3 from "assets/images/Sporten_Images/Zwemmen3.avif";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import "../../../../H1asH3.css"


// Images

import { Link } from 'react-router-dom';


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Zwemmen in Amerika: Hoe je een Sportbeurs Kunt Krijgen en deel kunt uitmaken van een Prestigieuze Sportgeschiedenis', isSubheader: false },

    { id: 'Hoofdstuk 1', title: '1. De Geschiedenis en Ontwikkeling van Zwemmen in Amerika', isSubheader: false },
    { id: '1.1', title: '1.1 Vroege Jaren en de Rol van Universiteiten', isSubheader: true },
    { id: '1.2', title: '1.2 Groei en Professionalisering', isSubheader: true },

    { id: 'Hoofdstuk 2', title: '2. Collegiaal Zwemmen Vandaag de Dag', isSubheader: false },
    { id: '2.1', title: '2.1 Belangrijke Competities en Conferenties', isSubheader: true },
    { id: '2.2', title: '2.2 Topprogramma’s en Prestigieuze Teams', isSubheader: true },

    { id: 'Hoofdstuk 3', title: '3: Van College naar Professioneel Niveau', isSubheader: false },
    { id: '3.1', title: '3.1 Het Pad naar Professionalisme', isSubheader: true },
    { id: '3.2', title: '3.2 Professioneel Zwemmen en Olympische Spelen', isSubheader: true },


    { id: 'Hoofdstuk 4', title: '4. Internationale Invloeden en Competities', isSubheader: false },
    { id: '4.1', title: '4.1 Buitenlandse Invloeden', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikanen in het Buitenland', isSubheader: true },

    { id: 'Hoofdstuk 5', title: '5. Zwembeurzen en de Toekomst', isSubheader: false },
    { id: '5.1', title: '5.1 Het Belang van Beurzen', isSubheader: true },
    { id: '5.2', title: '5.2 Hoe Sportbeurs Amerika NL Kan Helpen', isSubheader: true },

  ];

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Inhoud
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Verberg</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Toon</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
            <MKBox id="intro" mb={3}>
            <MKTypography variant="h1" className="h1-as-h3" mb={2}>
              Zwemmen in Amerika: Hoe je een Sportbeurs Kunt Krijgen en deel kunt uitmaken van een Prestigieuze Sportgeschiedenis              </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
  <img
    src={Image1}
    alt="Zwemstadion gevuld met toeschouwers tijdens een college wedstrijd."
    style={{
      maxWidth: "100%",
      border: "2px solid #fff",
      boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
      cursor: "pointer",
      outline: "none",
    }}
    onClick={() => openModal1(Image1)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
  Een stadion tijdens een wedstrijd
</figcaption>
</figure>
              Zwemmen is een van de meest populaire en competitieve sporten ter wereld. Miljoenen mensen genieten van zwemmen, zowel recreatief als professioneel. Maar wist je dat zwemmen in Amerika een heel ander niveau heeft bereikt? Zwemmen in Amerika is niet alleen een sport, het is een cultuur, een traditie en een manier van leven.
<br/>
<br/>
In dit artikel zullen we je alles vertellen over de ontwikkeling van zwemmen als competitieve sport in de Verenigde Staten, het belang van zwemmen op collegeniveau voor de vooruitgang van de sport, en hoe je een sportbeurs kunt krijgen om te studeren en te zwemmen in Amerika. We zullen ook ingaan op de rol van Sportbeursamerika.nl, een organisatie die jonge zwemmers helpt om hun droom waar te maken.
<br/>
<br/>
Zwemmen is een sport die al eeuwenlang wordt beoefend, maar pas in de 19e eeuw begon het zich te ontwikkelen als een georganiseerde en gereguleerde activiteit. De eerste moderne Olympische Spelen in 1896 bevatten zwemwedstrijden als een van de hoofdevenementen, en sindsdien is zwemmen een vast onderdeel van de Olympische agenda.
<br/>
<br/>
Maar waarom is zwemmen zo populair en succesvol in Amerika? Wat maakt het zo speciaal en uniek? Het antwoord ligt in de rol van universiteiten in het bevorderen van zwemmen als een academische en atletische discipline. Universiteiten in Amerika bieden niet alleen hoogwaardig onderwijs aan, maar ook uitstekende faciliteiten, coaching en begeleiding voor student-atleten die zich willen specialiseren in zwemmen. Bovendien bieden ze ook financiële steun aan in de vorm van sportbeurzen, waardoor getalenteerde zwemmers de kans krijgen om hun potentieel te ontplooien zonder zich zorgen te hoeven maken over de kosten van hun studie.
<br/>
<br/>
Sportbeurzen zijn echter niet gemakkelijk te verkrijgen. Ze zijn zeer concurrerend en vereisen een hoge mate van toewijding, discipline en prestatie. Daarom is het belangrijk om goed voorbereid te zijn en professionele hulp te zoeken bij het aanvragen van een sportbeurs. Dat is waar Sportbeursamerika.nl om de hoek komt kijken.
<br/>
<br/>
Sportbeursamerika.nl is een organisatie die gespecialiseerd is in het begeleiden van jonge zwemmers naar sportbeurzen in Amerika. Ze hebben een team van experts die je kunnen helpen met alle aspecten van het proces, zoals het kiezen van de juiste universiteit, het opstellen van je cv en motivatiebrief, het voorbereiden op je toelatingsexamens en interviews, het regelen van je visum en verzekering, en nog veel meer. Ze hebben ook contacten met honderden universiteiten en coaches in Amerika, waardoor ze je kunnen koppelen aan de beste opties voor jouw profiel en doelen.
<br/>
<br/>
Als je dus droomt van studeren en zwemmen in Amerika, dan is Sportbeursamerika.nl je beste bondgenoot. Ze hebben al honderden zwemmers geholpen om hun droom waar te maken, en ze kunnen jou ook helpen. Maar voordat je contact met hen opneemt, laten we eerst eens kijken naar de geschiedenis en ontwikkeling van zwemmen in Amerika, en waarom het zo’n geweldige kans is voor jou.
              </MKTypography>
              {/* Add bulleted list or other content as needed */}
            </MKBox>
             {/* Main chapters and subchapters with placeholder text */}
            <MKBox id="Hoofdstuk 1" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>              Hoofdstuk 1: De Geschiedenis en Ontwikkeling van Zwemmen in Amerika
              </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              Zwemmen heeft een lange en rijke geschiedenis in Amerika. Het begon allemaal in de late 19e eeuw, toen universiteiten begonnen met het vormen van hun eigen zwemteams en -competities. Dit was het begin van een traditie die zou leiden tot de opkomst van zwemmen als een nationale sport.
              </MKTypography>

              <MKBox id="1.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                1.1 Vroege Jaren en de Rol van Universiteiten
                </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                De eerste universiteit die een officieel zwemteam oprichtte was Harvard in 1878. Al snel volgden andere prestigieuze universiteiten zoals Yale, Princeton, Columbia en Cornell. Deze universiteiten organiseerden onderlinge wedstrijden en toernooien, die de aandacht trokken van het publiek en de media. Ze introduceerden ook nieuwe regels en technieken voor het zwemmen, zoals de borstcrawl, de rugslag en de vlinderslag.
<br/>
<br/>
In 1902 werd de Intercollegiate Swimming Association (ISA) opgericht, de eerste nationale organisatie voor universitair zwemmen. De ISA organiseerde jaarlijkse kampioenschappen voor mannen, waarbij de beste teams en individuele zwemmers werden bekroond. In 1916 werd de National Collegiate Athletic Association (NCAA) opgericht, een overkoepelende organisatie voor alle universitaire sporten. De NCAA nam de verantwoordelijkheid over voor het organiseren van de nationale kampioenschappen voor universitair zwemmen, die tot op de dag van vandaag worden gehouden.
<br/>
<br/>
Universitair zwemmen was echter niet alleen voor mannen. Vrouwen begonnen ook te zwemmen op universitair niveau in de vroege 20e eeuw, hoewel ze te maken hadden met meer beperkingen en discriminatie. De eerste vrouwelijke universitaire zwemteams werden gevormd in 1914 aan de University of Chicago en in 1916 aan de University of Wisconsin. In 1922 werd de Women’s Intercollegiate Swimming Association (WISA) opgericht, een equivalent van de ISA voor vrouwen. De WISA organiseerde ook jaarlijkse kampioenschappen voor vrouwen, die veel populariteit genoten.
<br/>
<br/>
Universitair zwemmen speelde een cruciale rol in het bevorderen van zwemmen als een professionele sport in Amerika. Veel universitaire zwemmers gingen door naar professionele competities en Olympische Spelen, waar ze hun land vertegenwoordigden en medailles wonnen. Sommige van de meest bekende namen zijn Johnny Weissmuller, Mark Spitz, Matt Biondi, Janet Evans, Michael Phelps en Katie Ledecky.
                </MKTypography>
              </MKBox>
              <MKBox id="1.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                1.2 Groei en Professionalisering
                </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Naast universitair zwemmen ontstonden er ook andere vormen van professioneel zwemmen in Amerika. Een daarvan was het masterzwemmen, dat zich richtte op volwassen zwemmers die hun vaardigheden wilden verbeteren of hun conditie wilden behouden. Het masterzwemmen begon in 1970 met de oprichting van de United States Masters Swimming (USMS), een organisatie die lokale clubs, wedstrijden en programma’s voor volwassen zwemmers aanbood. Het masterzwemmen groeide snel uit tot een wereldwijde beweging, met miljoenen leden in meer dan 100 landen.
<br/>
<br/>
Een andere vorm van professioneel zwemmen was het openwaterzwemmen, dat zich afspeelde in natuurlijke wateren zoals meren, rivieren en oceanen. Het openwaterzwemmen was een uitdagende en avontuurlijke sport, die veel uithoudingsvermogen, navigatievaardigheden en aanpassingsvermogen vereiste. Het openwaterzwemmen kreeg meer erkenning in 2008, toen het werd toegevoegd als een Olympisch evenement. Sommige van de meest beroemde openwaterzwemmers zijn Gertrude Ederle, Lynne Cox, Maarten van der Weijden en Ous Mellouli.
<br/>
<br/>
Professioneel zwemmen bereikte zijn hoogtepunt tijdens de Olympische Spelen, waar Amerikaanse zwemmers domineerden. Sinds 1896 heeft Amerika meer dan 500 medailles gewonnen in het zwemmen, waarvan meer dan 200 gouden. Dit maakt Amerika verreweg het meest succesvolle land in het Olympisch zwemmen.
<br/>
<br/>
Maar hoe kun jij ook deel uitmaken van deze glorieuze sportgeschiedenis? Hoe kun je studeren en zwemmen in Amerika met een sportbeurs? Dat zullen we je vertellen in het volgende hoofdstuk.

                </MKTypography>


              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 2" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>              Hoofdstuk 2: Collegiaal Zwemmen Vandaag de Dag
                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              Zoals je hebt gezien, heeft universitair zwemmen een lange en trotse geschiedenis in Amerika. Maar hoe zit het met het heden? Hoe is universitair zwemmen vandaag de dag georganiseerd en gestructureerd? Wat zijn de belangrijkste competities en conferenties? Welke zijn de topprogramma’s en prestigieuze teams? Laten we dat eens nader bekijken.
              </MKTypography>

              <MKBox id="2.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                2.1 Belangrijke Competities en Conferenties
                  </MKTypography>
                  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                  <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
  <img
    src={Image2}
    alt="Katie Ledecky zwemt voor Stanford in een college competitie."
    style={{
      maxWidth: "100%",
      border: "2px solid #fff",
      boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
      cursor: "pointer",
      outline: "none",
    }}
    onClick={() => openModal2(Image2)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
  Katie Ledecky in actie als zwemster voor Stanford
</figcaption>
</figure>
                Universitair zwemmen in Amerika wordt voornamelijk gereguleerd door de NCAA, die drie divisies heeft: Division I, Division II en Division III. Elke divisie heeft zijn eigen regels, criteria en kampioenschappen. Over het algemeen geldt dat Division I de meest competitieve en prestigieuze divisie is, met de beste zwemmers, coaches en faciliteiten. Division II en III zijn minder competitief, maar bieden nog steeds een hoge kwaliteit van onderwijs en sport.
<br/>
<br/>
Binnen elke divisie zijn er verschillende conferenties, die groepen van universiteiten zijn die geografisch of historisch verbonden zijn. Elke conferentie organiseert zijn eigen kampioenschappen, waarbij de beste teams en individuele zwemmers zich kwalificeren voor de nationale kampioenschappen van de NCAA. Sommige van de meest bekende conferenties in het universitair zwemmen zijn:
                </MKTypography>

                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
De Southeastern Conference (SEC), die bestaat uit 14 universiteiten in het zuidoosten van Amerika, zoals Alabama, Florida, Georgia, Kentucky, LSU, Tennessee en Texas A&M. De SEC staat bekend om zijn dominantie in het zwemmen, vooral bij de mannen. De SEC heeft meer dan 50 nationale titels gewonnen in het zwemmen, waarvan meer dan 40 door de mannen van Florida.
</li>
<li>
De Pacific-12 Conference (Pac-12), die bestaat uit 12 universiteiten aan de westkust van Amerika, zoals Arizona, California, Stanford, UCLA, USC en Washington. De Pac-12 staat bekend om zijn excellentie in het zwemmen, vooral bij de vrouwen. De Pac-12 heeft meer dan 40 nationale titels gewonnen in het zwemmen, waarvan meer dan 30 door de vrouwen van Stanford.
</li>
<li>
De Big Ten Conference (Big Ten), die bestaat uit 14 universiteiten in het middenwesten en noordoosten van Amerika, zoals Indiana, Michigan, Minnesota, Ohio State, Penn State en Wisconsin. De Big Ten staat bekend om zijn diversiteit en balans in het zwemmen, met sterke teams bij zowel mannen als vrouwen. De Big Ten heeft meer dan 30 nationale titels gewonnen in het zwemmen, waarvan meer dan 20 door de mannen van Michigan.
</li>
</ul>
</MKTypography>
<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
Dit zijn slechts enkele voorbeelden van de vele conferenties die bestaan in het universitair zwemmen. Elke conferentie heeft zijn eigen cultuur, rivaliteit en traditie, die bijdragen aan de spanning en opwinding van het universitair zwemmen.
                </MKTypography>

              </MKBox>
              <MKBox id="2.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                2.2 Topprogramma’s en Prestigieuze Teams
                  </MKTypography>
                  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                  Naast de conferenties zijn er ook individuele universiteiten die zich onderscheiden door hun topprogramma’s en prestigieuze teams in het zwemmen. Deze universiteiten bieden niet alleen uitstekend onderwijs aan hun studenten, maar ook eersteklas coaching, training en faciliteiten voor hun atleten. Ze trekken ook veel talentvolle zwemmers aan uit binnen- en buitenland, die willen studeren en zwemmen op het hoogste niveau.
<br/>
<br/>
Sommige van deze topuniversiteiten zijn:
                </MKTypography>
                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
Stanford University: Stanford is een van de meest gerenommeerde universiteiten ter wereld op academisch gebied, maar ook op sportgebied. Stanford heeft een van de beste vrouwelijke zwemprogramma’s in Amerika, met meer dan 10 nationale titels en meer dan 100 Olympische medailles. Stanford heeft ook een sterk mannelijk zwemprogramma, met meer dan 5 nationale titels en meer dan 50 Olympische medailles. Enkele beroemde alumni van Stanford zijn John Hencken, Pablo Morales, Jenny Thompson, Summer Sanders, Misty Hyman, Janet Evans en Katie Ledecky.
</li>
<li>
University of California: California is een andere topuniversiteit op academisch en sportief gebied. California heeft een van de beste mannelijke zwemprogramma’s in Amerika, met meer dan 10 nationale titels en meer dan 100 Olympische medailles. California heeft ook een goed vrouwelijk zwemprogramma, met meer dan 5 nationale titels en meer dan 50 Olympische medailles. Enkele beroemde alumni van California zijn Matt Biondi, Anthony Ervin, Natalie Coughlin, Nathan Adrian, Dana Vollmer en Ryan Murphy.</li>
<li>
University of Texas: Texas is een van de grootste en meest invloedrijke universiteiten in Amerika, met een sterke reputatie op academisch en sportief gebied. Texas heeft een van de beste mannelijke zwemprogramma’s in Amerika, met meer dan 15 nationale titels en meer dan 100 Olympische medailles. Texas heeft ook een goed vrouwelijk zwemprogramma, met meer dan 5 nationale titels en meer dan 50 Olympische medailles. Enkele beroemde alumni van Texas zijn Josh Davis, Aaron Peirsol, Ian Crocker, Brendan Hansen, Ricky Berens en Joseph Schooling.

</li>
</ul>
</MKTypography>
<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
Dit zijn slechts enkele van de vele topuniversiteiten die bestaan in het universitair zwemmen. Elke universiteit heeft zijn eigen geschiedenis, filosofie en visie, die bijdragen aan de ontwikkeling van zwemmers.
<br/>
<br/>
Zoals je kunt zien, is universitair zwemmen vandaag de dag een zeer dynamische en competitieve sport, die veel mogelijkheden biedt voor student-atleten die willen excelleren in zowel academisch als atletisch opzicht. Maar hoe kun je toegang krijgen tot deze mogelijkheden? Hoe kun je een sportbeurs krijgen om te studeren en te zwemmen in Amerika? Dat zullen we je vertellen in het volgende hoofdstuk.

                </MKTypography>
               


              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 3" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>              Hoofdstuk 3: Van College naar Professioneel Niveau                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              Studeren en zwemmen in Amerika is niet alleen een geweldige ervaring, maar ook een geweldige kans om je carrière als professioneel zwemmer te starten. Veel universitaire zwemmers gaan door naar professioneel niveau, waar ze hun land vertegenwoordigen in internationale competities en Olympische Spelen. Maar hoe maak je de overstap van college naar professioneel niveau? Wat zijn de uitdagingen en voordelen van deze overstap? Laten we dat eens nader bekijken.
              </MKTypography>

              <MKBox id="3.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                3.1 Het Pad naar Professionalisme
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Om de overstap van college naar professioneel niveau te maken, moet je eerst voldoen aan de eisen van de NCAA. De NCAA heeft strikte regels over de amateurstatus van student-atleten, die inhouden dat je geen geld mag verdienen of accepteren voor je sportprestaties. Dit betekent dat je geen sponsorcontracten, prijzengeld of andere vormen van compensatie mag aangaan als je nog studeert en zwemt op universitair niveau.
<br/>
<br/>
Als je echter klaar bent met je studie of besluit om je studie te beëindigen, kun je je amateurstatus opgeven en een professionele zwemmer worden. Dit betekent dat je je kunt aansluiten bij een professionele zwemorganisatie, zoals USA Swimming, die de nationale bestuursorganisatie is voor het zwemmen in Amerika. USA Swimming biedt je de mogelijkheid om deel te nemen aan professionele zwemcompetities, zoals de Pro Swim Series, de US Open, de US Nationals en de Olympic Trials. Deze competities zijn bedoeld om je vaardigheden te verbeteren, je ranking te verhogen en je te kwalificeren voor internationale evenementen.
<br/>
<br/>
Als professionele zwemmer kun je ook profiteren van sponsorcontracten, prijzengeld en andere vormen van compensatie voor je sportprestaties. Dit kan je helpen om je financiële situatie te verbeteren, je levensstijl te ondersteunen en je motivatie te vergroten. Echter, het worden van een professionele zwemmer brengt ook meer verantwoordelijkheden en uitdagingen met zich mee. Je moet bijvoorbeeld zorgen voor je eigen trainingsschema, voedingsplan, reiskosten, medische zorg en belastingen. Je moet ook omgaan met meer concurrentie, druk en verwachtingen van jezelf en anderen.
<br/>
<br/>
Daarom is het belangrijk om goed voorbereid te zijn op de overstap van college naar professioneel niveau. Je moet niet alleen beschikken over uitstekende zwemvaardigheden, maar ook over een sterke mentale instelling, een duidelijk doel en een goede ondersteuning. Je moet ook realistisch zijn over je kansen en mogelijkheden, en bereid zijn om hard te werken en offers te brengen.
                </MKTypography>
              </MKBox>
              <MKBox id="3.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                3.2 Professioneel Zwemmen en Olympische Spelen
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Een van de grootste dromen van elke professionele zwemmer is om deel te nemen aan de Olympische Spelen, het meest prestigieuze en belangrijke sportevenement ter wereld. De Olympische Spelen zijn niet alleen een kans om je land te vertegenwoordigen en medailles te winnen, maar ook om geschiedenis te schrijven en een legende te worden.
<br/>
<br/>
Om deel te nemen aan de Olympische Spelen, moet je echter eerst voldoen aan de eisen van het Internationaal Olympisch Comité (IOC) en de Internationale Zwemfederatie (FINA). Deze organisaties hebben strikte regels over de kwalificatiecriteria, het aantal deelnemers per land, de antidopingcontroles en andere aspecten van het Olympisch zwemmen.
<br/>
<br/>
Een van de belangrijkste eisen om zich te kwalificeren voor de Olympische Spelen is om een bepaalde tijdslimiet te halen in een erkende wedstrijd. Deze tijdslimiet wordt bepaald door FINA op basis van het gemiddelde van de beste 16 prestaties ter wereld in elke discipline in de afgelopen vier jaar. De tijdslimiet verschilt per geslacht, leeftijdsgroep en slag.
<br/>
<br/>
Een andere belangrijke eis om zich te kwalificeren voor de Olympische Spelen is om geselecteerd te worden door je nationale zwemorganisatie, zoals USA Swimming. Elke nationale zwemorganisatie heeft zijn eigen selectieprocedure, die meestal bestaat uit een reeks van proeven of kampioenschappen, waarbij de beste zwemmers worden gekozen om het land te vertegenwoordigen. Elke nationale zwemorganisatie heeft ook een beperkt aantal plaatsen per discipline, die afhankelijk zijn van het aantal gekwalificeerde zwemmers en de quota van het IOC en FINA.
<br/>
<br/>
Als je erin slaagt om aan deze eisen te voldoen en je te kwalificeren voor de Olympische Spelen, dan ben je een van de gelukkigen die de kans krijgt om deel uit te maken van het grootste sportevenement ter wereld. Je zult in staat zijn om te concurreren met de beste zwemmers ter wereld, jezelf uit te dagen en te verbeteren, en misschien zelfs een medaille te winnen en je naam in de geschiedenisboeken te schrijven.
<br/>
<br/>
Zoals je kunt zien, is de overstap van college naar professioneel niveau een spannende en lonende reis, die veel mogelijkheden biedt voor je carrière als zwemmer. Maar hoe kun je deze reis beginnen? Hoe kun je een sportbeurs krijgen om te studeren en te zwemmen in Amerika? Dat zullen we je vertellen in het volgende hoofdstuk.
                </MKTypography>
              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 4" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>              Hoofdstuk 4: Internationale Invloeden en Competities
                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              Zwemmen in Amerika is niet alleen een nationale sport, maar ook een internationale sport. Zwemmen in Amerika wordt beïnvloed door en beïnvloedt ook andere landen en culturen, die hun eigen stijlen, technieken en tradities hebben in het zwemmen. Zwemmen in Amerika wordt ook uitgedaagd en verrijkt door andere internationale competities, die de mogelijkheid bieden om te leren van en te concurreren met andere zwemmers over de hele wereld. Maar hoe zijn deze internationale invloeden en competities ontstaan en ontwikkeld? Hoe beïnvloeden ze het universitair en professioneel zwemmen in Amerika? Laten we dat eens nader bekijken.
              </MKTypography>

              <MKBox id="4.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                4.1 Buitenlandse Invloeden
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
  <img
    src={Image3}
    alt="Zwemster in volle actie tijdens het nationale college kampioenschap."
    style={{
      maxWidth: "100%",
      border: "2px solid #fff",
      boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
      cursor: "pointer",
      outline: "none",
    }}
    onClick={() => openModal3(Image3)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
    Het NCAA nationale kampioenschap

</figcaption>
</figure>
                Zwemmen is een universele sport, die in bijna elk land ter wereld wordt beoefend. Elk land heeft zijn eigen geschiedenis, cultuur en identiteit in het zwemmen, die worden weerspiegeld in de manier waarop ze zwemmen, trainen en coachen. Sommige landen hebben een sterke invloed gehad op het zwemmen in Amerika, zowel positief als negatief.
<br/>
<br/>
Een van de positieve invloeden is Australië, dat een van de grootste rivalen en bondgenoten van Amerika is in het zwemmen. Australië heeft een lange en trotse traditie in het zwemmen, met meer dan 100 Olympische medailles en meer dan 20 wereldrecords. Australië staat bekend om zijn innovatie en creativiteit in het zwemmen, met nieuwe technieken, strategieën en methoden. Australië heeft ook veel getalenteerde en charismatische zwemmers voortgebracht, zoals Dawn Fraser, Shane Gould, Ian Thorpe, Grant Hackett, Libby Trickett en Cate Campbell.
<br/>
<br/>
Australië heeft het zwemmen in Amerika beïnvloed door een gezonde concurrentie en samenwerking te creëren, die beide landen motiveert om zich te verbeteren en te leren van elkaar. Veel Australische en Amerikaanse zwemmers hebben getraind, gestudeerd of gewerkt samen, waardoor ze hun vaardigheden, kennis en vriendschappen hebben vergroot. Sommige voorbeelden zijn:
                </MKTypography>
                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
In 1976 trainde Mark Spitz, de Amerikaanse ster die zeven gouden medailles won in München, samen met John Devitt, de Australische legende die twee gouden medailles won in Melbourne en Rome.
</li>
<li>
In 1988 studeerde Matt Biondi, de Amerikaanse sensatie die zeven medailles won in Seoul, af aan de University of California met een diploma in politieke economie.
</li>
<li>
In 2016 studeerde Joseph Schooling, de Singaporese verrassing die de eerste gouden medaille voor zijn land won op de 100 meter vlinderslag in Rio de Janeiro, af aan de University of Texas met een diploma in economie.
</li>
</ul>
</MKTypography>
<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
Dit zijn slechts enkele voorbeelden van hoe buitenlandse invloeden het zwemmen in Amerika hebben beïnvloed. Er zijn nog veel meer landen en culturen die hun eigen stempel hebben gedrukt op het zwemmen, zoals Japan, China, Rusland, Frankrijk, Brazilië en Zuid-Afrika. Het zwemmen in Amerika is dus niet alleen een nationale sport, maar ook een internationale sport, die voortdurend evolueert en verandert door de interactie met andere landen en culturen.

                </MKTypography>



              </MKBox>
              <MKBox id="4.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                4.2 Amerikanen in het Buitenland
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Zwemmen in Amerika is niet alleen beïnvloed door en beïnvloedt ook andere landen en culturen, maar ook uitgedaagd en verrijkt door andere internationale competities. Zwemmen in Amerika biedt de mogelijkheid om te leren van en te concurreren met andere zwemmers over de hele wereld, die hun eigen stijlen, technieken en tradities hebben in het zwemmen. Zwemmen in Amerika biedt ook de mogelijkheid om te reizen naar en te ervaren andere plaatsen, mensen en culturen, die je horizon kunnen verbreden en je perspectief kunnen veranderen.
<br/>
<br/>
Een van de belangrijkste internationale competities waar Amerikaanse zwemmers aan deelnemen is het wereldkampioenschap zwemmen, dat om de twee jaar wordt gehouden door FINA. Het wereldkampioenschap zwemmen is een evenement dat alle disciplines van het zwemmen omvat, zoals langebaan-, kortebaan-, openwater-, synchroon- en schoonspringen. Het wereldkampioenschap zwemmen is een kans om te strijden tegen de beste zwemmers ter wereld, je ranking te verhogen en je kwalificatie te verbeteren voor andere evenementen.
<br/>
<br/>
Een andere belangrijke internationale competitie waar Amerikaanse zwemmers aan deelnemen is de wereldbeker zwemmen, die elk jaar wordt gehouden door FINA. De wereldbeker zwemmen is een serie van kortebaanwedstrijden die plaatsvinden in verschillende steden over de hele wereld. De wereldbeker zwemmen is een kans om te racen tegen verschillende tegenstanders, je vaardigheden te testen en je prijzengeld te verdienen.
<br/>
<br/>
Een derde belangrijke internationale competitie waar Amerikaanse zwemmers aan deelnemen is de Pan-Amerikaanse Spelen, die om de vier jaar worden gehouden door de Pan-Amerikaanse Sportorganisatie (PASO). De Pan-Amerikaanse Spelen zijn een multisportevenement dat atleten uit Noord-, Zuid- en Midden-Amerika samenbrengt. De Pan-Amerikaanse Spelen zijn een kans om je continent te vertegenwoordigen, je solidariteit te tonen en je medailles te winnen.
<br/>
<br/>
Dit zijn slechts enkele voorbeelden van de vele internationale competities waar Amerikaanse zwemmers aan deelnemen. Er zijn nog veel meer internationale competities die bestaan in het zwemmen, zoals de Gemenebestspelen, de Europese kampioenschappen, de Aziatische Spelen en de Afrikaanse Spelen. 
                </MKTypography>
              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 5" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>              Hoofdstuk 5: Zwembeurzen en de Toekomst
                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              Zwemmen is een sport die veel voordelen biedt voor jonge atleten. Het verbetert de fysieke en mentale gezondheid, het stimuleert de discipline en het teamwork, en het opent de deur naar vele mogelijkheden. Een van die mogelijkheden is het verkrijgen van een zwembeurs in Amerika, waar je kunt studeren en zwemmen op het hoogste niveau. In dit hoofdstuk zullen we bespreken hoe belangrijk zwembeurzen zijn voor de toekomst van de sport, en hoe Sportbeursamerika.nl je kan helpen om je droom waar te maken.              </MKTypography>

              <MKBox id="5.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                5.1 Het Belang van Beurzen
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Zwembeurzen zijn een geweldige kans voor jonge zwemmers om hun talent te ontwikkelen en hun academische doelen te bereiken. Zwembeurzen zijn financiële steun die wordt verleend door universiteiten aan student-atleten die voldoen aan bepaalde criteria. Deze criteria kunnen variëren afhankelijk van de universiteit, de divisie, de conferentie, en de coach. Over het algemeen wordt er gekeken naar de zwemprestaties, de academische resultaten, het karakter, en de motivatie van de kandidaten.
<br/>
<br/>
Zwembeurzen kunnen verschillende voordelen hebben voor de student-atleten, zoals:
                </MKTypography>
                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
Het verminderen of elimineren van de kosten van het collegegeld, de boeken, de huisvesting, en andere uitgaven.
</li>
<li>
Het bieden van toegang tot hoogwaardige faciliteiten, apparatuur, coaching, medische zorg, en voeding.
</li>
<li>
Het vergroten van de blootstelling aan professionele en olympische scouts en netwerken.
</li>
<li>
Het verbeteren van de sociale en culturele ervaring door het ontmoeten van mensen uit verschillende achtergronden en landen.
</li>
<li>
Het vergroten van de kansen op een succesvolle carrière na het afstuderen.</li>
</ul>
</MKTypography>
<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
Zwembeurzen zijn ook belangrijk voor de toekomst van de sport, omdat ze bijdragen aan:

                </MKTypography>
                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
Het verhogen van het niveau en de concurrentiekracht van het zwemmen in Amerika en in de wereld.
</li>
<li>
Het stimuleren van innovatie en onderzoek op het gebied van zwemtechniek, training, en preventie van blessures.
</li>
<li>
Het inspireren en motiveren van jonge generaties om te zwemmen en hun potentieel te bereiken.
</li>

</ul>
</MKTypography>


              </MKBox>
              <MKBox id="5.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                5.2 Hoe Sportbeurs Amerika NL Kan Helpen 
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
  <img
    src={Image4}
    alt="Logo van Sportbeurs Amerika NL, bestaande uit een voetbal met het continent Noord-Amerika erop afgebeeld, met daarboven een afstudeerhoed (graduation cap) die bovenop de bal rust alsof het een hoofd is. De naam van het bedrijf is prominent weergegeven, wat de focus op sportbeurzen in Amerika voor Europese studenten benadrukt."
    style={{
      maxWidth: "100%",
      height: "auto",
      border: "2px solid #fff",
      boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)", // Apply shadow around the entire image
      cursor: "pointer",
      outline: "none",
      borderRadius: "50%", // Set border-radius to 50% for a circular image
      marginTop: "0px", // Add top margin to move the image up


    }}
    onClick={() => openModal4(Image4)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
  
</figcaption>
</figure>
Sportbeurs Amerika NL is een organisatie gespecialiseerd in het begeleiden van jonge sporttalenten naar sportbeurzen in Amerika.  Met een gepassioneerde en ervaren expert aan het roer, ondersteunt Sportbeurs Amerika NL de student-atleet bij iedere stap in het traject, vanaf de eerste oriëntatie tot aan de uiteindelijke plaatsing. Sportbeurs Amerika NL biedt onder andere de volgende diensten aan:
                </MKTypography>

                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
Een persoonlijke intakegesprek om de wensen, mogelijkheden en verwachtingen van de student-atleet te bespreken.
</li>
<li>
Een professionele evaluatie van het sportieve en academische niveau van de student-atleet.
</li>
<li>
Een uitgebreid netwerk van contacten met Amerikaanse coaches, universiteiten en colleges.
</li>
<li>
Een effectieve promotie van het profiel en de video’s van de student-atleet bij potentiële coaches.
</li>
<li>
Een deskundig advies over de beste opties voor de student-atleet op basis van zijn of haar voorkeuren, doelen en budget.
</li>
<li>
Een onderhandeling over de beste beursaanbiedingen namens de student-atleet.
</li>
<li>
Een hulp bij het aanvragen van de benodigde documenten, zoals het visum, het transcript, het diploma en de toelatingstesten.
</li>
<li>
Een voorbereiding op het leven in Amerika, zoals de cultuur, de taal, de regels en de tips.
</li>
<li>
Een nazorg en een follow-up tijdens het verblijf in Amerika.</li>

</ul>
</MKTypography>

<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Wil jij ook je passie voor American football combineren met een goede opleiding in Amerika? <Link to="/Diensten-Producten">Bekijk het volledige aanbod</Link> van Sportbeurs Amerika NL en maak een vrijblijvende afspraak om je te laten informeren over de mogelijkheden. 
      Ik sta voor je klaar om je te helpen bij het realiseren van je Amerikaanse droom.
    </MKTypography>


              </MKBox>
            </MKBox>

          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;
/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================
* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Images
import post2 from "assets/images/TijdUS.png";
import "../../../../H1asH3.css"

function Profile() {
  return (
    <MKBox component="section" py={{ xs: 0, sm: 0 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -16, md: -20 }} textAlign="center" />
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox display="flex" flexDirection={{ xs: "column", sm: "column", md: "column", lg: "row" }} justifyContent="space-between" alignItems="center" mb={1}>
                <MKTypography variant="h1" className="h1-as-h3" mb={{ xs: 1, sm: 1, md: 0 }}>Deel 1: Mijn Jeugd in Nederland</MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text">
                <br />
                In de betoverende omgeving van Nijmegen begon mijn levenslange liefdesaffaire met voetbal. Als kind van de straten van Nijmegen voelde ik de magie van de sport met elke stap die ik zette op het groene gras. Mijn eerste club was BVC '12, waar ik als jonge knaap mijn eerste dribbels en doelpunten beleefde. Het was een tijd van onschuld, waarin ik mijn passie voor de sport ontdekte en waar de fundamenten van mijn toekomst werden gelegd.
                <br />
                <br />


Na mijn avontuur bij BVC '12 begon ik aan een reis door de lokale voetbalclubs, zoals Quick, NEC en Union. Deze reis vormde de basis van mijn voetbalopleiding en bracht me dichter bij mijn droom: de top van het Nederlandse jeugdvoetbal bereiken. Als jonge voetballer droomde ik ervan om op de grote stadions te spelen, omringd door een zee van juichende fans. Het was een droom die ik deelde met veel andere jonge talenten in Nederland, en ik stond erop om er alles aan te doen om deze droom te verwezenlijken.
<br />
<br />

Maar terwijl ik mijn voetbaltalent ontwikkelde, begon ik ook te beseffen hoe cruciaal goed onderwijs is voor een solide toekomst. In Nederland leken mijn ambities op het gebied van voetbal en onderwijs soms tegenstrijdig. Het was een uitdaging om beide passies in balans te houden.<br />
<br />

De sprankelende diversiteit en de immense mogelijkheden van Amerika hadden me altijd al gefascineerd, en tijdens een vakantie met mijn familie werd deze fascinatie alleen maar sterker. Ik wist bijna zeker dat ik ooit zou terugkeren naar dit land, misschien wel voor een langere periode. Later, via via, hoorde ik verhalen over de mogelijkheden om een voetbalcarrière te combineren met een universitaire studie in Amerika, en mijn verlangen om dezelfde weg te bewandelen, groeide exponentieel.
<br />
<br />

Toch was het niet eenvoudig om alles te regelen. Het complexe proces van sportbeurzen en studiemogelijkheden in Amerika leek aanvankelijk ontmoedigend. Ergens wist ik dat het eenvoudiger en toegankelijker kon, wat uiteindelijk jaren later mijn motivatie zou zijn om dit bedrijf op te zetten.
<br />
<br />

Na contact te hebben gehad met verschillende coaches, had de coach van de University of Tulsa een bijzonder verhaal te delen. Hij vertelde over een team dat altijd meestreed om de prijzen, consistent bij de top 25 van het land hoorde en deelnam aan geweldige competities. Zijn woorden gaven me het vertrouwen dat dit de plek was waar ik zowel mijn voetbaldromen als mijn academische ambities kon verwezenlijken.
<br />
<br />

En zo gebeurde het, twee weken nadat ik 18 werd, zat mijn hoofdstuk in Nederland (voorlopig) erop. Ik stapte in het vliegtuig naar Amerika, klaar voor het avontuur dat voor me lag. Vastberaden om mijn passies voor voetbal en onderwijs te verenigen, stond ik aan het begin van een opwindende reis. Meer over mijn ervaringen in Amerika zal ik graag met jullie delen in deel 2 van mijn verhaal, waarin ik dieper inga op mijn avonturen in de Verenigde Staten.
</MKTypography>
<hr style={{ margin: '20px 0'}} />

<MKTypography component="a" href="#" variant="body1" fontWeight="light" color="info" mt={3}>
  <Grid container justifyContent="flex-end"> {/* This line is updated */}
    <Grid item xs={12} lg={6} mb={{ xs: 3, lg: 0 }}>
      <TransparentBlogCard
        image={post2}
        title="Deel 2: Mijn Tijd in Amerika "
        action={{
          type: "internal",
          route: "/Over-Mij/Tijd-in-Amerika",
          color: "info",
          label: "lees meer",
        }}
      />
    </Grid>
  </Grid>
</MKTypography>

</Grid>
</Grid>
</Grid>
</Container>
</MKBox>
);
}

export default Profile;

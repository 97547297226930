/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sporten_Images/Volleybal1.avif";
import Image2 from "assets/images/Sporten_Images/volleybal2.webp";
import Image3 from "assets/images/Sporten_Images/Volleybal3.webp";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import "../../../../H1asH3.css"


// Images
import { Link } from 'react-router-dom';



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Volleybal Scholarship: Hoe kun je studeren en volleyballen in Amerika?', isSubheader: false },

    { id: 'Hoofdstuk 1', title: '1. De Geschiedenis en Ontwikkeling van Volleybal in Amerika', isSubheader: false },
    { id: '1.1', title: '1.1 Vroege Jaren en de Rol van Onderwijsinstellingen', isSubheader: true },
    { id: '1.2', title: '1.2 Groei en Professionalisering', isSubheader: true },

    { id: 'Hoofdstuk 2', title: '2. Universitair Volleybal Vandaag de Dag', isSubheader: false },
    { id: '2.1', title: '2.1 Belangrijke Competities en Conferenties', isSubheader: true },
    { id: '2.2', title: '2.2 Topprogramma’s en Prestigieuze Teams', isSubheader: true },

    { id: 'Hoofdstuk 3', title: '3: Van College naar de Profs', isSubheader: false },
    { id: '3.1', title: '3.1 Het Pad naar Professionalisme', isSubheader: true },
    { id: '3.2', title: '3.2 Professionele Competities en Carrièremogelijkheden', isSubheader: true },


    { id: 'Hoofdstuk 4', title: '4. Internationale Invloeden en Competities', isSubheader: false },
    { id: '4.1', title: '4.1 Buitenlandse Invloeden', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikanen op het Wereldtoneel', isSubheader: true },

    { id: 'Hoofdstuk 5', title: '5. Volleybalbeurzen en de Toekomst', isSubheader: false },
    { id: '5.1', title: '5.1 Het Belang van Beurzen', isSubheader: true },
    { id: '5.2', title: '5.2 Hoe Sportbeurs Amerika NL Kan Helpen', isSubheader: true },

  ];

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Inhoud
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Verberg</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Toon</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
           <Grid item xs={12} md={9}>
            <MKBox id="intro" mb={3}>
            <MKTypography variant="h1" className="h1-as-h3" mb={2}>
              Volleybal Scholarship: Hoe kun je studeren en volleyballen in Amerika?
              </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
  <img
    src={Image1}
    alt="Volleybalwedstrijd in Nebraska, bezocht door meer dan 92000 fans."
    style={{
      maxWidth: "100%",
      border: "2px solid #fff",
      boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
      cursor: "pointer",
      outline: "none",
    }}
    onClick={() => openModal1(Image1)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
92,000 toeschouwers kijken naar een volleybal wedstrijd in Nebraska</figcaption>
</figure>
              Volleybal is een sport die veel vraagt van je fysieke en mentale capaciteiten. Je moet snel kunnen reageren, goed kunnen samenwerken, tactisch kunnen denken en een sterke conditie hebben. Daarnaast is volleybal ook een sport die veel plezier en voldoening geeft, zeker als je op een hoog niveau speelt. Maar hoe kun je als volleybaltalent je sportieve ambities waarmaken zonder je studie te verwaarlozen? Het antwoord is: door te kiezen voor een volleybal scholarship in Amerika.
<br/>
<br/>
Een volleybal scholarship is een financiële tegemoetkoming die je krijgt van een Amerikaanse universiteit om daar te studeren en te volleyballen. Met een volleybal scholarship kun je dus twee vliegen in één klap slaan: je kunt een erkend diploma behalen aan een prestigieuze onderwijsinstelling, terwijl je ook je volleybalvaardigheden verder ontwikkelt onder professionele begeleiding. Bovendien kun je genieten van de unieke ervaring om te leven en te leren in een andere cultuur, met nieuwe vrienden en uitdagingen.
<br/>
<br/>
Maar hoe kom je in aanmerking voor een volleybal scholarship? Wat zijn de eisen en de verwachtingen? En hoe vind je de juiste universiteit die bij jou past? Dat zijn allemaal vragen waar Sportbeursamerika.nl je bij kan helpen. Sportbeursamerika.nl is dé specialist op het gebied van sportbeurzen in Amerika. Ik heb jarenlange ervaring en een uitgebreid netwerk om jou te begeleiden naar jouw droomuniversiteit. Ik zorg ervoor dat je alle stappen doorloopt om succesvol te solliciteren naar een volleybal scholarship, van het maken van een online profiel tot het regelen van alle praktische zaken.
<br/>
<br/>
Wil je meer weten over hoe Sportbeursamerika.nl jou kan helpen om te studeren en volleyballen in Amerika? Lees dan verder of neem contact met mij op voor een vrijblijvend kennismakingsgesprek. Ik vertel je graag meer over de mogelijkheden en de voordelen van een volleybal scholarship in Amerika.
              </MKTypography>
              {/* Add bulleted list or other content as needed */}
            </MKBox>
             {/* Main chapters and subchapters with placeholder text */}
            <MKBox id="Hoofdstuk 1" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 1: De Geschiedenis en Ontwikkeling van Volleybal in Amerika
              </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              Volleybal is een sport die al meer dan een eeuw bestaat, maar die vooral in de laatste decennia enorm gegroeid is in populariteit en professionaliteit. In dit hoofdstuk gaan we in op de geschiedenis en de ontwikkeling van volleybal in Amerika, van de vroege jaren tot nu.
              </MKTypography>

              <MKBox id="1.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                1.1 Vroege Jaren en de Rol van Onderwijsinstellingen
                </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Volleybal werd uitgevonden in 1895 door William G. Morgan, een leraar lichamelijke opvoeding aan de YMCA (Young Men’s Christian Association) in Holyoke, Massachusetts. Hij wilde een spel creëren dat minder fysiek veeleisend was dan basketbal, dat een jaar eerder was bedacht door zijn collega James Naismith. Hij noemde het spel oorspronkelijk “mintonette”, maar het kreeg al snel de naam “volley ball”, omdat de spelers de bal over een net moesten slaan.
<br/>
<br/>
Het spel verspreidde zich snel over de Verenigde Staten en andere landen, vooral via de YMCA’s en andere onderwijsinstellingen. In 1916 werd een van de eerste officiële volleybaltoernooi gehouden op de Universiteit van Californië in Berkeley. In 1922 werd de United States Volleyball Association (USVBA) opgericht, die later de USA Volleyball (USAV) zou worden. Deze organisatie werd verantwoordelijk voor het reguleren en promoten van volleybal in Amerika.
<br/>
<br/>
In 1928 werd het eerste nationale kampioenschap voor mannen georganiseerd door de USVBA, gevolgd door het eerste nationale kampioenschap voor vrouwen in 1949. In 1955 werd het eerste wereldkampioenschap voor mannen gehouden in Frankrijk, waar Amerika als zesde eindigde. In 1956 werd het eerste wereldkampioenschap voor vrouwen gehouden in Frankrijk, waar Amerika als vierde eindigde.
                </MKTypography>
              </MKBox>
              <MKBox id="1.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                1.2 Groei en Professionalisering                </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                In de jaren zestig en zeventig begon volleybal steeds meer te groeien en te professionaliseren in Amerika. Dit kwam mede door de introductie van nieuwe regels, zoals de invoering van het rally-point systeem, waarbij elk punt telt, ongeacht wie serveert. Ook kwam er meer aandacht voor volleybal door de televisie-uitzendingen van de Olympische Spelen, waar volleybal sinds 1964 een officiële sport is.
<br/>
<br/>
Een van de belangrijkste personen in de geschiedenis van volleybal in Amerika is Doug Beal, die zowel als speler als als coach veel successen behaalde. Hij speelde voor het nationale team van 1970 tot 1976 en won onder andere goud op de Pan-Amerikaanse Spelen in 1975. Hij werd later de coach van het nationale team van 1977 tot 1984 en leidde hen naar hun eerste Olympische goud in 1984 in Los Angeles. Hij was ook betrokken bij de oprichting van de Major League Volleyball (MLV), een professionele competitie voor vrouwen die bestond van 1987 tot 1989.
<br/>
<br/>
Een ander belangrijk evenement in de geschiedenis van volleybal in Amerika was de oprichting van de Association of Volleyball Professionals (AVP) in 1983, die zich richtte op het beachvolleybal. Beachvolleybal is een variant van volleybal die gespeeld wordt op zand met teams van twee spelers. De AVP organiseerde toernooien en competities voor zowel mannen als vrouwen en hielp om beachvolleybal populair te maken bij het publiek. Enkele bekende beachvolleyballers zijn Karch Kiraly, Sinjin Smith, Randy Stoklos, Misty May-Treanor en Kerri Walsh Jennings.
                </MKTypography>
              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 2" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 2: Universitair Volleybal Vandaag de Dag
                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              Volleybal is een van de meest populaire en competitieve sporten op universitair niveau in Amerika. Elk jaar nemen duizenden teams deel aan verschillende competities en conferenties, met als hoogtepunt de nationale kampioenschappen. In dit hoofdstuk geven we je een overzicht van de belangrijkste competities en conferenties, en bespreken we enkele van de topprogramma’s en prestigieuze teams in het universitaire volleybal.
              </MKTypography>

              <MKBox id="2.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                2.1 Belangrijke Competities en Conferenties                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
  <img
    src={Image2}
    alt="Volleybalteam viert gezamenlijk een overwinning."
    style={{
      maxWidth: "100%",
      border: "2px solid #fff",
      boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
      cursor: "pointer",
      outline: "none",
    }}
    onClick={() => openModal2(Image2)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
  Volleybalsters vieren een overwinning
</figcaption>
</figure>
                De belangrijkste organisatie die verantwoordelijk is voor het organiseren en reguleren van het universitaire volleybal in Amerika is de National Collegiate Athletic Association (NCAA). De NCAA verdeelt de universiteiten in drie divisies, gebaseerd op hun grootte, budget en sportieve prestaties. De divisies zijn:
                                </MKTypography>

                                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
Divisie I: Dit is de hoogste divisie, waar de meeste grote en bekende universiteiten in zitten. De teams in deze divisie krijgen de meeste aandacht, media en beurzen. Er zijn ongeveer 330 universiteiten in deze divisie, verdeeld over 32 conferenties. Een conferentie is een groep van universiteiten die geografisch of historisch verbonden zijn, en die onderling wedstrijden spelen. Enkele voorbeelden van conferenties in Divisie I zijn de Big Ten, de Pac-12, de Southeastern Conference (SEC) en de Atlantic Coast Conference (ACC).
</li>
<li>
Divisie II: Dit is de middelste divisie, waar de universiteiten iets kleiner en minder bekend zijn dan in Divisie I, maar nog steeds een hoog niveau van volleybal hebben. De teams in deze divisie krijgen minder aandacht, media en beurzen dan in Divisie I, maar meer dan in Divisie III. Er zijn ongeveer 300 universiteiten in deze divisie, verdeeld over 23 conferenties. Enkele voorbeelden van conferenties in Divisie II zijn de Great Lakes Valley Conference (GLVC), de California Collegiate Athletic Association (CCAA) en de Gulf South Conference (GSC).

</li>
<li>
Divisie III: Dit is de laagste divisie, waar de universiteiten vooral gericht zijn op academische excellentie en minder op sportieve prestaties. De teams in deze divisie krijgen geen beurzen voor hun atleten, maar wel financiële hulp op basis van hun academische verdiensten. Er zijn ongeveer 450 universiteiten in deze divisie, verdeeld over 43 conferenties. Enkele voorbeelden van conferenties in Divisie III zijn de New England Small College Athletic Conference (NESCAC), de University Athletic Association (UAA) en de Centennial Conference.

</li>
</ul>
</MKTypography>
<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
Elk jaar spelen de teams binnen hun divisie en conferentie wedstrijden om zich te kwalificeren voor de nationale kampioenschappen, die ook wel bekend staan als de NCAA Tournament. Dit is een knock-out toernooi waarbij de beste teams uit elke divisie strijden om de titel van nationaal kampioen. Het toernooi wordt gehouden in het voorjaar voor mannen en in het najaar voor vrouwen.
                                </MKTypography>

              </MKBox>
              <MKBox id="2.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                2.2 Topprogramma’s en Prestigieuze Teams
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Het niveau van het universitaire volleybal in Amerika is zeer hoog, en er zijn veel teams die uitblinken in hun divisie en conferentie. Sommige teams hebben echter een bijzondere reputatie en geschiedenis opgebouwd door hun consistente prestaties, hun talentvolle spelers en coaches, en hun grote fanbase. Dit zijn enkele van de topprogramma’s en prestigieuze teams in het universitaire volleybal:
                </MKTypography>
                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
UCLA Bruins: De UCLA Bruins, vertegenwoordigend de Universiteit van Californië in Los Angeles, staan bekend om hun sterke traditie in zowel het mannen- als vrouwenvolleybal in NCAA Divisie I. Historisch gezien hebben ze meerdere nationale kampioenschappen gewonnen en zijn ze erin geslaagd om enkele van de meest gerespecteerde spelers in de sport voort te brengen. Dit team is een prominent lid van de Pac-12 Conferentie.

</li>
<li>
Stanford Cardinal: Het vrouwenvolleybalteam van Stanford Universiteit, gelegen in Stanford, Californië, heeft zich onderscheiden door het winnen van een indrukwekkend aantal nationale kampioenschappen in NCAA Divisie I, meer dan enig ander team in hun categorie. Ze hebben consistent getalenteerde spelers geproduceerd en concurreren in de Pac-12 Conferentie.


</li>
<li>
Penn State Nittany Lions: Het vrouwenvolleybalteam van de Pennsylvania State Universiteit in University Park, Pennsylvania, heeft een reputatie van uitmuntendheid in NCAA Divisie I, met talrijke nationale kampioenschappen op hun naam. Ze staan bekend om hun reeks opeenvolgende titels en hebben door de jaren heen opvallende spelers opgeleid. Dit team is een belangrijk lid van de Big Ten Conferentie.


</li>
<li>
BYU Cougars: De Brigham Young Universiteit in Provo, Utah, heeft een gerespecteerd mannenvolleybalteam in NCAA Divisie I, dat meerdere keren het nationaal kampioenschap heeft gewonnen. Het team heeft consistent uitgeblonken door het ontwikkelen van topvolleybalspelers en is een deel van de Mountain Pacific Sports Federation (MPSF).


</li>
</ul>
</MKTypography>
<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
Deze programma's zijn door de jaren heen prominent en toonaangevend geweest in het universitaire volleybal in de Verenigde Staten, bekend om hun sterke tradities, getalenteerde spelers, en invloedrijke coaches.

                </MKTypography>


              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 3" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 3: Van College naar de Profs
                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              Voor veel student-atleten die volleybal spelen op universitair niveau, is het een droom om ook professioneel volleybal te spelen na hun afstuderen. Maar hoe realistisch is deze droom, en wat zijn de stappen die je moet nemen om deze te verwezenlijken? In dit hoofdstuk bespreken we het pad naar professionalisme, de professionele competities en carrièremogelijkheden voor volleybalspelers.
              </MKTypography>

              <MKBox id="3.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                3.1 Het Pad naar Professionalisme                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Het spelen van volleybal op universitair niveau is een geweldige manier om je volleybalvaardigheden te verbeteren, je academische diploma te behalen en je persoonlijk te ontwikkelen. Maar het is ook een zeer competitieve en veeleisende omgeving, waar je veel tijd en energie moet investeren in je sport en je studie. Daarom is het belangrijk om realistisch te zijn over je kansen om professioneel volleybal te spelen na je college carrière.
<br/>
<br/>
In tegenstelling tot sommige andere sporten, zoals basketbal of voetbal, is er geen professionele volleybalcompetitie in Amerika die direct rekruteert uit de universiteiten. Dit betekent dat je als afgestudeerde speler moet zoeken naar andere mogelijkheden om je professionele carrière te starten, meestal in het buitenland. Er zijn verschillende professionele volleybalcompetities in Europa, Azië, Zuid-Amerika en andere regio’s, waar je kunt spelen voor clubs die je een salaris en andere voordelen bieden.
<br/>
<br/>
Maar hoe kom je in contact met deze clubs, en hoe zorg je ervoor dat je opvalt tussen de vele andere spelers die ook op zoek zijn naar een contract? Dit zijn enkele tips om je te helpen bij het vinden van een professionele volleybalclub:
                </MKTypography>
                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
Maak een online profiel aan op een platform zoals Volleyball Agency of Volleyball Market, waar je je video’s, statistieken, referenties en contactgegevens kunt delen met potentiële clubs en agenten.
</li>
<li>
Neem contact op met je voormalige coaches, teamgenoten of andere contacten die al ervaring hebben met professioneel volleybal of die connecties hebben met clubs of agenten. Zij kunnen je advies geven, aanbevelingen doen of je introduceren bij de juiste personen.

</li>
<li>
Doe mee aan try-outs, showcases of camps die georganiseerd worden door professionele clubs of organisaties. Dit zijn evenementen waar je je vaardigheden kunt laten zien aan scouts, coaches of managers die op zoek zijn naar nieuwe spelers. Zorg ervoor dat je goed voorbereid bent, zowel fysiek als mentaal, en dat je een positieve indruk maakt.

</li>
<li>
Wees flexibel en bereid om te verhuizen naar een ander land of continent. Professioneel volleybal spelen betekent vaak dat je moet leven in een andere cultuur, met een andere taal, een ander klimaat en andere gewoontes. Dit kan een grote uitdaging zijn, maar ook een geweldige kans om te leren en te groeien als persoon.

</li>
</ul>
</MKTypography>


              </MKBox>
              <MKBox id="3.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                3.2 Professionele Competities en Carrièremogelijkheden
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Als je erin slaagt om een contract te krijgen bij een professionele volleybalclub, dan kun je genieten van de voordelen van het spelen op het hoogste niveau van de sport. Je kunt deelnemen aan spannende competities, tegenover de beste spelers ter wereld staan, reizen naar verschillende landen en steden, en geld verdienen met je passie. Maar wat zijn de belangrijkste professionele competities waar je aan kunt deelnemen, en wat zijn de andere carrièremogelijkheden voor volleybalspelers?
<br/>
<br/>
Dit zijn enkele van de meest prestigieuze en lucratieve professionele volleybalcompetities in de wereld:
                </MKTypography>

                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
De Italiaanse Serie A: Dit is de hoogste divisie van het Italiaanse volleybal, die bestaat uit 14 teams voor mannen en 12 teams voor vrouwen. De Serie A staat bekend als een van de sterkste en meest competitieve competities ter wereld, met veel internationale sterren en hoge salarissen. De kampioen van de Serie A kwalificeert zich ook voor de Champions League, het belangrijkste clubtoernooi in Europa.
</li>
<li>
De Turkse Süper Lig: Dit is de hoogste divisie van het Turkse volleybal, die bestaat uit 12 teams voor mannen en 14 teams voor vrouwen. De Süper Lig is ook een van de toonaangevende competities in de wereld, met veel investeringen, media-aandacht en talentvolle spelers. De kampioen van de Süper Lig kwalificeert zich ook voor de Champions League.

</li>
<li>
De Braziliaanse Superliga: Dit is de hoogste divisie van het Braziliaanse volleybal, die bestaat uit 12 teams voor mannen en 12 teams voor vrouwen. De Superliga is de dominantste competitie in Zuid-Amerika, met veel passie, sfeer en kwaliteit. De kampioen van de Superliga kwalificeert zich ook voor het Wereldkampioenschap voor Clubs, het belangrijkste clubtoernooi in de wereld.

</li>
</ul>
</MKTypography>
<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
Naast het spelen in professionele competities, zijn er ook andere mogelijkheden voor volleybalspelers om hun carrière voort te zetten of uit te breiden. Dit zijn enkele voorbeelden:
                </MKTypography>
                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
Het coachen of trainen van andere spelers: Als je je speelcarrière wilt beëindigen of verminderen, kun je ook je kennis en ervaring overdragen aan andere spelers, als coach of trainer. Je kunt werken voor een professionele club, een universiteit, een school of een andere organisatie die volleybal aanbiedt. Je kunt ook je eigen volleybalschool of -academie oprichten, waar je jonge talenten kunt opleiden en begeleiden.
</li>
<li>
Het werken in de media of de industrie: Als je interesse hebt in de media of de industrie die gerelateerd zijn aan volleybal, kun je ook daar een carrière in opbouwen. Je kunt bijvoorbeeld werken als commentator, analist, journalist, schrijver, fotograaf of videomaker voor verschillende media-outlets die volleybal verslaan. Je kunt ook werken als vertegenwoordiger, adviseur, marketeer of ontwerper voor verschillende bedrijven die volleybalproducten of -diensten aanbieden.

</li>

</ul>
</MKTypography>





              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 4" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 4: Internationale Invloeden en Competities
                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              Volleybal is een wereldwijde sport, die gespeeld en gevolgd wordt door miljoenen mensen in verschillende landen en continenten. Het universitaire volleybal in Amerika staat niet op zichzelf, maar wordt beïnvloed en uitgedaagd door de internationale volleybalgemeenschap. In dit hoofdstuk bespreken we de buitenlandse invloeden en de internationale competities die een rol spelen in het universitaire volleybal.              </MKTypography>

              <MKBox id="4.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                4.1 Buitenlandse Invloeden
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
  <img
    src={Image3}
    alt="Volleybalstadion van Missouri gevuld met enthousiaste toeschouwers."
    style={{
      maxWidth: "100%",
      border: "2px solid #fff",
      boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
      cursor: "pointer",
      outline: "none",
    }}
    onClick={() => openModal3(Image3)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
  Een wedstrijd van de University of Missouri
</figcaption>
</figure>
                Het universitaire volleybal in Amerika heeft veel te danken aan de buitenlandse invloeden die de sport hebben verrijkt en verbeterd. Een van de belangrijkste invloeden is de immigratie van spelers, coaches en trainers uit andere landen, die hun kennis, ervaring en cultuur hebben meegebracht naar Amerika. Sommige van deze immigranten zijn zelfs uitgegroeid tot legendes in het universitaire volleybal, zoals:
                </MKTypography>
                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
Arie Selinger: Hij is een Isrealische coach die in 1969 naar Amerika verhuisde om het nationale vrouwenteam te coachen. Hij leidde het team naar hun eerste Olympische medaille, een zilveren in 1984. Hij was ook de coach van verschillende universiteitsteams, zoals Long Beach State, waar hij vier keer nationaal kampioen werd.
</li>
<li>
Andy Banachowski: Hij is een Poolse speler die in 1951 naar Amerika emigreerde. Hij speelde voor UCLA, waar hij twee keer nationaal kampioen werd. Hij werd later de coach van het vrouwenteam van UCLA, waar hij zes keer nationaal kampioen werd en meer dan 1000 wedstrijden won.

</li>
<li>
Marv Dunphy: Hij is een Canadese speler die in 1972 naar Amerika kwam om te spelen voor Pepperdine University. Hij werd later de coach van het mannenteam van Pepperdine, waar hij vier keer nationaal kampioen werd. Hij was ook de coach van het nationale mannenteam, dat goud won op de Olympische Spelen van 1988.

</li>
</ul>
</MKTypography>
<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
Een andere belangrijke invloed is de populariteit en het niveau van het internationale volleybal, dat veel inspiratie en motivatie biedt voor het universitaire volleybal. Het internationale volleybal omvat verschillende regionale en mondiale toernooien, waar de beste teams en spelers ter wereld elkaar ontmoeten. Sommige van deze toernooien zijn:
                </MKTypography>
                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
De Olympische Spelen: Dit is het grootste en meest prestigieuze sportevenement ter wereld, dat elke vier jaar wordt gehouden. Volleybal is sinds 1964 een officiële Olympische sport, zowel voor mannen als voor vrouwen. De Verenigde Staten hebben in totaal 9 Olympische medailles gewonnen in volleybal, waarvan 3 gouden, 3 zilveren en 3 bronzen.
</li>
<li>
Het Wereldkampioenschap: Dit is het belangrijkste toernooi voor nationale teams, dat elke vier jaar wordt gehouden. Het wordt georganiseerd door de Fédération Internationale de Volleyball (FIVB), de wereldwijde bestuursorganisatie voor volleybal. Het Wereldkampioenschap bestaat sinds 1949 voor mannen en sinds 1952 voor vrouwen. De Verenigde Staten hebben in totaal 5 Wereldkampioenschapsmedailles gewonnen in volleybal, waarvan 2 gouden, 1 zilveren en 2 bronzen.

</li>
<li>
De World Cup: Dit is een ander toernooi voor nationale teams, dat elke vier jaar wordt gehouden. Het wordt ook georganiseerd door de FIVB, maar het verschilt van het Wereldkampioenschap in het aantal deelnemende teams (12 in plaats van 24) en het kwalificatiesysteem (gebaseerd op continentale kampioenschappen). De World Cup bestaat sinds 1965 voor mannen en sinds 1973 voor vrouwen. De Verenigde Staten hebben in totaal 7 World Cup-medailles gewonnen in volleybal, waarvan 2 gouden, 3 zilveren en 2 bronzen.
</li>


</ul>
</MKTypography>



              </MKBox>
              <MKBox id="4.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                4.2 Amerikanen op het Wereldtoneel
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Het universitaire volleybal in Amerika heeft niet alleen veel geleerd van het internationale volleybal, maar heeft ook veel bijgedragen aan het internationale volleybal. Veel Amerikaanse spelers die op universitair niveau hebben gespeeld, hebben ook succesvol deelgenomen aan internationale competities, zowel voor hun nationale team als voor professionele clubs. Dit zijn enkele voorbeelden van Amerikaanse spelers die uitblonken op het wereldtoneel:
                </MKTypography>
                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
Karch Kiraly: Hij is een van de beste volleybalspelers aller tijden, die zowel in het zaalvolleybal als in het beachvolleybal heeft geschitterd. Hij speelde voor UCLA, waar hij drie keer nationaal kampioen werd. Hij speelde ook voor het nationale team, waarmee hij twee keer Olympisch goud won in het zaalvolleybal (1984 en 1988) en een keer in het beachvolleybal (1996). Hij speelde ook professioneel in Italië, waar hij vier keer de Italiaanse titel won en twee keer de Champions League.
</li>
<li>
Logan Tom: Zij is een van de beste vrouwelijke volleybalspelers aller tijden, die zowel als aanvaller als als libero heeft gespeeld. Ze speelde voor Stanford, waar ze nationaal kampioen werd en vier keer All-American werd. Ze speelde ook voor het nationale team, waarmee ze vier keer deelnam aan de Olympische Spelen en twee keer zilver won (2008 en 2012). Ze speelde ook professioneel in verschillende landen, zoals Brazilië, Turkije, Japan en China.

</li>
<li>
Matt Anderson: Hij is een van de beste mannelijke volleybalspelers van de huidige generatie, die zowel als opposite als als buitenaanvaller kan spelen. Hij speelde voor Penn State, waar hij nationaal kampioen werd in 2008 en All-American werd in 2007 en 2008. Hij speelt ook voor het nationale team, waarmee hij brons won op de Olympische Spelen van 2016 en goud op de World Cup van 2015. Hij speelde ook professioneel in Rusland, waar hij drie keer de Russische titel won en twee keer de Champions League.
</li>


</ul>
</MKTypography>



              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 5" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 5: Volleybalbeurzen en de Toekomst
                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              Volleybal is een van de meest populaire en competitieve sporten in Amerika. Elk jaar strijden duizenden student-atleten voor een plekje in een van de vele universitaire volleybalteams. Maar hoe kom je daar? En wat zijn de voordelen van het spelen van volleybal op universitair niveau? In dit hoofdstuk bespreken we het belang van volleybalbeurzen en de rol van Sportbeursamerika.nl in het helpen van jonge talenten om hun droom waar te maken.
              </MKTypography>

              <MKBox id="5.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                5.1 Het Belang van Beurzen 
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Een volleybalbeurs is een financiële ondersteuning die een universiteit biedt aan een student-atleet om zijn of haar studie en sport te combineren. Een volleybalbeurs kan de kosten van het collegegeld, de boeken, de huisvesting, de maaltijden en andere kosten dekken. Een volleybalbeurs kan variëren van een volledige beurs, die alle kosten dekt, tot een gedeeltelijke beurs, die een percentage van de kosten dekt.
<br/>
<br/>
Een volleybalbeurs is niet alleen een financieel voordeel, maar ook een academisch en sportief voordeel. Met een volleybalbeurs krijg je toegang tot een hoogwaardige opleiding aan een prestigieuze universiteit in Amerika. Je kunt kiezen uit een breed scala aan studierichtingen en diploma’s die aansluiten bij je interesses en ambities. Je krijgt ook de kans om je Engelse taalvaardigheid te verbeteren en je culturele horizon te verbreden.
<br/>
<br/>
Met een volleybalbeurs kun je ook je sportieve vaardigheden en potentieel ontwikkelen op het hoogste niveau. Je maakt deel uit van een professioneel en gedisciplineerd team dat wordt geleid door ervaren coaches. Je traint met de beste faciliteiten en apparatuur die beschikbaar zijn. Je speelt tegen sterke tegenstanders in spannende competities en toernooien. Je leert ook belangrijke vaardigheden zoals teamwork, leiderschap, communicatie en zelfvertrouwen.
<br/>
<br/>
Een volleybalbeurs is dus een unieke kans om je academische en sportieve carrière te bevorderen. Maar hoe kom je in aanmerking voor een volleybalbeurs? En hoe vind je de juiste universiteit voor jou? Dat is waar Sportbeursamerika.nl je kan helpen.
                </MKTypography>
              </MKBox>
              <MKBox id="5.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                5.2 Hoe Sportbeurs Amerika NL Kan Helpen 
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
  <img
    src={Image4}
    alt="Logo van Sportbeurs Amerika NL, bestaande uit een voetbal met het continent Noord-Amerika erop afgebeeld, met daarboven een afstudeerhoed (graduation cap) die bovenop de bal rust alsof het een hoofd is. De naam van het bedrijf is prominent weergegeven, wat de focus op sportbeurzen in Amerika voor Europese studenten benadrukt."
    style={{
      maxWidth: "100%",
      height: "auto",
      border: "2px solid #fff",
      boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)", // Apply shadow around the entire image
      cursor: "pointer",
      outline: "none",
      borderRadius: "50%", // Set border-radius to 50% for a circular image
      marginTop: "0px", // Add top margin to move the image up


    }}
    onClick={() => openModal4(Image4)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
  
</figcaption>
</figure>
Sportbeurs Amerika NL is een organisatie gespecialiseerd in het begeleiden van jonge sporttalenten naar sportbeurzen in Amerika.  Met een gepassioneerde en ervaren expert aan het roer, ondersteunt Sportbeurs Amerika NL de student-atleet bij iedere stap in het traject, vanaf de eerste oriëntatie tot aan de uiteindelijke plaatsing. Sportbeurs Amerika NL biedt onder andere de volgende diensten aan:
                </MKTypography>

                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
Een persoonlijke intakegesprek om de wensen, mogelijkheden en verwachtingen van de student-atleet te bespreken.
</li>
<li>
Een professionele evaluatie van het sportieve en academische niveau van de student-atleet.
</li>
<li>
Een uitgebreid netwerk van contacten met Amerikaanse coaches, universiteiten en colleges.
</li>
<li>
Een effectieve promotie van het profiel en de video’s van de student-atleet bij potentiële coaches.
</li>
<li>
Een deskundig advies over de beste opties voor de student-atleet op basis van zijn of haar voorkeuren, doelen en budget.
</li>
<li>
Een onderhandeling over de beste beursaanbiedingen namens de student-atleet.
</li>
<li>
Een hulp bij het aanvragen van de benodigde documenten, zoals het visum, het transcript, het diploma en de toelatingstesten.
</li>
<li>
Een voorbereiding op het leven in Amerika, zoals de cultuur, de taal, de regels en de tips.
</li>
<li>
Een nazorg en een follow-up tijdens het verblijf in Amerika.</li>

</ul>
</MKTypography>

<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Wil jij ook je passie voor American football combineren met een goede opleiding in Amerika? <Link to="/Diensten-Producten">Bekijk het volledige aanbod</Link> van Sportbeurs Amerika NL en maak een vrijblijvende afspraak om je te laten informeren over de mogelijkheden. 
      Ik sta voor je klaar om je te helpen bij het realiseren van je Amerikaanse droom.
    </MKTypography>


              </MKBox>
            </MKBox>

          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;
/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import ImageModal from '../../../../../components/ImageModal'; // Import the modal component

import React, { useState } from 'react'; // Step 1: Import useState
import { Link } from 'react-router-dom';


import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sporten_Images/Football1.jpg";
import Image2 from "assets/images/Sporten_Images/Football2.webp";
import Image3 from "assets/images/Sporten_Images/Football3.webp";
import Image4 from "assets/images/Logos/Sportbeurs.png";
import "../../../../H1asH3.css"


// Images



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'American Football Beurs USA: Hoe Je Een Plek Kunt Vinden in Een Universiteitsteam', isSubheader: false },

    { id: 'Hoofdstuk 1', title: '1. De Geschiedenis en Ontwikkeling van American Football', isSubheader: false },
    { id: '1.1', title: '1.1 De Beginjaren', isSubheader: true },
    { id: '1.2', title: '1.2 Groei en Professionalisering', isSubheader: true },

    { id: 'Hoofdstuk 2', title: '2. American Football op Universitair Niveau', isSubheader: false },
    { id: '2.1', title: '2.1 Belangrijkste Competities en Conferenties', isSubheader: true },
    { id: '2.2', title: '2.2 Ontwikkeling van Spelers', isSubheader: true },

    { id: 'Hoofdstuk 3', title: '3. Van College naar de NFL', isSubheader: false },
    { id: '3.1', title: '3.1 Het Pad naar de NFL', isSubheader: true },
    { id: '3.2', title: '3.2 Andere Professionele Mogelijkheden', isSubheader: true },


    { id: 'Hoofdstuk 4', title: '4. De Invloed van American Football op de Amerikaanse Cultuur', isSubheader: false },
    { id: '4.1', title: '4.1 Culturele Impact', isSubheader: true },
    { id: '4.2', title: '4.2 Rolmodellen en Inspiratie', isSubheader: true },

    { id: 'Hoofdstuk 5', title: '5. Sportbeurzen en de Toekomst van American Football', isSubheader: false },
    { id: '5.1', title: '5.1 Het Belang van Beurzen', isSubheader: true },
    { id: '5.2', title: '5.2 Hoe Sportbeurs Amerika NL Kan Helpen', isSubheader: true },


  ];

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };

  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Inhoud
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Verberg</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Toon</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
            <MKBox id="intro" mb={3}>
              <MKTypography variant="h1" className="h1-as-h3" mb={2}>
              American Football Beurs USA: Hoe Je Een Plek Kunt Vinden in Een Universiteitsteam
              </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
  <img
    src={Image1}
    alt="Luchtfoto van een drukbezochte college football wedstrijd bij Indiana University, met spelers in actie op het veld en fans op de tribunes."
    style={{
      maxWidth: "100%",
      border: "2px solid #fff",
      boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
      cursor: "pointer",
      outline: "none",
    }}
    onClick={() => openModal1(Image1)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
Indiana University football wedstrijd vanaf de tribunes.</figcaption>
</figure>
              American Football is een sport die ontstaan is in de Verenigde Staten in de late 19e eeuw. Het is een teamsport waarbij twee teams van elf spelers proberen om een ovale bal over de doellijn van de tegenstander te brengen of tussen de doelpalen te schoppen. Het spel wordt gekenmerkt door fysiek contact, tactiek, strategie en spektakel.
<br/>
<br/>
American Football is niet alleen een sport, maar ook een cultuur. Het is een belangrijk onderdeel van de Amerikaanse identiteit, waarden en tradities. Het is een bron van trots, passie en inspiratie voor miljoenen mensen. Het is ook een industrie die miljarden dollars genereert en duizenden banen creëert.
<br/>
<br/>
Maar voordat je het kunt maken als een professionele speler in de NFL, moet je eerst je talent laten zien op universitair niveau. College football is een essentieel onderdeel van de spelersontwikkeling en biedt jonge atleten de kans om hun vaardigheden te verbeteren, een opleiding te volgen en een netwerk op te bouwen.
<br/>
<br/>
College football is echter niet makkelijk toegankelijk voor iedereen. Er zijn duizenden teams en spelers die strijden om een beperkt aantal plekken en beurzen. Bovendien zijn er veel regels en eisen waaraan je moet voldoen om in aanmerking te komen voor een universiteitsteam.
<br/>
<br/>
Dat is waar Sportbeursamerika.nl je kan helpen. Ik ben gespecialiseerd in het matchen van jonge talenten met universiteitsteams die op zoek zijn naar versterking. Ik help je met het vinden van een sportbeurs, het regelen van je visum, het voorbereiden van je toelatingsexamen en het onderhandelen met coaches. Met mijn hulp kun je jouw droom waarmaken om te studeren en te spelen in Amerika.
<br/>
<br/>
Wil je weten hoe? Lees dan verder!
              </MKTypography>
            </MKBox>
             {/* Main chapters and subchapters with placeholder text */}
            <MKBox id="Hoofdstuk 1: De Geschiedenis en Ontwikkeling van American Footbal" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
                Hoofdstuk 1: De Geschiedenis en Ontwikkeling van American Football
              </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              American Football is een sport die ontstaan is in de Verenigde Staten in de late 19e eeuw. Het is een afstammeling van rugby en voetbal, twee sporten die populair waren in Europa. In dit hoofdstuk zullen we je meer vertellen over de beginjaren en de groei en professionalisering van American Football.
              </MKTypography>

              <MKBox id="1.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                1.1 De Beginjaren
                </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Het ontstaan van American Football wordt vaak toegeschreven aan een wedstrijd tussen twee universiteitsteams in 1869: Rutgers en Princeton. Deze wedstrijd was echter meer een soort voetbal dan het huidige American Football. Er waren 25 spelers per team, de bal mocht niet met de handen worden aangeraakt en er waren geen doelpalen.
<br/>
<br/>
De eerste wedstrijd die meer leek op het moderne American Football vond plaats in 1874 tussen Harvard en McGill. Deze wedstrijd was een combinatie van rugby en voetbal, waarbij de bal wel met de handen mocht worden gedragen en er wel doelpalen waren. Harvard won de wedstrijd met 3-0.
<br/>
<br/>
De rol van universiteiten bij de vorming van de eerste teams en competities was cruciaal. Zij waren de pioniers die de regels en stijl van het spel bepaalden. In 1873 werd de eerste intercollegiate football association opgericht, bestaande uit Yale, Columbia, Princeton en Harvard. In 1880 werd Walter Camp, een speler en coach van Yale, beschouwd als de "Father of American Football" omdat hij belangrijke innovaties introduceerde, zoals het verminderen van het aantal spelers tot 11, het invoeren van de scrimmage line en het downs systeem.
                </MKTypography>
              </MKBox>
              <MKBox id="1.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                1.2 Groei en Professionalisering
                </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                De evolutie van American Football naar een nationaal fenomeen begon in de late 19e en vroege 20e eeuw. Het spel werd steeds populairder onder studenten, toeschouwers en media. Het werd ook steeds gewelddadiger en gevaarlijker, wat leidde tot veel blessures en zelfs doden. In 1905 dreigde president Theodore Roosevelt om het spel te verbieden als er geen hervormingen zouden komen. Dit leidde tot de oprichting van de National Collegiate Athletic Association (NCAA) in 1906, een organisatie die verantwoordelijk is voor het reguleren en organiseren van universiteitssporten.
<br/>
<br/>
De impact van professioneel American football op het universiteitsspel was ook significant. De eerste professionele American football competitie werd opgericht in 1892, maar werd pas echt succesvol in 1920 met de oprichting van de American Professional Football Association (APFA), die later werd omgedoopt tot de National Football League (NFL). De NFL trok veel talentvolle spelers weg van de universiteiten, waardoor deze hun niveau moesten verhogen om concurrerend te blijven. De NFL werd ook een belangrijke bron van inkomsten en exposure voor de universiteiten, die vaak hun stadions verhuurden aan professionele teams.
<br/>
<br/>
In dit hoofdstuk hebben we je meer verteld over de geschiedenis en ontwikkeling van American Football. In het volgende hoofdstuk zullen we je meer vertellen over American Football op universitair niveau.
                </MKTypography>
              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 2" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 2: American Football op Universitair Niveau
                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              American Football op universitair niveau is een van de meest populaire en competitieve sporten in de Verenigde Staten. Elk jaar strijden meer dan duizend teams en honderdduizend spelers om de nationale titel, de regionale kampioenschappen en de prestigieuze bowl games. In dit hoofdstuk zullen we je meer vertellen over de belangrijkste competities en conferenties in American Football, en de ontwikkeling van spelers op universitair niveau.
              </MKTypography>

              <MKBox id="2.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                2.1 Belangrijkste Competities en Conferenties
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>

        
                Een overzicht van de NCAA divisies en belangrijkste conferenties in American Football. De NCAA is de organisatie die verantwoordelijk is voor het reguleren en organiseren van universiteitssporten in de Verenigde Staten. De NCAA verdeelt de teams in drie divisies: Division I, Division II en Division III. Division I is de hoogste divisie en bestaat uit twee subdivisies: Football Bowl Subdivision (FBS) en Football Championship Subdivision (FCS). FBS teams zijn de meest prestigieuze en lucratieve teams, die strijden om een plek in de College Football Playoff, een systeem dat sinds 2014 de nationale kampioen bepaalt. FCS teams zijn minder bekend en rijk, maar hebben hun eigen play-off systeem om hun kampioen te bepalen.
<br/>
<br/>
Binnen elke divisie en subdivisie zijn er verschillende conferenties, die groepen van teams zijn die geografisch of historisch verbonden zijn. Elke conferentie heeft zijn eigen schema, regels en kampioenschap. De belangrijkste conferenties in FBS zijn: Atlantic Coast Conference (ACC), Big Ten Conference, Big 12 Conference, Pac-12 Conference, Southeastern Conference (SEC) en American Athletic Conference (AAC). De belangrijkste conferenties in FCS zijn: Big Sky Conference, Colonial Athletic Association (CAA), Missouri Valley Football Conference (MVFC) en Southern Conference (SoCon).
<br/>
<br/>
De meest prestigieuze universiteitsprogramma’s en hun impact op de sport. Sommige universiteiten hebben een lange en succesvolle geschiedenis in American Football, die teruggaat tot de beginjaren van de sport. Zij hebben veel nationale titels, bowl games, individuele prijzen en professionele spelers voortgebracht. Zij hebben ook veel fans, media-aandacht en financiële middelen. Enkele voorbeelden van deze universiteiten zijn: Alabama, Notre Dame, Ohio State, Oklahoma, USC, Michigan, Texas, Clemson, Florida State en LSU.
                </MKTypography>
              </MKBox>
              <MKBox id="2.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                2.2 Ontwikkeling van Spelers
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
  <img
    src={Image2}
    alt="Jonge Tom Brady in actie als quarterback voor de University of Michigan tijdens een college football wedstrijd, gekleed in het blauw-gele tenue van het team."
    style={{
      maxWidth: "100%",
      border: "2px solid #fff",
      boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
      cursor: "pointer",
      outline: "none",
    }}
    onClick={() => openModal2(Image2)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
Tom Brady in actie namens de University of Michigan.</figcaption>
</figure>
                Hoe universiteitsteams bijdragen aan de technische en fysieke ontwikkeling van spelers. Universiteitsteams bieden jonge spelers de kans om hun vaardigheden te verbeteren onder begeleiding van ervaren coaches en stafleden. Zij bieden ook toegang tot hoogwaardige faciliteiten, apparatuur en medische zorg. Bovendien stellen zij spelers bloot aan een hoog niveau van competitie, waarbij zij het opnemen tegen andere getalenteerde spelers uit het hele land.
<br/>
<br/>
Voorbeelden van beroemde spelers die hun vaardigheden op universitair niveau hebben geslepen. Veel professionele spelers hebben hun carrière begonnen op universitair niveau, waar zij zich hebben onderscheiden door hun prestaties en potentieel. Sommige spelers hebben zelfs prijzen gewonnen zoals de Heisman Trophy, de meest prestigieuze individuele prijs in het college football. Enkele voorbeelden van deze spelers zijn: Tom Brady (Michigan), Peyton Manning (Tennessee), Joe Montana (Notre Dame), Barry Sanders (Oklahoma State), Jerry Rice (Mississippi Valley State) en Tim Tebow (Florida).
<br/>
<br/>
In dit hoofdstuk hebben we je meer verteld over American Football op universitair niveau. In het volgende hoofdstuk zullen we je meer vertellen over het pad naar de NFL en andere professionele mogelijkheden.

                </MKTypography>
              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 3" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 3: Van College naar de NFL
                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              College football is een springplank naar de National Football League (NFL), de hoogste professionele competitie ter wereld. Elk jaar worden honderden spelers geselecteerd door de NFL teams in de NFL Draft, een evenement waarbij de teams om de beurt een speler kiezen uit een pool van universiteitsspelers. In dit hoofdstuk zullen we je meer vertellen over het pad naar de NFL en andere professionele mogelijkheden.
              </MKTypography>

              <MKBox id="3.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                3.1 Het Pad naar de NFL
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Hoe college football functioneert als een springplank naar de National Football League (NFL). College football is de belangrijkste bron van talent voor de NFL. De meeste spelers die in de NFL spelen, hebben eerst op universitair niveau gespeeld. College football biedt spelers de kans om zich te bewijzen tegen andere topatleten, hun vaardigheden te ontwikkelen, hun statistieken te verbeteren en hun draftwaarde te verhogen. College football geeft ook spelers exposure en bekendheid bij de scouts, coaches en media.
<br/>
<br/>
Elk jaar worden er ongeveer 250 spelers gekozen in de NFL Draft, die meestal plaatsvindt in april. De draft bestaat uit zeven rondes, waarbij elke ronde 32 picks heeft (een voor elk team). De volgorde van de picks wordt bepaald door het resultaat van het vorige seizoen, waarbij het slechtste team als eerste mag kiezen en het beste team als laatste. De draft is een spannend en onvoorspelbaar evenement, waarbij sommige spelers hoger of lager worden gekozen dan verwacht, en sommige spelers helemaal niet worden gekozen. Enkele voorbeelden van beroemde spelers die in de NFL Draft zijn gekozen zijn: John Elway (eerste pick in 1983), Troy Aikman (eerste pick in 1989), Peyton Manning (eerste pick in 1998), Tom Brady (199e pick in 2000), Aaron Rodgers (24e pick in 2005) en Patrick Mahomes (10e pick in 2017).
                </MKTypography>
              </MKBox>
              <MKBox id="3.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                3.2 Andere Professionele Mogelijkheden
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Alternatieve paden naar een professionele carrière in American Football buiten de NFL. Niet alle spelers die op universitair niveau spelen, halen het tot de NFL. Sommige spelers worden niet gekozen in de draft, of worden wel gekozen maar slagen er niet in om een plek te vinden in een team. Sommige spelers raken geblesseerd of verliezen hun motivatie of passie. Maar dat betekent niet dat ze hun droom om professioneel te spelen moeten opgeven. Er zijn andere professionele mogelijkheden buiten de NFL, zoals:
                </MKTypography>
                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
  <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
    <li>
      De Canadian Football League (CFL): Dit is een professionele competitie die bestaat uit negen teams die spelen in Canada. De CFL heeft enkele verschillen met de NFL, zoals een groter veld, meer spelers per team en minder downs per drive. De CFL trekt veel spelers aan die niet in de NFL kunnen spelen, maar toch op een hoog niveau willen spelen.
    </li>
    <li>
      De XFL: Dit is een nieuwe professionele competitie die is opgericht in 2020 als een alternatief voor de NFL. De XFL bestaat uit acht teams die spelen in de Verenigde Staten. De XFL heeft als doel om een sneller, spannender en innovatiever spel te bieden dan de NFL. De XFL biedt ook meer kansen voor spelers die over het hoofd zijn gezien of genegeerd door de NFL.
    </li>
    <li>
      De European League of Football (ELF): Dit is een nieuwe professionele competitie die is opgericht in 2021 als een poging om American Football te promoten en te ontwikkelen in Europa. De ELF bestaat uit acht teams die spelen in Duitsland, Polen, Spanje en Frankrijk. De ELF wil een platform bieden voor Europese spelers om hun talent te tonen en te groeien als atleten.
    </li>
  </ul>
</MKTypography>
<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
In dit hoofdstuk hebben we je meer verteld over het pad naar de NFL en andere professionele mogelijkheden. In het volgende hoofdstuk zullen we je meer vertellen over de invloed van American Football op de Amerikaanse cultuur.
                </MKTypography>

              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 4" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 4: De Invloed van American Football op de Amerikaanse Cultuur
                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              American Football is niet alleen een sport, maar ook een cultuur. Het is een belangrijk onderdeel van de Amerikaanse identiteit, waarden en tradities. Het is een bron van trots, passie en inspiratie voor miljoenen mensen. Het is ook een industrie die miljarden dollars genereert en duizenden banen creëert. In dit hoofdstuk zullen we je meer vertellen over de culturele impact van American Football, en de rolmodellen en inspiratie die het biedt.
              </MKTypography>

              <MKBox id="4.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                4.1 Culturele Impact
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
  <img
    src={Image3}
    alt="Uitzicht vanaf de tribune op het levendige en volgepakte Wisconsin college football stadion tijdens een spannende wedstrijd, met fans die hun team aanmoedigen."
    style={{
      maxWidth: "100%",
      border: "2px solid #fff",
      boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
      cursor: "pointer",
      outline: "none",
    }}
    onClick={() => openModal3(Image3)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
Wedstrijddag in het volgepakte Wisconsin stadion</figcaption>
</figure>
                Het belang van American Football in de Amerikaanse samenleving en cultuur. American Football is een van de meest populaire en invloedrijke sporten in de Verenigde Staten. Het trekt elk jaar miljoenen fans, toeschouwers en media aan. Het is een onderdeel van het dagelijks leven, het onderwijs, de politiek, de economie en de entertainment van veel Amerikanen. Het is ook een symbool van nationale eenheid, diversiteit en trots.
<br/>
<br/>
Grote evenementen en hun impact op de nationale identiteit, zoals de Super Bowl. De Super Bowl is het jaarlijkse kampioenschap van de NFL, dat meestal plaatsvindt in februari. Het is het grootste en meest bekeken sportevenement ter wereld, met meer dan 100 miljoen kijkers in de Verenigde Staten alleen al. De Super Bowl is meer dan een sportwedstrijd, het is een cultureel fenomeen. Het is een gelegenheid voor familie, vrienden en collega’s om samen te komen, te feesten, te wedden en te juichen. Het is ook een podium voor muziek, reclame, kunst en politiek. De Super Bowl wordt vaak gezien als een weerspiegeling van de Amerikaanse cultuur, waarden en trends.
                </MKTypography>
              </MKBox>
              <MKBox id="4.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                4.2 Rolmodellen en Inspiratie
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                American Football spelers zijn vaak rolmodellen en inspiratiebronnen voor jonge atleten die hun droom willen nastreven om te spelen op universitair of professioneel niveau. Sommige spelers hebben bijzondere verhalen van tegenslag, doorzettingsvermogen en succes die anderen motiveren en inspireren. Sommige spelers hebben ook een positieve invloed op hun gemeenschap, hun fans en hun land door hun acties, woorden en daden. Enkele voorbeelden van deze spelers zijn: Colin Kaepernick (die knielde tijdens het volkslied om aandacht te vragen voor raciale onrechtvaardigheid), Russell Wilson (die zijn team leidde naar de Super Bowl als een underdog), JJ Watt (die miljoenen dollars inzamelde voor de slachtoffers van orkaan Harvey), Drew Brees (die zijn stad New Orleans hielp herstellen na orkaan Katrina) en Tom Brady (die op 43-jarige leeftijd nog steeds op topniveau speelt).
<br/>
<br/>
In dit hoofdstuk hebben we je meer verteld over de invloed van American Football op de Amerikaanse cultuur. In het volgende hoofdstuk zullen we je meer vertellen over sportbeurzen en de toekomst van American Football.



                </MKTypography>
              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 5" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 5: Sportbeurzen en de Toekomst van American Football
                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              American Football is een sport die veel kansen biedt voor jonge atleten die hun talent willen laten zien en zich willen ontwikkelen. Een van de belangrijkste kansen is het verkrijgen van een sportbeurs om te studeren en te spelen op universitair niveau in de Verenigde Staten. In dit hoofdstuk zullen we het belang van sportbeurzen bespreken, en hoe Sportbeursamerika.nl je kan helpen om je droom waar te maken.              </MKTypography>

              <MKBox id="5.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                5.1 Het Belang van Beurzen                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Een sportbeurs is een financiële ondersteuning die een universiteit biedt aan een student-atleet om zijn of haar studie en sport te combineren. Een sportbeurs kan de kosten van het collegegeld, de boeken, de huisvesting, de maaltijden en andere kosten dekken. Een sportbeurs kan ook andere voordelen bieden, zoals toegang tot academische begeleiding, medische zorg, trainingsfaciliteiten en uitrusting.
<br/>
<br/>
Een sportbeurs is niet alleen een financieel voordeel, maar ook een kans om jezelf te ontwikkelen als speler en als persoon. Door te studeren en te spelen op universitair niveau, kun je je vaardigheden verbeteren, je netwerk uitbreiden, je horizon verbreden en je toekomstperspectieven vergroten. Je kunt ook deelnemen aan spannende competities, reizen naar verschillende staten, en genieten van de Amerikaanse cultuur.
<br/>
<br/>
Een sportbeurs is echter niet gemakkelijk te verkrijgen. Er zijn duizenden student-atleten die concurreren voor een beperkt aantal beurzen. Bovendien zijn er veel factoren die meespelen bij het toekennen van een beurs, zoals je academische prestaties, je atletische prestaties, je persoonlijkheid, je motivatie, je leiderschap en je aanpassingsvermogen. Daarom is het belangrijk om je goed voor te bereiden en jezelf te onderscheiden van de rest.
                </MKTypography>
              </MKBox>
              <MKBox id="5.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                5.2 Hoe Sportbeurs Amerika NL Kan Helpen 
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
  <img
    src={Image4}
    alt="Logo van Sportbeurs Amerika NL, bestaande uit een voetbal met het continent Noord-Amerika erop afgebeeld, met daarboven een afstudeerhoed (graduation cap) die bovenop de bal rust alsof het een hoofd is. De naam van het bedrijf is prominent weergegeven, wat de focus op sportbeurzen in Amerika voor Europese studenten benadrukt."
    style={{
      maxWidth: "100%",
      height: "auto",
      border: "2px solid #fff",
      boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)", // Apply shadow around the entire image
      cursor: "pointer",
      outline: "none",
      borderRadius: "50%", // Set border-radius to 50% for a circular image
      marginTop: "0px", // Add top margin to move the image up


    }}
    onClick={() => openModal4(Image4)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
  
</figcaption>
</figure>
Sportbeurs Amerika NL is een organisatie gespecialiseerd in het begeleiden van jonge sporttalenten naar sportbeurzen in Amerika.  Met een gepassioneerde en ervaren expert aan het roer, ondersteunt Sportbeurs Amerika NL de student-atleet bij iedere stap in het traject, vanaf de eerste oriëntatie tot aan de uiteindelijke plaatsing. Sportbeurs Amerika NL biedt onder andere de volgende diensten aan:
                </MKTypography>

                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
Een persoonlijke intakegesprek om de wensen, mogelijkheden en verwachtingen van de student-atleet te bespreken.
</li>
<li>
Een professionele evaluatie van het sportieve en academische niveau van de student-atleet.
</li>
<li>
Een uitgebreid netwerk van contacten met Amerikaanse coaches, universiteiten en colleges.
</li>
<li>
Een effectieve promotie van het profiel en de video’s van de student-atleet bij potentiële coaches.
</li>
<li>
Een deskundig advies over de beste opties voor de student-atleet op basis van zijn of haar voorkeuren, doelen en budget.
</li>
<li>
Een onderhandeling over de beste beursaanbiedingen namens de student-atleet.
</li>
<li>
Een hulp bij het aanvragen van de benodigde documenten, zoals het visum, het transcript, het diploma en de toelatingstesten.
</li>
<li>
Een voorbereiding op het leven in Amerika, zoals de cultuur, de taal, de regels en de tips.
</li>
<li>
Een nazorg en een follow-up tijdens het verblijf in Amerika.</li>

</ul>
</MKTypography>

<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Wil jij ook je passie voor American football combineren met een goede opleiding in Amerika? <Link to="/Diensten-Producten">Bekijk het volledige aanbod</Link> van Sportbeurs Amerika NL en maak een vrijblijvende afspraak om je te laten informeren over de mogelijkheden. 
      Ik sta voor je klaar om je te helpen bij het realiseren van je Amerikaanse droom.
    </MKTypography>


              </MKBox>
            </MKBox>

          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;
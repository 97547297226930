
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import SchoolIcon from '@mui/icons-material/School';
import StarIcon from '@mui/icons-material/Star';
import Filter1Icon from '@mui/icons-material/Filter1';
import Filter2Icon from '@mui/icons-material/Filter2'; 
import Filter3Icon from '@mui/icons-material/Filter3'; 
import Filter4Icon from '@mui/icons-material/Filter4'; 
import Filter5Icon from '@mui/icons-material/Filter5'; 
import Filter6Icon from '@mui/icons-material/Filter6'; 
import Filter7Icon from '@mui/icons-material/Filter7'; 
import Filter8Icon from '@mui/icons-material/Filter8'; 
import Filter9Icon from '@mui/icons-material/Filter9';  // Import the number 1 icon
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import ScheduleIcon from '@mui/icons-material/Schedule';
import VideocamIcon from '@mui/icons-material/Videocam';
import QuizIcon from '@mui/icons-material/Quiz';
import RuleIcon from '@mui/icons-material/Rule';
import ChecklistIcon from '@mui/icons-material/Checklist';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import DescriptionIcon from '@mui/icons-material/Description';




const boxStyle = {
  boxShadow: '0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25), 0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15)',
  background: '#f5f5f5', // Light grey background
  color: '#000', // Text color set to black for contrast
};

const arrowStyle = {
  borderRight: '7px solid white',
};

function Timeline() {
  return (

<VerticalTimeline lineColor='rgb(33, 150, 243)'>
<VerticalTimelineElement
    iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
    icon={<StarIcon />}
  />
<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Add position relative to the style
  contentArrowStyle={arrowStyle}
  date="Stap 1"
  iconStyle={{ 
    background: 'rgb(33, 150, 243)', 
    color: '#fff', 
    boxShadow: '0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25), 0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15)', // Ensure the shadow is applied here
    border: '2px solid white' // Maintain the border
  }}
  icon={<RecordVoiceOverIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Reduced opacity for a watermark effect
  }}>
    <Filter1Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  
  <h3 className="vertical-timeline-element-title">Intakegesprek:</h3>
  <p style={{ 
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Added Roboto as the font family
  fontSize: '17px', // Changed font size to 18px
  fontWeight: '400', // Ensure the font weight is normal
  marginBottom: '10px', // Adjust the value as needed


}}>
  Dit is je startblok – het moment waarop we jouw dromen en doelen afstemmen op een realistisch pad naar een sportbeurs in Amerika.

</p>

<ul style={{ 
  listStyleType: 'disc', 
  marginLeft: '20px', 
  fontSize: '17px', 
}}>  
<li>
    <strong>Persoonlijke Doelstellingen:</strong> Verhelderen van je ambities en wat je zoekt in een sportbeurs en universitaire ervaring.
    </li>
    <li>
    <strong>Sportieve Vaardigheden:</strong>  Evaluatie van je huidige sportprestaties en potentieel voor competitie op universitair niveau.
    </li>
    <li>
    <strong>Academische Aspiraties:</strong>  Begrip van je academische interesses en doelen, en hoe deze te integreren met je sport.
    </li>
    <li>
    <strong>Persoonlijke Doelstellingen:</strong>  Op basis van het gesprek stel ik een eerste strategie en tijdlijn op voor je traject naar een sportbeurs.
    </li>
   
  </ul>
  <p style={{ 
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Added Roboto as the font family
  fontSize: '17px', // Changed font size to 18px
  fontWeight: '400', // Ensure the font weight is normal

}}>
We leggen samen de grondslag voor een succesvolle matching met een Amerikaanse universiteit en zetten de koers uit voor de volgende stappen in het proces.
</p>
  
</VerticalTimelineElement>
<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Add position relative to the style
  contentArrowStyle={arrowStyle}
  date="Stap 2"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<ScheduleIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Reduced opacity for a watermark effect
  }}>
    <Filter2Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Beoordeling en Planning:

</h3>
  <p style={{ 
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Added Roboto as the font family
  fontSize: '17px', // Changed font size to 18px
  fontWeight: '400', // Ensure the font weight is normal
  marginBottom: '10px', // Adjust the value as needed


}}>
Na je intakegesprek neem ik de tijd voor een grondige beoordeling en het opstellen van een persoonlijk plan.


</p>

<ul style={{ 
  listStyleType: 'disc', 
  marginLeft: '20px', 
  fontSize: '17px', 
}}>  
<li>
    <strong>Potentieel Analyseren:</strong> Ik beoordeel je sportieve en academische achtergrond om je kansen in de VS in te schatten.
    </li>
    <li>
    <strong>Plan van Aanpak:</strong>  Ik ontwikkel een op maat gemaakt plan, inclusief tijdlijn en mijlpalen, om je doelen te bereiken.
    </li>
    <li>
    <strong>Strategisch Advies:</strong>  Advies over hoe je je sport- en studieprestaties kunt verbeteren om je beurskansen te vergroten.
    </li>
    <li>
    <strong>Doeluniversiteiten:</strong>  Samen identificeren we een lijst van doeluniversiteiten die aansluiten bij jouw profiel en ambities.
    </li>
   
  </ul>
  <p style={{ 
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Added Roboto as the font family
  fontSize: '17px', // Changed font size to 18px
  fontWeight: '400', // Ensure the font weight is normal

}}>
Dit gedetailleerde plan vormt de basis voor je reis naar een sportbeurs en zorgt ervoor dat je voorbereid bent op elke volgende stap.




</p>
  
</VerticalTimelineElement>
<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Add position relative to the style
  contentArrowStyle={arrowStyle}
  date="Stap 3"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<VideocamIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Reduced opacity for a watermark effect
  }}>
    <Filter3Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Videomateriaal en Sportportfolio:

</h3>
  <p style={{ 
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Added Roboto as the font family
  fontSize: '17px', // Changed font size to 18px
  fontWeight: '400', // Ensure the font weight is normal
  marginBottom: '10px', // Adjust the value as needed


}}>
Het creëren van een overtuigend sportportfolio en professioneel videomateriaal is cruciaal voor jouw zichtbaarheid bij coaches.


</p>

<ul style={{ 
  listStyleType: 'disc', 
  marginLeft: '20px', 
  fontSize: '17px', 
}}>  
<li>
    <strong>Videoproductie:</strong>  Ik adviseer over de inhoud en begeleiden de productie van een highlight video die jouw vaardigheden optimaal in beeld brengt.
    </li>
    <li>
    <strong>Portfolio Ontwikkeling:</strong>  Ik help bij het samenstellen van een uitgebreid sportportfolio, inclusief statistieken, prestaties en aanbevelingen.
    </li>
    <li>
    <strong>Persoonlijk Verhaal:</strong>  Jouw sportieve reis en prestaties worden verweven tot een persoonlijk verhaal dat je onderscheidt van anderen.
    </li>
    <li>
    <strong>Digitale Presentatie:</strong>  Ik zorg dat je portfolio en video digitaal toegankelijk en deelbaar zijn voor coaches en scouting teams.
    </li>
   
  </ul>
  <p style={{ 
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Added Roboto as the font family
  fontSize: '17px', // Changed font size to 18px
  fontWeight: '400', // Ensure the font weight is normal

}}>
Met deze stap zorgen we ervoor dat je talent onmiskenbaar naar voren komt en de aandacht van Amerikaanse universiteiten trekt.




</p>
  
</VerticalTimelineElement>
<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Add position relative to the style
  contentArrowStyle={arrowStyle}
  date="Stap 4"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<QuizIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Reduced opacity for a watermark effect
  }}>
    <Filter4Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Academische Voorbereiding:

</h3>
  <p style={{ 
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Added Roboto as the font family
  fontSize: '17px', // Changed font size to 18px
  fontWeight: '400', // Ensure the font weight is normal
  marginBottom: '10px', // Adjust the value as needed


}}>
Een goede academische voorbereiding is essentieel om toegelaten te worden en succesvol te zijn in de VS.


</p>

<ul style={{ 
  listStyleType: 'disc', 
  marginLeft: '20px', 
  fontSize: '17px', 
}}>  
<li>
    <strong>Testvoorbereiding:</strong> Ik bied studiemateriaal en begeleiding voor SAT/ACT en TOEFL/IELTS, essentiële tests voor jouw toelating.
    </li>
    <li>
    <strong>Studieplanning:</strong>  Samen stellen we een studieplanning op om je voor te bereiden op de examens, zonder je sporttraining te verwaarlozen.
    </li>
    <li>
    <strong>Aanmeldingsdocumenten:</strong>  Ik help met het opstellen en reviseren van je aanmeldingsessays en andere vereiste documentatie.

    </li>
    <li>
    <strong>Academisch Advies:</strong>  Je ontvangt advies over de academische eisen en hoe je jouw academische profiel kunt versterken.
    </li>
   
  </ul>
  <p style={{ 
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Added Roboto as the font family
  fontSize: '17px', // Changed font size to 18px
  fontWeight: '400', // Ensure the font weight is normal

}}>
Mijn streven is ervoor te zorgen dat je academisch voorbereid bent om niet alleen geaccepteerd te worden, maar ook om te excelleren aan de universiteit.




</p>
  
</VerticalTimelineElement>
<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Add position relative to the style
  contentArrowStyle={arrowStyle}
  date="Stap 5"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<SchoolIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Reduced opacity for a watermark effect
  }}>
    <Filter5Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Promotie bij Universiteiten:

</h3>
  <p style={{ 
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Added Roboto as the font family
  fontSize: '17px', // Changed font size to 18px
  fontWeight: '400', // Ensure the font weight is normal
  marginBottom: '10px', // Adjust the value as needed


}}>
De promotie van jouw profiel bij universiteiten is de sleutelstap waar mijn expertise en uitgebreide netwerk het meest tot hun recht komen.


</p>

<ul style={{ 
  listStyleType: 'disc', 
  marginLeft: '20px', 
  fontSize: '17px', 
}}>  
<li>
    <strong>Sleutelrol Netwerk:</strong> Mijn uitgebreide netwerk binnen Amerikaanse universiteiten is van onschatbare waarde bij het onder de aandacht brengen van jouw kandidatuur.
    </li>
    <li>
    <strong>Strategische Positionering:</strong>  Ik benut mijn kennis en ervaring om jouw profiel strategisch te positioneren, zodat het opvalt bij de juiste sportprogramma's en academische instellingen.
    </li>
    <li>
    <strong>Aanpasbaar Promotieplan::</strong>  Ik creër een flexibel en op maat gemaakt promotieplan dat de unieke aspecten van jouw sport- en academisch profiel benadrukt.
    </li>
    <li>
    <strong>Maximalisatie van Kansen:</strong>  Door gerichte promotie zorg ik ervoor dat jouw kansen op het verkrijgen van een beurs worden gemaximaliseerd, met een nadrukkelijke focus op de beste fit voor jouw persoonlijke en sportieve ambities.
    </li>
   
  </ul>
  <p style={{ 
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Added Roboto as the font family
  fontSize: '17px', // Changed font size to 18px
  fontWeight: '400', // Ensure the font weight is normal

}}>
Mijn meerwaarde ligt in het effectief in de schijnwerpers zetten van jouw talent en aspiraties, waardoor de kans op succes significant toeneemt. Dit is het moment waarop mijn netwerk, mijn kennis van het Amerikaanse universitaire systeem en mijn persoonlijke benadering samen een doorslaggevend verschil maken.




</p>
  
</VerticalTimelineElement>
<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Add position relative to the style
  contentArrowStyle={arrowStyle}
  date="Stap 6"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<RecordVoiceOverIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Reduced opacity for a watermark effect
  }}>
    <Filter6Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Communicatie met Coaches:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
    marginBottom: '10px',
  }}>
    Effectieve communicatie met coaches is essentieel. Ik fungeer als jouw persoonlijke vertegenwoordiger om een sterke eerste indruk te maken.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Directe Introducties:</strong> Persoonlijke introducties bij coaches uit mijn netwerk, op maat gemaakt voor jouw sportdiscipline.
    </li>
    <li>
      <strong>Communicatiestrategie:</strong> Ontwikkeling van een doeltreffende communicatiestrategie om je kwaliteiten en ambities te benadrukken.
    </li>
    <li>
      <strong>Onderhandelingsbegeleiding:</strong> Begeleiding bij het communiceren met coaches tijdens de onderhandelingsfase voor sportbeurzen.
    </li>
    <li>
      <strong>Voortdurende Ondersteuning:</strong> Voortdurende ondersteuning en advies bij alle communicatie om ervoor te zorgen dat je een positieve en blijvende indruk maakt.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
  }}>
    Mijn rol is om als brug te fungeren tussen jou en de Amerikaanse coaches, en om een communicatiekanaal te creëren dat jouw kansen op een succesvolle sportbeurs maximaliseert.
  </p>
  
</VerticalTimelineElement>
<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Add position relative to the style
  contentArrowStyle={arrowStyle}
  date="Stap 7"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<RuleIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Reduced opacity for a watermark effect
  }}>
    <Filter7Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Selectie en Beursaanbiedingen:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
    marginBottom: '10px',
  }}>
    Het selectieproces is cruciaal; hier komen de vruchten van ons gezamenlijk werk tot bloei in de vorm van beursaanbiedingen.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Beoordeling Aanbiedingen:</strong> Samen beoordelen we de ontvangen aanbiedingen en bespreken we de voor- en nadelen van elke optie.
    </li>
    <li>
      <strong>Strategisch Advies:</strong> Ik bied strategisch advies om je te helpen bij het maken van de beste keuze voor jouw sportieve en academische toekomst.
    </li>
    <li>
      <strong>Onderhandelingsondersteuning:</strong> Mijn ervaring in onderhandelingen zet ik in om voor jou de meest gunstige voorwaarden te realiseren.
    </li>
    <li>
      <strong>Definitieve Keuze:</strong> Met mijn hulp maak je een goed geïnformeerde definitieve keuze waar je sportieve carrière en onderwijs in de VS beginnen.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
  }}>
    Mijn begeleiding in deze fase is gericht op het waarborgen van de best mogelijke uitkomst voor jouw ambities, en het zekerstellen van jouw toekomst bij een topuniversiteit in Amerika.
  </p>
  
</VerticalTimelineElement>
<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Add position relative to the style
  contentArrowStyle={arrowStyle}
  date="Stap 8"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<DescriptionIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Reduced opacity for a watermark effect
  }}>
    <Filter8Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Visumaanvraag en Aanmeldingsproces:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
    marginBottom: '10px',
  }}>
Na de selectie begint een cruciale fase: het afronden van je visumaanvraag en aanmeldingsprocedures.

</p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Visumaanvraag:</strong>  Begeleiding bij het aanvragen van het juiste studentenvisum, inclusief het verzamelen van de benodigde documentatie.
    </li>
    <li>
      <strong>Aanmeldingsbegeleiding:</strong> Hulp bij het voltooien van alle aanmeldingsformulieren en documenten voor je gekozen universiteit en competitie.
    </li>
    <li>
      <strong>Financiële Planning:</strong> AAdvies over financiële zaken zoals studiebeursgelden, collegegeld en woonkosten in de VS.
    </li>
    <li>
      <strong>Laatste Controle:</strong> Een grondige laatste controle om ervoor te zorgen dat alle administratieve en logistieke aspecten zijn afgehandeld.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
  }}>
    Mijn begeleiding bij de voorbereiding zorgt ervoor dat je zelfverzekerd en goed geïnformeerd aan je Amerikaanse avontuur begint.
  </p>
  
</VerticalTimelineElement>

<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Add position relative to the style
  contentArrowStyle={arrowStyle}
  date="Stap 9"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<ChecklistIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Reduced opacity for a watermark effect
  }}>
    <Filter8Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Voorbereiding op Vertrek:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
    marginBottom: '10px',
  }}>
Een soepele overgang naar de VS vereist nauwkeurige voorbereiding; de laatste voorbereidingen worden nu getroffen.  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Checklist:</strong> Een uitgebreide checklist om niets te vergeten, van essentiële documenten tot persoonlijke benodigdheden.
    </li>
    <li>
      <strong>Accommodatie:</strong> Hulp bij het vinden van geschikte huisvesting, dicht bij je universiteit en met de juiste voorzieningen.
    </li>
    <li>
      <strong>Verzekering:</strong> Advies over de juiste verzekeringen voor gezondheid, reizen en verblijf.
    </li>
    <li>
      <strong>Voorlichting:</strong> Een voorlichtingssessie over culturele verschillen, het leven in de VS en praktische tips voor dagelijks leven.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
  }}>
    Mijn begeleiding bij de voorbereiding zorgt ervoor dat je zelfverzekerd en goed geïnformeerd aan je Amerikaanse avontuur begint.
  </p>
  
</VerticalTimelineElement>
<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Add position relative to the style
  contentArrowStyle={arrowStyle}
  date="Stap 10"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<FlightTakeoffIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Reduced opacity for a watermark effect
  }}>
    <Filter9Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Vertrek en Aankomst in de VS:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
    marginBottom: '10px',
  }}>
    De laatste stap in je voorbereiding is aangebroken: de reis naar Amerika.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Voorbereiding op Vertrek:</strong> Ik zorg ervoor dat je volledig voorbereid bent op je vertrek, met een gedetailleerde checklist en laatste adviezen.
    </li>
    <li>
      <strong>Afscheidsmoment:</strong> Een moment om stil te staan bij deze belangrijke mijlpaal en om afscheid te nemen voordat je jouw droom achterna gaat.
    </li>
    <li>
      <strong>Aankomst in de VS:</strong> Bij aankomst bied ik ondersteuning om je te helpen settelen en wegwijs te maken in je nieuwe omgeving.
    </li>
    <li>
      <strong>Eerste Dagen:</strong> De eerste dagen zijn spannend, en ik zorg dat je de ondersteuning krijgt die je nodig hebt om een goede start te maken.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
  }}>
    Ik sta bij elke stap aan je zijde, van het opstijgen in Nederland tot aan je aankomst op de campus in de VS, klaar om aan je studie en sportcarrière te beginnen.
  </p>
  
</VerticalTimelineElement>




  
  <VerticalTimelineElement
    iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
    icon={<SportsScoreIcon />}
  />
</VerticalTimeline>
  );
}

export default Timeline;

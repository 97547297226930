/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sporten_Images/Honkbal1.jpg";
import Image2 from "assets/images/Sporten_Images/Honkbal2.jpeg";
import Image3 from "assets/images/Sporten_Images/Honkbal3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";



// Images
import { Link } from 'react-router-dom';

import "../../../../H1asH3.css"

const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Honkbal en studeren in Amerika: een droom die werkelijkheid kan worden', isSubheader: false },

    { id: 'Hoofdstuk 1', title: '1. De Geschiedenis en Ontwikkeling van College Honkbal', isSubheader: false },
    { id: '1.1', title: '1.1 Vroege Jaren en de Rol van Colleges', isSubheader: true },
    { id: '1.2', title: '1.2 Groei en Professionalisering', isSubheader: true },

    { id: 'Hoofdstuk 2', title: '2. College Honkbal Vandaag de Dag', isSubheader: false },
    { id: '2.1', title: '2.1 Belangrijke Competities en Conferenties', isSubheader: true },
    { id: '2.2', title: '2.2 Topprogramma’s en Prestigieuze Teams', isSubheader: true },

    { id: 'Hoofdstuk 3', title: '3: Van College naar de Profs', isSubheader: false },
    { id: '3.1', title: '3.1 Het Pad naar Professionalisme', isSubheader: true },
    { id: '3.2', title: '3.2 De MLB Draft en Andere Professionele Mogelijkheden', isSubheader: true },


    { id: 'Hoofdstuk 4', title: '4. Internationale Invloeden', isSubheader: false },
    { id: '4.1', title: '4.1 Buitenlandse Spelers in het College Honkbal', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikanen in het Buitenland', isSubheader: true },

    { id: 'Hoofdstuk 5', title: '5. Honkbalbeurzen en de Toekomst', isSubheader: false },
    { id: '5.1', title: '5.1 Het Belang van Beurzen', isSubheader: true },
    { id: '5.2', title: '5.2 Hoe Sportbeurs Amerika NL Kan Helpen', isSubheader: true },

  ];

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };

  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Inhoud
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Verberg</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Toon</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
            <MKBox id="intro" mb={3}>
            <MKTypography variant="h1" className="h1-as-h3" mb={2}>
              Honkbal en studeren in Amerika: een droom die werkelijkheid kan worden
              </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
  <img
    src={Image1}
    alt="Luchtfoto van het stadion tijdens de College World Series."
    style={{
      maxWidth: "100%",
      border: "2px solid #fff",
      boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
      cursor: "pointer",
      outline: "none",
    }}
    onClick={() => openModal1(Image1)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
  De College World Series
</figcaption>
</figure>
              Honkbal is een van de populairste en belangrijkste sporten in de Verenigde Staten. Miljoenen mensen volgen de Major League Baseball (MLB), de hoogste professionele honkbalcompetitie ter wereld. Maar voordat je het kunt maken in de MLB, moet je eerst je vaardigheden ontwikkelen en bewijzen op een ander niveau: het college honkbal.
<br/>
<br/>
College honkbal is het honkbal dat gespeeld wordt door studenten van Amerikaanse universiteiten en hogescholen. Het is een zeer competitieve en prestigieuze sport, die veel aandacht en talent trekt. College honkbal dient als een belangrijke stap voor spelers die professioneel willen gaan spelen, maar ook als een kans om een goede opleiding te krijgen.
<br/>
<br/>
Maar hoe kom je in het college honkbal? En hoe kun je een beurs krijgen om te studeren en te spelen in de VS? Dat is waar Sportbeursamerika.nl je kan helpen. Sportbeursamerika.nl is een organisatie die gespecialiseerd is in het begeleiden en ondersteunen van student-atleten die hun droom willen waarmaken. Ik help je met alles wat je nodig hebt om een geschikte universiteit te vinden, een beurs te krijgen en je voor te bereiden op het leven in de VS.
<br/>
<br/>
In dit artikel zullen we je alles vertellen wat je moet weten over college honkbal in de VS. We zullen ingaan op de geschiedenis en ontwikkeling van deze sport, de belangrijkste competities en conferenties, de topprogramma’s en prestigieuze teams, het pad naar de profs, de internationale invloeden en natuurlijk het belang van beurzen. Na het lezen van dit artikel zul je een beter beeld hebben van wat college honkbal inhoudt en hoe je je kansen kunt vergroten om er deel van uit te maken.
<br/>
<br/>
Ben je klaar om je droom waar te maken? Laten we beginnen!
              </MKTypography>
              {/* Add bulleted list or other content as needed */}
            </MKBox>
             {/* Main chapters and subchapters with placeholder text */}
            <MKBox id="Hoofdstuk 1" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 1: De Geschiedenis en Ontwikkeling van College Honkbal              </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              Honkbal is een sport die al meer dan een eeuw bestaat. Maar hoe is het ontstaan en hoe is het uitgegroeid tot een van de populairste en belangrijkste sporten in de VS? In dit hoofdstuk zullen we je een kort overzicht geven van de geschiedenis en ontwikkeling van college honkbal.
              </MKTypography>

              <MKBox id="1.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                1.1 Vroege Jaren en de Rol van Colleges                </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                De oorsprong van honkbal is niet helemaal duidelijk. Er zijn verschillende theorieën over hoe en waar het spel is begonnen. Sommigen zeggen dat het afstamt van oude Europese spellen zoals cricket, rounders of stoolball. Anderen zeggen dat het is ontstaan uit een inheems Amerikaans spel genaamd lacrosse. Weer anderen zeggen dat het is bedacht door een Amerikaanse soldaat genaamd Abner Doubleday in 1839.
<br/>
<br/>
Wat wel zeker is, is dat honkbal al snel populair werd in de VS, vooral in de steden aan de oostkust. Het werd gespeeld door amateurs, clubs, fabrieksarbeiders en soldaten. Het werd ook gespeeld door studenten van universiteiten en hogescholen, die hun eigen teams en regels hadden.
<br/>
<br/>
De eerste bekende intercollegiale honkbalwedstrijd in de Verenigde Staten vond plaats in 1859 tussen Amherst College en Williams College, twee gerespecteerde academische instellingen in Massachusetts. Deze wedstrijd is historisch significant omdat het een van de vroegste voorbeelden is van honkbalwedstrijden op universitair niveau, een voorbode van de latere ontwikkeling van college honkbal. Hoewel de exacte uitslag en het verloop van de wedstrijd in historische bronnen kunnen variëren, markeert dit evenement een belangrijk beginpunt in de geschiedenis van het intercollegiale honkbal. Het illustreert hoe honkbal in de vroege dagen populair werd onder studenten en hoe universiteiten belangrijke locaties werden voor de groei en ontwikkeling van de sport in de Verenigde Staten.

<br/>
<br/>
In de jaren 1860 en 1870 groeide het aantal college teams gestaag. Sommige van de eerste teams waren Harvard, Yale, Princeton, Dartmouth, Brown en Columbia. Deze teams speelden vooral tegen elkaar of tegen lokale clubs. 
<br/>
<br/>
In 1879 vond de eerste intercollegiate honkbalconferentie plaats in Springfield, Massachusetts. Het doel was om de regels en standaarden van het spel te uniformeren en te verbeteren. De conferentie leidde tot de oprichting van de American College Baseball Association Zes prestigieuze onderwijsinstellingen - Harvard, Yale, Princeton, Amherst, Dartmouth en Brown - maakten hier deel van uit.
Dit was het begin van een lange traditie van nationale kampioenschappen in college honkbal.
                </MKTypography>
              </MKBox>
              <MKBox id="1.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                1.2 Groei en Professionalisering                </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                In de late 19e en vroege 20e eeuw werd honkbal steeds populairder en professioneler in de VS. De National League (NL) en de American League (AL) werden opgericht als de belangrijkste professionele honkbalcompetities. Ze trokken veel spelers, fans en geld aan.
<br/>
<br/>
Dit had ook invloed op college honkbal. Veel college spelers werden gerekruteerd of getekend door professionele teams. Sommigen verlieten hun universiteit om professioneel te gaan spelen. Anderen bleven studeren, maar speelden ook parttime voor professionele teams.
<br/>
<br/>
Dit leidde tot een aantal problemen en conflicten in college honkbal. Sommige universiteiten waren tegen het idee dat hun spelers betaald werden om te spelen. Ze vonden dat dit indruiste tegen de amateuristische geest en de academische integriteit van college sporten. Ze wilden ook hun beste spelers behouden voor hun eigen teams.
<br/>
<br/>
Andere universiteiten waren meer tolerant of pragmatisch over het fenomeen van professionele spelers. Ze erkenden dat honkbal een lucratieve en prestigieuze sport was, die veel voordelen kon bieden voor hun instellingen. Ze wilden ook concurreren met andere universiteiten op het hoogste niveau.
<br/>
<br/>
Om deze problemen op te lossen, werden er verschillende maatregelen genomen door verschillende organisaties. De IBA werd vervangen door de National Collegiate Athletic Association (NCAA) in 1906. De NCAA nam de verantwoordelijkheid voor het reguleren en organiseren van college honkbal en andere sporten. De NCAA stelde ook regels en richtlijnen op voor de elegibiliteit en het gedrag van college spelers.
<br/>
<br/>
De NCAA organiseerde ook het eerste College World Series (CWS) in 1947. Dit was het nieuwe nationale kampioenschap voor college honkbal, dat elk jaar werd gehouden in Omaha, Nebraska. Het CWS werd al snel een van de meest prestigieuze en populaire evenementen in college sporten.
<br/>
<br/>
Naast de NCAA, waren er ook andere organisaties die betrokken waren bij college honkbal. Een daarvan was de National Association of Intercollegiate Athletics (NAIA), die werd opgericht in 1937. De NAIA was een alternatief voor de NCAA, die zich richtte op kleinere universiteiten en hogescholen. De NAIA organiseerde ook zijn eigen nationale kampioenschap voor college honkbal.
<br/>
<br/>
Een andere organisatie was de National Junior College Athletic Association (NJCAA), die werd opgericht in 1938. De NJCAA was een organisatie voor community colleges, die tweejarige opleidingen aanboden. De NJCAA organiseerde ook zijn eigen nationale kampioenschap voor college honkbal.
<br/>
<br/>
Deze organisaties droegen allemaal bij aan de groei en professionalisering van college honkbal. Ze creëerden meer mogelijkheden en diversiteit voor spelers, coaches, scholen en fans. Ze maakten ook college honkbal tot een belangrijk onderdeel van het Amerikaanse honkbalsysteem.
                </MKTypography>
              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 2" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 2: College Honkbal Vandaag de Dag
                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              College honkbal is vandaag de dag een van de meest populaire en belangrijke sporten in de VS. Het trekt miljoenen fans, media en sponsors aan. Het levert duizenden spelers, coaches en officials op. Het biedt honderden beurzen, prijzen en onderscheidingen aan.
<br/>
<br/>
Maar hoe ziet college honkbal er vandaag de dag uit? Hoe is het systeem georganiseerd? Welke zijn de belangrijkste competities en conferenties? Welke zijn de topprogramma’s en prestigieuze teams? In dit hoofdstuk zullen we je een overzicht geven van college honkbal vandaag de dag.
              </MKTypography>

              <MKBox id="2.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                2.1 Belangrijke Competities en Conferenties
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
  <img
    src={Image2}
    alt="Barry Bonds in actie voor Arizona State in college honkbal."
    style={{
      maxWidth: "100%",
      border: "2px solid #fff",
      boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
      cursor: "pointer",
      outline: "none",
    }}
    onClick={() => openModal2(Image2)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
  Barry Bonds als speler van Arizona State
</figcaption>
</figure>
                Zoals we in het vorige hoofdstuk hebben gezien, zijn er verschillende organisaties die betrokken zijn bij college honkbal. De belangrijkste daarvan is de NCAA, die meer dan 1.200 universiteiten en hogescholen vertegenwoordigt. De NCAA is verdeeld in drie divisies: Division I, Division II en Division III.
<br/>
<br/>
Division I is het hoogste niveau van college honkbal. Het bestaat uit ongeveer 300 scholen, die verdeeld zijn in 31 conferenties. Elke conferentie heeft zijn eigen regels, schema’s en kampioenschappen. De bekendste conferenties zijn de Atlantic Coast Conference (ACC), de Big Ten Conference (B1G), de Big 12 Conference (B12), de Pac-12 Conference (P12) en de Southeastern Conference (SEC).
<br/>
<br/>
Division II is het middelste niveau van college honkbal. Het bestaat uit ongeveer 270 scholen, die verdeeld zijn in 23 conferenties. De conferenties zijn meestal regionaal georiënteerd, zoals de California Collegiate Athletic Association (CCAA), de Gulf South Conference (GSC) en de Northeast-10 Conference (NE10).
<br/>
<br/>
Division III is het laagste niveau van college honkbal. Het bestaat uit ongeveer 380 scholen, die verdeeld zijn in 43 conferenties. De conferenties zijn ook meestal regionaal georiënteerd, zoals de New England Small College Athletic Conference (NESCAC), de Southern California Intercollegiate Athletic Conference (SCIAC) en de Wisconsin Intercollegiate Athletic Conference (WIAC).
<br/>
<br/>
Naast de NCAA, zijn er ook andere organisaties die betrokken zijn bij college honkbal. Een daarvan is de NAIA, die ongeveer 190 scholen vertegenwoordigt. De NAIA is verdeeld in 21 conferenties, zoals de American Midwest Conference (AMC), de Golden State Athletic Conference (GSAC) en de Heart of America Athletic Conference (HAAC).
<br/>
<br/>
Een andere organisatie is de NJCAA, die ongeveer 500 community colleges vertegenwoordigt. De NJCAA is verdeeld in drie divisies: Division I, Division II en Division III. Elke divisie is verder onderverdeeld in districten en regio’s.
<br/>
<br/>
Deze organisaties organiseren elk jaar hun eigen nationale kampioenschappen voor college honkbal. De NCAA Division I kampioenschap is het meest bekende en prestigieuze evenement. Het wordt ook wel het College World Series (CWS) genoemd.
<br/>
<br/>
Het CWS bestaat uit een dubbele eliminatie toernooi van 64 teams, die geselecteerd worden op basis van hun prestaties in het reguliere seizoen en hun conferentie kampioenschappen. De teams worden verdeeld in 16 regionale groepen van vier teams elk. De winnaars van elke regionale groep gaan door naar de super regionale ronde, waar ze spelen tegen een ander team in een best-of-three serie. De winnaars van elke super regionale ronde gaan door naar het CWS finaletoernooi, dat bestaat uit twee groepen van vier teams elk. De winnaars van elke groep spelen tegen elkaar in een best-of-three serie om de nationale kampioen te bepalen.
<br/>
<br/>
Het CWS finaletoernooi wordt elk jaar gehouden in juni in Omaha, Nebraska. Het wordt beschouwd als een van de grootste en spannendste evenementen in college sporten. Het trekt duizenden fans, media en scouts aan.
<br/>
<br/>
De NCAA Division II en Division III kampioenschappen hebben een vergelijkbaar formaat, maar met minder teams. De NAIA en de NJCAA kampioenschappen hebben ook hun eigen formaten, die variëren afhankelijk van het aantal deelnemende teams.
                </MKTypography>
              </MKBox>
              <MKBox id="2.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                2.2 Topprogramma’s en Prestigieuze Teams                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                College honkbal is een zeer competitieve en prestigieuze sport, die veel talent en traditie heeft. Er zijn veel programma’s en teams die zich onderscheiden door hun succes en reputatie. Sommige van deze programma’s en teams zijn:
                </MKTypography>
                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
Texas Longhorns: Het honkbalteam van de Universiteit van Texas in Austin, dat uitkomt in de Big 12 Conference. Dit programma heeft een indrukwekkende staat van dienst met talloze deelnames aan het College World Series en meerdere kampioenschappen. Enkele bekende alumni zijn onder meer Roger Clemens, Burt Hooton en Huston Street.


</li>
<li>
LSU Tigers: Het honkbalteam van de Louisiana State University in Baton Rouge, dat actief is in de SEC. LSU staat bekend om zijn dominantie in college honkbal en heeft meerdere kampioenschappen gewonnen. Enkele bekende alumni zijn Albert Belle, Aaron Hill en DJ LeMahieu.


</li>
<li>
USC Trojans: Het honkbalteam van de Universiteit van Zuid-Californië in Los Angeles, lid van de Pac-12 Conference. USC heeft een rijke traditie met talloze kampioenschappen en heeft veel getalenteerde spelers voortgebracht. Enkele beroemde alumni zijn Tom Seaver, Randy Johnson en Mark McGwire.

</li>
</ul>
</MKTypography>
<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
Dit zijn slechts enkele voorbeelden van de topprogramma’s en prestigieuze teams in college honkbal. Er zijn nog veel meer programma’s en teams die hun eigen geschiedenis, cultuur en identiteit hebben.
<br/>
<br/>
Deze programma’s en teams dragen bij aan de ontwikkeling van spelers op verschillende manieren. Ze bieden hen een hoog niveau van coaching, training, faciliteiten en competitie. Ze helpen hen ook om hun academische, atletische en persoonlijke doelen te bereiken.
                </MKTypography>
              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 3" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 3: Van College naar de Profs                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              College honkbal is niet alleen een sport. Het is ook een pad naar professionalisme. Veel college honkbalspelers hebben de ambitie om professioneel te gaan spelen, in de MLB of in andere professionele competities. Maar hoe ziet dat pad eruit? Hoe kun je van college naar de profs gaan? In dit hoofdstuk zullen we je een overzicht geven van het pad naar professionalisme in honkbal.
              </MKTypography>

              <MKBox id="3.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                3.1 Het Pad naar Professionalisme
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Het pad naar professionalisme in honkbal begint meestal met de MLB Draft. De MLB Draft is een jaarlijks evenement waarbij de 30 MLB teams om de beurt spelers kiezen uit verschillende bronnen, waaronder college honkbal, high school honkbal, internationale honkbal en onafhankelijke honkbal.
<br/>
<br/>
De MLB Draft bestaat uit 40 rondes, waarbij elk team één speler per ronde mag kiezen. De volgorde van de keuzes wordt bepaald door het omgekeerde klassement van het vorige MLB seizoen, met enkele uitzonderingen voor compensatiekeuzes en loterijkeuzes.
<br/>
<br/>
De MLB Draft is een belangrijke kans voor college honkbalspelers om hun professionele carrière te beginnen. Elk jaar worden er ongeveer 1.200 spelers gekozen in de MLB Draft, waarvan ongeveer 60% uit college honkbal komt.
<br/>
<br/>
Als je gekozen wordt in de MLB Draft, heb je twee opties: je kunt een contract tekenen met het team dat je gekozen heeft, of je kunt ervoor kiezen om niet te tekenen en terug te keren naar je universiteit of een andere bron.
<br/>
<br/>
Als je ervoor kiest om een contract te tekenen, krijg je een bonus die afhankelijk is van de ronde waarin je gekozen bent. De bonus varieert van enkele miljoenen dollars voor de eerste ronde tot enkele duizenden dollars voor de latere rondes.
<br/>
<br/>
Als je een contract tekent, word je toegewezen aan een van de minor league teams van het team dat je gekozen heeft. De minor leagues zijn de lagere niveaus van professioneel honkbal, die dienen als een opleidingssysteem voor de MLB. Er zijn zes niveaus in de minor leagues: Rookie, Class A Short Season, Class A, Class A Advanced, Double-A en Triple-A.
<br/>
<br/>
Als je in de minor leagues speelt, moet je je vaardigheden verbeteren en je prestaties laten zien om op te klimmen naar hogere niveaus. Je moet ook concurreren met andere spelers die dezelfde droom hebben als jij: het halen van de major leagues.
<br/>
<br/>
Het halen van de major leagues is het ultieme doel voor elke professionele honkbalspeler. Het is het hoogste niveau van professioneel honkbal, waar je kunt spelen tegen de beste spelers ter wereld, voor de grootste fans en voor de grootste salarissen.
<br/>
<br/>
Maar het halen van de major leagues is niet gemakkelijk. Slechts ongeveer 10% van de spelers die gekozen worden in de MLB Draft bereiken uiteindelijk de major leagues. En slechts ongeveer 1% van de spelers die gekozen worden in de MLB Draft hebben een lange en succesvolle carrière in de major leagues.
<br/>
<br/>
Het pad naar professionalisme in honkbal is dus een lang en moeilijk pad, dat veel toewijding, hard werken en geluk vereist. Maar het is ook een pad dat veel beloningen, kansen en dromen kan bieden.
                </MKTypography>
              </MKBox>
              <MKBox id="3.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                3.2 De MLB Draft en Andere Professionele Mogelijkheden
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                De MLB Draft is niet de enige manier om professioneel te gaan spelen in honkbal. Er zijn ook andere professionele mogelijkheden buiten de MLB, die ook aantrekkelijk kunnen zijn voor college honkbalspelers.
<br/>
<br/>
Een daarvan is de independent league. De independent league is een professionele honkbalcompetitie die niet verbonden is met de MLB of de minor leagues. De independent league bestaat uit verschillende competities, zoals de Atlantic League, de American Association en de Frontier League.
<br/>
<br/>
De independent league biedt een alternatief voor spelers die niet gekozen worden in de MLB Draft of die geen contract krijgen aangeboden door een MLB team. De independent league biedt ook een kans voor spelers die uit de minor leagues worden vrijgelaten of die hun carrière willen verlengen.
<br/>
<br/>
De independent league heeft een lager niveau van spelen en betalen dan de MLB of de minor leagues, maar het heeft ook een hoger niveau van vrijheid en plezier. De independent league staat bekend om zijn creativiteit, diversiteit en experimenten. De independent league heeft ook een aantal spelers voortgebracht die uiteindelijk de major leagues hebben bereikt, zoals Jose Bautista, Rich Hill en Max Muncy.
<br/>
<br/>
Een andere professionele mogelijkheid is de international league. De international league is een verzamelnaam voor de professionele honkbalcompetities die buiten de VS worden gespeeld. De international league bestaat uit verschillende competities, zoals de Nippon Professional Baseball (NPB) in Japan, de Korean Baseball Organization (KBO) in Zuid-Korea, de Chinese Professional Baseball League (CPBL) in Taiwan en de Liga Mexicana de Beisbol (LMB) in Mexico.
<br/>
<br/>
De international league biedt een kans voor spelers die hun horizon willen verbreden en een andere cultuur willen ervaren. De international league biedt ook een kans voor spelers die een hoger niveau van spelen en betalen willen dan de independent league, maar die niet in staat zijn om de MLB of de minor leagues te halen.
<br/>
<br/>
Dit zijn slechts enkele voorbeelden van de professionele mogelijkheden buiten de MLB. Er zijn nog veel meer mogelijkheden, zoals de winter leagues, de college summer leagues en de amateur leagues.
<br/>
<br/>
Deze mogelijkheden bieden allemaal verschillende voordelen en nadelen voor college honkbalspelers. Ze bieden allemaal verschillende niveaus van competitie, beloning, uitdaging en plezier. Ze bieden allemaal verschillende paden naar professionalisme in honkbal.
                </MKTypography>
              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 4" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 4: Internationale Invloeden
                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              College honkbal is niet alleen een Amerikaanse sport. Het is ook een internationale sport, die beïnvloed wordt door spelers, coaches en fans uit verschillende landen en culturen. In dit hoofdstuk zullen we je een overzicht geven van de internationale invloeden in college honkbal.
              </MKTypography>

              <MKBox id="4.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                4.1 Buitenlandse Spelers in het College Honkbal
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
  <img
    src={Image3}
    alt="USC honkbalteam viert een overwinning."
    style={{
      maxWidth: "100%",
      border: "2px solid #fff",
      boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
      cursor: "pointer",
      outline: "none",
    }}
    onClick={() => openModal3(Image3)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
    Spelers van USC vieren een overwinning

</figcaption>
</figure>
                Een van de belangrijkste internationale invloeden in college honkbal is de aanwezigheid van buitenlandse spelers. Buitenlandse spelers zijn spelers die niet geboren zijn in de VS of die geen Amerikaans staatsburgerschap hebben. Ze komen uit verschillende landen, zoals Canada, Mexico, Japan, Zuid-Korea, Taiwan, Cuba, Venezuela, Dominicaanse Republiek en Nederland.
<br/>
<br/>
Buitenlandse spelers spelen een belangrijke rol in college honkbal. Ze brengen hun eigen vaardigheden, stijlen en perspectieven naar het spel. Ze verrijken de diversiteit en kwaliteit van college honkbal. Ze vergroten ook de internationale bekendheid en aantrekkingskracht van college honkbal.
<br/>
<br/>
Er zijn veel succesverhalen van buitenlandse spelers die het gemaakt hebben in de Amerikaanse college honkbalwereld. Een bekend voorbeeld is Nomar Garciaparra. Nomar Garciaparra was een Amerikaanse shortstop van Mexicaanse komaf, die speelde voor de Georgia Institute of Technology. Hij werd gekozen in de eerste ronde van de MLB Draft in 1994 door de Boston Red Sox. Hij speelde 14 seizoenen in de MLB, waar hij zes keer een All-Star werd en twee keer de AL batting title won. En zo zijn er nog veel meer voorbeelden van buitenslandse spelers die hun eigen 

                </MKTypography>
              
              </MKBox>
              <MKBox id="4.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                4.2 Amerikanen in het Buitenland
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Een andere internationale invloed in college honkbal is de aanwezigheid van Amerikanen in het buitenland. Amerikanen in het buitenland zijn spelers die geboren zijn in de VS of die Amerikaans staatsburgerschap hebben, maar die spelen of gespeeld hebben in professionele competities buiten de VS.
<br/>
<br/>
Amerikanen in het buitenland spelen ook een belangrijke rol in college honkbal. Ze brengen hun eigen ervaringen, kennis en netwerken naar het spel. Ze leren ook van andere culturen en stijlen van honkbal. Ze vergroten ook de internationale uitwisseling en samenwerking van college honkbal.
<br/>
<br/>
Deze kansen bieden veel voordelen voor Amerikaanse college honkbalspelers. Ze bieden hen een hoger niveau van competitie, blootstelling en beloning. Ze helpen hen ook om hun horizon te verbreden, hun aanpassingsvermogen te vergroten en hun netwerk te vergroten.
<br/>
<br/>
De internationale ervaring kan ook gunstig zijn voor de ontwikkeling van een speler. Het kan hen helpen om nieuwe vaardigheden, technieken en strategieën te leren. Het kan hen ook helpen om hun zwakke punten te verbeteren, hun sterke punten te benutten en hun potentieel te maximaliseren.
                </MKTypography>
              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 5" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 5: Honkbalbeurzen en de Toekomst
                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              Als je een passie hebt voor honkbal en je wilt jezelf ontwikkelen als speler en als student, dan is een honkbalbeurs in Amerika misschien wel de beste optie voor jou. Een honkbalbeurs geeft je de kans om te sporten en te studeren op hoog niveau, terwijl je geniet van een unieke culturele ervaring. Maar hoe kom je aan een honkbalbeurs? En wat zijn de voordelen en uitdagingen van deze weg? In dit hoofdstuk zullen we deze vragen beantwoorden en je laten zien hoe Sportbeursamerika.nl je kan helpen om je droom waar te maken.
              </MKTypography>

              <MKBox id="5.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                5.1 Het Belang van Beurzen
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Een honkbalbeurs is een financiële ondersteuning die een universiteit of college biedt aan een student-atleet om zijn of haar studiekosten te dekken. Een honkbalbeurs kan variëren van een volledige beurs, die alle kosten dekt, tot een gedeeltelijke beurs, die een percentage van de kosten dekt. Een honkbalbeurs is gebaseerd op zowel academische als atletische prestaties, en wordt elk jaar opnieuw beoordeeld.
<br/>
<br/>
Een honkbalbeurs is niet alleen een financieel voordeel, maar ook een sportief en educatief voordeel. Met een honkbalbeurs kun je namelijk trainen en spelen onder professionele begeleiding, gebruik maken van uitstekende faciliteiten en apparatuur, en deelnemen aan spannende competities en toernooien. Bovendien kun je een diploma behalen aan een gerenommeerde instelling, kiezen uit een breed scala aan studierichtingen, en je Engelse taalvaardigheid verbeteren.
<br/>
<br/>
Een honkbalbeurs is ook een persoonlijk voordeel, want het stelt je in staat om je horizon te verbreden, nieuwe vrienden te maken, andere culturen te leren kennen, en onvergetelijke herinneringen te creëren. Een honkbalbeurs is dus een investering in jezelf, je toekomst, en je passie.
                </MKTypography>
              </MKBox>
              <MKBox id="5.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                5.2 Hoe Sportbeurs Amerika NL Kan Helpen 
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
  <img
    src={Image4}
    alt="Logo van Sportbeurs Amerika NL, bestaande uit een voetbal met het continent Noord-Amerika erop afgebeeld, met daarboven een afstudeerhoed (graduation cap) die bovenop de bal rust alsof het een hoofd is. De naam van het bedrijf is prominent weergegeven, wat de focus op sportbeurzen in Amerika voor Europese studenten benadrukt."
    style={{
      maxWidth: "100%",
      height: "auto",
      border: "2px solid #fff",
      boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)", // Apply shadow around the entire image
      cursor: "pointer",
      outline: "none",
      borderRadius: "50%", // Set border-radius to 50% for a circular image
      marginTop: "0px", // Add top margin to move the image up


    }}
    onClick={() => openModal4(Image4)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
  
</figcaption>
</figure>
Sportbeurs Amerika NL is een organisatie gespecialiseerd in het begeleiden van jonge sporttalenten naar sportbeurzen in Amerika.  Met een gepassioneerde en ervaren expert aan het roer, ondersteunt Sportbeurs Amerika NL de student-atleet bij iedere stap in het traject, vanaf de eerste oriëntatie tot aan de uiteindelijke plaatsing. Sportbeurs Amerika NL biedt onder andere de volgende diensten aan:
                </MKTypography>

                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
Een persoonlijke intakegesprek om de wensen, mogelijkheden en verwachtingen van de student-atleet te bespreken.
</li>
<li>
Een professionele evaluatie van het sportieve en academische niveau van de student-atleet.
</li>
<li>
Een uitgebreid netwerk van contacten met Amerikaanse coaches, universiteiten en colleges.
</li>
<li>
Een effectieve promotie van het profiel en de video’s van de student-atleet bij potentiële coaches.
</li>
<li>
Een deskundig advies over de beste opties voor de student-atleet op basis van zijn of haar voorkeuren, doelen en budget.
</li>
<li>
Een onderhandeling over de beste beursaanbiedingen namens de student-atleet.
</li>
<li>
Een hulp bij het aanvragen van de benodigde documenten, zoals het visum, het transcript, het diploma en de toelatingstesten.
</li>
<li>
Een voorbereiding op het leven in Amerika, zoals de cultuur, de taal, de regels en de tips.
</li>
<li>
Een nazorg en een follow-up tijdens het verblijf in Amerika.</li>

</ul>
</MKTypography>

<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Wil jij ook je passie voor American football combineren met een goede opleiding in Amerika? <Link to="/Diensten-Producten">Bekijk het volledige aanbod</Link> van Sportbeurs Amerika NL en maak een vrijblijvende afspraak om je te laten informeren over de mogelijkheden. 
      Ik sta voor je klaar om je te helpen bij het realiseren van je Amerikaanse droom.
    </MKTypography>


              </MKBox>
            </MKBox>

          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;
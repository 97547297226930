/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Kit 2 PRO React React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Navbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `name` key is used for the name of the route on the Navbar.
  2. The `icon` key is used for the icon of the route on the Navbar.
  3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  4. The `route` key is used to store the route location which is used for the react router.
  5. The `href` key is used to store the external links location.
  6. The `component` key is used to store the component of its route.
  7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
  8. The `description` key is used to define the description of
          a route under its name.
  9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
          you can set the columns amount based on this key.
  10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/

// @mui material components
import Icon from "@mui/material/Icon";
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';

// Pages
import Faq from "pages/Contact/FAQs";
import Author from "pages/Over/Overmij/Profiel";
import VirtualRealityPage from "pages/Content/Podcast";
import AllSports from "pages/Alle Sporten/Overzicht";
import Contactformulier from "pages/Contact/Contactformulier"
import Jeugd from "pages/Over/Overmij/Jeugd";
import Amerika from "pages/Over/Overmij/Amerika";
import NaUSA from "pages/Over/Overmij/NaUSA";
import DienstenProducten from "pages/Aanbod/Diensten_producten";

import Timeline from "pages/Aanbod/Traject";
import AmericanFootball from "pages/Alle Sporten/Sporten/AmericanFootball";
import Atletiek from "pages/Alle Sporten/Sporten/Atletiek";
import Basketbal from "pages/Alle Sporten/Sporten/Basketbal";
import Golf from "pages/Alle Sporten/Sporten/Golf";
import Hockey from "pages/Alle Sporten/Sporten/Hockey";
import Honkbal from "pages/Alle Sporten/Sporten/Honkbal";
import Ijshockey from "pages/Alle Sporten/Sporten/Ijshockey";
import Roeien from "pages/Alle Sporten/Sporten/Roeien";
import Softbal from "pages/Alle Sporten/Sporten/Softbal";
import Tennis from "pages/Alle Sporten/Sporten/Tennis";
import Turnen from "pages/Alle Sporten/Sporten/Turnen";
import Voetbal from "pages/Alle Sporten/Sporten/Voetbal";
import Volleybal from "pages/Alle Sporten/Sporten/Volleybal";
import Waterpolo from "pages/Alle Sporten/Sporten/Waterpolo";
import Worstelen from "pages/Alle Sporten/Sporten/Worstelen";
import Zwemmen from "pages/Alle Sporten/Sporten/Zwemmen";
import CompanyTimeline from "pages/Over/Over Sportbeurs Amerika";
import Kenniscentrum from "pages/Content/Kenniscentrum";





const routes = [

  

  {
    name: "Sporten",
    icon: <SportsSoccerIcon></SportsSoccerIcon>,
    collapse: [
      {
        name: "Alle Sporten",
        description: "Ontdek alle sporten",
        route: "/Alle-Sporten",
        component: <AllSports />,
      
        dropdown: true,
        collapse: [
          {
            name: "American Football",
            route: "/Alle-Sporten/American-Football",
            component: <AmericanFootball />,
          },

          {
            name: "Atletiek",
            route: "/Alle-Sporten/Atletiek",
            component: <Atletiek />,
          },

          {
            name: "Basktebal",
            route: "/Alle-Sporten/Basketbal",
            component: <Basketbal />,
          },

          {
            name: "Golf",
            route: "/Alle-Sporten/Golf",
            component: <Golf />,
          },

          {
            name: "Hockey",
            route: "/Alle-Sporten/Hockey",
            component: <Hockey />,
          },

          {
            name: "Honkbal",
            route: "/Alle-Sporten/Honkbal",
            component: <Honkbal />,
          },

          {
            name: "Ijshockey",
            route: "/Alle-Sporten/Ijshockey",
            component: <Ijshockey />,
          },

          {
            name: "Roeien",
            route: "/Alle-Sporten/Roeien",
            component: <Roeien />,
          },

          {
            name: "Softbal",
            route: "/Alle-Sporten/Softbal",
            component: <Softbal />,
          },

          {
            name: "Tennis",
            route: "/Alle-Sporten/Tennis",
            component: <Tennis />,
          },
          {
            name: "Turnen",
            route: "/Alle-Sporten/Turnen",
            component: <Turnen />,
          },
          {
            name: "Voetbal",
            route: "/Alle-Sporten/Voetbal",
            component: <Voetbal />,
          },
          {
            name: "Volleybal",
            route: "/Alle-Sporten/Volleybal",
            component: <Volleybal />,
          },
          {
            name: "Waterpolo",
            route: "/Alle-Sporten/Waterpolo",
            component: <Waterpolo />,
          },
          {
            name: "Worstelen",
            route: "/Alle-Sporten/Worstelen",
            component: <Worstelen />,
          },
          {
            name: "Zwemmen",
            route: "/Alle-Sporten/Zwemmen",
            component: <Zwemmen />,
          },
        
      ],
},
],
  },     

    {
      name: "Over",
      icon: <Icon>person</Icon>,
    
      collapse: [
        {
          name: "Over Mij",
          description: "Even voorstellen",
          route: "/Over-Mij",
          component: <Author />,
        
          dropdown: true,
          collapse: [
            {
              name: "Deel 1: Mijn Jeugd in Nederland",
              route: "/Over-Mij/Jeugd-Voetbal",
              component: <Jeugd />,
            },

            {
              name: "Deel 2: Mijn Tijd in Amerika",
              route: "/Over-Mij/Tijd-in-Amerika",
              component: <Amerika />,
            },

            {
              name: "Deel 3: Terug in Nederland",
              route: "/Over-Mij/Terug-in-Nederland",
              component: <NaUSA />,
            },
             ],
         },
         {
          name: "Over Sportbeurs Amerika",
          description: "De bedrijfshistorie in beeld",
          route: "/Over-Sportbeurs-Amerika",
          component: <CompanyTimeline />,
        
          dropdown: false,
          
         },
    ],
},    
 
  {
    name: "aanbod",
    icon: <Icon>monetization_on</Icon>,
    collapse: [
      {
        name: "Diensten & Producten",
        description: "Ontdek alle diensten en producten",
        route: "/Diensten-Producten",
        component: <DienstenProducten />,
        dropdown: false,
      
      },
      {
        name: "Traject",
        description: "Inzicht in het traject",
        route: "/Traject",
        component: <Timeline />,
        dropdown: false,
       
      },
      
    ],
  },
  {
    name: "Content",
    icon: <Icon>article</Icon>,
    collapse: [
      {
        name: "Podcast",
        route: "/Podcast",
        description: "Luister naar mijn podcast",
        component: <VirtualRealityPage />
      },
      {
        name: "Kenniscentrum",
        route: "/Kenniscentrum",
        description: "Handige artikelen en links",
        component: <Kenniscentrum />
      },
     
    ],
  },
  {
    name: "Contact",
    icon: <Icon>mail</Icon>,
    collapse: [
      {
        name: "FAQ",
        description:"Antwoorden op veelgestelde vragen",
        route: "/FAQs",
        component: <Faq />,
      },
      {
        name: "Contactformulier",
        route: "/Contactformulier",
        component: <Contactformulier />,
        description: "Neem contact op",
      },
  
     
    ],
  },
];

export default routes;

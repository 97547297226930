/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { Helmet } from 'react-helmet';


import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DetailedFooter from "examples/Footers/DetailedFooter";
import SimpleInfoCard from "examples/Cards/InfoCards/SimpleInfoCard";

// HelpCenter page components
import FaqCollapse from "pages/Contact/FAQs/components/FaqCollapse";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import "../../H1asH3.css"



function Faq() {
  const [collapse, setCollapse] = useState(false);

  return (
    <>
    <Helmet>
    <title>Veelgestelde Vragen - Alles Over Sportbeurzen in de VS</title>
    <meta name="description" content="Vind antwoorden op veelgestelde vragen over sportbeurzen in Amerika, het proces, voorwaarden, en hoe Sportbeurs Amerika je kan helpen." />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta charset="UTF-8" />
    <meta name="robots" content="index, follow" />
    
    {/* Open Graph Tags */}
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://sportbeursamerika.nl/FAQs" />
    <meta property="og:title" content="Veelgestelde Vragen - Alles Over Sportbeurzen in de VS" />
    <meta property="og:description" content="Ontdek antwoorden op essentiële vragen over het navigeren van het traject naar een sportbeurs in de VS met Sportbeurs Amerika." />
    <meta property="og:image" content="https://sportbeursamerika.nl/path/to/faq-page-image.jpg" /> 

    {/* Favicon */}
    <link rel="icon" href="https://sportbeursamerika.nl/32x32Sportbeurs.png" />
</Helmet>

      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://calendly.com/sportbeurs-amerika/intake",
          label: "MAAK AFSPRAAK",
          color: "info",
        }}
      
         
      />
      <MKBox component="section" pt={20} pb={12}>
        <Container sx={{ mb: 8 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={3}>
              <SimpleInfoCard
                icon="sports_soccer"
                title="Sport"
                description="Ontdek de essentiële stappen en criteria om als student atleet een sportbeurs in de VS te bemachtigen."
                direction="center"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <SimpleInfoCard
                icon="school"
                title="Studie"
                description="Leer alles over de academische vereisten en voorbereidingen die nodig zijn voor het combineren van topsport met studeren in Amerika."
                direction="center"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <SimpleInfoCard
                icon="connect_without_contact_icon"
                title="Sociaal"
                description="Krijg inzicht in het sociale aspect van het leven als student atleet in de VS, inclusief netwerken en het aanpassen aan een nieuwe cultuur."
                direction="center"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <SimpleInfoCard
                icon="info_outlined"
                title="Algemeen"
                description="Vind antwoorden op algemene vragen over het proces, de voorbereiding en de verwachtingen van het verkrijgen van een sportbeurs in Amerika."
                direction="center"
              />
            </Grid>
          </Grid>
        </Container>
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Card>
                <MKBox
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  p={3}
                  mt={-3}
                  mx={2}
                >
                  <MKTypography className="h1-as-h3" variant="h1" color="white">
                    Frequently Asked Questions
                  </MKTypography>
                 
                </MKBox>
                <MKBox p={6}>
                <FaqCollapse
                    title="Waarom kost het intakegesprek nu €14,99?"
                    open={collapse === 25}
                    onClick={() => (collapse === 25 ? setCollapse(false) : setCollapse(25))}
                  >
Voorheen bood ik het intakegesprek gratis aan. Het was een plezier om deze service kosteloos te verstrekken, omdat het mij helpt uw behoeften beter te begrijpen en mijn diensten daarop af te stemmen. Echter, deze benadering leidde soms tot aanvragen van mensen die niet serieus overwogen om van mijn diensten gebruik te maken. Dit resulteerde in veel tijd besteed aan gesprekken die niet leidden tot een wederzijds voordelige samenwerking.

Om de kwaliteit en efficiëntie van mijn dienstverlening te waarborgen, heb ik besloten een kleine vergoeding van €14,99 voor het intakegesprek te vragen. Dit helpt om te verzekeren dat degenen die een gesprek aanvragen, serieus overwegen om van mijn diensten gebruik te maken. Ik wil u verzekeren dat dit gesprek nog steeds vrijblijvend is en volledig gericht op het begrijpen van uw situatie en het bespreken van hoe ik u het beste kan helpen.

Ik waardeer uw begrip en kijk uit naar een productief en inzichtelijk gesprek met u.
                  </FaqCollapse>
                  <MKTypography variant="h3" my={3}>
Sport                  </MKTypography>
                  <FaqCollapse
                    title="Wat zijn de vereisten om in aanmerking te komen voor een sportbeurs?"
                    open={collapse === 1}
                    onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
                  >
                   Een sportbeurs in Amerika vereist dat je excelleert in zowel academica als je sport. Scholen kijken naar je middelbare schooldiploma, je cijfers, en vaak zijn testresultaten zoals de SAT of ACT nodig. Voor niet-Engelssprekenden is een TOEFL- of IELTS-score meestal vereist. Sportief moet je aanzienlijke prestaties in je sport kunnen aantonen op een niveau dat competitief is binnen de Amerikaanse college-scene. Dit omvat vaak het spelen in nationale competities of bij gerenommeerde clubs, met bewijs zoals speelvideo's, statistieken en aanbevelingen van coaches. Persoonlijke kwaliteiten zoals leiderschap, teamwork en doorzettingsvermogen worden ook gewogen. Het behalen van een beurs is een strijd waarbij je opvalt tussen vele aspirant-student-atleten, dus het is belangrijk om jezelf te onderscheiden en je vroeg aan te melden. Veel studenten kiezen voor ondersteuning door gespecialiseerde wervingsbureaus.

                  </FaqCollapse>
                  <FaqCollapse
                    title="Hoe kan ik me laten scouten door Amerikaanse coaches?"
                    open={collapse === 2}
                    onClick={() => (collapse === 2 ? setCollapse(false) : setCollapse(2))}
                  >
                  Om gescout te worden door Amerikaanse coaches is het essentieel om je sportieve vaardigheden te demonstreren en academische kwalificaties op peil te houden. Het begint met het verzamelen van speelbeelden, statistieken, en aanbevelingen die je bekwaamheid en potentieel benadrukken. Echter, in de competitieve wereld van college sports, speelt een sterk netwerk een sleutelrol. Hier komt 'Sportbeurs Amerika NL' om de hoek kijken. Met mijn uitgebreide netwerk van contacten binnen Amerikaanse universiteiten kan ik deuren openen die anders gesloten blijven. Ik presenteer je direct aan de coaches, zorgen dat je opvalt en helpen bij het navigeren door de complexe wereld van recruitment. Mijn connecties en ervaring vergroten significant de kans dat je talent wordt opgemerkt en dat je de sportbeurs krijgt die je ambieert. Met 'Sportbeurs Amerika NL' sta je sterker in het scoutingsproces.

                  </FaqCollapse>
                  <FaqCollapse
                    title="Welke verplichtingen ga ik aan met een sportbeurs?"
                    open={collapse === 3}
                    onClick={() => (collapse === 3 ? setCollapse(false) : setCollapse(3))}
                  >
                    Met een sportbeurs ga je verschillende verplichtingen aan. Ten eerste wordt er van je verwacht dat je academisch succesvol blijft en de normen van de universiteit handhaaft. Je moet ook voldoen aan de training- en wedstrijdschema's van je sportteam, wat een aanzienlijke tijdsbesteding vereist. Daarnaast kun je verplicht worden tot het deelnemen aan teambijeenkomsten, media-evenementen en gemeenschapsdiensten georganiseerd door je school. De universiteit steunt je met een beurs in de verwachting dat je zowel op het veld als in de klas presteert en een positieve vertegenwoordiger bent van het sportprogramma. Overtreding van teamregels, slechte academische prestaties of gedragsproblemen kunnen leiden tot sancties of zelfs het intrekken van de beurs. Het is dus belangrijk om een balans te vinden en je verantwoordelijkheden serieus te nemen om je beurs te behouden.

                  </FaqCollapse>
                  <FaqCollapse
                    title="Kan ik mijn sport beoefenen en tegelijkertijd goed presteren in mijn studie?"
                    open={collapse === 4}
                    onClick={() => (collapse === 4 ? setCollapse(false) : setCollapse(4))}
                  >
                  Ja, het is mogelijk om zowel in je sport als in je studie uit te blinken. Amerikaanse universiteiten bieden vaak uitgebreide ondersteuning aan student-atleten, zoals tutoring, studieplanning en academische adviseurs, specifiek ontworpen om je te helpen de balans te vinden tussen sportverplichtingen en academische vereisten. Tijdmanagement en organisatie zijn cruciaal. Je zult leren om prioriteiten te stellen en efficiënt om te gaan met je tijd om succesvol te zijn op beide fronten. Het vereist discipline en toewijding, maar met de juiste mindset en ondersteuningssystemen is het zeker haalbaar. Vele student-atleten voor je hebben bewezen dat het mogelijk is om hoge academische prestaties te leveren terwijl ze ook excelleren in hun sport.

                  </FaqCollapse>
                  <FaqCollapse
                    title="Wat gebeurt er met mijn beurs als ik geblesseerd raak?"
                    open={collapse === 5}
                    onClick={() => (collapse === 5 ? setCollapse(false) : setCollapse(5))}
                  >
                  Als je geblesseerd raakt, betekent dat niet automatisch dat je je sportbeurs verliest. De meeste Amerikaanse universiteiten hanteren een beleid waarbij student-atleten hun beurs behouden tijdens het herstel van een blessure, zolang zij aan de academische eisen blijven voldoen en betrokken blijven bij het team in een aangepaste rol. Je wordt ondersteund door de sportmedische staf van de universiteit tijdens je herstelproces. Echter, de specifieke voorwaarden kunnen variëren per school en de ernst van de blessure kan een rol spelen, dus het is belangrijk om het beurscontract goed te begrijpen en te bespreken wat de verwachtingen zijn in geval van een blessure.

                  </FaqCollapse>
                  <FaqCollapse
                    title="Hoe belangrijk is sport op Amerikaanse universiteiten?" 
                    open={collapse === 6}
                    onClick={() => (collapse === 6 ? setCollapse(false) : setCollapse(6))}
                  >
Sport speelt een centrale rol op Amerikaanse universiteiten; het draagt bij aan de schoolgeest en gemeenschapszin. Atletische programma's op vele campussen krijgen aanzienlijke investeringen en aandacht, met topfaciliteiten en coaching. Sportevenementen trekken grote menigten en media-aandacht. Voor student-atleten bieden deze programma's niet alleen de kans om op hoog niveau te concurreren, maar ook om leiderschaps- en teamwerkvaardigheden te ontwikkelen, wat waardevol is voor persoonlijke groei en toekomstige carrières.

                  </FaqCollapse>
                  <FaqCollapse
                    title="Hoe ziet een typische dag eruit voor een student-atleet in de VS?"
                    open={collapse === 7}
                    onClick={() => (collapse === 7 ? setCollapse(false) : setCollapse(7))}
                  >
                   Een typische dag voor een student-atleet in de VS is strak georganiseerd en vereist discipline. De dag begint vaak vroeg met trainingen of krachttraining, gevolgd door lessen gedurende de dag. Student-atleten kunnen tussen de lessen door gebruik maken van studiebegeleiding of huiswerk maken. Na de middaglessen volgen meestal teamtrainingen, tactische besprekingen of wedstrijden. Avonden zijn gereserveerd voor extra studietijd, herstel en voorbereiding voor de volgende dag. Het leven van een student-atleet is een zorgvuldige balans tussen sport, studie en persoonlijke tijd.

                  </FaqCollapse>
                  <MKTypography variant="h3" mt={6} mb={3}>
Studie                  </MKTypography>
                 
                  
                  <FaqCollapse
                    title="Welke studierichtingen kan ik volgen met een sportbeurs?"
                    open={collapse === 8}
                    onClick={() => (collapse === 8 ? setCollapse(false) : setCollapse(8))}
                  >
                   Met een sportbeurs in de VS kun je bijna elke studierichting volgen die de universiteit aanbiedt, zolang je voldoet aan de toelatingseisen van de specifieke opleiding. Dit varieert van kunst en geesteswetenschappen tot ingenieurswetenschappen en bedrijfskunde. Het is belangrijk om een studie te kiezen die aansluit bij je interesses en carrière-ambities en waarbij je een evenwicht kunt vinden tussen je academische verplichtingen en je sport. Universiteiten moedigen student-atleten vaak aan om te kiezen voor een studiepad dat hen naast hun sportcarrière ook academisch uitdaagt en voorbereidt op de toekomst.

                  </FaqCollapse>
                  <FaqCollapse
                    title="Hoe verschillen Amerikaanse universiteiten van Europese universiteiten?"
                    open={collapse === 9}
                    onClick={() => (collapse === 9 ? setCollapse(false) : setCollapse(9))}
                  >
                    Amerikaanse universiteiten verschillen op meerdere vlakken van Europese universiteiten. Een opvallend verschil is de campuscultuur; Amerikaanse universiteiten bieden vaak een allesomvattende ervaring met uitgebreide faciliteiten, zoals sportcomplexen, eetgelegenheden, en studentenverblijven op de campus zelf. Ook is het onderwijssysteem flexibeler, studenten kunnen vaak vakken kiezen buiten hun hoofdrichting en hun eigen rooster samenstellen.
De betrokkenheid bij sport is veel groter in de VS, met universitaire sportteams die op nationaal niveau concurreren en sportbeurzen aanbieden voor getalenteerde atleten. Bovendien is de interactie met docenten informeler en toegankelijker, en er is veel nadruk op continue evaluatie en deelname in de klas in plaats van alleen examens.
Financieel gezien zijn de kosten voor een studie aan een Amerikaanse universiteit over het algemeen hoger dan in Europa, maar dit wordt vaak gecompenseerd door een breed scala aan beurzen en financiële hulp programma's die beschikbaar zijn voor zowel Amerikaanse als internationale studenten.

                  </FaqCollapse>
                  <FaqCollapse
                    title="Hoe zwaar is het academische programma voor student-atleten?"

                    open={collapse === 10}
                    onClick={() => (collapse === 10 ? setCollapse(false) : setCollapse(10))}
                  >
                  Het academische programma voor student-atleten in de VS kan uitdagend zijn vanwege de combinatie van sportverplichtingen en studie-eisen. Student-atleten moeten een volledig curriculum volgen en voldoen aan dezelfde academische standaarden als niet-atleten, wat kan leiden tot een volle agenda. De trainings- en wedstrijdschema's vergen veel tijd, wat betekent dat effectief tijdbeheer essentieel is om zowel op het veld als in de klas succesvol te zijn. Ondanks deze uitdagingen, bieden veel universiteiten ondersteunende diensten aan, zoals tutoring en academische advisering, speciaal ontworpen om atleten te helpen hun sport- en studieverplichtingen in evenwicht te brengen.

                  </FaqCollapse>
                  <FaqCollapse
                    title="Wat zijn de taalvereisten voor niet-Engelssprekende studenten?"
                    open={collapse === 11}
                    onClick={() => (collapse === 11 ? setCollapse(false) : setCollapse(11))}
                  >
                    Niet-Engelssprekende studenten die willen studeren aan een Amerikaanse universiteit moeten vaak aantonen dat zij de Engelse taal voldoende beheersen. Dit wordt doorgaans gemeten aan de hand van gestandaardiseerde taaltoetsen zoals de TOEFL (Test of English as a Foreign Language) of de IELTS (International English Language Testing System). Elke universiteit stelt haar eigen minimumscores vast die studenten moeten behalen. Sommige instellingen kunnen ook alternatieve tests accepteren of hun eigen taalvaardigheidstesten aanbieden.
Daarnaast kunnen student-atleten die een sportbeurs ontvangen, extra taalondersteuning krijgen via hun universiteit om hen te helpen slagen in zowel hun academische als atletische inspanningen. Het is belangrijk dat studenten de specifieke taaleisen van hun gekozen universiteit en hun sportprogramma controleren om te zorgen dat ze aan alle voorwaarden voldoen.

                  </FaqCollapse>
                  <FaqCollapse
  title="Wat zijn de algemene toelatingseisen voor Amerikaanse universiteiten?"
  open={collapse === 12}
  onClick={() => (collapse === 12 ? setCollapse(false) : setCollapse(12))}
>
De algemene toelatingseisen voor Amerikaanse universiteiten omvatten:

<ul style={{ paddingLeft: '30px' }}> {/* Indentation applied here */}
    <li>Academische Geschiedenis: Bewijs van secundair onderwijs succes, meestal door middel van een middelbare schooldiploma of equivalent, met transcripties die cursussen en cijfers weergeven.</li>
    <li>Standaardtesten: SAT of ACT scores worden vaak vereist voor binnenlandse studenten, en soms ook voor internationale aanvragers.</li>
    <li>Engelse Taalvaardigheid: Niet-Engelssprekende studenten moeten TOEFL of IELTS scores indienen om hun beheersing van het Engels aan te tonen.</li>
    <li>Aanbevelingsbrieven: Brieven van leraren of schooladviseurs die je academische en persoonlijke kwaliteiten belichten.</li>
    <li>Persoonlijke Verklaring of Essays: Schriftelijke stukken waarin studenten hun motivatie, doelen en geschiktheid voor de universiteit uitleggen.</li>
    <li>Extracurriculaire Activiteiten: Bewijs van betrokkenheid buiten de klas, zoals clubs, sporten, vrijwilligerswerk en andere interesses.</li>
    <li>Voor internationale studenten kunnen er aanvullende eisen zijn, zoals financiële documentatie om te bewijzen dat ze hun studie en levensonderhoud kunnen bekostigen, en soms een interview. Specifieke programma's en universiteiten kunnen ook extra eisen stellen, dus het is belangrijk om de toelatingsinformatie per instelling te controleren.</li>
  </ul>
</FaqCollapse>

                  <FaqCollapse
                    title="Krijg ik academische begeleiding als atleet?"
                    open={collapse === 13}
                    onClick={() => (collapse === 13 ? setCollapse(false) : setCollapse(13))}
                  >
                  Ja, op Amerikaanse universiteiten krijgen student-atleten vaak academische begeleiding om hen te helpen slagen. Deze ondersteuning kan bestaan uit studiehallen, waarbij bepaalde uren worden gereserveerd voor studeren, toegang tot bijles voor uitdagende vakken, en academische adviseurs die speciaal zijn toegewezen aan atleten om hen te helpen hun cursusbelasting en sportverplichtingen in evenwicht te brengen. Deze diensten zijn ontworpen om atleten te helpen hun academische doelen te bereiken en in aanmerking te blijven komen voor hun sport.

                  </FaqCollapse>
                  <FaqCollapse
                    title="Wat zijn de gevolgen als ik academisch niet presteer?"
                    open={collapse === 14}
                    onClick={() => (collapse === 14 ? setCollapse(false) : setCollapse(14))}
                  >
Als je academisch niet presteert, kunnen er verschillende gevolgen zijn, afhankelijk van de regels van de universiteit en de sportafdeling. In het algemeen kunnen student-atleten die niet aan de academische normen voldoen, worden geplaatst op academische proeftijd. Dit kan leiden tot verlies van speeltijd, beperkingen in deelname aan sportevenementen, of in ernstige gevallen, het intrekken van de sportbeurs. Bovendien moeten student-atleten een bepaalde GPA behouden om in aanmerking te komen voor wedstrijden volgens de NCAA of NAIA regels. Het is daarom cruciaal om gebruik te maken van de beschikbare academische hulpbronnen en begeleiding aangeboden door de universiteit om academische problemen te voorkomen.

                  </FaqCollapse>
                  <FaqCollapse
                    title="Voor hoeveel universiteiten kan ik me aanmelden?"
                    open={collapse === 13}
                    onClick={() => (collapse === 13 ? setCollapse(false) : setCollapse(13))}
                  >
Voor student-atleten is het proces van aanmelden bij universiteiten vaak gekoppeld aan het voorafgaande recruitmentproces. Meestal beginnen zij met aanmelden nadat er al contact is geweest met coaches en er interesse is getoond in hun sportieve vaardigheden. Dit betekent dat het aantal universiteiten waarbij een student-atleet zich aanmeldt, kan variëren afhankelijk van de interesse die zij ontvangen van sportprogramma's. Het is gebruikelijk dat een atleet zich richt op een kleiner aantal scholen — vaak tussen de 3 en 5 — waarvan ze weten dat er wederzijdse interesse is en waar ze al voorafgaand contact met de coachingstaf hebben gehad over de mogelijkheden voor sportbeurzen. Dit zorgt voor een gerichter en vaak effectiever aanmeldingsproces, omdat de kansen op acceptatie en het verkrijgen van een sportbeurs hoger zijn bij deze instellingen.

                  </FaqCollapse>
                  <MKTypography variant="h3" mt={6} mb={3}>
                  Sociaal

                  </MKTypography>
                  <FaqCollapse
                    title="Hoe is het sociale leven voor student-atleten in Amerika?"

                    open={collapse === 15}
                    onClick={() => (collapse === 15 ? setCollapse(false) : setCollapse(15))}
                  >
Het sociale leven voor student-atleten in Amerika kan zeer dynamisch en vervullend zijn, maar ook druk. Als atleet ben je vaak een bekend gezicht op de campus en dat kan leiden tot een actief sociaal leven. Veel student-atleten vinden een gemeenschap binnen hun team en de bredere atletiekafdeling, wat een sterke sociale ondersteuning biedt.
De betrokkenheid bij zowel sport als academica kan echter uitdagend zijn voor de tijd die beschikbaar is voor andere sociale activiteiten. Trainingen, wedstrijden, reistijden voor competities, en de noodzaak om academische verplichtingen na te komen, nemen een groot deel van de tijd in beslag.
Desondanks proberen veel atleten tijd vrij te maken voor sociale evenementen, studentenorganisaties en campusactiviteiten, wat bijdraagt aan een rijke universiteitservaring. De universiteitscultuur in de VS stimuleert ook betrokkenheid bij vrijwilligerswerk, leiderschapsontwikkeling en andere extracurriculaire activiteiten, wat allemaal bijdraagt aan het sociale leven.
Het is belangrijk voor student-atleten om een goede balans te vinden tussen sport, studie en sociale activiteiten, zodat ze een volledige en evenwichtige universiteitservaring kunnen hebben.

                  </FaqCollapse>
                  <FaqCollapse
                    title="Kan ik deelnemen aan studentenorganisaties of andere activiteiten buiten sport?"

                    open={collapse === 16}
                    onClick={() => (collapse === 16 ? setCollapse(false) : setCollapse(16))}
                  >
Zeker, als student-atleet heb je de mogelijkheid om deel te nemen aan studentenorganisaties en extracurriculaire activiteiten, maar het is belangrijk om te erkennen dat je sportverplichtingen vaak voorrang hebben. Trainingen, wedstrijden en reizen voor competities kunnen een groot deel van je tijd in beslag nemen. Daarom zul je moeten uitblinken in tijdmanagement om je sport, studies en andere activiteiten te kunnen combineren. Hoewel sportprioriteit heeft, moedigen veel universiteiten student-atleten aan om een evenwichtige universiteitservaring na te streven, en zij bieden vaak flexibele mogelijkheden voor atleten om deel te nemen aan het bredere campusleven. Dit kan leiden tot een zeer bevredigend, maar druk universiteitsleven waarbij planning en organisatie sleutel zijn.

                  </FaqCollapse>
                  <FaqCollapse
                    title="Hoe kan ik omgaan met heimwee of cultuurschok?"

                    open={collapse === 17}
                    onClick={() => (collapse === 17 ? setCollapse(false) : setCollapse(17))}
                  >
Heimwee en cultuurschok zijn natuurlijke reacties op het leven ver van huis, maar weet dat je niet alleen bent. Als atleet ben je onderdeel van een team, een gemeenschap van individuen die jouw passie voor sport delen en vaak soortgelijke ervaringen doormaken. Dit gevoel van kameraadschap kan een krachtige buffer zijn tegen heimwee. Blijf actief betrokken bij je team en maak gebruik van de structuur en routine die het teamleven biedt. Deel je gevoelens met teamgenoten; de kans is groot dat zij zich kunnen inleven en steun kunnen bieden. Onthoud ook dat deze ervaringen bijdragen aan persoonlijke groei. Met tijd en geduld zul je je aanpassen en kan je nieuwe omgeving gaan aanvoelen als een tweede thuis. Universiteiten bieden ook begeleiding en ondersteuning voor internationale studenten, dus schroom niet om hulp te zoeken als je het nodig hebt. Je bent omringd door mensen die je willen zien slagen, zowel op het veld als daarbuiten.

                  </FaqCollapse>
                  <FaqCollapse
                    title="Zijn er netwerkmogelijkheden met alumni of professionals?"
                    open={collapse === 18}
                    onClick={() => (collapse === 18 ? setCollapse(false) : setCollapse(18))}
                  >
Ja, veel Amerikaanse universiteiten bieden uitstekende netwerkmogelijkheden met alumni en professionals, vooral voor student-atleten. Sportteams hebben vaak een loyaal netwerk van voormalige spelers die succesvolle carrières hebben opgebouwd. Universiteiten organiseren regelmatig netwerkevenementen, carrièrebeurzen, en mentorprogramma's waar je in contact kunt komen met afgestudeerden en professionals uit verschillende sectoren. Deze contacten kunnen van onschatbare waarde zijn voor stages, baanmogelijkheden, en professioneel advies. Als atleet krijg je vaak ook de kans om deel te nemen aan fondsenwervende evenementen en alumni-bijeenkomsten, wat extra gelegenheden biedt om relaties op te bouwen. Het is belangrijk om deze kansen te benutten en je netwerk op te bouwen gedurende je tijd aan de universiteit.

                  </FaqCollapse>
                  <FaqCollapse
                    title="Wat zijn de huisvestingsmogelijkheden voor student-atleten?"

                    open={collapse === 19}
                    onClick={() => (collapse === 19 ? setCollapse(false) : setCollapse(19))}
                  >
Student-atleten hebben bij Amerikaanse universiteiten vaak meerdere huisvestingsopties:
<ul style={{ paddingLeft: '30px' }}> {/* Indentation applied here */}
    <li>
    Atletenwooncomplexen: Sommige universiteiten bieden speciale woonvoorzieningen voor atleten met faciliteiten die gericht zijn op de behoeften van sporters, zoals fitnessruimtes en studiezalen.
      </li>
    <li>
    Campuswoningen: Traditionele slaapzalen of residentiehallen op de campus zijn een gebruikelijke keuze, waarbij eerstejaarsstudenten vaak in deze gemeenschappelijke woonruimtes verblijven.
      </li>
    <li>
    Universiteitsappartementen: Voor oudere of transferstudent-atleten zijn er soms appartementen beschikbaar die meer privacy en zelfstandigheid bieden dan slaapzalen.
      </li>
    <li>
    Privéwoningen: Sommige atleten kiezen voor off-campus woningen, zoals appartementen of huizen, die ze huren in de nabijgelegen gemeenschap.
      </li>
  </ul>
De keuze hangt af van persoonlijke voorkeur, budget, beschikbaarheid en het beleid van de universiteit. Universiteiten hebben vaak een afdeling huisvesting die kan helpen bij het vinden van de juiste optie voor jou als student-atleet.

                  </FaqCollapse>
                  <MKTypography variant="h3" mt={6} mb={3}>
                  Algemeen

                  </MKTypography>
                  <FaqCollapse
                    title="Wanneer moet ik mij aanmelden voor een sportbeurs?"
                    open={collapse === 20}
                    onClick={() => (collapse === 20 ? setCollapse(false) : setCollapse(20))}
                  >
Als internationale atleet is het aanmeldingsproces voor een sportbeurs in de Verenigde Staten vergelijkbaar met dat voor binnenlandse atleten, maar er zijn extra stappen die je moet overwegen. SportBeurs Amerika NL begeleid een student bij al deze stappen:
<ul style={{ paddingLeft: '30px' }}> {/* Indentation applied here */}
    <li>
    Vroegtijdige Voorbereiding: Begin al vroeg, idealiter in de voorlaatste jaar van je middelbare school, om informatie te verzamelen over universiteiten en hun sportbeursprogramma's.
      </li>
    <li>
    Contact met Coaches: Met hulp van Sportbeurs Amerika NL wordt in je voorlaatste jaar contact gelegd met coaches Dit omvat o.a. mailen,  sportprestaties en videobeelden te delen, en interesse in het programma tonen.
      </li>
    <li>
    Engelse Taalvaardigheid: Zorg ervoor dat je de vereiste Engelse taaltesten zoals TOEFL of IELTS hebt afgelegd ruim voordat je je gaat aanmelden. Studiemateriaal en extra informatie over deze testen kan aangevraagd worden.
      </li>
    <li>
    Academische Documentatie: Sportbeurs Amerika NL helpt met tijdig vertalen en certificeren van je academische documenten, aangezien universiteiten deze in het Engels nodig hebben.
      </li>
      <li>
      Aanmeldingen: Start het formele aanmeldingsproces bij universiteiten in je laatste jaar van de middelbare school, rekening houdend met de deadlines van de universiteiten en sportseizoenen.
      </li>
      <li>
      Beurzen en Financiën: Onderzoek beurzenmogelijkheden en zorg voor de nodige financiële documentatie, aangezien dit vaak vereist is voor internationale studenten.
      </li>
      <li>
      Eligibility Centers: Registreer bij de relevante eligibility centers zoals de NCAA Eligibility Center of de NAIA Eligibility Center, die je amateurstatus en academische geschiedenis beoordelen.
      </li>
      <li>
      Officiële Bezoeken: Indien mogelijk, plan bezoeken aan campussen die je hebben uitgenodigd of waar je serieuze interesse in hebt.
      </li>
  </ul>
Het is essentieel om je bewust te zijn van en te voldoen aan de specifieke deadlines en vereisten, aangezien deze kunnen verschillen per universiteit en sportbeursprogramma. Bovendien kan het proces langer duren vanwege de benodigde tijd voor het verkrijgen van visa en het verwerken van internationale documentatie.

                  </FaqCollapse>
                  <FaqCollapse
                    title="Welke kosten zijn verbonden aan een sportbeurs die niet gedekt worden?"
                    open={collapse === 21}
                    onClick={() => (collapse === 21 ? setCollapse(false) : setCollapse(21))}
                  >
Hoewel een sportbeurs veel van de kosten van het universiteitsbezoek kan dekken, zijn er bepaalde uitgaven die mogelijk niet worden gedekt en die je zelf moet financieren:
<ul style={{ paddingLeft: '30px' }}> {/* Indentation applied here */}
    <li>
    Persoonlijke uitgaven: Zakgeld voor activiteiten zoals uit eten gaan, persoonlijke reizen, of entertainment.
      </li>
    <li>
    Boeken en schoolbenodigdheden: Soms worden de kosten van boeken en ander studiemateriaal niet volledig gedekt.
      </li>
    <li>
    Reiskosten: Reizen naar huis tijdens vakanties en pauzes zijn vaak niet inbegrepen.
      </li>
    <li>
    Kamer en bord: Sommige beurzen dekken slechts een deel van de huisvesting en maaltijdplannen.
      </li>
      <li>
      Kosten voor cursus-specifieke materialen: Speciale labbenodigdheden of kunstbenodigdheden kunnen extra kosten met zich meebrengen.
      </li>
      <li>
      Ziektekostenverzekering: Internationale studenten moeten meestal een ziektekostenverzekering afsluiten die niet altijd door beurzen wordt gedekt.
      </li>
      <li>
      Vergoedingen: Diverse vergoedingen zoals technologie-, activiteiten- of faciliteitenvergoedingen kunnen apart in rekening worden gebracht.
      </li>
      
  </ul>
Het is belangrijk om de voorwaarden van je sportbeurs nauwkeurig te bekijken en een budget te plannen voor de kosten die niet gedekt zijn. Sommige universiteiten bieden aanvullende financiële hulp of werk-studieprogramma's om deze kosten te helpen dekken.

                  </FaqCollapse>
                  <FaqCollapse
                    title="Hoe werkt de visumaanvraag voor studeren in de VS?"
                    open={collapse === 22}
                    onClick={() => (collapse === 22 ? setCollapse(false) : setCollapse(22))}
                  >
Als u als Europese student een sportbeurs hebt verkregen om in de VS te studeren, is het verkrijgen van een geschikt visum een belangrijke stap. Hierbij zal mijn bedrijf, Sportbeurs Amerika NL, u bijstaan. Zodra u bent toegelaten tot een Amerikaanse universiteit, ontvangt u van de instelling het Formulier I-20, essentieel voor uw visumaanvraag. Met dit document voldoet u aan de SEVIS I-901 fee en plant u een visuminterview bij de Amerikaanse ambassade of consulaat. Vervolgens vult u het online DS-160 formulier in en verzamelt u de benodigde documenten, zoals financiële bewijzen en academische records. Met de hulp van Sportbeurs Amerika NL bereidt u zich voor op het interview en zorgt u ervoor dat alle documentatie correct is, waardoor het proces gestroomlijnd verloopt. Mijn ondersteuning optimaliseert uw kans op een succesvolle visumaanvraag, zodat u zich kunt richten op uw sportieve en academische ambities in de VS.

                  </FaqCollapse>
                  <FaqCollapse
                    title="Is er medische verzekering inbegrepen bij de beurs?
"
                    open={collapse === 23}
                    onClick={() => (collapse === 23 ? setCollapse(false) : setCollapse(23))}
                  >
Bij sommige sportbeurzen is een medische verzekering inbegrepen, maar dit is niet standaard bij elke beurs of universiteit. Het is cruciaal om dit bij uw specifieke programma na te gaan. Als u uw sportbeurs via Sportbeurs Amerika NL regelt, help ik u te begrijpen wat uw beurs dekt en adviseer ik u over hoe u zich kunt verzekeren voor de zaken die niet gedekt zijn. In het geval dat medische verzekering niet is inbegrepen, ondersteun ik u bij het vinden van een passende verzekering om ervoor te zorgen dat u goed verzekerd bent tijdens uw studie en sportactiviteiten in de VS. Medische zorg kan duur zijn in Amerika, dus het is essentieel om hier goed op voorbereid te zijn.

                  </FaqCollapse>
                  <FaqCollapse
                    title="Hoe kan ik mijn familie bezoeken of zij mij tijdens mijn studie?"

                    open={collapse === 24}
                    onClick={() => (collapse === 24 ? setCollapse(false) : setCollapse(24))}
                  >
Student-atleten kunnen vaak hun familie bezoeken tijdens de vastgestelde schoolvakanties, zoals de zomer- en winterpauzes. Het is belangrijk om rekening te houden met de sportkalender en academische verplichtingen bij het plannen van reizen. Voor ouders of familieleden die vanuit het buitenland naar de VS komen, is het meestal nodig een toeristenvisum aan te vragen. Zij moeten dit proces starten ruim voordat ze van plan zijn te reizen. Vergeet niet dat het plannen van reizen en bezoek ook een financiële overweging is, dus het is verstandig om dit van tevoren te budgetteren.

                  </FaqCollapse>
                  <FaqCollapse
                    title="Wat gebeurt er na mijn afstuderen? Kan ik in de VS blijven werken?                    "
                    open={collapse === 25}
                    onClick={() => (collapse === 25 ? setCollapse(false) : setCollapse(25))}
                  >
Na het afronden van je studie met een sportbeurs in de VS heb je verschillende opties. Als je wilt blijven werken, kun je gebruik maken van de Optional Practical Training (OPT), die afgestudeerden toestaat om tot 12 maanden in hun studieveld te werken. Voor afgestudeerden in STEM-vakgebieden kan dit zelfs uitgebreid worden tot 24 maanden. Om hiervoor in aanmerking te komen, moet je een aanvraag indienen bij de U.S. Citizenship and Immigration Services (USCIS) en goedkeuring ontvangen voordat je visum verloopt. Langere termijn werkopties vereisen een werkvisum, dat vaak gesponsord wordt door een werkgever. Het is belangrijk om tijdens je studie al te beginnen met het verkennen van je carrièremogelijkheden en te netwerken om je kansen op werk na het afstuderen te vergroten.

                  </FaqCollapse>

                 
                  
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={{ xs: 0, lg: 3 }} pb={3}>
      <DetailedFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Faq;

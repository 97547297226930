/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sporten_Images/Golf1.jpeg";
import Image2 from "assets/images/Sporten_Images/Golf2.webp";
import Image3 from "assets/images/Sporten_Images/Golf3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"


// Images



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Golfbeurs: Hoe je kunt studeren en golfen in Amerika', isSubheader: false },

    { id: 'Hoofdstuk 1', title: '1. Geschiedenis en Ontwikkeling van College Golf', isSubheader: false },
    { id: '1.1', title: '1.1 Vroege Jaren', isSubheader: true },
    { id: '1.2', title: '1.2 Groei en Professionalisering', isSubheader: true },

    { id: 'Hoofdstuk 2', title: '2. Het Huidige Landschap van College Golf', isSubheader: false },
    { id: '2.1', title: '2.1 Belangrijke Competities en Conferenties', isSubheader: true },
    { id: '2.2', title: '2.2 Topprogramma’s en Prestigieuze Teams', isSubheader: true },

    { id: 'Hoofdstuk 3', title: '3: Van College naar de Profs', isSubheader: false },
    { id: '3.1', title: '3.1 Het Pad naar het Professionele Circuit', isSubheader: true },
    { id: '3.2', title: '3.2 Alternatieve Routes naar Professionalisme', isSubheader: true },


    { id: 'Hoofdstuk 4', title: '4. Internationale Invloeden en Kansen', isSubheader: false },
    { id: '4.1', title: '4.1 Buitenlandse Spelers in College Golf', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikaanse Spelers in het Buitenland', isSubheader: true },

    { id: 'Hoofdstuk 5', title: '5. Golfbeurzen en de Toekomst', isSubheader: false },
    { id: '5.1', title: '5.1 Het Belang van Golfbeurzen', isSubheader: true },
    { id: '5.2', title: '5.2 Hoe Sportbeurs Amerika NL Kan Helpen', isSubheader: true },

  ];

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Inhoud
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Verberg</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Toon</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
            <MKBox id="intro" mb={3}>
            <MKTypography variant="h1" className="h1-as-h3" mb={2}>
              Golfbeurs: Hoe je kunt studeren en golfen in Amerika
              </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
  <img
    src={Image1}
    alt="Golf green tijdens het NCAA kampioenschap, met spelers en toeschouwers rond de baan"
    style={{
      maxWidth: "100%",
      border: "2px solid #fff",
      boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
      cursor: "pointer",
      outline: "none",
    }}
    onClick={() => openModal1(Image1)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
  Het NCAA golf kampioenschap
</figcaption>
</figure>
              Golf is een van de meest populaire sporten op collegeniveau in de Verenigde Staten. Elk jaar strijden duizenden student-atleten om nationale titels, prestigieuze prijzen, en een plek in de professionele golfsport. College golf biedt niet alleen een kans om je sportieve vaardigheden te verbeteren, maar ook om een academische graad te behalen, een netwerk op te bouwen, en een onvergetelijke ervaring te beleven.
<br/>
<br/>
College golf is echter niet voor iedereen weggelegd. Het vereist een hoog niveau van toewijding, discipline, en talent. Bovendien is het niet gemakkelijk om toegelaten te worden tot een universiteit met een goed golfprogramma. De concurrentie is groot, en de kosten zijn hoog.
<br/>
<br/>
Gelukkig zijn er mogelijkheden om je droom waar te maken met behulp van een golfbeurs. Een golfbeurs is een financiële ondersteuning die je krijgt van een universiteit om daar te studeren en te golfen. Met een golfbeurs kun je je studiekosten verlagen of zelfs volledig dekken, terwijl je profiteert van alle voordelen van college golf.
<br/>
<br/>
Maar hoe kom je aan een golfbeurs? En waar moet je op letten bij het kiezen van een universiteit? Dat is waar Sportbeursamerika.nl je kan helpen. Sportbeursamerika.nl is dé specialist in het begeleiden van studenten naar sportbeurzen in Amerika. Ik heb ervaring en kennis van het Amerikaanse onderwijs- en sportsysteem, en ik heb al veel student-atleten geholpen om hun droom te verwezenlijken.
<br/>
<br/>
In dit artikel geven we je meer informatie over college golf, en laten we zien hoe Sportbeursamerika.nl je kan ondersteunen bij het vinden van de perfecte golfbeurs voor jou.
<br/>
<br/>
Wil je meer weten? Lees dan snel verder!
              </MKTypography>
              {/* Add bulleted list or other content as needed */}
            </MKBox>
             {/* Main chapters and subchapters with placeholder text */}
            <MKBox id="Hoofdstuk 1" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 1: Geschiedenis en Ontwikkeling van College Golf
              </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              College golf heeft een lange en rijke geschiedenis, die teruggaat tot het begin van de 20e eeuw. In dit hoofdstuk zullen we kijken naar de vroege jaren en de groei en professionalisering van college golf in de Verenigde Staten.
              </MKTypography>

              <MKBox id="1.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                1.1 Vroege Jaren
                </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                De eerste golfcompetities op universiteiten en hogescholen in de VS vonden plaats in de jaren 1890. De eerste officiële intercollegiate golf match was tussen Harvard en Yale in 1897. In 1901 werd de Intercollegiate Golf Association (IGA) opgericht, die het eerste nationale kampioenschap organiseerde in 1902. Yale won de eerste titel, en bleef domineren tot de jaren 1920.
<br/>
<br/>
De eerste golfprogramma’s op universiteiten waren vooral gericht op de sociale en recreatieve aspecten van de sport. De studenten speelden vooral op lokale banen, en hadden weinig begeleiding of faciliteiten. De golfteams bestonden meestal uit vier of vijf spelers, die zich kwalificeerden via interne toernooien.
                </MKTypography>
              </MKBox>
              <MKBox id="1.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                1.2 Groei en Professionalisering
                                </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                In de jaren 1930 begon college golf aanzienlijk te groeien en te professionaliseren. Deze periode zag een toename in het aantal universitaire golfprogramma's en een verbetering in de kwaliteit van training en faciliteiten. De professionalisering werd verder gestimuleerd door de toenemende aandacht voor de sport en de groeiende erkenning van het potentieel van golf op universitair niveau. In deze tijd nam de National Collegiate Athletic Association (NCAA) een leidende rol in het organiseren en reguleren van college golf, wat bijdroeg aan de verdere ontwikkeling en populariteit van de sport in de Verenigde Staten.





<br/>
<br/>
De NCAA nam de organisatie van het nationale kampioenschap over van de IGA, en introduceerde nieuwe regels en formaten. De NCAA verdeelde de universiteiten in verschillende divisies, gebaseerd op hun grootte en sportieve niveau. De NCAA organiseerde ook regionale kampioenschappen, om de kwalificatie voor het nationale kampioenschap te vergemakkelijken.
<br/>
<br/>
De universiteiten begonnen ook meer te investeren in hun golfprogramma’s, door het aanstellen van professionele coaches, het bouwen of verbeteren van golfbanen, en het aanbieden van beurzen aan getalenteerde spelers. De golfteams werden groter en sterker, en de competitie werd intenser.
<br/>
<br/>
In de jaren 1940 en 1950 kwamen er ook meer belangrijke toernooien bij, zoals de Walker Cup, de Palmer Cup, en de Eisenhower Trophy. Deze toernooien gaven college golfers de kans om zich te meten met internationale spelers, en om hun vaardigheden te verbeteren.
<br/>
<br/>
In dit hoofdstuk hebben we gezien hoe college golf is ontstaan en gegroeid in de Verenigde Staten. In het volgende hoofdstuk zullen we kijken naar het huidige landschap van college golf, en welke competities en programma’s er zijn.
                </MKTypography>
              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 2" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 2: Het Huidige Landschap van College Golf
                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              College golf is tegenwoordig een van de meest competitieve en prestigieuze sporten op universitair niveau in de Verenigde Staten. In dit hoofdstuk zullen we kijken naar de belangrijkste competities en conferenties, en de topprogramma’s en prestigieuze teams in college golf.
              </MKTypography>

              <MKBox id="2.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                2.1 Belangrijke Competities en Conferenties
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
  <img
    src={Image2}
    alt="Tiger Woods vertegenwoordigt Stanford in college golf, getoond in wedstrijdconcentratie."
    style={{
      maxWidth: "100%",
      border: "2px solid #fff",
      boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
      cursor: "pointer",
      outline: "none",
    }}
    onClick={() => openModal2(Image2)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
Tiger Woods in actie voor Stanford.
</figcaption>
</figure>
                De NCAA is de grootste en belangrijkste organisatie voor college golf in de VS. De NCAA verdeelt de universiteiten in drie divisies: Division I, Division II, en Division III. Elke divisie heeft zijn eigen kampioenschap, dat elk jaar wordt gehouden in mei of juni. De kampioenschappen bestaan uit een team- en een individueel toernooi, waarbij de beste spelers en teams van elke divisie strijden om de nationale titel.
<br/>
<br/>
De NCAA heeft ook verschillende regionale conferenties, die bestaan uit universiteiten die geografisch of historisch verbonden zijn. Elke conferentie heeft zijn eigen kampioenschap, dat dient als een kwalificatietoernooi voor het nationale kampioenschap. Sommige conferenties zijn sterker dan andere, en hebben meer invloed op het college golf landschap.
<br/>
<br/>
Enkele van de meest bekende conferenties zijn:
                </MKTypography>
                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
De Atlantic Coast Conference (ACC), die bestaat uit 15 universiteiten aan de oostkust, zoals Duke, North Carolina, en Clemson.

</li>
<li>
De Big Ten Conference, die bestaat uit 14 universiteiten in het middenwesten, zoals Michigan, Ohio State, en Illinois.

</li>
<li>
De Pac-12 Conference, die bestaat uit 12 universiteiten aan de westkust, zoals Stanford, California, en Arizona State.

</li>
<li>
De Southeastern Conference (SEC), die bestaat uit 14 universiteiten in het zuidoosten, zoals Alabama, Georgia, en Florida.

</li>
</ul>
</MKTypography>
<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
Naast de NCAA zijn er ook andere organisaties die college golf competities organiseren, zoals de National Association of Intercollegiate Athletics (NAIA), de National Junior College Athletic Association (NJCAA), en de National Christian College Athletic Association (NCCAA). Deze organisaties hebben hun eigen divisies, conferenties, en kampioenschappen, die meestal kleiner en minder prestigieus zijn dan die van de NCAA.

                </MKTypography>



              </MKBox>
              <MKBox id="2.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                2.2 Topprogramma’s en Prestigieuze Teams
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                College golf is niet alleen een sport, maar ook een opleiding. De universiteiten die college golf aanbieden hebben verschillende programma’s, die variëren in kwaliteit, reputatie, en faciliteiten. Sommige programma’s zijn vooraanstaand en prestigieus, en trekken de beste spelers en coaches aan. Andere programma’s zijn minder bekend of succesvol, maar bieden nog steeds goede mogelijkheden voor student-atleten.
<br/>
<br/>
Enkele van de factoren die een programma bepalen zijn:
                </MKTypography>
                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
De geschiedenis en traditie van het programma.

</li>
<li>
Het niveau en de resultaten van het team.

</li>
<li>
De kwaliteit en ervaring van de coach.
</li>
<li>
De faciliteiten en uitrusting van het programma.

</li>
<li>
De academische standaarden en eisen van het programma.

</li>
<li>
De sfeer en cultuur van het programma.

</li>
</ul>
</MKTypography>
<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
Enkele van de meest vooraanstaande universiteitsgolfprogramma’s zijn:

                </MKTypography>
                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
Stanford University: Stanford heeft een van de oudste en meest succesvolle golfprogramma's in de VS, met meerdere nationale titels in het mannen golf. Het programma heeft beroemde alumni voortgebracht zoals Tiger Woods en Tom Watson. Het vrouwen golfteam van Stanford heeft ook nationale erkenning gekregen met enkele NCAA titels en individuele kampioenen.


</li>
<li>
Oklahoma State University: Oklahoma State heeft een dominant golfprogramma, vooral bekend om het mannenteam, met meerdere nationale titels. Het programma heeft topgolfers voortgebracht zoals Rickie Fowler en Viktor Hovland.


</li>
<li>
Duke University: Duke staat bekend om zijn sterke vrouwen golfprogramma, met meerdere NCAA titels. Het mannen golfteam van Duke heeft sterke prestaties geleverd in conferentiekampioenschappen en heeft een respectabele geschiedenis in NCAA competities. Duke heeft getalenteerde golfers voortgebracht, waaronder Amanda Blumenherst en Brittany Lang.





</li>

</ul>
</MKTypography>
<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
In dit hoofdstuk hebben we gezien hoe het huidige landschap van college golf eruit ziet, en welke competities en programma’s er zijn. In het volgende hoofdstuk zullen we kijken hoe je van college naar de profs kunt gaan, en welke alternatieve routes er zijn.

                </MKTypography>

              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 3" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 3: Van College naar de Profs
                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              College golf is niet alleen een sport, maar ook een carrière. Veel college golfers hebben de ambitie om door te stromen naar het professionele circuit, en om te spelen op de hoogste niveaus van de golfsport. In dit hoofdstuk zullen we kijken hoe college golf kan dienen als een opstap naar professioneel golf, en welke alternatieve routes er zijn.
              </MKTypography>

              <MKBox id="3.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                3.1 Het Pad naar het Professionele Circuit                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                College golf is een uitstekende voorbereiding voor een professionele carrière in de golfsport. College golfers krijgen de kans om zich te ontwikkelen op sportief, academisch, en persoonlijk vlak. Ze leren om te gaan met druk, concurrentie, en verwachtingen. Ze spelen op uitdagende banen, tegen sterke tegenstanders, en onder verschillende omstandigheden. Ze krijgen ook toegang tot hoogwaardige faciliteiten, coaches, en begeleiding.
<br/>
<br/>
College golfers hebben ook een voordeel ten opzichte van andere spelers die niet naar de universiteit gaan. Ze hebben een diploma op zak, dat hen kan helpen bij het vinden van een baan of een sponsor als ze niet slagen als professional. Ze hebben ook een netwerk van contacten en vrienden, die hen kunnen ondersteunen of adviseren in hun carrière.
<br/>
<br/>
Veel college golfers maken de overstap naar het professionele circuit na het afronden van hun studie. Sommigen doen dat al eerder, als ze denken dat ze er klaar voor zijn. Om professioneel te worden, moeten ze zich kwalificeren voor een tour, zoals de PGA Tour, de LPGA Tour, of de European Tour. Dit kunnen ze doen door mee te doen aan kwalificatietoernooien, zoals de Q-School of de Korn Ferry Tour.
<br/>
<br/>
Veel voormalige college golfers hebben succes gehad op het professionele circuit. Enkele voorbeelden zijn:
                </MKTypography>
                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
Phil Mickelson: Mickelson speelde college golf voor Arizona State University, waar hij drie keer het NCAA kampioenschap won. Hij werd professioneel in 1992, en heeft sindsdien enorm veel PGA Tour overwinningen behaald, waaronder meerdere majors.

</li>
<li>
Annika Sörenstam: Sörenstam speelde college golf voor de University of Arizona, waar ze het NCAA kampioenschap won in 1991. Ze werd professioneel in 1992, en heeft sindsdien enorm veel professionele overwinningen behaald, waaronder meerdere majors.

</li>
<li>
Jon Rahm: Rahm speelde college golf voor Arizona State University, waar hij twee keer de Ben Hogan Award won als beste college golfer. Hij werd professioneel in 2016, en heeft sindsdien meerdere professionele overwinningen behaald, waaronder majors.
</li>

</ul>
</MKTypography>
              </MKBox>
              <MKBox id="3.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                3.2 Alternatieve Routes naar Professionalisme
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Hoewel college golf een veelgebruikte route is naar het professionele circuit, is het niet de enige. Er zijn ook andere wegen die spelers kunnen volgen om hun droom te bereiken. Sommige spelers kiezen ervoor om niet naar de universiteit te gaan, maar om direct professioneel te worden na hun middelbare school. Andere spelers gaan wel naar de universiteit, maar stoppen voortijdig met hun studie om zich volledig op hun sport te richten.
<br/>
<br/>
Enkele voorbeelden van spelers die een alternatieve route hebben genomen zijn:
                </MKTypography>
                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
Tiger Woods: Woods speelde college golf voor Stanford University, waar hij twee keer het NCAA kampioenschap won. Hij werd echter al professioneel in 1996, na twee jaar studie. Hij heeft sindsdien enorm veel PGA Tour overwinningen behaald, waaronder meerdere majors.
</li>
<li>
Lydia Ko: Ko speelde nooit college golf, maar werd al professioneel in 2013, op 16-jarige leeftijd. Ze heeft sindsdien meerdere LPGA Tour overwinningen behaald.

</li>
<li>
Jordan Spieth: Spieth speelde college golf voor de University of Texas, waar hij het NCAA kampioenschap won in 2012. Hij werd echter al professioneel in 2012, na één jaar studie. Hij heeft sindsdien meerdere PGA Tour overwinningen behaald, waaronder meerdere majors.
</li>

</ul>
</MKTypography>
<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
In dit hoofdstuk hebben we gezien hoe je van college naar de profs kunt gaan, en welke alternatieve routes er zijn. In het volgende hoofdstuk zullen we kijken naar de internationale invloeden en kansen in college golf.

                </MKTypography>
              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 4" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 4: Internationale Invloeden en Kansen
                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              College golf is niet alleen een Amerikaanse sport, maar ook een internationale sport. Er zijn veel buitenlandse spelers die naar de VS komen om te studeren en te golfen, en er zijn ook veel Amerikaanse spelers die in het buitenland spelen. In dit hoofdstuk zullen we kijken naar de impact en bijdragen van internationale student-atleten, en de kansen voor Amerikaanse college golfers om internationaal te spelen.
              </MKTypography>

              <MKBox id="4.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                4.1 Buitenlandse Spelers in College Golf
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
  <img
    src={Image3}
    alt="  Texas Longhorns, met Jordan Spieth, vieren het nationale kampioenschap"
    style={{
      maxWidth: "100%",
      border: "2px solid #fff",
      boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
      cursor: "pointer",
      outline: "none",
    }}
    onClick={() => openModal3(Image3)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
  Texas Longhorns, met Jordan Spieth, vieren het nationale kampioenschap
</figcaption>
</figure>
                College golf is een aantrekkelijke optie voor veel buitenlandse spelers, die op zoek zijn naar een combinatie van sport en onderwijs. Ze kunnen profiteren van de hoge kwaliteit van de Amerikaanse universiteiten, de uitstekende faciliteiten en begeleiding van de golfprogramma’s, en de sterke competitie en exposure van de college golf toernooien.
<br/>
<br/>
Buitenlandse spelers hebben ook veel te bieden aan de Amerikaanse universiteiten. Ze brengen diversiteit, cultuur, en talent mee naar hun teams. Ze kunnen ook hun ervaringen en perspectieven delen met hun teamgenoten en coaches, en zo bijdragen aan hun ontwikkeling.
<br/>
<br/>
Veel buitenlandse spelers hebben succes gehad in college golf, en hebben zich onderscheiden als student-atleten. Enkele voorbeelden zijn:
                </MKTypography>
                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
Maria Fassi: Fassi speelde college golf voor de University of Arkansas, waar ze twee keer de ANNIKA Award won als beste vrouwelijke college golfer. Ze werd professioneel in 2019, en heeft sindsdien meerdere professionele overwinningen behaald.
</li>
<li>
Viktor Hovland: Hovland speelde college golf voor Oklahoma State University, waar hij het NCAA kampioenschap won in 2018. Hij werd professioneel in 2019, en heeft sindsdien meerder professionele overwinningen behaald.

</li>

</ul>
</MKTypography>
              </MKBox>
              <MKBox id="4.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                4.2 Amerikaanse Spelers in het Buitenland
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                College golf is niet de enige manier om je te ontwikkelen als golfer. Er zijn ook veel kansen voor Amerikaanse college golfers om internationaal te spelen, en om andere culturen en banen te ervaren. Dit kan hun horizon verbreden, hun aanpassingsvermogen vergroten, en hun spel verbeteren.
<br/>
<br/>
Amerikaanse college golfers kunnen internationaal spelen door middel van verschillende programma’s, zoals:
                </MKTypography>
                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
De Palmer Cup: De Palmer Cup is een jaarlijks toernooi tussen teams van Amerikaanse en internationale college golfers. Het is vernoemd naar Arnold Palmer, een beroemde golfer die ook college golf speelde. Het toernooi wordt gehouden op verschillende locaties over de hele wereld, zoals Frankrijk, Ierland, Engeland, en Zweden.
</li>
<li>
De Eisenhower Trophy: De Eisenhower Trophy is een tweejaarlijks toernooi tussen teams van amateur golfers uit verschillende landen. Het is vernoemd naar Dwight D. Eisenhower, een voormalige president van de VS die ook een fervent golfer was. Het toernooi wordt gehouden op verschillende locaties over de hele wereld, zoals Australië, Japan, Mexico, en Turkije.

</li>
<li>
De Study Abroad Program: De Study Abroad Program is een academisch programma dat studenten de mogelijkheid biedt om een semester of een jaar te studeren aan een buitenlandse universiteit. Sommige universiteiten hebben ook golfprogramma’s die samenwerken met buitenlandse universiteiten, waardoor student-atleten ook kunnen golfen tijdens hun verblijf.

</li>

</ul>
</MKTypography>

<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
Veel Amerikaanse college golfers hebben geprofiteerd van het spelen in het buitenland, en hebben hun spel naar een hoger niveau getild. Enkele voorbeelden zijn:

                </MKTypography>
                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
Collin Morikawa: Morikawa speelde college golf voor de University of California, Berkeley, waar hij vier keer All-American werd. Hij speelde ook in de Palmer Cup in 2017 en 2018, en in de Eisenhower Trophy in 2018. Hij werd professioneel in 2019, en heeft sindsdien meerdere professionele overwinningen behaald.
</li>
<li>
Jennifer Kupcho: Kupcho speelde college golf voor Wake Forest University, waar ze het NCAA kampioenschap won in 2018. Ze speelde ook in de Palmer Cup in 2018, en in de Curtis Cup in 2016 en 2018. Ze werd professioneel in 2019, en heeft sindsdien meerdere professionele overwinningen behaald.

</li>
<li>
Patrick Reed: Reed speelde college golf voor de University of Georgia en Augusta State University, waar hij twee keer het NCAA kampioenschap won. Hij speelde ook in de Palmer Cup in 2011, en studeerde een semester aan de University of Stirling in Schotland. Hij werd professioneel in 2011, en heeft sindsdien meerdere professionele overwinningen behaald.

</li>

</ul>
</MKTypography>
<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
In dit hoofdstuk hebben we gezien hoe college golf een internationale sport is, en welke invloeden en kansen er zijn voor buitenlandse en Amerikaanse spelers. In het volgende hoofdstuk zullen we kijken naar het belang van golfbeurzen, en de rol van Sportbeursamerika.nl.

                </MKTypography>
              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 5" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 5: Golfbeurzen en de Toekomst
                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              Als je een passie hebt voor golf en je wilt jezelf ontwikkelen op zowel sportief als academisch gebied, dan is een golfbeurs in de Verenigde Staten een geweldige kans. In dit hoofdstuk leggen we uit wat een golfbeurs inhoudt, waarom het zo belangrijk is voor jonge golfers, en hoe Sportbeursamerika.nl je kan helpen om je droom waar te maken.
              </MKTypography>

              <MKBox id="5.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                5.1 Het Belang van Golfbeurzen
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Een golfbeurs is een financiële tegemoetkoming die je krijgt van een universiteit of college om daar te komen studeren en golfen. De hoogte van de beurs hangt af van je sportieve en academische niveau, en kan variëren van een gedeeltelijke tot een volledige dekking van je studiekosten, verblijf, boeken, materiaal, en reiskosten. Een golfbeurs kan dus een flinke besparing opleveren op je studie in de VS, die anders al snel tienduizenden euro’s per jaar kan kosten.
<br/>
<br/>
Maar een golfbeurs biedt meer dan alleen financiële voordelen. Het geeft je ook de kans om te studeren aan een gerenommeerde universiteit of college, waar je een internationaal erkend diploma kunt behalen. Je kunt kiezen uit een breed scala aan opleidingen, die aansluiten bij jouw interesses en ambities. Je krijgt ook toegang tot uitstekende faciliteiten, zoals moderne bibliotheken, laboratoria, en sportcentra.
<br/>
<br/>
Daarnaast is een golfbeurs een unieke kans om je golfvaardigheden te verbeteren onder professionele begeleiding. Je maakt deel uit van een universiteitsteam, dat regelmatig traint en wedstrijden speelt tegen andere teams in de regio of het land. Je krijgt coaching van ervaren trainers, die je helpen om je techniek, tactiek, mentale kracht, en fysieke conditie te optimaliseren. Je speelt ook op verschillende banen, die elk hun eigen uitdagingen bieden.
<br/>
<br/>
Bovendien is een golfbeurs een geweldige manier om je persoonlijk te ontwikkelen. Je leert om te gaan met een nieuwe cultuur, taal, en omgeving. Je maakt vrienden voor het leven met je teamgenoten, klasgenoten, en huisgenoten. Je doet waardevolle levenservaring op, die je zelfstandiger, zelfverzekerder, en flexibeler maakt. Je vergroot ook je netwerk, dat je kan helpen bij je toekomstige carrière.
<br/>
<br/>
Kortom, een golfbeurs is een investering in jezelf, die je veel kan opleveren op zowel sportief, academisch, als persoonlijk vlak.
                </MKTypography>
              </MKBox>
              <MKBox id="5.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                5.2 Hoe Sportbeurs Amerika NL Kan Helpen 
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
  <img
    src={Image4}
    alt="Logo van Sportbeurs Amerika NL, bestaande uit een voetbal met het continent Noord-Amerika erop afgebeeld, met daarboven een afstudeerhoed (graduation cap) die bovenop de bal rust alsof het een hoofd is. De naam van het bedrijf is prominent weergegeven, wat de focus op sportbeurzen in Amerika voor Europese studenten benadrukt."
    style={{
      maxWidth: "100%",
      height: "auto",
      border: "2px solid #fff",
      boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)", // Apply shadow around the entire image
      cursor: "pointer",
      outline: "none",
      borderRadius: "50%", // Set border-radius to 50% for a circular image
      marginTop: "0px", // Add top margin to move the image up


    }}
    onClick={() => openModal4(Image4)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
  
</figcaption>
</figure>
Sportbeurs Amerika NL is een organisatie gespecialiseerd in het begeleiden van jonge sporttalenten naar sportbeurzen in Amerika.  Met een gepassioneerde en ervaren expert aan het roer, ondersteunt Sportbeurs Amerika NL de student-atleet bij iedere stap in het traject, vanaf de eerste oriëntatie tot aan de uiteindelijke plaatsing. Sportbeurs Amerika NL biedt onder andere de volgende diensten aan:
                </MKTypography>

                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
Een persoonlijke intakegesprek om de wensen, mogelijkheden en verwachtingen van de student-atleet te bespreken.
</li>
<li>
Een professionele evaluatie van het sportieve en academische niveau van de student-atleet.
</li>
<li>
Een uitgebreid netwerk van contacten met Amerikaanse coaches, universiteiten en colleges.
</li>
<li>
Een effectieve promotie van het profiel en de video’s van de student-atleet bij potentiële coaches.
</li>
<li>
Een deskundig advies over de beste opties voor de student-atleet op basis van zijn of haar voorkeuren, doelen en budget.
</li>
<li>
Een onderhandeling over de beste beursaanbiedingen namens de student-atleet.
</li>
<li>
Een hulp bij het aanvragen van de benodigde documenten, zoals het visum, het transcript, het diploma en de toelatingstesten.
</li>
<li>
Een voorbereiding op het leven in Amerika, zoals de cultuur, de taal, de regels en de tips.
</li>
<li>
Een nazorg en een follow-up tijdens het verblijf in Amerika.</li>

</ul>
</MKTypography>

<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Wil jij ook je passie voor American football combineren met een goede opleiding in Amerika? <Link to="/Diensten-Producten">Bekijk het volledige aanbod</Link> van Sportbeurs Amerika NL en maak een vrijblijvende afspraak om je te laten informeren over de mogelijkheden. 
      Ik sta voor je klaar om je te helpen bij het realiseren van je Amerikaanse droom.
    </MKTypography>


              </MKBox>
            </MKBox>

          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;
import { useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 PRO React themes
import theme from "assets/theme";
import Presentation from "pages/Homepage";
import SAT from "pages/Aanbod/Diensten_producten/ProductPage/PP/Producten/SAT";
import ACT from "pages/Aanbod/Diensten_producten/ProductPage/PP/Producten/ACT";
import TOEFL from "pages/Aanbod/Diensten_producten/ProductPage/PP/Producten/TOEFL";
import Hoodie from "pages/Aanbod/Diensten_producten/ProductPage/PP/Producten/Hoodie";
import Shirt from "pages/Aanbod/Diensten_producten/ProductPage/PP/Producten/Shirt";
import AllSports from "pages/Alle Sporten/Overzicht";
import DienstenProducten from "pages/Aanbod/Diensten_producten";
import Privacy from "pages/Support/Privacy";
import NAIA from "pages/Competities/NAIA";
import NCAA from "pages/Competities/NCAA";
import NJCAA from "pages/Competities/NJCAA";
import Staten from "pages/Staten";
import WebsiteNotFound from "pages/404";
import { Helmet } from "react-helmet";





// Material Kit 2 PRO React routes
import routes from "routes";
import Author from "pages/Over/Overmij/Profiel";

export default function App() {
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Helmet>
                {/* Google Analytics */}
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-TH40XECGLT"></script>
                <script>
                    {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', 'G-TH40XECGLT');
                    `}
                </script>
            </Helmet>
      <Routes>
        {getRoutes(routes)}
        <Route path="/" element={<Presentation />} />
        <Route path="/Diensten-Producten/ACT-gids" element={<ACT />} />

        <Route path="/Diensten-Producten/TOEFL-gids" element={<TOEFL />} />
        <Route path="/Diensten-Producten/Hoodie" element={<Hoodie />} />
        <Route path="/Diensten-Producten/T-shirt" element={<Shirt />} />
        <Route path="/Diensten-Producten/SAT-gids" element={<SAT />} />

        <Route path="/Over-Mij" element={<Author />} />
        <Route path="/Alle-Sporten" element={<AllSports />} />
        <Route path="/Diensten-Producten" element={<DienstenProducten />} />
        <Route path="/Privacyverklaring" element={<Privacy />} />
        <Route path="/NCAA" element={<NCAA />} />
        <Route path="/NAIA" element={<NAIA />} />
        <Route path="/NJCAA" element={<NJCAA />} />
        <Route path="/Staten" element={<Staten />} />
        <Route path="*" element={<WebsiteNotFound />} /> {/* Update this line */}







      </Routes>
    </ThemeProvider>
  );
}


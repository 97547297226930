/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/


// @mui material components
import React, { useState } from 'react'; // Step 1: Import useState


import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Images
import post1 from "assets/images/Logos/NCAA.png";
import post3 from "assets/images/Logos/NAIA.png";



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {

 const articleStructure = [
    { id: 'intro', title: 'De NJCAA: De Springplank naar Grote Colleges en de NCAA', isSubheader: false },

    { id: 'Hoofdstuk 1', title: 'De Oorsprong en Groei van de NJCAA', isSubheader: true },
    { id: '1.1', title: 'De Drie Divisies van de NJCAA', isSubheader: true },
    { id: '1.2', title: 'De Overstap naar Grotere Colleges en de NCAA', isSubheader: true },

    { id: 'Hoofdstuk 2', title: 'De Rol van Academici', isSubheader: true },
    { id: '2.1', title: 'Financiën en Zichtbaarheid', isSubheader: true },
    { id: '2.2', title: 'Uitdagingen en de Toekomst', isSubheader: true },
    { id: '2.3', title: 'Tot Slot', isSubheader: true },



  
  ];

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };


  return (
     <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Inhoud
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Verberg</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Toon</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
            <MKBox id="intro" mb={3}>



                                <MKTypography variant="h1" className="h1-as-h3" mb={{ xs: 1, sm: 1, md: 0 }}>De NJCAA: De Springplank naar Grote Colleges en de NCAA</MKTypography>
              </MKBox>

              <MKBox id="Hoofdstuk 1" mb={3}>

              <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              De Oorsprong en Groei van de NJCAA
              </MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              De National Junior College Athletic Association (NJCAA) werd in 1938 opgericht en is sindsdien uitgegroeid tot een prominente speler in het Amerikaanse college sportlandschap. De organisatie biedt sportmogelijkheden aan junior colleges, kleinere tweejarige instellingen die studenten een opstap bieden naar hoger onderwijs en atletische uitmuntendheid.

<br />
                <br />
                In de beginjaren was de NJCAA beperkt in omvang, maar het is gegroeid en heeft zich aangepast om te voldoen aan de behoeften van een divers en veranderend studentenbestand. De nadruk ligt op het bieden van toegang tot atletische competitie terwijl ook de academische vooruitgang wordt bevorderd.

</MKTypography>

<MKBox id="1.1" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
                <br />
                De Drie Divisies van de NJCAA
                </MKTypography>
                </MKBox>

                <MKTypography variant="body1" fontWeight="light" color="text"> 
                De NJCAA is opgedeeld in drie divisies, elk ontworpen om aan verschillende behoeften en mogelijkheden van student-atleten te voldoen:

<br /><br />
  <MKTypography component="span" variant="body1" fontWeight="bold">
    Divisie I:{' '}
  </MKTypography>
  Deze divisie omvat de scholen die in staat zijn om volledige atletiekbeurzen aan te bieden, wat resulteert in een hoger niveau van competitie. Student-atleten hebben hier de kans om hun vaardigheden te ontwikkelen en te concurreren op een intensief niveau, vaak als een springplank naar NCAA-divisies.

<br /><br />
  <MKTypography component="span" variant="body1" fontWeight="bold">
    Divisie II:{' '}
  </MKTypography>
  Hier vinden we een evenwicht tussen atletische competitie en academische verantwoordelijkheden, met scholen die gedeeltelijke beurzen mogen aanbieden. Dit zorgt voor een gezonde balans en geeft student-atleten de kans om te excelleren in hun sport, terwijl ze ook voorbereid worden op academisch succes.   <br /><br />
  <MKTypography component="span" variant="body1" fontWeight="bold">
    Divisie III:{' '}
  </MKTypography>
  In deze divisie ligt de nadruk op het bieden van competitieve sportmogelijkheden zonder atletiekbeurzen. Student-atleten krijgen de kans om deel te nemen aan sporten terwijl ze ook volledig deelnemen aan het academische leven, wat resulteert in een goed afgeronde college ervaring.  </MKTypography>
  
  
  <MKBox id="1.2" mb={3}>

  <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              De Overstap naar Grotere Colleges en de NCAA
              </MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              De NJCAA speelt een sleutelrol als springplank voor student-atleten die hun academische en atletische carrière willen voortzetten aan grotere vierjarige instellingen, waaronder die in de NCAA. Veel student-atleten beginnen hun reis in de NJCAA om hun vaardigheden te ontwikkelen, academische kwalificaties te verbeteren en zichzelf in de kijker te spelen bij recruiters van grotere programma's.


<br />
                <br />
                Door zich te bewijzen in de competitieve omgeving van de NJCAA, kunnen student-atleten aandacht trekken van NCAA-scholen en mogelijk beurzen verdienen om hun opleiding en sportcarrière voort te zetten. Dit proces is niet alleen gunstig voor de atleten zelf, maar draagt ook bij aan de versterking van sportprogramma's over het hele land.


                </MKTypography>
                <MKBox id="Hoofdstuk 2" mb={3}>
  
  
  <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              De Rol van Academici
              </MKTypography>

              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              De NJCAA zet zich sterk in voor het academisch succes en de ontwikkeling van haar student-atleten. Er gelden rigoureuze academische standaarden, en de aangesloten scholen bieden essentiële begeleiding om te waarborgen dat atleten zowel in de collegezaal als in hun sport uitblinken. Dit illustreert de cruciale rol van een evenwichtige benadering binnen het college sportlandschap, hoewel het niveau iets lager kan liggen dan bij sommige andere onderwijsinstellingen.
<br />
                <br />
                </MKTypography>

                <MKBox id="2.1" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Financiën en Zichtbaarheid
              </MKTypography>

              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              Ondanks dat de financiële middelen beperkter zijn in vergelijking met de NCAA, speelt de NJCAA een cruciale rol in het ondersteunen van atletische programma's en het bevorderen van kansen voor student-atleten. De organisatie werkt ook aan het vergroten van haar zichtbaarheid en bereik, met het doel om meer studenten en atleten te bereiken en te inspireren.


<br />
                </MKTypography>

                <MKBox id="2.2" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Uitdagingen en de Toekomst
              </MKTypography>

              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              De NJCAA staat, net als andere atletische organisaties, voor uitdagingen, waaronder het navigeren door kwesties van gelijkheid in sport en het ondersteunen van de mentale gezondheid van student-atleten. Echter, met haar toewijding aan het bevorderen van mogelijkheden en het ondersteunen van student-atleten, is de organisatie goed gepositioneerd om een positieve impact te blijven maken.



                </MKTypography>

                <MKBox id="2.3" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Tot Slot
              </MKTypography>

              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              De National Junior College Athletic Association is een essentiële component van het Amerikaanse college sportlandschap, die unieke mogelijkheden biedt voor student-atleten om te concurreren, te leren en te groeien. Met haar toewijding aan academische en atletische uitmuntendheid blijft de NJCAA een invloedrijke kracht in het vormgeven van de toekomst van jonge atleten in Amerika.








<br />


                </MKTypography>

              <hr style={{ margin: '20px 0'}} />
              
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post1}
                    title="NCAA"
                    description="Het Hart van College Sport in Amerika"
                    action={{
                      type: "internal",
                      route: "/NCAA",
                      color: "info",
                      label: "lees meer",
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post3}
                    title="NAIA"
                    description="Een Alternatieve Route in College Sport in Amerika"
                    action={{
                      type: "internal",
                      route: "/NAIA",
                      color: "info",
                      label: "lees meer",
                     }}
                    />
                  </Grid>
                </Grid>
  
              </Grid>
          </Grid>
        </Container>
      </MKBox>
    );
  }
  
  export default Beschrijving;
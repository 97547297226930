import Alabama from "assets/images/Staten_Images/Alabama.webp"
import Florida from "assets/images/Staten_Images/Florida.jpeg"
import Connecticut from "assets/images/Staten_Images/Connecticut.jpeg"
import Kentucky from "assets/images/Staten_Images/Kentucky.webp"
import Michigan from "assets/images/Staten_Images/Michigan.jpeg"
import North_Carolina from "assets/images/Staten_Images/North_Carolina.jpg"
import Texas from "assets/images/Staten_Images/Texas.jpeg"



const states = [
  {
    name: "Alabama",
    image: Alabama,
    imageDescription: "Alabama vs Auburn is een van de grootste college football rivaliteiten",
    description: [
"College sports vormen een onmisbaar element van de cultuur in Alabama, met een bijzondere nadruk op American football. De University of Alabama (UA) in Tuscaloosa en Auburn University in Auburn domineren het sportlandschap in de staat.",
"UA's footballteam, de Crimson Tide, heeft een rijke historie en talloze nationale kampioenschappen gewonnen. De thuiswedstrijden in het immense Bryant-Denny Stadium trekken tot wel 100.000 fans. De passie van de fans is enorm, en de 'Roll Tide' kreet is overal in Alabama bekend.",
"Aan de andere kant hebben we Auburn University met hun footballteam, de Tigers. Zij vormen met UA de Iron Bowl, een van de meest intense rivaliteiten in college sports. Deze jaarlijkse clash zet de hele staat op zijn kop en staat garant voor spanning en spektakel.",
"Naast football kent Alabama sterke programma's in basketbal, baseball en andere sporten, ondersteund door de krachtige gemeenschapsgeest. Sport is in Alabama meer dan alleen een tijdverdrijf; het is een essentieel onderdeel van de levensstijl en identiteit van de staat.",
],
  },

  {
    name: "Alaska",
    description: [
    "In tegenstelling tot veel andere Amerikaanse staten speelt college sport een bescheidener rol in Alaska, voornamelijk vanwege de afgelegen ligging en het bijzondere klimaat. Hier ligt de nadruk meer op wintersporten en outdoor activiteiten dan op de traditionele Amerikaanse sporten zoals football en baseball.",
    "De University of Alaska Anchorage (UAA) en de University of Alaska Fairbanks (UAF) zijn de voornaamste instellingen met actieve sportprogramma's. UAA heeft een sterk ski- en schaatsteam, en UAF staat bekend om zijn ijshockeyteam, de Nanooks, die een trouwe lokale aanhang hebben.",
    "In Alaska draait college sport meer om de beleving en de gemeenschap dan om grootschalige evenementen. Wedstrijden bieden een kans voor de gemeenschap om samen te komen, de atleten aan te moedigen en de unieke sportcultuur van de staat te vieren.",
    "Ondanks de kleinere schaal heeft college sport in Alaska zijn eigen charme, en biedt het een warme gemeenschap in de koude omgeving van de staat."
    ],
    },

    {
      name: "Arizona",
      description: [
      "College sports zijn immens populair in Arizona, met een rijke traditie in zowel American football als basketbal. De Arizona State University (ASU) in Tempe en de University of Arizona (UA) in Tucson zijn de spil in het sportieve landschap van de staat.",
      "ASU's footballteam, de Sun Devils, geniet een fervente aanhang, terwijl hun basketbalteam regelmatig schittert in de NCAA-toernooien. UA, met hun Wildcats, staat bekend om hun succesvolle basketbalprogramma en intense rivaliteit met ASU, culminerend in de jaarlijkse 'Duel in the Desert' footballwedstrijd.",
      "Naast de grotere sporten, excelleert Arizona ook in baseball, softball, en golf, waarbij de universiteitsteams vaak in de nationale schijnwerpers staan. De steun van de gemeenschap is overweldigend, en sportevenementen worden gezien als belangrijke sociale gelegenheden.",
      "College sports in Arizona belichamen de geest van competitie en gemeenschap, waarbij de intense rivaliteit en passie van de fans bijdragen aan een bruisende en levendige sportcultuur."
      ],
      },


{
  name: "Arkansas",
  description: [
  "In Arkansas vervullen college sports een centrale rol in het gemeenschapsleven, met een bijzondere nadruk op American football en basketbal. De University of Arkansas in Fayetteville is een ware krachtpatser in de staat, bekend om zijn Razorbacks.",
  "Het footballteam van de Razorbacks geniet regionale en nationale bekendheid, met een toegewijde fanbase die elke wedstrijd vol passie volgt. Hun thuiswedstrijden in het indrukwekkende Razorback Stadium zijn een waar spektakel, waar de 'Woo Pig Sooie' strijdkreet door tienduizenden fans wordt gescandeerd.",
  "Basketbal is eveneens een belangrijk onderdeel van de sportcultuur in Arkansas, met de Razorbacks die regelmatig deelnemen aan het NCAA-toernooi. De spanning en energie in Bud Walton Arena tijdens de basketbalseizoen zijn tastbaar.",
  "Buiten de grote sporten hebben de Razorbacks ook sterke programma's in track and field, baseball, en softball. Deze diversiteit in sporten verrijkt de sportcultuur in Arkansas, waar college sports een bron van trots, gemeenschapszin en vermaak zijn."
  ],
  },

  {
    name: "California",
    description: [
    "College sports in California zijn divers en intens competitief, met talloze universiteiten die excelleren in een verscheidenheid aan sporten. Van American football tot zwemmen, de staat heeft het allemaal.",
    "De Pac-12 conferentie, met prominente scholen zoals de University of California, Los Angeles (UCLA) en de University of Southern California (USC), staat bekend om zijn hoge sportniveau. UCLA’s basketbalteam heeft een rijke geschiedenis, terwijl USC traditioneel sterk is in football.",
    "Naast de bekende teams zijn er ook kleinere scholen met indrukwekkende sportprogramma’s, zoals Stanford University, bekend om hun uitmuntendheid in zwemmen en atletiek. Deze diversiteit zorgt voor een dynamisch en spannend sportklimaat in de staat.",
    "California’s aangename klimaat en uitgebreide sportfaciliteiten stimuleren actieve deelname en enthousiasme voor college sports. Of het nu gaat om een footballwedstrijd in een vol stadion of een lokale zwemwedstrijd, sport vormt een integraal onderdeel van de Californische levensstijl."
    ],
    },

    {
      name: "Colorado",
      description: [
      "College sports in Colorado genieten een levendige en enthousiaste aanhang, mede dankzij de indrukwekkende natuurlijke omgeving en het actieve buitenleven. De staat heeft een rijke traditie in American football, basketbal, en wintersporten.",
      "De University of Colorado Boulder (CU) en Colorado State University (CSU) zijn prominente spelers in de college sports scene, met de jaarlijkse 'Rocky Mountain Showdown' footballwedstrijd als hoogtepunt van hun rivaliteit.",
      "CU's footballteam, de Buffaloes, heeft een gepassioneerde fanbase, terwijl hun ski-team uitblinkt in nationale competities, profiterend van Colorado's uitstekende wintersportfaciliteiten. CSU is eveneens competitief in diverse sporten, met een sterke aanwezigheid in American football en basketbal.",
      "Naast de grotere universiteiten hebben kleinere colleges in Colorado ook actieve sportprogramma’s, die bijdragen aan de rijke en diverse sportcultuur in de staat. College sports in Colorado staan voor gemeenschap, doorzettingsvermogen en de viering van sport in alle vormen."
      ],
      },

      {
        name: "Connecticut",
        image: Connecticut,
        imageDescription: "UConn's vrouwen basketbal team is een van de dominanste college sportploegen allertijden",


        description: [
        "College sports in Connecticut worden gedomineerd door basketbal, met een bijzondere nadruk op de succesvolle programma's van de University of Connecticut (UConn). De staat heeft een rijke traditie en een gepassioneerde fanbase die college sports nauw aan het hart liggen.",
        "UConn's vrouwenbasketbalteam is nationaal bekend en heeft meerdere NCAA-kampioenschappen gewonnen, waardoor ze een krachtpatser in de sport zijn. Het mannenteam heeft ook indrukwekkende prestaties neergezet, wat basketbal tot een centraal onderdeel van de sportcultuur in Connecticut maakt.",
        "Hoewel basketbal domineert, zijn er ook sterke programma's in andere sporten zoals ijshockey, voetbal en lacrosse, met verschillende universiteiten die competitief zijn op nationaal niveau.",
        "College sports in Connecticut belichamen de trots en de gemeenschapszin van de staat, waarbij sportevenementen een kans bieden voor fans om samen te komen, de teams aan te moedigen en deel te nemen aan een rijke sporttraditie."
        ],
        },

        {
          name: "Delaware",
          description: [
          "Hoewel Delaware een van de kleinste staten in de VS is, heeft het een levendige college sports scene, met een sterke betrokkenheid van de lokale gemeenschap. De University of Delaware (UD) in Newark is het middelpunt van de actie, waar de Blue Hens uitblinken in diverse sporten.",
          "UD's footballteam heeft een rijke historie en een toegewijde fanbase, met spannende thuiswedstrijden in het Delaware Stadium. Basketbal en lacrosse zijn ook populaire sporten, met teams die regelmatig strijden om regionale en nationale erkenning.",
          "Ondanks zijn kleine omvang, heeft Delaware sterke programma's in atletiek, zwemmen en andere sporten, die allemaal bijdragen aan de dynamische sportcultuur in de staat. College sports zijn hier meer dan alleen een tijdverdrijf; ze vormen een belangrijk onderdeel van de gemeenschapsidentiteit en zorgen voor een sterke band tussen de universiteit en de bredere gemeenschap.",
          "In Delaware brengen college sports mensen samen, waarbij de kleinschaligheid juist zorgt voor een hechte en gepassioneerde sportgemeenschap."
          ],
          },


{
  name: "Florida",
  image: Florida,
  imageDescription: "Ben Hill Griffin Stadium is het thuis can de Flroida Gators",

  description: [
  "Florida is een hotspot voor college sports, met een aantal van de meest competitieve en gepassioneerde programma's in het land. American football en basketbal domineren de sportscene, maar er is ook ruimte voor een verscheidenheid aan andere sporten.",
  "Teams zoals de Florida Gators en de Florida State Seminoles hebben nationale bekendheid en een rijke historie in zowel football als basketbal. Hun intense rivaliteit trekt jaarlijks duizenden fans, en de wedstrijden zijn hoogtepunten van het sportseizoen.",
  "Daarnaast zijn er ook kleinere universiteiten en colleges met sterke sportprogramma's, die bijdragen aan de rijke sportcultuur in de staat. Baseball, atletiek en zwemmen zijn ook populair, en de uitstekende faciliteiten en het zonnige klimaat maken Florida een ideale plek voor college atleten.",
  "In Florida is college sport meer dan alleen competitie; het is een manier van leven, een bron van trots en een belangrijk onderdeel van de gemeenschapsidentiteit."
  ],
  },

  {
    name: "Georgia",
    description: [
    "College sports in Georgia worden gekenmerkt door intense passie en diepgewortelde rivaliteiten, met American football als de onbetwiste koning. De staat huisvest enkele van de meest prestigieuze programma's in het land, met fans die elke wedstrijd vol vuur beleven.",
    "De University of Georgia Bulldogs in Athens en de Georgia Tech Yellow Jackets in Atlanta zijn de grootste spelers, met hun jaarlijkse 'Clean, Old-Fashioned Hate' rivaliteit die de staat in zijn greep houdt. Football wedstrijden, vooral in het enorme Sanford Stadium van UGA, trekken tienduizenden fans.",
    "Naast football, excelleert Georgia ook in basketbal, atletiek en baseball, met talentvolle atleten en toegewijde supporters. De sportprogramma's op kleinere scholen en colleges zorgen voor een rijk en divers sportlandschap in de hele staat.",
    "Sport is in Georgia meer dan competitie; het is een levensstijl, een bron van gemeenschapszin en een onmisbaar onderdeel van de regionale identiteit."
    ],
    },


{
  name: "Hawaii",
  description: [
  "College sports in Hawaii bieden een unieke ervaring, geïnspireerd door de eilandenpracht en de sterke lokale cultuur. Hoewel geografisch geïsoleerd, zijn sportteams en fans in Hawaii buitengewoon gepassioneerd en betrokken.",
  "De University of Hawaii at Manoa speelt een centrale rol in de college sports scene, met het footballteam, de Rainbow Warriors, als een belangrijk middelpunt. Hun thuiswedstrijden in het Aloha Stadium creëren een levendige en feestelijke atmosfeer, met een sterke eilandengeest.",
  "Naast American football genieten ook volleybal, surfen en kano-roeien populariteit, weerspiegelend de unieke sportcultuur en het natuurlijke landschap van Hawaii. De Rainbow Warriors zijn ook competitief in basketbal en baseball, met een toewijding die door de gehele gemeenschap wordt gedeeld.",
  "In Hawaii vertegenwoordigen college sports de Aloha-spirit, met teams en fans die de eilandencultuur, gemeenschap en trots omarmen, en samen een unieke en bruisende sportervaring creëren."
  ],
  },

  {
    name: "Idaho",
    description: [
    "College sports in Idaho kennen een sterke traditie, waarbij vooral American football en basketbal in de schijnwerpers staan. De gemeenschapszin en de betrokkenheid bij sportteams zijn opvallend, ondanks dat de staat geen enorme universiteiten herbergt.",
    "Boise State University, beroemd om zijn blauwe voetbalveld, speelt een prominente rol in het sportlandschap van Idaho. Het footballteam heeft nationale aandacht getrokken met zijn succes en innovatieve speelstijl, en de thuiswedstrijden zijn een spektakel op zich.",
    "Naast Boise State dragen ook kleinere scholen en colleges bij aan de rijke sportcultuur, met programma's in sporten zoals baseball, atletiek en wintersporten, die profiteren van de unieke natuurlijke omgeving van Idaho.",
    "In Idaho zijn college sports meer dan een tijdverdrijf; ze zijn een belangrijk onderdeel van de levensstijl en identiteit van de staat, met gemeenschappen die samen komen om hun teams te ondersteunen."
    ],
    },

    {
      name: "Illinois",
      description: [
      "Illinois heeft een rijke geschiedenis en een diepe liefde voor college sports, met krachtige programma's en toegewijde fans. American football en basketbal trekken het hele jaar door grote aandacht, maar er is ook een breed scala aan andere sporten die floreren.",
      "De University of Illinois Fighting Illini en de Northwestern University Wildcats leiden de weg, met intense rivaliteiten en competitieve leagues die de staat levendig houden. Memorial Stadium en Ryan Field zijn tempels van sport, waar elke wedstrijd een onvergetelijke ervaring is.",
      "Basketbal is eveneens groot, met de staat die regelmatig talent produceert dat zowel op nationaal als internationaal niveau presteert. De Illinois Fighting Illini hebben een rijke basketbaltraditie en zijn een constante bedreiging in de NCAA-tournooien.",
      "Van kleine colleges tot grote universiteiten, sport is in Illinois een bindmiddel, een bron van trots en een essentieel onderdeel van de cultuur en identiteit van de staat."
      ],
      },

      {
        name: "Indiana",
        description: [
        "Indiana staat bekend om zijn diepgewortelde liefde voor basketbal, en dat is duidelijk zichtbaar in de college sports cultuur van de staat. De rivaliteit, passie en toewijding van zowel de spelers als de fans zijn ongeëvenaard.",
        "Programma's zoals de Indiana Hoosiers en de Purdue Boilermakers zijn nationaal erkend, vooral op het gebied van basketbal. Het hele jaar door hangt er een elektrische sfeer in zalen als Assembly Hall en Mackey Arena, waar geschiedenis en traditie samenkomen.",
        "Hoewel basketbal het voortouw neemt, bloeien ook andere sporten in Indiana. American football, baseball, en atletiek hebben elk sterke programma's en genieten brede steun binnen de gemeenschap.",
        "Sport in Indiana is meer dan alleen een spel; het is een manier van leven, een katalysator voor gemeenschapszin en een trotse traditie die generaties lang wordt doorgegeven. Hier, in het hart van het Midwesten, is college sports een onlosmakelijk onderdeel van de identiteit van de staat."
        ],
        },

        {
          name: "Iowa",
          description: [
          "College sports in Iowa zijn een bron van trots en gemeenschapszin, met een rijke traditie in zowel American football als worstelen. De rivaliteit tussen de Iowa Hawkeyes en de Iowa State Cyclones staat centraal en zorgt elk seizoen voor onvergetelijke momenten.",
          "Het Kinnick Stadium in Iowa City en het Jack Trice Stadium in Ames zijn elke herfst het toneel van intense footballwedstrijden, die tienduizenden fans aantrekken. De passie en toewijding van de fans zijn kenmerkend voor de sportcultuur in de staat.",
          "Worstelen is een andere sport waarin Iowa uitblinkt, met de Hawkeyes die regelmatig nationale titels in de wacht slepen. Deze sport heeft een diepgewortelde traditie in Iowa en geniet een trouwe aanhang.",
          "Van kleine colleges tot grote universiteiten, sport in Iowa is meer dan competitie; het is een manier van leven, een bron van gemeenschapszin en een belangrijk onderdeel van de identiteit van de staat."
          ],
          },

          {
            name: "Kansas",
            description: [
            "College sports in Kansas zijn doordrenkt van traditie en gemeenschapszin, met basketbal als onbetwist hoogtepunt. De staat is thuis voor enkele van de meest iconische programma's in het land, en de rivaliteit en passie zijn intens.",
            "De Kansas Jayhawks en de Kansas State Wildcats leiden de lading, met hun jaarlijkse 'Sunflower Showdown' als een van de hoogtepunten van het sportjaar. Allen Fieldhouse, thuisbasis van de Jayhawks, staat bekend als een van de meest intimiderende arena's in college basketbal.",
            "Hoewel basketbal het hart van de sportcultuur in Kansas vormt, spelen ook American football, baseball en atletiek een belangrijke rol. De fans zijn ongekend toegewijd en dragen bij aan de levendige sfeer tijdens wedstrijden.",
            "Sport in Kansas is meer dan alleen competitie; het is een bron van trots, een katalysator voor gemeenschap en een essentieel onderdeel van de identiteit van de staat."
            ],
            },

            {
              name: "Kentucky",
              image: Kentucky,
              imageDescription: "Karl-Anthony Towns en Devin Booker speelden samen voor Kentucky en zijn later grote NBA sterren geworden",

              description: [
              "College sports in Kentucky zijn synoniem aan basketbal, met een rijke traditie en een gepassioneerde fanbasis. De rivaliteit tussen de Kentucky Wildcats en de Louisville Cardinals is een van de meest intensieve en bekeken in het land.",
              "Rupp Arena, de thuisbasis van de Wildcats, is een ware basketbaltempel, waar de geschiedenis en trots van het programma voelbaar zijn. De Wildcats hebben meerdere nationale kampioenschappen op hun naam staan en zijn een vaste waarde in het college basketbal.",
              "Naast basketbal heeft Kentucky ook sterke programma's in American football, paardensport en schieten, die allemaal de sportcultuur van de staat verrijken. De Kentucky Derby, een legendarische paardenrace, onderstreept de rijke traditie in de paardensport.",
              "In Kentucky is sport meer dan een tijdverdrijf; het is een manier van leven, een bron van gemeenschap en trots, en een cruciaal onderdeel van de identiteit van de staat."
              ],
              },


{
  name: "Louisiana",
  description: [
  "In Louisiana vormen college sports een integraal onderdeel van de cultuur, met een diepgewortelde passie voor American football en baseball. De Louisiana State University (LSU) Tigers zijn een powerhouse, trekken enorme menigten aan en zorgen voor een ongeëvenaarde sfeer.",
  "Het Tiger Stadium, ook bekend als 'Death Valley', is berucht om zijn luidruchtige fans en intimiderende atmosfeer, waardoor het een van de meest gevreesde thuisvelden in college football is. De 'Saturday Night in Death Valley' is meer dan een spel; het is een evenement, een traditie, een deel van de ziel van Louisiana.",
  "Naast LSU dragen ook kleinere universiteiten en colleges bij aan het rijke sportlandschap, met sterke programma’s in diverse sporten. Baseball geniet ook grote populariteit, met LSU dat regelmatig meedoet om de nationale titel.",
  "Sport in Louisiana is een viering van gemeenschap, een bron van trots en een bindmiddel dat mensen samenbrengt, ongeacht hun achtergrond. Het is een levendig en essentieel onderdeel van de levensstijl en identiteit van de staat."
  ],
  },

  {
    name: "Maine",
    description: [
    "College sports in Maine weerspiegelen de unieke aard en gemeenschapszin van de staat, met een sterke focus op wintersporten en atletiek. De University of Maine Black Bears leiden de weg, trots vertegenwoordigend in diverse NCAA divisies.",
    "Het Alfond Stadium in Orono is het kloppend hart van American football in de staat, terwijl de Harold Alfond Sports Arena een centrale rol speelt in het ijshockeyseizoen. De Black Bears hebben een trotse ijshockeytraditie, met enthousiaste fans die de kou trotseren om hun team te ondersteunen.",
    "Naast ijshockey en football, zijn er ook sterke programma’s in atletiek, roeien, en andere sporten die het atletische landschap van Maine verrijken. De staat geniet ook van levendige high school en club sportcompetities, waardoor sport een integraal onderdeel van de gemeenschap is.",
    "Sport in Maine is meer dan alleen competitie; het is een bron van trots, een manier om de unieke geest van de staat te vieren, en een bindmiddel dat mensen samenbrengt, ongeacht het seizoen."
    ],
    },

    {
      name: "Maryland",
      description: [
      "College sports in Maryland bloeien met een sterke traditie in basketbal, lacrosse en American football. De University of Maryland Terrapins, of simpelweg de Terps, staan centraal in de sportcultuur van de staat.",
      "Het Xfinity Center en het Maryland Stadium zijn de trotse arena’s voor basketbal en football, waar de Terps hun thuiswedstrijden spelen. De fans zijn fervent en dragen bij aan een elektriserende sfeer tijdens wedstrijden. Maryland staat bekend om zijn competitieve basketbalprogramma, met meerdere NCAA toernooideelnames.",
      "Lacrosse is een andere sport waar Maryland in uitblinkt, met de Terps die regelmatig strijden voor nationale titels. Deze sport heeft een speciale plek in het hart van de inwoners, en de intense college rivaliteiten maken elk seizoen tot een spektakel.",
      "Sport in Maryland is een uiting van gemeenschap, trots en traditie, en het speelt een cruciale rol in het samenbrengen van mensen en het vieren van de unieke cultuur van de staat."
      ],
      },


      {
        name: "Massachusetts",
        description: [
        "In Massachusetts spelen college sports een belangrijke rol in het dagelijkse leven, met een rijke geschiedenis in basketbal, American football, en hockey. De universiteiten hier hebben sterke sportprogramma’s en gepassioneerde fans.",
        "Basketbal heeft een bijzondere plek in de harten van de inwoners van Massachusetts; het spel werd hier immers uitgevonden. De University of Massachusetts Minutemen, bekend als de UMass Minutemen, zijn trots vertegenwoordigers in NCAA Division I basketbal.",
        "American football en hockey zijn ook populair, met intense lokale rivaliteiten die voor spannende competitie zorgen. De Harvard-Yale football wedstrijd, bekend als 'The Game', is een jaarlijks hoogtepunt.",
        "Sport in Massachusetts gaat over traditie, gemeenschap en competitie. Het brengt mensen samen, ongeacht hun achtergrond, en vormt een essentieel onderdeel van de culturele identiteit van de staat."
        ],
        },

        {
          name: "Michigan",
          image: Michigan,
          imageDescription: "Michigan Stadium: het derde grootste stadion ter wereld en tevens de thuisbasis van de Michigan Wolverines. Er passen 107.601 fans in",

          description: [
          "Michigan heeft een rijke traditie in college sports, met name in American football en basketbal. De rivaliteit tussen de University of Michigan Wolverines en de Michigan State Spartans is legendarisch en zorgt voor een elektrische sfeer in de hele staat.",
          "Het Michigan Stadium, ook bekend als 'The Big House', is een van de grootste stadions ter wereld en biedt plaats aan meer dan 100.000 fans. De Wolverines hebben een glorieuze geschiedenis, met meerdere nationale titels in football.",
          "Basketbal is ook enorm populair, met zowel Michigan als Michigan State die regelmatig meedingen in het NCAA toernooi. De passie en toewijding van de fans zijn ongeëvenaard, en wedstrijddagen zijn ware evenementen in Michigan.",
          "Naast deze grote sporten kent Michigan ook sterke programma’s in ijshockey, atletiek, en andere sporten. Sport is in Michigan meer dan alleen een tijdverdrijf; het is een belangrijk onderdeel van de levensstijl en de identiteit van de staat."
          ],
          },


{
  name: "Minnesota",
  description: [
  "College sports in Minnesota hebben een uniek karakter, met een sterke nadruk op ijshockey, American football en basketbal. De University of Minnesota Golden Gophers spelen een centrale rol in de sportgemeenschap van de staat.",
  "Het TCF Bank Stadium en de Williams Arena zijn bruisende centra van activiteit tijdens football- en basketbalseizoenen, terwijl Mariucci Arena een bolwerk is voor ijshockey. De Golden Gophers zijn trots vertegenwoordigers in de NCAA, met een rijke geschiedenis en toewijde fans.",
  "IJshockey is bijzonder populair in Minnesota, vaak aangeduid als 'The State of Hockey'. College teams genieten van sterke steun en leveren spannende actie, met name tijdens de befaamde 'Frozen Four' NCAA toernooi.",
  "Sport in Minnesota is diep verweven met de cultuur en identiteit van de staat, en biedt een bron van trots, gemeenschapszin en levendige rivaliteiten die mensen van alle leeftijden samenbrengen."
  ],
  },

  {
    name: "Mississippi",
    description: [
    "College sports zijn een integraal onderdeel van de cultuur in Mississippi, met een intense passie voor American football, basketbal, en baseball. De rivaliteit tussen de University of Mississippi (Ole Miss) Rebels en de Mississippi State Bulldogs is een van de meest intense in het land.",
    "Het Vaught-Hemingway Stadium in Oxford en het Davis Wade Stadium in Starkville zijn ware tempels voor football fans, waar de spanning en passie van het spel tastbaar zijn. De jaarlijkse Egg Bowl, de clash tussen Ole Miss en Mississippi State, is een hoogtepunt in de sportkalender.",
    "Basketbal en baseball zijn ook belangrijk, met beide universiteiten die sterke teams en tradities hebben opgebouwd. De fans zijn trouw en luidruchtig, en de sfeer bij wedstrijden is elektrisch.",
    "Sport in Mississippi gaat over gemeenschap, trots en traditie. Het verenigt mensen, creëert onvergetelijke momenten en vormt een essentieel onderdeel van de identiteit van de staat."
    ],
    },

    {
      name: "Missouri",
      description: [
      "College sports in Missouri zijn synoniem met passie, gemeenschap en competitie. De staat heeft een rijke sportgeschiedenis, met American football, basketbal en baseball in de hoofdrol.",
      "De University of Missouri Tigers, ook wel Mizzou genoemd, en de Missouri State Bears zijn twee prominente vertegenwoordigers in het college sportlandschap. Mizzou's Faurot Field en de Mizzou Arena zijn epicentra van opwinding en teamgeest tijdens wedstrijddagen.",
      "De Tigers hebben een sterke aanwezigheid in de SEC, een van de meest competitieve conferences in het college football. Hun basketbalteam is ook een vaste waarde in het NCAA toernooi, waarbij de fans onvoorwaardelijke steun bieden.",
      "Sport in Missouri versterkt de gemeenschapszin en brengt mensen samen. Het gaat niet alleen om het spel, maar ook om de ervaring, de tradities en de onbreekbare band tussen de teams en hun supporters."
      ],
      },

      {
        name: "Montana",
        description: [
        "In Montana spelen college sports een cruciale rol in het gemeenschapsleven, met American football, basketbal, en rodeo als populaire sporten. De Montana Grizzlies en de Montana State Bobcats domineren het sportlandschap, en hun rivaliteit is intens.",
        "De Grizzlies' Washington-Grizzly Stadium en de Bobcats' Bobcat Stadium zijn de trotse arena's voor football in Montana. Hier komen fans samen om hun teams te steunen, ongeacht het weer. De jaarlijkse Brawl of the Wild-wedstrijd tussen de Grizzlies en de Bobcats is een niet te missen evenement.",
        "Basketbal is eveneens populair, met levendige wedstrijden en een sterke fanbasis. Rodeo, uniek voor Montana, trekt ook veel aandacht en waardering, en weerspiegelt de cultuur en tradities van de staat.",
        "College sports in Montana gaan over gemeenschap, trots, en de liefde voor het spel. Ze brengen mensen samen, creëren levenslange herinneringen en dragen bij aan de unieke identiteit van Big Sky Country."
        ],
        },

        {
          name: "Nebraska",
          description: [
          "In Nebraska, worden college sports gekenmerkt door een diepgewortelde passie en loyaliteit, met een bijzondere focus op American football. De Nebraska Cornhuskers, gevestigd aan de University of Nebraska-Lincoln, zijn het hart van de sportcultuur in de staat.",
          "Het Memorial Stadium, thuisbasis van de Cornhuskers, wordt ook wel 'The Sea of Red' genoemd, waar fans in hun kenmerkende rode kleding samenkomen om hun team te steunen. Het stadion staat bekend om zijn oorverdovende lawaai en unieke atmosfeer op wedstrijddagen.",
          "Naast football speelt basketbal ook een significante rol, met zowel mannen- als vrouwenteams die strijden op hoog niveau. De fans zijn net zo toegewijd en zorgen voor een levendige sfeer bij elke wedstrijd.",
          "College sports in Nebraska gaan verder dan alleen de sport; het is een manier om gemeenschappen samen te brengen, tradities te vieren en een gevoel van trots en saamhorigheid te creëren in ‘The Cornhusker State’."
          ],
          },


{
  name: "Nevada",
  description: [
  "College sports in Nevada bieden een unieke mix van entertainment en competitie, met bijzondere aandacht voor basketbal en American football. De University of Nevada, Las Vegas (UNLV) en de University of Nevada, Reno zijn de twee belangrijkste instellingen op sportgebied.",
  "Het basketbalteam van UNLV, de Runnin' Rebels, heeft een rijke geschiedenis en is bekend in het hele land. Hun thuiswedstrijden zijn een ware attractie, compleet met energieke shows en een enthousiast publiek. Ook het footballteam van de Nevada Wolf Pack draagt bij aan de sportcultuur in de staat, met spannende wedstrijden en een trouwe fanbasis.",
  "In Nevada is er ook een levendige interesse in andere college sporten zoals baseball, softball en soccer. Deze sporten trekken lokale gemeenschappen aan en bieden volop kansen voor atleten om zich te ontwikkelen.",
  "Al met al zijn college sports in Nevada een essentieel onderdeel van de staat, waarbij ze niet alleen voor entertainment zorgen, maar ook gemeenschappen samenbrengen en de lokale cultuur verrijken."
  ],
  },

  {
    name: "New Hampshire",
    description: [
    "In de pittoreske staat New Hampshire spelen college sports een cruciale rol in het gemeenschapsleven. Hoewel de staat kleiner is dan vele anderen, is de toewijding aan sport enorm.",
    "De University of New Hampshire (UNH) domineert het sportlandschap, met name in hockey, waar de Wildcats consistent presteren op hoog niveau. Het Whittemore Center Arena, het thuisstadion van het hockeyteam, staat bekend om zijn levendige sfeer en gepassioneerde fans.",
    "Football en basketbal zijn ook populaire sporten binnen de UNH, en trekken tijdens het seizoen grote menigten. De gemeenschap is trots op hun teams en de studenten zijn nauw betrokken bij de sportevenementen.",
    "New Hampshire's benadering van college sports is gemeenschapsgericht, waarbij het gaat om het samenbrengen van mensen, het vieren van successen, en het creëren van onvergetelijke momenten in de schilderachtige omgeving van de staat."
    ],
    },

    {
      name: "New Jersey",
      description: [
      "College sports in New Jersey genieten een levendige en competitieve atmosfeer, met diverse universiteiten die strijden op hoog niveau. De staat herbergt enkele toonaangevende programma's die zowel regionaal als nationaal erkend worden.",
      "Een van de meest prominente universiteitsteams is de Rutgers Scarlet Knights, die deel uitmaken van de prestigieuze Big Ten Conference. Hun football- en basketbalwedstrijden trekken grote menigten en zorgen voor opwinding in de hele staat.",
      "New Jersey staat ook bekend om zijn sterke lacrosse- en soccerprogramma's, die jong talent aantrekken en ontwikkelen. Deze sporten spelen een belangrijke rol in de college sports cultuur van de staat, met spannende competities en toegewijde fans.",
      "De gemeenschap in New Jersey omarmt haar college sportteams, wat resulteert in een ondersteunende en gepassioneerde sfeer bij elk evenement. College sports in New Jersey zijn meer dan alleen spelletjes; ze zijn een essentieel onderdeel van de staat zijn culturele weefsel."
      ],
      },

      {
        name: "New Mexico",
        description: [
        "New Mexico, met zijn unieke culturele en geografische achtergrond, heeft een bijzondere relatie met college sports. De staat is thuisbasis voor enkele vooraanstaande universitaire sportprogramma's die op nationaal niveau concurreren.",
        "De University of New Mexico Lobos in Albuquerque zijn een centraal punt van trots. Hun basketbalteam trekt regelmatig grote menigten naar The Pit, een van de meest intimiderende arena's in het land.",
        "De New Mexico State Aggies uit Las Cruces zijn ook nationale concurrenten, vooral in basketbal en football, waar ze felle rivaliteiten hebben met de Lobos, culminerend in de Rio Grande Rivalry.",
        "Ondersteund door een rijke geschiedenis en een gepassioneerde fanbase, bieden college sports in New Mexico niet alleen entertainment, maar bevorderen ze ook de gemeenschapszin en staatsidentiteit. In deze woestijnstaat is sport een viering van talent, doorzettingsvermogen en gemeenschapsbanden."
        ],
        },

        {
          name: "New York",
          description: [
          "In de bruisende staat New York spelen college sports een dynamische en veelzijdige rol. De staat herbergt talloze universiteiten, elk met hun eigen unieke sporttradities.",
          "Syracuse University valt op met haar basketbalteam, de Orange, dat vaak meedoet op het hoogste nationale niveau. Het Carrier Dome stadium, bekend om zijn indrukwekkende capaciteit en luidruchtige sfeer, is een waar icoon in de college sports wereld.",
          "Daarnaast spelen kleinere universiteiten en colleges ook een belangrijke rol, met sterke programma's in sporten als lacrosse, soccer en baseball. Deze kleinere programma's voeden de competitieve geest in de staat en zorgen voor een rijk en divers sportlandschap.",
          "De betrokkenheid van de gemeenschap bij college sports in New York is immens, met fans die hun teams door dik en dun steunen. Van de drukke steden tot de rustigere landelijke gebieden, sport verenigt New Yorkers en brengt een gevoel van trots en samenhorigheid."
          ],
          },

          {
            name: "North Carolina",
            image: North_Carolina,
            imageDescription: "De iconische rivaliteit: DUKE vs. UNC in actie",

            description: [
            "North Carolina heeft een rijke traditie en een ongeëvenaarde passie als het op college sports aankomt, met name in basketbal. De rivaliteit tussen de University of North Carolina Tar Heels en de Duke University Blue Devils is wereldberoemd en staat garant voor spannende confrontaties.",
            "De Tar Heels en Blue Devils domineren niet alleen het basketbalseizoen; ze brengen ook een golf van opwinding en gemeenschapszin teweeg in de hele staat. Het is een sportcultuur die diep is ingebed in de identiteit van North Carolina.",
            "Naast basketbal speelt American football ook een prominente rol, met sterke teams en gepassioneerde fanbases. En in de lente bloeit de liefde voor baseball op, met zowel universitaire als college teams die op de velden strijden.",
            "College sports in North Carolina gaan verder dan de sportvelden en -hallen; ze zijn een levenswijze, een bron van trots en een manier voor gemeenschappen om samen te komen en hun staat te vieren."
            ],
            },

            {
              name: "North Dakota",
              description: [
              "College sports in North Dakota bieden een unieke ervaring, waarbij de gemeenschappen nauw betrokken zijn bij hun universiteitsteams. Het gevoel van trots en saamhorigheid is duidelijk zichtbaar tijdens sportevenementen, ondanks dat de staat minder prominente universiteiten heeft dan andere Amerikaanse regio's.",
              "Het American footballteam van North Dakota State University, de Bison, staat bekend om zijn successen in de NCAA Division I Football Championship Subdivision, wat de sportcultuur in de staat een flinke boost geeft. Fans verzamelen zich in het Fargodome om hun team te steunen, wat voor een intens sfeer zorgt.",
              "Ook ijshockey speelt een grote rol in North Dakota, met de University of North Dakota Fighting Hawks als een toonaangevend team. De passie voor deze wintersport is duidelijk merkbaar tijdens het seizoen, waarbij fans massaal naar de arena komen.",
              "College sports in North Dakota, met name American football en ijshockey, brengen gemeenschappen samen, creëren een sterke lokale trots en dragen bij aan de levendige sportcultuur van de staat."
              ],
              },

              {
                name: "Ohio",
                description: [
                "College sports zijn een integraal onderdeel van de cultuur in Ohio, met een bijzondere nadruk op American football en basketbal. De Ohio State University (OSU) en de University of Cincinnati zijn twee toonaangevende instituten op het gebied van sport.",
                "OSU's footballteam, de Buckeyes, heeft nationale bekendheid en is een ware kracht in de NCAA. Hun wedstrijden in het gigantische Ohio Stadium trekken meer dan 100.000 fans, waardoor de sfeer tijdens thuiswedstrijden ongeëvenaard is. 'O-H-I-O' is een kreet die in de hele staat bekend is.",
                "Basketbal is ook prominent aanwezig, met zowel mannen- als vrouwenteams die strijden op hoog niveau. De staat Ohio is de thuisbasis van meerdere universiteitsteams die regelmatig deelnemen aan het NCAA-toernooi, wat bijdraagt aan de basketbalgekte.",
                "College sports in Ohio bieden meer dan alleen competitie; ze zijn een bron van trots, traditie en gemeenschapsgevoel, en verenigen mensen uit alle lagen van de bevolking."
                ],
                },

                {
                  name: "Oklahoma",
                  description: [
                  "In Oklahoma nemen college sports een centrale plaats in het hart van de inwoners in, met een diepgewortelde passie voor American football. De University of Oklahoma (OU) en Oklahoma State University (OSU) zijn de pronkjuwelen van de sportwereld in de staat.",
                  "OU’s footballteam, de Sooners, heeft een rijke geschiedenis en talloze successen behaald, wat zorgt voor een bruisende sfeer rondom hun wedstrijden. Het Gaylord Family Oklahoma Memorial Stadium is een ware vesting voor het team, waar de fans hun 'Boomer Sooner' kreet luidkeels laten horen.",
                  "OSU doet niet onder voor OU, met sterke prestaties in zowel football als andere sporten, waarbij hun American footballteam, de Cowboys, een belangrijke rol speelt. De inzet van de fans tijdens de 'Bedlam Series', de jaarlijkse strijd tussen OU en OSU, is enorm.",
                  "College sports in Oklahoma symboliseren de strijd, het doorzettingsvermogen en de gemeenschapszin van de staat, waardoor het veel meer is dan alleen een spel."
                  ],
                  },


{
  name: "Oregon",
  description: [
  "College sports in Oregon vormen een essentieel onderdeel van de staat, waar de nadruk ligt op zowel American football als atletiek. De University of Oregon (UO) in Eugene staat centraal in deze sportieve passie en heeft een nationale reputatie opgebouwd.",
  "UO's footballteam, de Ducks, staat bekend om hun snelle spel en unieke uniformen, terwijl het atletiekprogramma een van de beste in het land is. Het jaarlijkse Prefontaine Classic atletiekevenement, gehouden in het beroemde Hayward Field, benadrukt Oregon's toewijding aan atletiek.",
  "Oregon State University (OSU) met hun footballteam, de Beavers, biedt krachtige tegenstand, vooral tijdens de 'Civil War' - de jaarlijkse clash met de Ducks. Dit is meer dan een spel; het is een staatstraditie die gemeenschappen samenbrengt.",
  "Naast deze sporten geniet basketbal ook populariteit en zorgen gepassioneerde fans ervoor dat college sports in Oregon een belevenis is die je niet wilt missen. Het is een viering van atletisch talent, gemeenschap en de unieke sportcultuur van de staat."
  ],
  },


{
  name: "Pennsylvania",
  description: [
  "Pennsylvania koestert een diepe waardering voor college sports, met een sterk accent op American football, basketbal, en atletiek. Teams zoals de Penn State Nittany Lions en de Pittsburgh Panthers domineren het sportlandschap en zorgen voor spannende rivaliteiten.",
  "Beaver Stadium, de thuisbasis van de Nittany Lions, staat bekend om zijn overweldigende sfeer en intense fanbeleving, met name tijdens de 'White Out' wedstrijden. Deze momenten vormen het hoogtepunt van de sportkalender en trekken fans uit de hele staat.",
  "Op het basketbalveld heeft Villanova Wildcats recentelijk nationaal succes behaald, met meerdere kampioenschappen die de staat in vervoering brachten. Hun prestaties hebben Pennsylvania op de kaart gezet als een basketbalmacht.",
  "Atletiek speelt ook een cruciale rol, met de prestigieuze Penn Relays die elk jaar topatleten naar Philadelphia trekken. Deze rijke sportcultuur versterkt de gemeenschapszin en draagt bij aan de trots van Pennsylvania."
  ],
  },

  {
    name: "Rhode Island",
    description: [
    "Ondanks zijn kleine omvang heeft Rhode Island een levendige college sports scene, waarbij basketbal en zeilen opvallend populair zijn. De staat heeft teams die met hart en ziel spelen, wat voor opwindende momenten zorgt.",
    "Teams zoals de Rhode Island Rams en de Providence Friars hebben een trouwe fanbasis en zorgen voor meeslepende wedstrijden. De Dunkin' Donuts Center in Providence wordt een bruisende arena tijdens basketbalwedstrijden, waarbij de lokale bevolking massaal uitloopt om hun teams te steunen.",
    "Naast basketbal, is Rhode Island bekend om zijn uitmuntende zeilprogramma's, met de prachtige kustlijn die perfecte omstandigheden biedt voor deze sport. Universiteiten zoals Brown en URI hebben sterke zeilteams die nationaal concurreren.",
    "Deze sporten dragen bij aan de levendige cultuur van Rhode Island, waarbij elke wedstrijd een kans is om de unieke geest van deze kleine maar trotse staat te vieren."
    ],
    },

    {
      name: "South Carolina",
      description: [
      "College sports in South Carolina ademen traditie en passie, met American football en basketbal als centrale punten van belangstelling. De intense rivaliteit tussen de University of South Carolina Gamecocks en de Clemson University Tigers domineert het sportlandschap.",
      "Tijdens de jaarlijkse football showdown, 'The Palmetto Bowl', staat de hele staat op zijn kop, waarbij de scheidslijn tussen garnet en orange overduidelijk is. De energie en passie van de fans zijn ongekend, waardoor elke wedstrijd een onvergetelijke ervaring wordt.",
      "Basketbal heeft ook een prominente plaats in het hart van de sportfans in South Carolina, met teams die strijden om de top in zowel mannen- als vrouwencompetities. De Gamecocks’ vrouwenbasketbalteam heeft bijvoorbeeld nationaal succes behaald, wat de trots en betrokkenheid van de gemeenschap verder versterkt.",
      "De sportcultuur in South Carolina gaat verder dan de speelvelden; het is een manier van leven, een bron van trots en een onmisbaar onderdeel van de identiteit van de staat."
      ],
      },

      {
        name: "South Dakota",
        description: [
        "College sports in South Dakota bieden een unieke en hartstochtelijke ervaring, met een nadruk op basketbal, American football, en atletiek. De South Dakota State University Jackrabbits en de University of South Dakota Coyotes zijn prominente spelers in het sportlandschap.",
        "Basketbalwedstrijden, vooral tijdens de rivaliserende ontmoetingen tussen de Jackrabbits en de Coyotes, trekken enthousiaste menigten en creëren een elektriserende atmosfeer. De staat juicht voor zowel mannen- als vrouwenteams, waarbij de prestaties op het veld een bron van trots zijn voor de lokale gemeenschappen.",
        "American football geniet ook van grote populariteit, met spannende wedstrijden en een sterke betrokkenheid van de fans. De atletiekprogramma's in South Dakota blinken uit, met atleten die nationaal concurreren en de staat met trots vertegenwoordigen.",
        "College sports in South Dakota zijn meer dan alleen wedstrijden; ze vormen een essentieel onderdeel van de gemeenschap, versterken de banden tussen inwoners, en dragen bij aan de levendige cultuur van de staat."
        ],
        },

     
{
  name: "Tennessee",
  description: [
  "In Tennessee speelt college sports een cruciale rol in de gemeenschap, met een rijke traditie in American football, basketbal, en atletiek. De University of Tennessee Volunteers en de Vanderbilt University Commodores zijn toonaangevende teams in het sportlandschap van de staat.",
  "De Volunteers, met hun iconische oranje kleur, trekken enorme menigten naar het Neyland Stadium, een van de grootste American football stadions in het land. De passie van de fans is ongeëvenaard, en 'Rocky Top' klinkt luid tijdens elke wedstrijd.",
  "Basketbal is ook een grote trekpleister in Tennessee, met zowel mannen- als vrouwenteams die nationale erkenning krijgen. De staat heeft een rijke geschiedenis in het produceren van topatleten en heeft bijgedragen aan de groei van de sport op nationaal niveau.",
  "College sports in Tennessee vormen een essentieel onderdeel van de cultuur en identiteit van de staat, het brengt mensen samen, viert tradities, en inspireert de volgende generatie atleten."
  ],
  },

  {
    name: "Texas",
    image: Texas,
    imageDescription: "Traditierijke rivaliteit: Texas Longhorns nemen het op tegen Texas A&M",

    description: [
    "College sports in Texas zijn synoniem aan grootte, passie en een rijke traditie, met American football dat de boventoon voert. Teams zoals de Texas Longhorns en de Texas A&M Aggies hebben een gigantische aanhang en hun wedstrijden zijn hoogtepunten van het seizoen.",
    "Het Darrell K Royal–Texas Memorial Stadium in Austin en Kyle Field in College Station zijn tempels van sport, waar elke thuiswedstrijd uitmondt in een spectaculair evenement. De rivaliteit tussen de verschillende universiteitsteams is intens en draagt bij aan de unieke sportcultuur in de staat.",
    "Naast football, zijn basketbal en baseball ook populair, met sterke programma’s die nationaal concurreren. De steun voor vrouwensporten groeit ook gestaag, waarbij vooral basketbal en atletiek opvallen.",
    "College sports in Texas zijn meer dan alleen een tijdverdrijf; het is een manier van leven, een bron van trots en een bindmiddel dat gemeenschappen samenbrengt."
    ],
    },


{
  name: "Utah",
  description: [
  "In Utah nemen college sports een belangrijke plaats in binnen de gemeenschap, waarbij basketbal en American football de populairste sporten zijn. De University of Utah Utes en de Brigham Young University Cougars voeren hevige strijd in verschillende disciplines.",
  "Het jaarlijkse football duel tussen de Utes en de Cougars, bekend als the 'Holy War', is een van de meest verwachte sportevenementen in de staat. Het brengt fans samen voor een viering van sportiviteit, rivaliteit en staatsroem.",
  "Basketbal kent ook een rijke traditie in Utah, met spannende seizoenen en memorabele momenten in zowel mannen- als vrouwencompetities. De teams streven naar uitmuntendheid en de steun van de fans is onwrikbaar.",
  "De impact van college sports in Utah gaat verder dan het speelveld. Het bevordert gemeenschapszin, schooltrots en levert een belangrijke bijdrage aan de culturele identiteit van de staat."
  ],
  },

  {
    name: "Vermont",
    description: [
    "In Vermont, waar de natuur overheerst en gemeenschappen hecht zijn, spelen college sports een unieke rol in het samenbrengen van mensen. Wintersporten zoals skiën en snowboarden staan hier prominent op de kaart, gezien het bergachtige terrein en koude klimaat.",
    "De University of Vermont Catamounts is het middelpunt van de college sports scene, met teams die uitblinken in verschillende sporten, inclusief ijshockey en basketbal. De Catamounts hebben een trouwe aanhang, en de sfeer bij thuiswedstrijden is intens.",
    "Basketbal geniet grote populariteit, en de wedstrijden van de Catamounts zijn een must-see voor sportliefhebbers. Het team heeft meerdere keren deelgenomen aan het NCAA-toernooi, wat de staat op de kaart zet in de college basketball wereld.",
    "College sports in Vermont zijn meer dan alleen een spel; ze zijn een manier om gemeenschappen samen te brengen, talent te ontwikkelen en de unieke sportcultuur van de staat te vieren."
    ],
    },

    {
      name: "Virginia",
      description: [
      "In Virginia vormen college sports een integraal onderdeel van de lokale cultuur, met een sterke focus op American football, basketbal en de opkomende populariteit van voetbal. De Virginia Cavaliers en Virginia Tech Hokies zijn toonaangevende universiteitsteams, die passie en rivaliteit op het veld brengen.",
      "American football trekt massa's fans naar de stadions, waar de atmosfeer op wedstrijddagen elektrisch is. De investering in basketbalprogramma's heeft ook vruchten afgeworpen, met teams die nationaal concurreren en een toegewijde aanhang hebben.",
      "Voetbal wint snel terrein in Virginia, met zowel mannen- als vrouwenteams die zich ontwikkelen tot competitieve krachten. De universiteiten investeren in faciliteiten en coaching, terwijl lokale academies jong talent voortbrengen.",
      "Deze mix van traditionele en opkomende sporten verrijkt de college sports scene in Virginia, creëert een levendige sportgemeenschap en biedt atleten en fans onvergetelijke ervaringen en een gevoel van trots en saamhorigheid."
      ],
      },

      {
        name: "Washington",
        description: [
        "College sports in Washington bloeien met een mix van traditie en enthousiasme, waarbij American football, basketbal en roeien in het middelpunt van de belangstelling staan. De University of Washington Huskies en de Washington State University Cougars zijn de pioniers van de sportcultuur in de staat.",
        "Het Husky Stadium in Seattle en het Martin Stadium in Pullman bruisen van opwinding tijdens American football-wedstrijden, waar fans uit het hele gebied samenkomen om hun teams aan te moedigen. De 'Apple Cup', de jaarlijkse confrontatie tussen de Huskies en de Cougars, is een hoogtepunt van de rivaliteit.",
        "Basketbal speelt ook een belangrijke rol, met zowel mannen- als vrouwenteams die strijden op hoog niveau. De Huskies hebben een sterke erfenis in deze sport en trekken fervente supporters aan.",
        "Roeien is een andere opvallende discipline, met de Huskies die talloze nationale kampioenschappen hebben gewonnen. College sports in Washington weerspiegelen de diversiteit en passie van de staat, waar sportiviteit, competitie en saamhorigheid hoog in het vaandel staan."
        ],
        },

        {
          name: "West Virginia",
          description: [
          "College sports vormen het kloppende hart van West Virginia, met een sterke nadruk op American football en basketbal. De West Virginia University Mountaineers zijn de trotse vertegenwoordigers van de staat, en hun wedstrijden brengen de gemeenschap bijeen.",
          "Het Milan Puskar Stadium in Morgantown komt tot leven tijdens de thuiswedstrijden van de Mountaineers, waarbij fans de iconische goud-blauwe kleuren dragen en luidkeels juichen. Het 'Backyard Brawl', een historische rivaliteit met de University of Pittsburgh, voegt extra spanning toe aan het seizoen.",
          "Basketbal is een andere passie, met opwindende wedstrijden die de fans op het puntje van hun stoel houden. De Mountaineers hebben zowel mannen- als vrouwenteams die op nationaal niveau concurreren.",
          "College sports in West Virginia zijn een bron van trots en eenheid, en ze weerspiegelen de sterke gemeenschapszin en tradities van de staat."
          ],
          },


{
  name: "Wisconsin",
  description: [
  "College sports zijn diepgeworteld in de cultuur van West Wisconsin, met American football, basketbal en ijshockey als prominente disciplines. De University of Wisconsin Badgers zijn de drijvende kracht achter de sportcultuur in de staat.",
  "Het Camp Randall Stadium in Madison bruist van opwinding tijdens American football-wedstrijden, waarbij fans zich verzamelen om 'Jump Around' te zingen en hun team aan te moedigen. De rivaliteit met teams als de University of Michigan Wolverines is legendarisch en zorgt voor intense wedstrijden.",
  "Basketbal kent ook een rijke traditie, met de Badgers die regelmatig strijden om de top in de NCAA-toernooien. Ijshockey heeft een speciale plaats in het hart van de staat, met spannende wedstrijden die de winteravonden verlichten.",
  "College sports in West Wisconsin gaan verder dan het speelveld; ze zijn een bron van trots, saamhorigheid en versterken de banden tussen gemeenschappen in de staat."
  ],
  },


  {
    name: "Wyoming",
    description: [
    "College sports in Wyoming hebben een uniek karakter, met een sterke nadruk op buitensporten zoals skiën, snowboarden en bergbeklimmen. De University of Wyoming Cowboys en Cowgirls zijn de vaandeldragers van deze avontuurlijke sportcultuur.",
    "De 'War Memorial Stadium' in Laramie komt tot leven tijdens American football-wedstrijden, waar fans zich verzamelen om hun steun te betuigen aan de Cowboys. De adembenemende bergen dienen als achtergrond voor deze sportieve evenementen.",
    "De universiteit heeft ook een sterke ski- en snowboardtraditie, met atleten die nationale en internationale competities domineren. De uitgestrekte wildernis van Wyoming dient als speeltuin voor avonturiers en outdoor-enthousiastelingen.",
    "College sports in Wyoming vieren de schoonheid van de natuur en de avontuurlijke geest van de staat, waar sportiviteit, passie en de liefde voor de buitenlucht samenkomen."
    ],
    }



];

export default states;
import React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import Container from "@mui/material/Container";
import Scarecrow from "../../assets/images/Scarecrow.png";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Header from "./header";

function WebsiteNotFound() {
  const navigate = useNavigate(); // Use the useNavigate hook

  // Function to navigate to the homepage
  const navigateToHome = () => {
    navigate('/'); // Use navigate to go to the homepage
  };

  return (
    <Container
      sx={{
        display: "flex",
        justifyContent: "center"
      }}
    >
      <Header />
      <Box
        sx={{
          width: "100%",
          minWidth: "100px"
        }}
      >
        <img src={Scarecrow} alt="Scarecrow" className="notFoundImage" />
      </Box>
      <Stack spacing={4} alignItems="flex-start" justifyContent="center">
        <Typography variant="h1" sx={{ textAlign: "left" }}>
          I have bad news for you
        </Typography>
        <Typography variant="subtitle1" sx={{ textAlign: "left" }}>
          The page you are looking for might be removed or is temporarily
          unavailable
        </Typography>
        <Button
          variant="contained"
          color="info"
          sx={{ padding: "1rem" }}
          onClick={navigateToHome} // Attach the navigateToHome function to onClick
        >
          Back to homepage
        </Button>
      </Stack>
    </Container>
  );
}

export default WebsiteNotFound;

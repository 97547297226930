/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sporten_Images/Basketbal1.jpeg";
import Image2 from "assets/images/Sporten_Images/Basketbal2.webp";
import Image3 from "assets/images/Sporten_Images/Basketbal3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import { Link } from 'react-router-dom';

import "../../../../H1asH3.css"

// Images



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Basketbalbeurs USA: Hoe je kunt studeren en basketballen in Amerika', isSubheader: false },

    { id: 'Hoofdstuk 1', title: '1. Geschiedenis en Ontwikkeling van College Basketbal', isSubheader: false },
    { id: '1.1', title: '1.1 Vroege Jaren', isSubheader: true },
    { id: '1.2', title: '1.2 Groei en Professionalisering', isSubheader: true },

    { id: 'Hoofdstuk 2', title: '2. College Basketbal Vandaag de Dag', isSubheader: false },
    { id: '2.1', title: '2.1 Belangrijke Competities en Conferenties', isSubheader: true },
    { id: '2.2', title: '2.2 Topprogramma’s en Prestigieuze Teams', isSubheader: true },

    { id: 'Hoofdstuk 3', title: '3: Van College naar de NBA', isSubheader: false },
    { id: '3.1', title: '3.1 Het Pad naar de NBA', isSubheader: true },
    { id: '3.2', title: '3.2 Andere Professionele Mogelijkheden', isSubheader: true },


    { id: 'Hoofdstuk 4', title: '4. Internationale Invloeden en Kansen', isSubheader: false },
    { id: '4.1', title: '4.1 Buitenlandse Spelers in College Basketbal', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikaanse Spelers in het Buitenland', isSubheader: true },

    { id: 'Hoofdstuk 5', title: '5. Basketbalbeurzen en de Toekomst', isSubheader: false },
    { id: '5.1', title: '5.1 Het Belang van Sportbeurzen', isSubheader: true },
    { id: '5.2', title: '5.2 Hoe Sportbeurs Amerika NL Kan Helpen', isSubheader: true },

  ];

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Inhoud
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Verberg</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Toon</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
            <MKBox id="intro" mb={3}>
            <MKTypography variant="h1" className="h1-as-h3" mb={2}>
              Basketbalbeurs USA: Hoe je kunt studeren en basketballen in Amerika
              </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
  <img
    src={Image1}
    alt="Sfeervol gevuld stadion van University of Arizona tijdens een college basketbalwedstrijd"
    style={{
      maxWidth: "100%",
      border: "2px solid #fff",
      boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
      cursor: "pointer",
      outline: "none",
    }}
    onClick={() => openModal1(Image1)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
Wedstrijddag in het University of Arizona stadion</figcaption>
</figure>
              Basketbal is een van de populairste sporten in de Verenigde Staten. Miljoenen mensen volgen de wedstrijden van de NBA, de professionele basketbalcompetitie, en de NCAA, de universitaire basketbalcompetitie. Basketbal is niet alleen een spannend spel om naar te kijken, maar ook een geweldige manier om jezelf te ontwikkelen als speler en als persoon.
<br/>
<br/>
Als je een jonge basketbalspeler bent die droomt van een carrière in de NBA, of gewoon wilt genieten van het spelen van basketbal op hoog niveau, dan is een basketbalbeurs in Amerika misschien iets voor jou. Een basketbalbeurs is een financiële ondersteuning die je krijgt van een Amerikaanse universiteit om daar te studeren en te basketballen. Met een basketbalbeurs kun je niet alleen je academische diploma behalen, maar ook je basketbaltalenten verbeteren onder professionele begeleiding.
<br/>
<br/>
Maar hoe kom je aan een basketbalbeurs in Amerika? Wat zijn de voordelen en uitdagingen van het spelen van college basketbal? En wat zijn de mogelijkheden na je college carrière? In dit artikel zullen we al deze vragen beantwoorden en je alles vertellen wat je moet weten over basketbalbeurzen in Amerika. We zullen ook ingaan op de rol van Sportbeursamerika.nl, een organisatie die gespecialiseerd is in het helpen van jonge spelers om hun droom van een basketbalbeurs te verwezenlijken.
<br/>
<br/>
Wil je meer weten over basketbalbeurzen in Amerika? Lees dan verder!
              </MKTypography>
              {/* Add bulleted list or other content as needed */}
            </MKBox>
             {/* Main chapters and subchapters with placeholder text */}
            <MKBox id="Hoofdstuk 1" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 1: Geschiedenis en Ontwikkeling van College Basketbal
              </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              Basketbal is een relatief jonge sport, die pas in 1891 werd uitgevonden door James Naismith, een Canadese leraar lichamelijke opvoeding. Hij bedacht het spel als een indoor activiteit voor zijn studenten tijdens de wintermaanden. Het spel werd al snel populair en verspreidde zich over verschillende scholen en universiteiten in Noord-Amerika.
              </MKTypography>

              <MKBox id="1.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                1.1 Vroege Jaren
                </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                De eerste officiële wedstrijd van basketbal op collegeniveau vond plaats in 1895 tussen de University of Chicago en de University of Iowa. In 1901 werd de eerste intercollegiate conferentie opgericht, de Western Conference, later bekend als de Big Ten Conference. In 1906 werd de eerste nationale kampioenschap georganiseerd door de Amateur Athletic Union (AAU), die tot 1936 het college basketbal domineerde.
<br/>
<br/>
In 1939 werd het eerste NCAA toernooi gehouden, met acht teams die streden om de titel. Het toernooi werd gewonnen door Oregon, dat Ohio State versloeg met 46-33. Het toernooi groeide uit tot een jaarlijks evenement dat steeds meer aandacht trok van fans en media.
                </MKTypography>
              </MKBox>
              <MKBox id="1.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                1.2 Groei en Professionalisering
                </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Na de Tweede Wereldoorlog nam het college basketbal toe in populariteit en kwaliteit. Er ontstonden nieuwe sterren, zoals Bill Russell, Wilt Chamberlain, Oscar Robertson en Kareem Abdul-Jabbar, die later ook furore maakten in de NBA. Er werden ook nieuwe competities en conferenties gevormd, zoals de National Invitation Tournament (NIT), de Atlantic Coast Conference (ACC) en de Southeastern Conference (SEC).
<br/>
<br/>
In 1957 vond een van de meest memorabele wedstrijden in de geschiedenis van het college basketbal plaats, toen North Carolina Tar Heels wonnen van Kansas Jayhawks met 54-53 na drie verlengingen. De wedstrijd wordt beschouwd als een keerpunt in het college basketbal, omdat het liet zien hoe spannend en dramatisch het spel kon zijn.
<br/>
<br/>
In 1966 vond een andere historische wedstrijd plaats, toen Texas Western Miners wonnen van Kentucky Wildcats met 72-65. De wedstrijd was baanbrekend omdat Texas Western het eerste team was dat volledig bestond uit zwarte spelers, terwijl Kentucky volledig wit was. De wedstrijd wordt gezien als een symbool voor de strijd voor burgerrechten en integratie in Amerika.
<br/>
<br/>
In 1979 vond het eerste live uitgezonden NCAA toernooi plaats, met een recordaantal kijkers. De finale ging tussen Michigan State Spartans en Indiana State Sycamores, die respectievelijk geleid werden door twee toekomstige NBA legendes, Magic Johnson en Larry Bird. De wedstrijd werd gewonnen door Michigan State met 75-64 en wordt beschouwd als een van de beste finales ooit.
<br/>
<br/>
In de jaren 80 en 90 bleef het college basketbal groeien en evolueren, met nieuwe regels, stijlen en strategieën. Er ontstonden nieuwe rivaliteiten, zoals Duke vs North Carolina, Georgetown vs Syracuse en UCLA vs Arizona. Er waren ook nieuwe sterren, zoals Michael Jordan, Patrick Ewing, Shaquille O’Neal en Tim Duncan, die het college basketbal naar een hoger niveau tilden.
                </MKTypography>
              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 2" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 2: College Basketbal Vandaag de Dag
                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              College basketbal is vandaag de dag een van de meest populaire en prestigieuze sporten in Amerika. Elk jaar trekken de wedstrijden miljoenen fans naar de stadions en de televisieschermen. College basketbal is ook een belangrijke bron van talent voor de NBA, die elk jaar honderden spelers rekruteert uit de universiteitsteams.              </MKTypography>

              <MKBox id="2.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                2.1 Belangrijke Competities en Conferenties                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
  <img
    src={Image2}
    alt="Steph Curry in actie voor Davidson College, uitblinkend in college basketbal."
    style={{
      maxWidth: "100%",
      border: "2px solid #fff",
      boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
      cursor: "pointer",
      outline: "none",
    }}
    onClick={() => openModal2(Image2)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
  Stephen Curry in actie als speler van het Davidson College
</figcaption>
</figure>
                Het college basketbal wordt voornamelijk georganiseerd door de National Collegiate Athletic Association (NCAA), die meer dan 1.000 universiteiten en hogescholen overziet. De NCAA verdeelt de teams in drie divisies, waarvan Divisie I de hoogste is. Divisie I bestaat uit 32 conferenties, die elk een aantal teams omvatten die geografisch of historisch verbonden zijn.
<br/>
<br/>
De conferenties zijn belangrijk omdat ze bepalen welke teams tegen elkaar spelen tijdens het reguliere seizoen, dat loopt van november tot maart. Elke conferentie heeft zijn eigen schema, regels en kampioenschap. De conferentiekampioenen krijgen automatisch een plaats in het NCAA toernooi, dat het hoogtepunt is van het college basketbal.
<br/>
<br/>
Het NCAA toernooi, ook wel bekend als March Madness, is een knock-out toernooi dat elk jaar in maart en april wordt gehouden. Het toernooi bestaat uit 68 teams, waarvan 32 de conferentiekampioenen zijn en 36 de at-large bids, die worden geselecteerd door een comité op basis van hun prestaties gedurende het seizoen. De teams worden verdeeld in vier regio’s en gerangschikt van 1 tot 16.
<br/>
<br/>
Het toernooi begint met vier play-in games, waarbij de laagst gerangschikte teams strijden om de laatste vier plaatsen in het hoofdtoernooi. Het hoofdtoernooi bestaat uit zes rondes, waarbij elke ronde een team elimineert tot er één overblijft. De laatste vier teams, ook wel bekend als de Final Four, spelen in een neutrale locatie om te bepalen wie de nationale kampioen wordt.
<br/>
<br/>
Het NCAA toernooi is een van de meest spannende en onvoorspelbare sportevenementen ter wereld. Elk jaar zijn er verrassingen, drama’s en sensaties, die bekend staan als upsets, buzzer beaters en Cinderella stories. Het toernooi trekt miljoenen kijkers en gokkers aan, die proberen te voorspellen wie de winnaar zal zijn.
<br/>
<br/>
Naast het NCAA toernooi zijn er ook andere belangrijke competities in het college basketbal, zoals het NIT, het CBI, het CIT en het NAIA toernooi. Deze competities bieden kansen voor teams die niet gekwalificeerd zijn voor het NCAA toernooi of die extra ervaring willen opdoen.
                </MKTypography>
              </MKBox>
              <MKBox id="2.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                2.2 Topprogramma’s en Prestigieuze Teams
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Het college basketbal kent vele topprogramma’s en prestigieuze teams, die zich onderscheiden door hun geschiedenis, traditie, succes en invloed. Sommige van deze programma’s zijn:
                </MKTypography>
                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
UCLA Bruins: Het meest succesvolle programma in de geschiedenis van het college basketbal, met 11 nationale titels, waarvan 10 onder coach John Wooden in de jaren 60 en 70. UCLA heeft ook vele beroemde spelers voortgebracht, zoals Kareem Abdul-Jabbar, Bill Walton en Reggie Miller.

</li>
<li>
Kentucky Wildcats: Het op één na meest succesvolle programma in de geschiedenis van het college basketbal, met acht nationale titels en 17 Final Four optredens. Kentucky staat bekend om zijn fanatieke fans en zijn vermogen om toprekruten aan te trekken.
</li>
<li>
North Carolina Tar Heels: Het derde meest succesvolle programma in de geschiedenis van het college basketbal, met zes nationale titels en 20 Final Four optredens. North Carolina heeft ook vele legendarische coaches gehad, zoals Dean Smith en Roy Williams, en spelers, zoals Michael Jordan, James Worthy en Vince Carter.
</li>
<li>
Duke Blue Devils: Een van de meest dominante programma’s in het moderne college basketbal, met vijf nationale titels en 16 Final Four optredens sinds 1986. Duke staat bekend om zijn coach Mike Krzyzewski, de meest winnende coach in de geschiedenis van het college basketbal, en zijn spelers, zoals Christian Laettner, Grant Hill en Zion Williamson.
</li>
<li>
Kansas Jayhawks: Een van de meest traditionele en invloedrijke programma’s in het college basketbal, met drie nationale titels en 15 Final Four optredens. Kansas is de geboorteplaats van het spel, aangezien James Naismith er de eerste coach was. Kansas heeft ook vele grote coaches gehad, zoals Phog Allen, Larry Brown en Bill Self, en spelers, zoals Wilt Chamberlain, Danny Manning en Paul Pierce.
</li>
</ul>
</MKTypography>
<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
Deze programma’s zijn slechts enkele voorbeelden van de vele uitstekende teams die het college basketbal rijk is. Elk jaar zijn er nieuwe uitdagers en verrassingen die het spel spannend en onvoorspelbaar maken.
                </MKTypography>
              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 3" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 3: Van College naar de NBA
                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              College basketbal is niet alleen een sport, maar ook een opleiding. Veel spelers die deelnemen aan het college basketbal hebben als doel om de NBA te bereiken, de beste en meest prestigieuze professionele basketbalcompetitie ter wereld. De NBA is echter niet gemakkelijk te bereiken, en vereist veel talent, hard werk en geluk.
              </MKTypography>

              <MKBox id="3.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                3.1 Het Pad naar de NBA                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Om in aanmerking te komen voor de NBA, moet een speler minstens 19 jaar oud zijn en minstens een jaar na zijn middelbare school afgestudeerd zijn. Dit betekent dat de meeste spelers die de NBA willen bereiken, eerst naar het college gaan en daar minstens een jaar spelen. Dit wordt ook wel de one-and-done regel genoemd, omdat veel spelers na één jaar college zich aanmelden voor de NBA draft.
<br/>
<br/>
De NBA draft is een jaarlijks evenement waarbij de 30 NBA teams om de beurt een speler kiezen uit een pool van beschikbare spelers. De draft bestaat uit twee rondes, waarbij elk team één keuze heeft per ronde. De volgorde van de keuzes wordt bepaald door een loterij voor de teams die het vorige seizoen niet de play-offs hebben gehaald, en door het omgekeerde klassement voor de teams die dat wel hebben gedaan.
<br/>
<br/>
De draft is een belangrijk moment voor de spelers, omdat het bepaalt welk team hun rechten krijgt en hoeveel geld ze kunnen verdienen. De spelers die in de eerste ronde worden gekozen, krijgen een gegarandeerd contract voor minstens twee jaar, terwijl de spelers die in de tweede ronde worden gekozen, geen garanties hebben. De spelers die niet worden gekozen, kunnen proberen om een contract te krijgen via de free agency of via de G League, de ontwikkelingscompetitie van de NBA.
<br/>
<br/>
De draft is echter geen garantie voor succes in de NBA. Veel spelers die hoog worden gekozen, voldoen niet aan de verwachtingen of raken geblesseerd. Anderzijds zijn er ook veel spelers die laag of helemaal niet worden gekozen, maar toch uitblinken in de NBA. Een bekend voorbeeld hiervan is Giannis Antetokounmpo, die in 2013 als 15e werd gekozen en in 2021 werd uitgeroepen tot MVP en kampioen van de NBA.
                </MKTypography>
              </MKBox>
              <MKBox id="3.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                3.2 Andere Professionele Mogelijkheden
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Niet alle spelers die aan het college basketbal meedoen, hebben als doel om naar de NBA te gaan. Sommige spelers kiezen ervoor om hun academische diploma af te maken en een andere carrière na te streven. Andere spelers kiezen ervoor om professioneel basketbal te spelen in andere landen of competities.
<br/>
<br/>
Er zijn veel professionele basketbalcompetities over de hele wereld, die verschillende niveaus van kwaliteit, salaris en levensstijl bieden. Sommige van deze competities zijn:
                </MKTypography>
                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
EuroLeague: De hoogste en meest prestigieuze Europese clubcompetitie, met 18 teams uit 10 landen. De EuroLeague wordt beschouwd als de tweede beste competitie ter wereld na de NBA.

</li>
<li>
Chinese Basketball Association (CBA): De hoogste en meest populaire Chinese clubcompetitie, met 20 teams uit heel China. De CBA staat bekend om zijn hoge salarissen en grote publiek.
</li>
<li>
Australian National Basketball League (NBL): De hoogste en meest competitieve Australische clubcompetitie, met negen teams uit Australië en Nieuw-Zeeland. De NBL staat bekend om zijn snelle en fysieke spel.
</li>
<li>
Basketball Africa League (BAL): De nieuwste en meest ambitieuze Afrikaanse clubcompetitie, met 12 teams uit 12 landen. De BAL wordt gesteund door de NBA en FIBA en heeft als doel om het basketbal op het continent te ontwikkelen.
</li>
</ul>
</MKTypography>
<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
Deze competities zijn slechts enkele voorbeelden van de vele mogelijkheden die er zijn voor professionele basketbalspelers buiten de NBA. Veel spelers vinden het leuk om in andere culturen te leven en te spelen, terwijl ze ook hun passie voor het spel voortzetten.
                </MKTypography>
              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 4" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 4: Internationale Invloeden en Kansen
                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              Basketbal is een wereldwijde sport, die mensen van verschillende nationaliteiten, culturen en achtergronden samenbrengt. Het college basketbal is daar een goed voorbeeld van, aangezien het zowel buitenlandse als Amerikaanse spelers aantrekt en kansen biedt. In dit hoofdstuk zullen we kijken naar de internationale invloeden en kansen in het college basketbal.
              </MKTypography>

              <MKBox id="4.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                4.1 Buitenlandse Spelers in College Basketbal
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
  <img
    src={Image3}
    alt="NCAA Final Four basketbalwedstrijd met een menigte van bijna 80,000 fans in het stadion."
    style={{
      maxWidth: "100%",
      border: "2px solid #fff",
      boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
      cursor: "pointer",
      outline: "none",
    }}
    onClick={() => openModal3(Image3)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
  Bijna 80,000 toeshouwers tijdens een wedstrijd om het nationale kampioenschap
</figcaption>
</figure>
                Het college basketbal heeft altijd buitenlandse spelers verwelkomd, die hun eigen stijl, vaardigheden en perspectieven hebben bijgedragen aan het spel. Volgens de NCAA waren er in het seizoen 2019-2020 meer dan 1.200 buitenlandse spelers in Divisie I, afkomstig uit 101 landen. De meest voorkomende landen waren Canada, Australië, Nigeria, Frankrijk en het Verenigd Koninkrijk.
<br/>
<br/>
Buitenlandse spelers kiezen om verschillende redenen voor het college basketbal. Sommigen willen hun academische opleiding combineren met hun sportieve ambities. Anderen willen zich aanpassen aan de Amerikaanse cultuur en levensstijl. Weer anderen willen hun kansen vergroten om de NBA te bereiken of om voor hun nationale teams te spelen.
<br/>
<br/>
Buitenlandse spelers hebben een grote impact gehad op het college basketbal, zowel individueel als collectief. Sommige van de meest succesvolle en invloedrijke buitenlandse spelers in de geschiedenis van het college basketbal zijn:
                </MKTypography>
                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
Hakeem Olajuwon: Een Nigeriaanse speler die speelde voor Houston Cougars van 1981 tot 1984. Hij leidde zijn team naar drie opeenvolgende Final Four optredens en won de nationale speler van het jaar award in 1984. Hij werd als eerste gekozen in de NBA draft van 1984 en werd een van de beste centers aller tijden.

</li>
<li>
Andrew Bogut: Een Australische speler die speelde voor Utah Utes van 2003 tot 2005. Hij won de nationale speler van het jaar award in 2005 en leidde zijn team naar de Sweet Sixteen. Hij werd als eerste gekozen in de NBA draft van 2005 en werd een All-Star en een kampioen.
</li>
<li>
Luka Garza: Een Amerikaanse speler met Bosnische roots die speelde voor Iowa Hawkeyes van 2017 tot 2021. Hij won twee keer de nationale speler van het jaar award, in 2020 en 2021, en werd de all-time topscorer van zijn universiteit. Hij werd als 52e gekozen in de NBA draft van 2021 en wordt beschouwd als een van de beste college spelers ooit.
</li>
</ul>
</MKTypography>
<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
Deze spelers zijn slechts enkele voorbeelden van de vele buitenlandse spelers die hebben geschitterd in het college basketbal. Elk jaar zijn er nieuwe talenten die zich laten zien en het spel verrijken.
                </MKTypography>
              </MKBox>
              <MKBox id="4.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                4.2 Amerikaanse Spelers in het Buitenland
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Niet alleen buitenlandse spelers komen naar Amerika om te studeren en te basketballen, maar ook Amerikaanse spelers gaan naar het buitenland om dezelfde redenen. Sommige Amerikaanse spelers kiezen ervoor om hun middelbare school of college carrière over te slaan of af te breken om professioneel te spelen in andere landen of competities.
<br/>
<br/>
De redenen voor deze keuze kunnen variëren, maar sommige zijn:
                </MKTypography>
                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
Financieel: Sommige spelers willen geld verdienen met hun talent, in plaats van te wachten op een NBA contract of een beurs.

</li>
<li>
Sportief: Sommige spelers willen zich ontwikkelen tegen sterkere tegenstanders, in plaats van te domineren op hun niveau.
</li>
<li>
Persoonlijk: Sommige spelers willen reizen, nieuwe culturen leren kennen of familiebanden onderhouden.
</li>
</ul>
</MKTypography>
<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
Amerikaanse spelers die naar het buitenland gaan, hebben verschillende ervaringen en resultaten. Sommige slagen erin om hun droom van de NBA te verwezenlijken, terwijl anderen daar genoegen nemen met een lange en succesvolle carrière. Sommige passen zich goed aan aan hun nieuwe omgeving, terwijl anderen moeite hebben met de taal, het eten of de levensstijl.
<br/>
<br/>
Enkele voorbeelden van Amerikaanse spelers die naar het buitenland zijn gegaan, zijn:
                </MKTypography>
                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
Brandon Jennings: Een speler die zijn middelbare school oversloeg om in 2008 te spelen voor Lottomatica Roma in Italië. Hij werd als 10e gekozen in de NBA draft van 2009 en speelde 10 seizoenen in de NBA.

</li>
<li>
Emmanuel Mudiay: Een speler die zijn college oversloeg om in 2014 te spelen voor Guangdong Southern Tigers in China. Hij werd als 7e gekozen in de NBA draft van 2015 en speelt momenteel voor Utah Jazz.</li>
<li>
LaMelo Ball: Een speler die zijn middelbare school en college afbrak om in 2019 te spelen voor Illawarra Hawks in Australië. Hij werd als 3e gekozen in de NBA draft van 2020 en won de Rookie of the Year award.
</li>
</ul>
</MKTypography>
<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
Deze spelers zijn slechts enkele voorbeelden van de vele Amerikaanse spelers die hebben gekozen voor een alternatief pad naar het professionele basketbal. Elk jaar zijn er nieuwe avonturiers die het spel globaliseren.

                </MKTypography>
              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 5" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 5: Basketbalbeurzen en de Toekomst
                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              Basketbalbeurzen zijn een geweldige kans voor jonge spelers om hun dromen waar te maken. Met een basketbalbeurs kun je studeren aan een prestigieuze universiteit, spelen op een hoog niveau, en je voorbereiden op een professionele carrière. Maar hoe kom je aan een basketbalbeurs? En wat zijn de voorwaarden en verwachtingen? In dit hoofdstuk zullen we deze vragen beantwoorden en je ook vertellen over de rol van Sportbeursamerika.nl, een organisatie die je kan helpen bij het verkrijgen van een basketbalbeurs.              </MKTypography>

              <MKBox id="5.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                5.1 Het Belang van Sportbeurzen
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Een sportbeurs is een financiële ondersteuning die je krijgt van een universiteit om daar te studeren en te sporten. Een sportbeurs kan je collegegeld, boeken, huisvesting, maaltijden en andere kosten dekken. Een sportbeurs kan ook je toegang tot betere faciliteiten, coaches, trainers en medische zorg vergemakkelijken.
<br/>
<br/>
Een sportbeurs is echter niet zomaar een cadeau. Het is een contract tussen jou en de universiteit, waarbij je je verbindt om bepaalde academische en sportieve eisen te voldoen. Je moet bijvoorbeeld een minimum GPA (Grade Point Average) behouden, regelmatig naar de lessen gaan, en deelnemen aan de trainingen en wedstrijden van je team. Je moet ook de regels en waarden van de universiteit respecteren en je gedragen als een goede ambassadeur.
<br/>
<br/>
Een sportbeurs is dus een uitdaging, maar ook een beloning. Het is een kans om jezelf te ontwikkelen op verschillende gebieden, zoals kennis, vaardigheden, karakter en leiderschap. Het is ook een kans om vrienden te maken, plezier te hebben en onvergetelijke ervaringen op te doen.
                </MKTypography>
              </MKBox>
              <MKBox id="5.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                5.2 Hoe Sportbeurs Amerika NL Kan Helpen 
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
  <img
    src={Image4}
    alt="Logo van Sportbeurs Amerika NL, bestaande uit een voetbal met het continent Noord-Amerika erop afgebeeld, met daarboven een afstudeerhoed (graduation cap) die bovenop de bal rust alsof het een hoofd is. De naam van het bedrijf is prominent weergegeven, wat de focus op sportbeurzen in Amerika voor Europese studenten benadrukt."
    style={{
      maxWidth: "100%",
      height: "auto",
      border: "2px solid #fff",
      boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)", // Apply shadow around the entire image
      cursor: "pointer",
      outline: "none",
      borderRadius: "50%", // Set border-radius to 50% for a circular image
      marginTop: "0px", // Add top margin to move the image up


    }}
    onClick={() => openModal4(Image4)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
  
</figcaption>
</figure>
Sportbeurs Amerika NL is een organisatie gespecialiseerd in het begeleiden van jonge sporttalenten naar sportbeurzen in Amerika.  Met een gepassioneerde en ervaren expert aan het roer, ondersteunt Sportbeurs Amerika NL de student-atleet bij iedere stap in het traject, vanaf de eerste oriëntatie tot aan de uiteindelijke plaatsing. Sportbeurs Amerika NL biedt onder andere de volgende diensten aan:
                </MKTypography>

                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
Een persoonlijke intakegesprek om de wensen, mogelijkheden en verwachtingen van de student-atleet te bespreken.
</li>
<li>
Een professionele evaluatie van het sportieve en academische niveau van de student-atleet.
</li>
<li>
Een uitgebreid netwerk van contacten met Amerikaanse coaches, universiteiten en colleges.
</li>
<li>
Een effectieve promotie van het profiel en de video’s van de student-atleet bij potentiële coaches.
</li>
<li>
Een deskundig advies over de beste opties voor de student-atleet op basis van zijn of haar voorkeuren, doelen en budget.
</li>
<li>
Een onderhandeling over de beste beursaanbiedingen namens de student-atleet.
</li>
<li>
Een hulp bij het aanvragen van de benodigde documenten, zoals het visum, het transcript, het diploma en de toelatingstesten.
</li>
<li>
Een voorbereiding op het leven in Amerika, zoals de cultuur, de taal, de regels en de tips.
</li>
<li>
Een nazorg en een follow-up tijdens het verblijf in Amerika.</li>

</ul>
</MKTypography>

<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Wil jij ook je passie voor American football combineren met een goede opleiding in Amerika? <Link to="/Diensten-Producten">Bekijk het volledige aanbod</Link> van Sportbeurs Amerika NL en maak een vrijblijvende afspraak om je te laten informeren over de mogelijkheden. 
      Ik sta voor je klaar om je te helpen bij het realiseren van je Amerikaanse droom.
    </MKTypography>


              </MKBox>
            </MKBox>

          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;
/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton"; // Import MKButton
import { Link } from 'react-router-dom';



// Rental page components
import FaqCollapse from "pages/Homepage/LandingPage/sections/FAQfunction";

function Faq() {
  const [collapse, setCollapse] = useState(false);

  return (
    <MKBox component="section" py={8}>
      <Container>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={8}>
            <MKTypography variant="h2" align="center" fontWeight="bold" gutterBottom>
              Frequently Asked Questions
            </MKTypography>
            <MKBox mb={2}>
              <MKTypography variant="body2" align="center" color="text">
              Alles wat je wilt weten: antwoorden op de meest gestelde vragen over studeren en sporten in Amerika en mijn diensten. Staat jouw vraag er niet tussen? Neem dan contact op.
              </MKTypography>
            </MKBox>
          </Grid>
          <Grid item xs={12} md={10}>
            <FaqCollapse
              title="Hoeveel kost het om in Amerika te studeren?"
              open={collapse === 1}
              onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
            >
              Het collegegeld en de kosten voor studeren in Amerika variëren sterk afhankelijk van de universiteit, het studieprogramma en de locatie. Gemiddeld genomen kunnen internationale studenten rekenen op een totale jaarlijkse uitgave van ongeveer $30.000 tot som wel $70.000 of meer. 
              Het is echter vermeldenswaard dat veel studenten een groot deel (of soms zelfs de volledigheid) van deze kosten kunnen dekken met sportbeurzen, studiebeurzen of een combinatie van beiden. Amerikaanse universiteiten bieden vaak sportbeurzen aan getalenteerde atleten, wat financiële steun biedt in ruil voor hun deelname aan universitaire sportprogramma's. Hierdoor kunnen student-atleten hun studie- en sportdoelen nastreven zonder aanzienlijke financiële lasten.
            </FaqCollapse>
            <FaqCollapse
              title="Wat is het verschil tussen een college en een universiteit in Amerika??"
              open={collapse === 2}
              onClick={() => (collapse === 2 ? setCollapse(false) : setCollapse(2))}
            >
              De terminologie kan verwarrend zijn, vooral voor mensen buiten de Verenigde Staten. In de praktijk verwijzen "college" en "universiteit" eigenlijk niet naar hetzelfde. Een "universiteit" is een alomvattend begrip voor een instelling voor hoger onderwijs die opleidingen op alle niveaus aanbiedt, inclusief bachelor-, master- en doctoraatsstudies.

Een "college" daarentegen is meestal een onderdeel van een universiteit en richt zich voornamelijk op undergraduate-programma's, zoals bacheloropleidingen. Met andere woorden, een college is een afdeling binnen een universiteit die specifiek gericht is op undergraduate-onderwijs. Universiteiten bieden doorgaans zowel undergraduate- als graduate-programma's aan, terwijl colleges zich concentreren op het undergraduate-niveau.

Dit onderscheid kan echter variëren tussen verschillende instellingen, en soms worden de termen door elkaar gebruikt. Het is daarom handig om de specifieke details en programma's van een onderwijsinstelling te bekijken om te begrijpen wat ze aanbieden.
            </FaqCollapse>
            <FaqCollapse
              title="Welke sporters worden door Sportbeurs Amerika NL begeleid?"
              open={collapse === 3}
              onClick={() => (collapse === 3 ? setCollapse(false) : setCollapse(3))}
            >
              Ik begeleid sporters uit een breed scala van sporten. Mijn sterke netwerk en mijn partnerschappen in Amerika stellen mij in staat om atleten te ondersteunen in verschillende disciplines. Dus, of je nu voetbalt, basketbalt, tennist, golf speelt, of een andere sport beoefent, ik sta klaar om je te helpen jouw academische en sportieve doelen na te streven in de Verenigde Staten.
              Voor een volledige lijst van sporten, <Link to="/Alle-Sporten" style={{ fontWeight: 'bold' }}>klik hier</Link>.
            </FaqCollapse>
            <FaqCollapse
              title="Hoe verloopt het gebruikelijke aanmeldingsproces, en hoelang van tevoren moet ik hiermee beginnen?"
              open={collapse === 4}
              onClick={() => (collapse === 4 ? setCollapse(false) : setCollapse(4))}
            >
              Het proces begint meestal met een uitgebreide kennismakingsbijeenkomst waarin we alle details bespreken en jouw specifieke situatie en doelen begrijpen. Tevens zal ik mijn werkwijze verder toelichten. De tijdsduur voor het gehele proces kan variëren, maar over het algemeen adviseer ik om minimaal 6 tot 12 maanden van tevoren te beginnen.  Het is belangrijk om te benadrukken dat hoe eerder je begint, hoe meer mogelijkheden zich kunnen voordoen, dus aarzel niet om vroeg aan het proces te beginnen. 

Tijdens deze periode zullen we samenwerken om een persoonlijk plan op te stellen dat rekening houdt met alle aspecten, zoals toelatingstests, visumaanvragen, het maken van sportfilmpjes en meer. Elk traject is uniek en op maat gemaakt, omdat er veel factoren zijn om te overwegen.

Een gedetailleerd overzicht van het volledige proces, kun je <Link to="/Traject" style={{ fontWeight: 'bold' }}>hier</Link> bekijken. Dit geeft een stapsgewijs inzicht in wat je kunt verwachten tijdens jouw reis naar studeren en sporten in de Verenigde Staten.
            </FaqCollapse>
            <FaqCollapse
              title="Wat zijn de kosten voor de diensten die je aanbiedt?"
              open={collapse === 5}
              onClick={() => (collapse === 5 ? setCollapse(false) : setCollapse(5))}
            >
 De kosten voor mijn diensten variëren afhankelijk van jouw specifieke behoeften en doelen. De intakegesprek is een belangrijke stap waarin we jouw situatie bespreken en een op maat gemaakt plan ontwikkelen. Het is ook de gelegenheid waarin we bepalen of we verder met elkaar willen samenwerken.

Na dit gesprek zal ik in staat zijn om een duidelijk beeld te krijgen van jouw wensen en behoeften, en op basis daarvan kan ik je een passende kosteninschatting geven.

Voor een volledig overzicht van de diensten en producten die ik aanbied, kun je <Link to="/Diensten-Producten" style={{ fontWeight: 'bold' }}>hier</Link> klikken. Hier vind je gedetailleerde informatie over hoe ik je kan helpen bij jouw streven om te studeren en te sporten in de Verenigde Staten.
            </FaqCollapse>
            <FaqCollapse
              title="Waarom zou ik kiezen om me te laten begeleiden door Sportbeurs Amerika NL?"
              open={collapse === 6}
              onClick={() => (collapse === 6 ? setCollapse(false) : setCollapse(6))}
            >
Wanneer je op een kruispunt staat in het realiseren van je dromen, zoals ik ooit stond, kunnen er veel vragen en twijfels ontstaan. De keuze om het zelf te doen, samen te werken met een bureau of zelfs het hele idee op te geven, kan overweldigend zijn. Ik begrijp deze onzekerheid diep, omdat ik daar zelf ook doorheen ben gegaan.

Het zou jammer zijn als deze twijfels iemand ervan weerhouden om te streven naar wat echt mogelijk is - een fantastische ervaring die wacht om ontdekt te worden. Daarom heb ik mij toegewijd aan het helpen realiseren van de dromen van anderen.

Ik breng mijn persoonlijke ervaringen in de praktijk door rechtstreeks betrokken te zijn bij elk project. Ik begrijp de vragen die je zou kunnen hebben en streef ernaar om een persoonlijke aanpak te bieden die aansluit bij jouw unieke behoeften. Samen kunnen we jouw visie tot leven brengen, gesteund door de kennis en het begrip dat voortkomt uit echte, geleefde ervaring
            </FaqCollapse>
          </Grid>
        </Grid>
        {/* Button Section */}
        <Grid container justifyContent="center" mt={4}>
          <Grid item xs={12} md={3}>
          <MKButton variant="gradient" color="light" fullWidth component={Link} to="/FAQs">
    Bekijk volledige FAQ
</MKButton>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Faq;


/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Images
import post2 from "assets/images/TijdUS.png";
import "../../../../H1asH3.css"


// Images

function Profile() {
  return (
    <MKBox component="section" py={{ xs: 0, sm: 0 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -16, md: -20 }} textAlign="center">
          </MKBox>
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
            <MKBox 
                display="flex" 
                flexDirection={{ xs: "column", sm: "column", md: "column", lg: "row" }} 
                justifyContent="space-between" 
                alignItems="center" 
    mb={1}
>
    <MKTypography variant="h1" className="h1-as-h3" mb={{ xs: 1, sm: 1, md: 0 }}>Deel 3: Terug in Nederland</MKTypography>

    
</MKBox>
           

              <MKTypography variant="body1" fontWeight="light" color="text">

              <MKTypography variant="h4">
               
                </MKTypography>
                <br />
                Bij mijn terugkomst in Nederland voelde ik een mengeling van nostalgie en vastberadenheid. De passie voor voetbal, hoewel nog steeds diepgeworteld, werd gecomplementeerd door een hernieuwde zin in academische uitdagingen. Amsterdam werd het decor voor mijn volgende avontuur. Hier stortte ik me op een master in Quantitative Finance, een discipline die zowel mijn analytische geest als mijn ambitie prikkelde.
                <br />
                <br />

Na het afronden van mijn studie ging ik aan de slag als consultant. Hoewel de wereld van cijfers en analyses mij boeide, voelde ik dat er iets ontbrak. De echo van mijn Amerikaanse avontuur bleef resoneren. Ik besefte dat ik een unieke kans had gehad, een kans die ik anderen ook gunde.
                <br />
                <br />
Daarom besloot ik mijn ervaringen en kennis in te zetten om andere jonge, ambitieuze sporters te begeleiden. Voordat ik dit bedrijf begon, benaderde ik mijn uitgebreide netwerk in Amerika. Ik raakte in gesprek met coaches, sporters, oud-sporters en anderen die mij en toekomstige studenten die ik zou gaan helpen, konden bijstaan om te begrijpen hoe we dit proces het beste konden aanpakken.
                <br />
                <br />
Tijdens de uitdagende COVID-19 pandemieperiode begon ik samen te werken met een toonaangevend bureau in Amerika. Hoewel ik minder buitenlandse studenten kon helpen vanwege de verminderde beschikbaarheid van beurzen, heb ik mijn expertise gebruikt om Amerikaanse studenten bij te staan. Deze ervaring heeft niet alleen mijn netwerk aanzienlijk uitgebreid, maar heeft me ook inzichten gegeven in de Amerikaanse beurzenmarkt die ik nu nog beter kan toepassen in Europa.
                <br />
                <br />
De uitdagingen tijdens de pandemie hebben me gesterkt in mijn missie om jonge talenten te begeleiden op hun weg naar Amerikaanse universiteiten en sportbeurzen. Het was een uitdaging, maar het gaf me een gevoel van voldoening dat ik nooit eerder had ervaren. Het was mijn manier om iets terug te geven aan de gemeenschap die me ooit zo warm had verwelkomd in de Verenigde Staten.
                <br />
                <br />
Dit is mijn verhaal, en ik ben er trots op dat ik nu anderen kan helpen hun eigen verhaal te schrijven in het land van onbegrensde mogelijkheden. Als je meer wilt weten over mijn bedrijf en de reis van mijn cliënten om hun dromen te verwezenlijken, blijf dan lezen op mijn website. Mocht je nog vragen hebben of contact willen opnemen, aarzel dan niet om dat te doen.
                
              </MKTypography>
              <hr style={{ margin: '20px 0'}} />
              <Grid item xs={12} lg={6} mb={{ xs: 3, lg: 0 }}>
            <TransparentBlogCard
              image={post2}
              title="Deel 2: Mijn Tijd in Amerika "
              action={{
                type: "internal",
                route: "/Over-Mij/Tijd-in-Amerika",
                color: "info",
                label: "lees meer",
              }}
            />
          </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}


export default Profile;

/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sporten_Images/Hockey1.jpg";
import Image2 from "assets/images/Sporten_Images/Hockey2.jpeg";
import Image3 from "assets/images/Sporten_Images/Hockey3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";



// Images
import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");




  
  
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Hockeybeurs Amerika: Hoe Field Hockey Je Kan Helpen Je Dromen Waar Te Maken', isSubheader: false },

    { id: 'Hoofdstuk 1', title: '1. De Geschiedenis en Ontwikkeling van Field Hockey in Amerika', isSubheader: false },
    { id: '1.1', title: '1.1 Vroege Geschiedenis', isSubheader: true },
    { id: '1.2', title: '1.2 Ontwikkeling en Professionalisering', isSubheader: true },

    { id: 'Hoofdstuk 2', title: '2. Field Hockey op Universitair Niveau', isSubheader: false },
    { id: '2.1', title: '2.1 Competities en Conferenties', isSubheader: true },
    { id: '2.2', title: '2.1 Invloed op Spelerontwikkeling', isSubheader: true },

    { id: 'Hoofdstuk 3', title: '3: De Overgang van Universiteit naar Professional', isSubheader: false },
    { id: '3.1', title: '3.1 Van College naar de Profs', isSubheader: true },
    { id: '3.2', title: '3.2 Professionele Kansen en Competities', isSubheader: true },

    { id: 'Hoofdstuk 4', title: '4. Internationale Invloeden en Kansen', isSubheader: false },
    { id: '4.1', title: '4.1 Globale Invloeden', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikanen op het Wereldtoneel', isSubheader: true },

    { id: 'Hoofdstuk 5', title: '5. Beurzen en de Toekomst van Field Hockey', isSubheader: false },
    { id: '5.1', title: '5.1 Belang van Beurzen', isSubheader: true },
    { id: '5.2', title: '5.2 Hoe Sportbeurs Amerika NL Kan Helpen', isSubheader: true },

  ];

  const [showNav, setShowNav] = useState(true);

  const [,] = useState("");


  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Inhoud
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Verberg</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Toon</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
            <MKBox id="intro" mb={3}>
            <MKTypography variant="h1" className="h1-as-h3" mb={2}>
              Hockeybeurs Amerika: Hoe Field Hockey Je Kan Helpen Je Dromen Waar Te Maken
              </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
  <img
    src={Image1}
    alt="Speelster in actie tijdens een Colgate college field hockey wedstrijd."
    style={{
      maxWidth: "100%",
      border: "2px solid #fff",
      boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
      cursor: "pointer",
      outline: "none",
    }}
    onClick={() => openModal1(Image1)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
  Speelster in actie tijdens een wedstrijd
</figcaption>
</figure>
      

              Field hockey is een van de snelst groeiende sporten in Amerika. Steeds meer jonge atleten ontdekken de voordelen en het plezier van deze dynamische en uitdagende sport. Maar wist je dat field hockey je ook kan helpen om je academische en professionele doelen te bereiken?
<br />
<br />

Als je een passie hebt voor field hockey, dan heb je een unieke kans om te spelen op collegeniveau in Amerika. Dit betekent dat je kunt studeren aan een van de beste universiteiten ter wereld, terwijl je je sportieve vaardigheden ontwikkelt onder professionele begeleiding. Bovendien kun je in aanmerking komen voor een sportbeurs, die je financiële steun kan bieden voor je studie.

<br />
<br />
Maar hoe kom je aan zo’n beurs? En hoe bereid je je voor op het spelen van field hockey in Amerika? Dat is waar Sportbeursamerika.nl je kan helpen. Sportbeursamerika.nl is een gespecialiseerd bureau dat jonge talenten helpt om hun droom van studeren en hockeyen in Amerika waar te maken. Ik bied persoonlijke begeleiding, advies en ondersteuning bij het vinden van de juiste universiteit, het aanvragen van een beurs, het regelen van alle praktische zaken en het integreren in de Amerikaanse cultuur.
<br />
<br />
In dit artikel zal ik je meer vertellen over de geschiedenis en ontwikkeling van field hockey in Amerika, de mogelijkheden en voordelen van het spelen op universitair niveau, de overgang van universiteit naar professioneel spelen, de internationale invloeden en kansen binnen de sport, en natuurlijk het belang van beurzen en de rol van Sportbeursamerika.nl. Na het lezen van dit artikel zul je een beter beeld hebben van wat field hockey in Amerika inhoudt, en hoe je je kunt voorbereiden op deze spannende uitdaging.
<br />
<br />
Ben je klaar om meer te leren over field hockey in Amerika? Lees dan snel verder!
              </MKTypography>
              {/* Add bulleted list or other content as needed */}
            </MKBox>
             {/* Main chapters and subchapters with placeholder text */}
            <MKBox id="Hoofdstuk 1" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 1: De Geschiedenis en Ontwikkeling van Field Hockey in Amerika
              </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              Field hockey is een sport met een lange en rijke geschiedenis. Maar hoe is het ontstaan en gegroeid in Amerika? In dit hoofdstuk zullen we je meenemen op een reis door de tijd, van de vroege introductie tot de huidige professionalisering van de sport.
              </MKTypography>

              <MKBox id="1.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                1.1 Vroege Geschiedenis
                </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Field hockey werd voor het eerst geïntroduceerd in de VS in de late 19e eeuw, door Britse immigranten en toeristen. Het spel werd al snel populair onder vrouwen, die het zagen als een manier om te sporten en te socialiseren. De eerste universitaire teams werden gevormd in 1901, aan de Vassar College in New York en de Bryn Mawr College in Pennsylvania. Deze teams speelden vooral tegen elkaar, of tegen lokale clubs.
<br />
<br />
In 1922 werd de United States Field Hockey Association (USFHA) opgericht, als de nationale organisatie voor de sport. De USFHA organiseerde de eerste nationale kampioenschappen voor vrouwen in 1925, en voor mannen in 1930. De USFHA was ook verantwoordelijk voor het selecteren en trainen van de nationale teams, die deelnamen aan internationale toernooien zoals de Olympische Spelen en de Wereldkampioenschappen.
                </MKTypography>
              </MKBox>
              <MKBox id="1.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                1.2 Ontwikkeling en Professionalisering
                </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                In de tweede helft van de 20e eeuw groeide field hockey verder in populariteit en kwaliteit in Amerika. Het spel werd meer verspreid over het land, met name in het noordoosten, het middenwesten en het westen. Het aantal universitaire teams nam toe, en er ontstonden meer competities en conferenties. Sommige van de meest prestigieuze en competitieve programma’s zijn onder andere Princeton University, University of Maryland, University of North Carolina, Stanford University en Harvard University.
<br />
<br />
Het niveau van het universitaire field hockey steeg ook, dankzij betere faciliteiten, coaching en rekrutering. Veel universiteiten bieden nu sportbeurzen aan voor getalenteerde field hockey spelers, waardoor ze kunnen studeren zonder financiële zorgen. Bovendien fungeert het universitaire field hockey als een kweekvijver voor het professionele field hockey, dat ook steeds meer ontwikkeld en geprofessionaliseerd is.
<br />
<br />

Het professionele field hockey heeft ook invloed op het nationale team, dat steeds beter presteert op het internationale toneel. Het Amerikaanse vrouwenteam heeft bijvoorbeeld twee keer brons gewonnen op de Olympische Spelen (in 1984 en 1996), en twee keer zilver op de Pan-Amerikaanse Spelen (in 2011 en 2015). Het Amerikaanse mannenteam heeft ook meerdere keren meegedaan aan de Olympische Spelen en de Wereldkampioenschappen, maar heeft nog geen medailles behaald.
<br />
<br />
Zoals je kunt zien, heeft field hockey een lange weg afgelegd in Amerika, van een recreatieve activiteit tot een professionele sport. Maar wat houdt het precies in om field hockey te spelen op universitair niveau? Dat zullen we je vertellen in het volgende hoofdstuk.
                </MKTypography>
              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 2" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 2: Field Hockey op Universitair Niveau
                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              Field hockey op universitair niveau is een van de meest uitdagende en lonende ervaringen die je kunt hebben als jonge atleet. Het biedt je de kans om je sportieve talent te combineren met je academische ambities, en om deel uit te maken van een hechte en diverse gemeenschap. In dit hoofdstuk zullen we je meer vertellen over de competities en conferenties, de invloed op spelerontwikkeling, en de voordelen van het spelen van field hockey op universitair niveau.
              </MKTypography>

              <MKBox id="2.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                2.1 Competities en Conferenties
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
  <img
    src={Image2}
    alt="Luchtfoto van het Boston College field hockey stadion"
    style={{
      maxWidth: "100%",
      border: "2px solid #fff",
      boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
      cursor: "pointer",
      outline: "none",
    }}
    onClick={() => openModal2(Image2)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
  Het stadion van Boston College
</figcaption>
</figure>
                Field hockey op universitair niveau wordt gereguleerd door de National Collegiate Athletic Association (NCAA), die de sport onderverdeelt in drie divisies: Division I, Division II en Division III. Elke divisie heeft zijn eigen regels, eisen en beurzen. Over het algemeen geldt dat Division I de hoogste competitie is, met de beste teams en spelers, en de meeste beurzen. Division II en III zijn minder competitief, maar bieden nog steeds veel mogelijkheden voor getalenteerde spelers.
<br />
<br />
Binnen elke divisie zijn er verschillende conferenties, die bestaan uit universiteiten die geografisch of historisch verbonden zijn. Elke conferentie heeft zijn eigen schema, ranglijst en kampioenschap. Sommige van de meest bekende conferenties zijn onder andere de Big Ten Conference, de Atlantic Coast Conference, de Ivy League, de Patriot League en de America East Conference.
<br />
<br />
De beste teams en spelers van elke conferentie kunnen zich kwalificeren voor het nationale kampioenschap, dat elk jaar wordt gehouden in november. Het nationale kampioenschap bestaat uit een knock-out toernooi, waarbij de winnaar wordt gekroond tot de nationale kampioen.
                </MKTypography>
              </MKBox>
              <MKBox id="2.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                2.2 Invloed op Spelerontwikkeling
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            
                Het spelen van field hockey op universitair niveau heeft een grote invloed op de ontwikkeling van spelers. Het stelt spelers in staat om hun vaardigheden te verbeteren onder professionele coaching, om te trainen met state-of-the-art faciliteiten en apparatuur, om te concurreren tegen andere topatleten, en om te leren van hun teamgenoten en tegenstanders.
<br />
<br />
Bovendien biedt het spelen van field hockey op universitair niveau spelers ook veel persoonlijke voordelen. Het helpt spelers om hun zelfvertrouwen, discipline, leiderschap, teamwork, communicatie en probleemoplossende vaardigheden te vergroten. Het helpt spelers ook om hun tijdmanagement, studiegewoonten, stressmanagement en gezondheid te verbeteren. Het helpt spelers ook om hun horizon te verbreden, door hen bloot te stellen aan verschillende culturen, perspectieven en ervaringen.
<br />
<br />
Het verhaal van Nederlandse hockeysters die studiebeurzen ontvangen om op universitair niveau in Amerika te spelen, is exemplarisch voor hoe deze ervaring hun ontwikkeling als spelers kan bevorderen. Velen hebben in de Verenigde Staten voor prestigieuze universiteitsteams gespeeld, waar ze niet alleen kampioenschappen en individuele titels hebben gewonnen, maar ook waardevolle lessen hebben geleerd.
<br />
<br />
De ervaring in Amerika biedt deze atleten een unieke kans om zich onder te dompelen in een andere cultuur, te genieten van nieuwe sociale omgevingen en een ander onderwijssysteem te ervaren. De interactie met coaches die hen uitdagen en ondersteunen, draagt bij aan het verfijnen van hun vaardigheden en het uitbreiden van hun tactisch begrip van het spel.
<br />
<br />
Na terugkeer naar Nederland hebben deze hockeysters hun opgedane kennis en ervaringen ingezet om op professioneel niveau te excelleren en in sommige gevallen de leiderschapsrollen binnen het nationale team op zich te nemen. Hun tijd in Amerika heeft hen vaak voorbereid op de druk en de verwachtingen die bij internationale competities komen kijken, en vormt een fundament voor hun latere succes.
<br />
<br />
Zoals je kunt zien, kan het spelen van field hockey op universitair niveau je veel voordelen bieden, zowel sportief als persoonlijk. Maar hoe maak je de overstap van universiteit naar professioneel spelen? Dat zullen we je uitleggen in het volgende hoofdstuk.

                </MKTypography>
              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 3" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 3: De Overgang van Universiteit naar Professional
                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              Field hockey op universitair niveau is een geweldige manier om je sportieve potentieel te benutten, maar het hoeft niet het einde van je carrière te zijn. Als je de ambitie en de passie hebt om verder te gaan, dan kun je de overstap maken naar professioneel spelen. In dit hoofdstuk zullen we je meer vertellen over hoe je van college naar de profs kunt gaan, en wat voor professionele kansen en competities er zijn voor field hockey spelers.
              </MKTypography>

              <MKBox id="3.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                3.1 Van College naar de Profs
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                De overgang van universiteit naar professioneel spelen is niet eenvoudig, maar ook niet onmogelijk. Het vereist veel toewijding, hard werken, talent en geluk. Het belangrijkste is dat je jezelf laat zien aan de juiste mensen, die je kunnen helpen om je droom waar te maken.

<br />
<br />
Een manier om jezelf te laten zien is door mee te doen aan try-outs. Try-outs zijn open trainingen of wedstrijden waarbij professionele teams spelers kunnen scouten en testen. Try-outs worden meestal gehouden voor of na het seizoen, en kunnen worden aangekondigd via websites, sociale media of mond-tot-mond reclame. Try-outs kunnen een goede kans zijn om je vaardigheden te tonen en feedback te krijgen van coaches en managers. Try-outs kunnen bij professionele teams over de hele wereld worden afgelegd.
<br />
<br />
Een andere manier om jezelf te laten zien is door gebruik te maken van je netwerk. Je netwerk bestaat uit alle mensen die je kent of ontmoet die iets met field hockey te maken hebben, zoals je coaches, teamgenoten, tegenstanders, vrienden, familie, docenten, alumni, etc. Je netwerk kan je helpen om op de hoogte te blijven van de laatste nieuwtjes, kansen en tips in de field hockey wereld. Je netwerk kan je ook helpen om in contact te komen met mensen die invloed of connecties hebben in de professionele scene.
<br />
<br />
Tevens keren veel Europese hockeyster terug naar Europa om daar hun geluk te beproeven. Zoals je kunt zien, zijn er verschillende manieren om jezelf in de kijker te spelen bij professionele teams. Maar wat voor soort teams zijn er eigenlijk? En wat voor soort competities zijn er? Dat zullen we je vertellen in het volgende onderdeel.
                </MKTypography>
              </MKBox>
              <MKBox id="3.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                3.2 Professionele Kansen en Competities
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Voor een fieldhockey speler die het Amerikaanse collegesysteem heeft doorlopen, staan de deuren naar een professionele carrière vaak wijd open. Deze ervaring is meer dan alleen een sprongplank; het is een grondige voorbereiding die hen op vele fronten heeft ontwikkeld - fysiek, tactisch, en mentaal.
<br />
<br />
Een speler die uitblinkt in het college hockey kan de aandacht trekken van scouts en professionele teams, niet alleen vanwege hun prestaties op het veld, maar ook vanwege de discipline en veelzijdigheid die ze hebben ontwikkeld door te studeren en te sporten tegelijkertijd. Professionele hockeycompetities wereldwijd, zoals in Nederland, België, Duitsland, en Australië, staan bekend om het werven van talentvolle spelers met een solide achtergrond in het college hockey.
<br />
<br />
De competitieve aard van het college hockey in Amerika zorgt er ook voor dat spelers gewend zijn aan een hoog niveau van training en fysieke voorbereiding, wat essentieel is voor het professionele spel. Daarnaast hebben ze vaak ervaring in het spelen van belangrijke wedstrijden en kampioenschappen, wat hen helpt om te gaan met de druk van professionele competities.
<br />
<br />
Bovendien biedt de internationale ervaring en blootstelling aan verschillende speelstijlen in Amerika deze spelers een uniek perspectief dat ze kunnen meenemen naar hun professionele carrière. Het helpt hen om zich snel aan te passen aan nieuwe teams en strategieën, en biedt een breder begrip van het spel.
<br />
<br />
Professionele teams waarderen de combinatie van academische discipline en atletische uitmuntendheid die een voormalig college-speler meebrengt. Deze spelers zijn vaak goed voorbereid om de uitdagingen van het professionele leven aan te gaan, inclusief de balans tussen sport en andere levensverplichtingen.
<br />
<br />

Zoals je kunt zien, zijn er veel professionele kansen en competities voor field hockey spelers in Amerika. Maar hoe zit het met de rest van de wereld? Hoe staat field hockey in Amerika in verhouding tot het internationale field hockey? Dat zullen we je vertellen in het volgende hoofdstuk.

                </MKTypography>
              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 4" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 4: Internationale Invloeden en Kansen
                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              Field hockey in Amerika staat niet op zichzelf, maar maakt deel uit van een grotere en meer diverse field hockey wereld. Field hockey is een wereldwijde sport, die gespeeld wordt in meer dan 100 landen, verspreid over alle continenten. Field hockey in Amerika wordt beïnvloed door en heeft invloed op het internationale field hockey. In dit hoofdstuk zullen we je meer vertellen over de globale invloeden, de Amerikanen op het wereldtoneel, en de internationale kansen voor field hockey spelers.
              </MKTypography>

              <MKBox id="4.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                4.1 Globale Invloeden                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
  <img
    src={Image3}
    alt="UNC field hockey team met de NCAA kampioenschapstrofee."
    style={{
      maxWidth: "100%",
      border: "2px solid #fff",
      boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
      cursor: "pointer",
      outline: "none",
    }}
    onClick={() => openModal3(Image3)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
  Speelsters van UNC vieren het nationale kampioenschap
</figcaption>
</figure>
                Field hockey in Amerika wordt beïnvloed door het field hockey dat gespeeld wordt in andere landen, vooral in Europa en Azië. Deze landen hebben een langere en rijkere traditie van field hockey, en hebben vaak een hoger niveau van spel en organisatie. Door te kijken naar en te leren van deze landen, kan field hockey in Amerika zich verder ontwikkelen en verbeteren.
<br />
<br />
Een van de manieren waarop field hockey in Amerika wordt beïnvloed door het internationale field hockey is door het overnemen van verschillende stijlen en tactieken. Bijvoorbeeld, het Nederlandse field hockey staat bekend om zijn snelle en aanvallende spel, met veel balbezit, combinaties en doelpogingen. Het Duitse field hockey staat bekend om zijn fysieke en verdedigende spel, met veel druk, duels en counters. Het Indiase field hockey staat bekend om zijn technische en creatieve spel, met veel dribbels, passes en trucs.
<br />
<br />
Door te leren van deze stijlen en tactieken, kan field hockey in Amerika zijn eigen spel diversifiëren en aanpassen aan verschillende situaties. Zo kan field hockey in Amerika meer variatie, flexibiliteit en effectiviteit creëren.
                </MKTypography>
              </MKBox>
              <MKBox id="4.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                4.2 Amerikanen op het Wereldtoneel
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Field hockey in Amerika heeft ook invloed op het internationale field hockey, door het leveren van spelers die meedoen aan de grootste en belangrijkste toernooien ter wereld. Deze spelers vertegenwoordigen niet alleen hun land, maar ook hun universiteit, hun team en hun sport. Door hun prestaties laten ze zien wat field hockey in Amerika te bieden heeft, en inspireren ze anderen om ook deel te nemen aan deze sport.
<br />
<br />
Een goed voorbeeld van hoe Amerikaanse spelers het wereldtoneel betreden is het verhaal van Lauren Crandall. Lauren is een Amerikaanse field hockey speelster die in een beurs kreeg om te spelen voor de Wake Forest University. Daar speelde ze vier seizoenen lang voor het team, waarmee ze twee keer de NCAA Division I titel won.
<br />
<br />
Lauren maakte ook deel uit van het Amerikaanse nationale team, waarmee ze deelnam aan drie Olympische Spelen (in 2008, 2012 en 2016), twee Wereldkampioenschappen (in 2010 en 2014) en vier Pan-Amerikaanse Spelen (in 2007, 2011, 2015 en 2019). Ze was ook de aanvoerder van het team van 2013 tot 2016.
<br />
<br />
Lauren zegt dat haar tijd in Amerika haar veel heeft geholpen om haar droom van het spelen op het hoogste niveau te realiseren. Ze zegt dat ze veel heeft geleerd van haar coach, Jennifer Averill, die haar heeft gesteund en gemotiveerd. Ze zegt ook dat ze veel heeft genoten van de cultuur, de mensen en het onderwijs in Amerika. Ze zegt ook dat ze trots is om haar land te vertegenwoordigen, en om een rolmodel te zijn voor jonge spelers.
<br />
<br />
Zoals je kunt zien, kan field hockey in Amerika je veel internationale invloeden en kansen bieden, zowel als speler als als persoon. Maar hoe kun je deze kansen benutten? En hoe kun je een beurs krijgen om te studeren en te spelen in Amerika? Dat zullen we je vertellen in het volgende hoofdstuk.
                </MKTypography>
              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 5" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 5: Beurzen en de Toekomst van Field Hockey
                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              Field hockey is een sport die veel kansen biedt voor jonge atleten die hun talent willen ontwikkelen en hun dromen willen najagen. Een van de belangrijkste kansen is het verkrijgen van een sportbeurs om te studeren en te spelen op een Amerikaanse universiteit. In dit hoofdstuk zullen we kijken hoe beurzen het verschil kunnen maken voor jonge field hockey spelers, en hoe Sportbeursamerika.nl hen kan helpen deze kans te grijpen.
              </MKTypography>

              <MKBox id="5.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                5.1 Belang van Beurzen 
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Een sportbeurs is een financiële ondersteuning die wordt aangeboden door een universiteit aan een atleet om zijn of haar studie en sport te bekostigen. Een sportbeurs kan de kosten van het collegegeld, de boeken, de huisvesting, de maaltijden, en de reiskosten dekken. Een sportbeurs kan ook toegang geven tot academische begeleiding, medische zorg, trainingsfaciliteiten, en andere voordelen.
<br />
<br />
Een sportbeurs is niet alleen een financieel voordeel, maar ook een sportief voordeel. Het stelt een atleet in staat om te spelen op een hoog niveau, onder begeleiding van professionele coaches, en tegen sterke tegenstanders. Het stelt een atleet ook in staat om zich te ontwikkelen als persoon, door te leren van verschillende culturen, ervaringen, en perspectieven.
<br />
<br />
Een sportbeurs is echter niet gemakkelijk te verkrijgen. Er is veel concurrentie voor een beperkt aantal beurzen, en de eisen zijn hoog. Een atleet moet niet alleen uitblinken in zijn of haar sport, maar ook in zijn of haar academische prestaties. Een atleet moet ook voldoen aan de toelatingseisen van de universiteit, de visumvereisten van het land, en de regels van de NCAA (de organisatie die het universitaire sporten reguleert).
                </MKTypography>
              </MKBox>
              <MKBox id="5.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                5.2 Hoe Sportbeurs Amerika NL Kan Helpen 
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
  <img
    src={Image4}
    alt="Logo van Sportbeurs Amerika NL, bestaande uit een voetbal met het continent Noord-Amerika erop afgebeeld, met daarboven een afstudeerhoed (graduation cap) die bovenop de bal rust alsof het een hoofd is. De naam van het bedrijf is prominent weergegeven, wat de focus op sportbeurzen in Amerika voor Europese studenten benadrukt."
    style={{
      maxWidth: "100%",
      height: "auto",
      border: "2px solid #fff",
      boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)", // Apply shadow around the entire image
      cursor: "pointer",
      outline: "none",
      borderRadius: "50%", // Set border-radius to 50% for a circular image
      marginTop: "0px", // Add top margin to move the image up


    }}
    onClick={() => openModal4(Image4)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
  
</figcaption>
</figure>
Sportbeurs Amerika NL is een organisatie gespecialiseerd in het begeleiden van jonge sporttalenten naar sportbeurzen in Amerika.  Met een gepassioneerde en ervaren expert aan het roer, ondersteunt Sportbeurs Amerika NL de student-atleet bij iedere stap in het traject, vanaf de eerste oriëntatie tot aan de uiteindelijke plaatsing. Sportbeurs Amerika NL biedt onder andere de volgende diensten aan:
                </MKTypography>

                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
Een persoonlijke intakegesprek om de wensen, mogelijkheden en verwachtingen van de student-atleet te bespreken.
</li>
<li>
Een professionele evaluatie van het sportieve en academische niveau van de student-atleet.
</li>
<li>
Een uitgebreid netwerk van contacten met Amerikaanse coaches, universiteiten en colleges.
</li>
<li>
Een effectieve promotie van het profiel en de video’s van de student-atleet bij potentiële coaches.
</li>
<li>
Een deskundig advies over de beste opties voor de student-atleet op basis van zijn of haar voorkeuren, doelen en budget.
</li>
<li>
Een onderhandeling over de beste beursaanbiedingen namens de student-atleet.
</li>
<li>
Een hulp bij het aanvragen van de benodigde documenten, zoals het visum, het transcript, het diploma en de toelatingstesten.
</li>
<li>
Een voorbereiding op het leven in Amerika, zoals de cultuur, de taal, de regels en de tips.
</li>
<li>
Een nazorg en een follow-up tijdens het verblijf in Amerika.</li>

</ul>
</MKTypography>

<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Wil jij ook je passie voor American football combineren met een goede opleiding in Amerika? <Link to="/Diensten-Producten">Bekijk het volledige aanbod</Link> van Sportbeurs Amerika NL en maak een vrijblijvende afspraak om je te laten informeren over de mogelijkheden. 
      Ik sta voor je klaar om je te helpen bij het realiseren van je Amerikaanse droom.
    </MKTypography>


              </MKBox>
            </MKBox>

          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;
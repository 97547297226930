/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
import Image1 from "assets/images/Sporten_Images/Ijshockey1.jpeg";
import Image2 from "assets/images/Sporten_Images/Ijshockey2.jpg";
import Image3 from "assets/images/Sporten_Images/ijshockey3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";



// Images

import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'IJshockeybeurs USA: Hoe je kunt studeren en ijshockeyen in Amerika', isSubheader: false },

    { id: 'Hoofdstuk 1', title: '1. De Geschiedenis en Ontwikkeling van IJshockey in Amerika', isSubheader: false },
    { id: '1.1', title: '1.1 Vroege Jaren en de Rol van Colleges', isSubheader: true },
    { id: '1.2', title: '1.2 Groei en Professionalisering', isSubheader: true },

    { id: 'Hoofdstuk 2', title: '2. Ijshockey op Collegenniveau Vandaag de Dag', isSubheader: false },
    { id: '2.1', title: '2.1 Belangrijke Competities en Conferenties', isSubheader: true },
    { id: '2.2', title: '2.2 Topprogramma’s en Prestigieuze Teams', isSubheader: true },

    { id: 'Hoofdstuk 3', title: '3: Van College naar de Profs', isSubheader: false },
    { id: '3.1', title: '3.1 Het Pad naar Professionalisme', isSubheader: true },
    { id: '3.2', title: '3.2 De NHL Draft en Andere Mogelijkheden', isSubheader: true },


    { id: 'Hoofdstuk 4', title: '4. Internationale Invloeden', isSubheader: false },
    { id: '4.1', title: '4.1 Buitenlandse Spelers in het College IJshockey', isSubheader: true },

    { id: 'Hoofdstuk 5', title: '5. IJshockeybeurzen en de Toekomst', isSubheader: false },
    { id: '5.1', title: '5.1 Het Belang van Beurzen', isSubheader: true },
    { id: '5.2', title: '5.2 Hoe Sportbeurs Amerika NL Kan Helpen', isSubheader: true },

  ];

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };


  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Inhoud
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Verberg</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Toon</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
            <MKBox id="intro" mb={3}>
            <MKTypography variant="h1" className="h1-as-h3" mb={2}>
              IJshockeybeurs USA: Hoe je kunt studeren en ijshockeyen in Amerika
              </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
  <img
    src={Image1}
    alt="Luchtfoto van een volgepakt stadion bij een college ijshockeywedstrijd."
    style={{
      maxWidth: "100%",
      border: "2px solid #fff",
      boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
      cursor: "pointer",
      outline: "none",
    }}
    onClick={() => openModal1(Image1)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
  Een bomvol stadion tijdens een college wedstrijd
</figcaption>
</figure>
              IJshockey is een van de populairste sporten in Amerika. Het is een snelle, fysieke en spannende sport die miljoenen fans trekt. IJshockey wordt gespeeld op verschillende niveaus, van amateur tot professioneel. Maar een van de belangrijkste en meest prestigieuze niveaus is het college ijshockey.
<br/>
<br/>
College ijshockey is het ijshockey dat gespeeld wordt door studenten van universiteiten en hogescholen in Amerika. Het is een onderdeel van het Amerikaanse onderwijssysteem, waarbij sport en studie gecombineerd worden. College ijshockey biedt jonge spelers de kans om zich te ontwikkelen op zowel academisch als atletisch gebied, terwijl ze ook genieten van het studentenleven.
<br/>
<br/>
College ijshockey is ook een springplank naar de professionele wereld. Veel spelers die in de NHL (National Hockey League) of andere professionele competities spelen, hebben eerst college ijshockey gespeeld. College ijshockey is dus een uitstekende manier om je voor te bereiden op een carrière in de sport.
<br/>
<br/>
Maar hoe kom je als jonge ijshockeyspeler in aanmerking voor college ijshockey? Dat is waar Sportbeursamerika.nl je kan helpen. Ik ben gespecialiseerd in het begeleiden van student-atleten die een sportbeurs willen krijgen in Amerika. Een sportbeurs is een financiële vergoeding die je krijgt van een universiteit of hogeschool om daar te studeren en te sporten. Met een sportbeurs kun je dus studeren en ijshockeyen in Amerika zonder hoge kosten te maken.
<br/>
<br/>
In dit artikel geven we je meer informatie over college ijshockey in Amerika, de geschiedenis, de ontwikkeling, de competities, de teams, de kansen, de internationale invloeden, en natuurlijk de beurzen. Ik vertel je ook hoe ik je kan helpen om jouw droom waar te maken. Ben je klaar om meer te weten te komen over ijshockeybeurs USA? Lees dan snel verder!
              </MKTypography>
              {/* Add bulleted list or other content as needed */}
            </MKBox>
             {/* Main chapters and subchapters with placeholder text */}
            <MKBox id="Hoofdstuk 1" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 1: De Geschiedenis en Ontwikkeling van IJshockey in Amerika
              </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              IJshockey is een sport die al meer dan een eeuw bestaat in Amerika. Het heeft een rijke en boeiende geschiedenis, die nauw verbonden is met de rol van colleges. In dit hoofdstuk gaan we dieper in op de oorsprong, de groei en de professionalisering van ijshockey in Amerika.
              </MKTypography>

              <MKBox id="1.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                1.1 Vroege Jaren en de Rol van Colleges
                </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                IJshockey is ontstaan in Canada, waar het voor het eerst gespeeld werd in de 19e eeuw. Het spel verspreidde zich al snel naar de Verenigde Staten, waar het vooral populair werd in de noordoostelijke staten. De eerste universiteitsteams werden gevormd in de late 19e en vroege 20e eeuw, zoals Yale, Harvard, Princeton en Dartmouth.
<br/>
<br/>
Deze teams speelden onderlinge wedstrijden, die vaak veel toeschouwers trokken. Ze speelden ook tegen Canadese teams, die meestal sterker waren. Om het niveau te verhogen, begonnen sommige universiteiten professionele spelers aan te trekken, die zich inschreven als studenten. Dit leidde tot controverse en kritiek, omdat het de amateurstatus van de sport aantastte.
<br/>
<br/>
Om een einde te maken aan deze praktijk, werd in 1898 de Intercollegiate Hockey Association (IHA) opgericht, de eerste collectie van college ijshockeyprogramma's in Amerika. De IHA, die soms ook werd aangeduid als de Intercollegiate Hockey League, omvatte teams zoals Brown, Columbia, University of Pennsylvania, en Yale. Deze organisatie was de enige kampioenschapsinstantie voor college ijshockey op dat moment en bepaalde de de facto nationale kampioen op basis van seizoensprestaties. De IHA bleef bestaan tot 1913.

<br/>
<br/>
Na de ontbinding van de IHA werd in 1912 de Intercollegiate Hockey League (IHL) opgericht door Harvard, Princeton en Yale. Deze league was een reactie op de behoefte van topcollegeteams om onderling te blijven concurreren. De IHL bestond tot 1917, toen de activiteiten werden opgeschort vanwege de Eerste Wereldoorlog.

                </MKTypography>
              </MKBox>
              <MKBox id="1.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                1.2 Groei en Professionalisering
                </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                In de loop van de 20e eeuw groeide college ijshockey uit tot een nationale sport, die gespeeld werd door honderden teams in verschillende divisies en conferenties. College ijshockey werd ook steeds meer beïnvloed door de opkomst van professionele ijshockeycompetities, zoals de NHL (opgericht in 1917) en de World Hockey Association (opgericht in 1972).
<br/>
<br/>
Deze professionele competities boden kansen voor getalenteerde college spelers om door te stromen naar een hoger niveau. Veel college spelers werden geselecteerd in de NHL Draft, het jaarlijkse evenement waarbij NHL teams nieuwe spelers kiezen. De eerste NHL draft waarin een college speler werd geselecteerd, vond plaats in 1967. Al Karlander, een speler van Michigan Tech, werd dat jaar door Detroit geselecteerd als de 17e keuze in totaal. Sindsdien zijn er meer dan duizend college spelers gedraft door NHL teams.
<br/>
<br/>
College ijshockey heeft ook belangrijke momenten gekend in de geschiedenis van de sport. Een van de meest memorabele was het “Miracle on Ice” in 1980, toen het Amerikaanse Olympische team, bestaande uit voornamelijk college spelers, de Sovjet-Unie versloeg in een historische wedstrijd tijdens de Winterspelen in Lake Placid. Dit was een enorme prestatie, aangezien de Sovjets toen beschouwd werden als het beste ijshockeyteam ter wereld.
<br/>
<br/>
College ijshockey heeft dus een lange en indrukwekkende geschiedenis, die getuigt van de passie en het talent van Amerikaanse spelers. College ijshockey heeft ook bijgedragen aan de ontwikkeling en innovatie van de sport, door nieuwe regels, tactieken en stijlen te introduceren.

                </MKTypography>
              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 2" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 2: IJshockey op Collegenniveau Vandaag de Dag
                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              College ijshockey is vandaag de dag een van de meest competitieve en spannende sporten in Amerika. Het wordt gespeeld door meer dan 400 teams, verdeeld over drie NCAA divisies, verschillende conferenties en regionale zones. In dit hoofdstuk geven we je een overzicht van de belangrijkste competities en conferenties, de topprogramma’s en prestigieuze teams, en de structuur van het seizoen en het kampioenschap.
              </MKTypography>

              <MKBox id="2.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                2.1 Belangrijke Competities en Conferenties
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
  <img
    src={Image2}
    alt="Spelers van Massachusetts vieren een overwinning in college ijshockey."
    style={{
      maxWidth: "100%",
      border: "2px solid #fff",
      boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
      cursor: "pointer",
      outline: "none",
    }}
    onClick={() => openModal2(Image2)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
  Spelers van Massachusetts vieren een overwinning
</figcaption>
</figure>
                De NCAA (National Collegiate Athletic Association) is de organisatie die het college ijshockey reguleert. De NCAA verdeelt de teams in drie divisies, gebaseerd op het niveau, het budget en het aantal beurzen dat ze aanbieden. De divisies zijn:
                </MKTypography>
                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
Divisie I: Dit is de hoogste divisie, waar de beste teams en spelers spelen. Er zijn 60 teams in Divisie I, verdeeld over zes conferenties: Atlantic Hockey, Big Ten, ECAC Hockey, Hockey East, NCHC (National Collegiate Hockey Conference) en WCHA (Western Collegiate Hockey Association). Deze conferenties organiseren hun eigen competities en toernooien gedurende het seizoen.
</li>
<li>
Divisie II: Dit is een tussenliggende divisie, waar minder teams en spelers spelen. Er zijn slechts zeven teams in Divisie II, die geen eigen conferentie hebben. Ze spelen meestal tegen teams uit Divisie III of andere competities.

</li>
<li>
Divisie III: Dit is de laagste divisie, waar de meeste teams en spelers spelen. Er zijn 84 teams in Divisie III, verdeeld over zeven conferenties: CCC (Commonwealth Coast Conference), MASCAC (Massachusetts State Collegiate Athletic Conference), MIAC (Minnesota Intercollegiate Athletic Conference), NCHA (Northern Collegiate Hockey Association), NEHC (New England Hockey Conference), SUNYAC (State University of New York Athletic Conference) en UCHC (United Collegiate Hockey Conference). Deze conferenties organiseren ook hun eigen competities en toernooien gedurende het seizoen.
</li>
</ul>
</MKTypography>
<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
Naast de NCAA divisies en conferenties, zijn er ook andere competities en organisaties die college ijshockey aanbieden.                
</MKTypography>


              </MKBox>
              <MKBox id="2.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                2.2 Topprogramma's en Prestigieuze Teams
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                College ijshockey heeft een aantal topprogramma's en prestigieuze teams, die zich onderscheiden door hun succes, traditie, reputatie en invloed. Sommige van deze programma’s en teams zijn:
                </MKTypography>
                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
University of North Dakota: Dit is een van de meest succesvolle college ijshockeyprogramma’s in Amerika, spelend in de NCHC conferentie in Divisie I. De universiteit heeft meerdere NCAA kampioenschappen gewonnen. Het programma heeft talrijke spelers voortgebracht die in de NHL hebben gespeeld, waaronder bekende namen zoals Jonathan Toews, Zach Parise en T.J. Oshie.</li>
<li>
Boston College: Een prominent college ijshockeyprogramma in Amerika, spelend in de Hockey East conferentie in Divisie I. Boston College heeft verscheidene nationale kampioenschappen op zijn naam staan. Het programma is ook bekend voor het opleiden van meer dan 80 NHL-spelers, met bekende alumni zoals Johnny Gaudreau, Patrick Eaves en Brian Gionta.
</li>
<li>
University of Minnesota: Dit historische college ijshockeyprogramma, uitkomend in de Big Ten conferentie in Divisie I, heeft een indrukwekkend aantal nationale kampioenschappen behaald. Het staat bekend om het voortbrengen van een recordaantal spelers die in de NHL hebben gespeeld, inclusief sterren zoals Phil Kessel, Paul Martin en Blake Wheeler.

</li>
<li>

University of Wisconsin: Een van de meest prestigieuze college ijshockeyprogramma’s in Amerika, spelend in de Big Ten conferentie in Divisie I. Wisconsin heeft meerdere nationale kampioenschappen gewonnen. Het programma heeft ook een aanzienlijk aantal spelers opgeleid die in de NHL hebben gespeeld, waaronder Ryan Suter, Joe Pavelski en Dany Heatley.

</li>

</ul>
</MKTypography>
<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
Deze programma’s en teams zijn slechts enkele voorbeelden van de vele die college ijshockey rijk is. Er zijn nog veel meer teams die hun eigen verhaal, cultuur en identiteit hebben. College ijshockey is dus een diverse en dynamische sport, die veel te bieden heeft aan spelers en fans.</MKTypography>
              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 3" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 3: Van College naar de Profs
                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              College ijshockey is niet alleen een sport, maar ook een leerschool. Veel spelers die college ijshockey spelen, hebben de ambitie om door te groeien naar de professionele wereld. In dit hoofdstuk bespreken we hoe college ijshockey als een springplank naar de NHL of andere professionele competities kan dienen, welke alumni het gemaakt hebben in de professionele ijshockeywereld, en welke mogelijkheden er zijn buiten de NHL.
              </MKTypography>

              <MKBox id="3.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                3.1 Het Pad naar Professionalisme                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                College ijshockey is een uitstekende manier om je voor te bereiden op een carrière in de sport. Door college ijshockey te spelen, kun je je vaardigheden, kennis en ervaring verbeteren, terwijl je ook een diploma behaalt. College ijshockey biedt je ook de kans om je te meten met andere getalenteerde spelers, die je uitdagen en inspireren.
<br/>
<br/>
Een van de belangrijkste voordelen van college ijshockey is dat het je zichtbaarheid en exposure vergroot. Door college ijshockey te spelen, kun je de aandacht trekken van scouts, coaches en managers van professionele teams, die op zoek zijn naar nieuwe spelers. Je kunt ook deelnemen aan showcases, camps en clinics, waar je jezelf kunt laten zien aan potentiële werkgevers.
<br/>
<br/>
Een ander voordeel van college ijshockey is dat het je flexibiliteit en opties vergroot. Door college ijshockey te spelen, kun je langer wachten met het maken van een beslissing over je toekomst. Je kunt ervoor kiezen om na je eerste, tweede, derde of vierde jaar over te stappen naar een professioneel team, afhankelijk van je situatie en voorkeur. Je kunt ook ervoor kiezen om je studie af te maken en daarna pas professioneel te gaan spelen.
<br/>
<br/>
College ijshockey heeft veel spelers voortgebracht die het gemaakt hebben in de professionele ijshockeywereld. Sommige van deze spelers zijn:
                </MKTypography>
                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
Jack Eichel: Hij speelde één seizoen voor Boston University in de Hockey East conferentie in Divisie I. Hij won de Hobey Baker Award als beste speler van het jaar in 2015. Hij werd als tweede gekozen in de NHL Draft van 2015 door de Buffalo Sabres, waar hij de aanvoerder en sterspeler werd. In 2023 won hij de Stanley Cup met de Vegas Golden Knight 
</li>
<li>
Cale Makar: Hij speelde twee seizoenen voor UMass Amherst in de Hockey East conferentie in Divisie I. Hij won ook de Hobey Baker Award als beste speler van het jaar in 2019. Hij werd als vierde gekozen in de NHL Draft van 2017 door de Colorado Avalanche, waar hij nu speelt als een van de beste verdedigers in de league.

</li>
<li>
Hilary Knight: Hilary Knight speelde vier seizoenen voor de University of Wisconsin in de WCHA conferentie in Divisie I. Ze was een zeer getalenteerde speler en werd drie keer genomineerd als top-10 finalist voor de Patty Kazmaier Memorial Award in 2009, 2011 en 2012. Hoewel ze de award niet won, was ze wel een twee keer benoemde First Team All-American. Knight speelde later voor de Boston Pride in de NWHL (National Women’s Hockey League) en het Amerikaanse nationale team, waarmee ze twee Olympische zilveren medailles en meerdere wereldkampioenschappen heeft gewonnen
</li>
</ul>
</MKTypography>
<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
Deze spelers zijn slechts enkele voorbeelden van de vele die hun college ijshockeycarrière hebben omgezet in een professionele carrière. College ijshockey is dus een bewezen pad naar professionalisme voor veel spelers.
</MKTypography>
              </MKBox>
              <MKBox id="3.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                3.2 De NHL Draft en Andere Mogelijkheden
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                De NHL (National Hockey League) is de belangrijkste professionele ijshockeycompetitie ter wereld. Het is de droom van veel jonge spelers om ooit in de NHL te spelen. Een van de manieren om dat te bereiken is via de NHL Draft, het jaarlijkse evenement waarbij NHL teams nieuwe spelers kiezen.
<br/>
<br/>
De NHL Draft is een spannend en belangrijk moment voor veel college spelers. Het is een kans om gekozen te worden door een NHL team, dat je een contract aanbiedt om voor hen te spelen. Het is ook een erkenning van je talent, potentieel en harde werk.
<br/>
<br/>
De NHL Draft bestaat uit zeven rondes, waarin elk NHL team om de beurt een speler kiest. De volgorde van de keuze wordt bepaald door een loterij, waarbij de teams met de slechtste resultaten in het vorige seizoen meer kans hebben om als eerste te kiezen. De NHL Draft vindt meestal plaats in juni of juli, na het einde van het college ijshockeyseizoen.
<br/>
<br/>
Om in aanmerking te komen voor de NHL Draft, moet je aan bepaalde criteria voldoen. Je moet een Noord-Amerikaanse speler zijn die tussen de 18 en 20 jaar oud is, of een internationale speler die tussen de 18 en 21 jaar oud is. Je moet ook niet eerder gedraft zijn door een NHL team, of je rechten hebben afgestaan.
<br/>
<br/>
Als je gedraft wordt door een NHL team, betekent dat niet dat je meteen voor hen gaat spelen. Je kunt ervoor kiezen om terug te gaan naar je college team en je studie af te maken, terwijl je nog steeds de rechten van het NHL team behoudt. Je kunt ook ervoor kiezen om je aan te sluiten bij het NHL team of hun minor league affiliate, waar je verder kunt ontwikkelen en trainen.
<br/>
<br/>
De NHL Draft is echter niet de enige manier om in de NHL te komen. Er zijn ook andere mogelijkheden, zoals Free agency. Dit is wanneer je als speler geen contract hebt met een NHL team, en dus vrij bent om te onderhandelen met elk team dat je wil. Dit kan gebeuren als je niet gedraft bent, of als je contract met een NHL team afloopt of wordt beëindigd. Als free agent kun je proberen om een contract te krijgen bij een NHL team dat interesse in je heeft.
<br/>
<br/>
De NHL is echter niet de enige professionele ijshockeycompetitie ter wereld. Er zijn ook andere competities, waar je als speler kunt spelen en geld kunt verdienen. Sommige van deze competities zijn:

                </MKTypography>
                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
KHL (Kontinental Hockey League): Dit is de belangrijkste professionele ijshockeycompetitie in Europa en Azië. Het bestaat uit 24 teams uit acht landen: Rusland, Finland, Wit-Rusland, Kazachstan, China, Letland, Slowakije en Tsjechië. Het heeft een hoog niveau van spel en salaris, en trekt veel spelers uit verschillende landen aan.
</li>
<li>
SHL (Swedish Hockey League): Dit is de belangrijkste professionele ijshockeycompetitie in Zweden. Het bestaat uit 14 teams die strijden om het nationale kampioenschap. Het staat bekend om zijn snelle en technische spel, en heeft veel spelers voortgebracht die in de NHL hebben gespeeld.

</li>
<li>
Liiga (Finnish Hockey League): Dit is de belangrijkste professionele ijshockeycompetitie in Finland. Het bestaat uit 15 teams die strijden om het nationale kampioenschap. Het staat bekend om zijn fysieke en tactische spel, en heeft ook veel spelers voortgebracht die in de NHL hebben gespeeld.
</li>
</ul>
</MKTypography>
<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
Deze competities zijn slechts enkele voorbeelden van de vele die er zijn in de wereld. Er zijn nog veel meer competities in andere landen, zoals Zwitserland, Duitsland, Frankrijk, Noorwegen, Denemarken, Oostenrijk, Polen, Japan en Australië. Deze competities bieden verschillende niveaus van spel, salaris en levensstijl aan spelers.
<br/>
<br/>
College ijshockey is dus niet het einde van de weg voor veel spelers. Het is eerder het begin van een spannende reis naar de professionele wereld. College ijshockey biedt veel mogelijkheden en opties aan spelers die hun droom willen najagen.

</MKTypography>
                
              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 4" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 4: Internationale Invloeden
                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              College ijshockey is een sport die niet alleen Amerikaans is, maar ook internationaal. College ijshockey wordt beïnvloed door de aanwezigheid en de prestaties van buitenlandse spelers in de Amerikaanse college competities. College ijshockey heeft ook invloed op de kansen en de ontwikkeling van Amerikaanse spelers in het buitenland. In dit hoofdstuk bespreken we de rol en de impact van internationale spelers in het college ijshockey, en de mogelijkheden voor Amerikaanse spelers om internationaal te spelen.
              </MKTypography>

              <MKBox id="4.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                4.1 Buitenlandse Spelers in het College IJshockey
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
  <img
    src={Image3}
    alt="Quinnipiac ijshockeyspelers vieren een doelpunt."
    style={{
      maxWidth: "100%",
      border: "2px solid #fff",
      boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
      cursor: "pointer",
      outline: "none",
    }}
    onClick={() => openModal3(Image3)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
  Speelsters van Quinnipiac vieren een doelpunt
</figcaption>
</figure>
                College ijshockey is een sport die openstaat voor spelers van over de hele wereld. Er zijn veel buitenlandse spelers die naar Amerika komen om college ijshockey te spelen, met verschillende achtergronden, culturen en nationaliteiten. Sommige van deze spelers zijn:
                </MKTypography>
                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>

<li>
Daryl Watts: Zij is een Canadese ijshockeyspeelster die twee seizoenen speelde voor zowel Boston College als Wisconsin in de NCAA Divisie I. Ze won de Patty Kazmaier Award in 2018 als eerstejaarsstudente, wat haar de eerste freshman maakte die de prijs won. Watts ging vervolgens spelen voor de Toronto Six in de Premier Hockey Federation (PHF), voorheen bekend als de National Women’s Hockey League (NWHL), en ze tekende in 2023 een recordcontract met de Toronto Six.
</li>
<li>
Rod Brind'Amour: Geboren in Canada, speelde college-ijshockey bij Michigan State University voordat hij naar de NHL ging. Hij had een zeer succesvolle professionele carrière, met hoogtepunten zoals het winnen van de Stanley Cup.</li>

</ul>
</MKTypography>
<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
Deze spelers zijn slechts enkele voorbeelden van de vele buitenlandse spelers die college ijshockey spelen of hebben gespeeld. Er zijn nog veel meer spelers uit andere landen, zoals Zweden, Rusland, Tsjechië, Duitsland, Frankrijk, Zwitserland, Denemarken, Japan en Australië.
<br/>
<br/>
Deze buitenlandse spelers hebben een belangrijke rol en impact in het college ijshockey. Ze brengen hun eigen stijl, vaardigheden en ervaring mee naar de Amerikaanse college competities. Ze verrijken het niveau en de diversiteit van het spel. Ze creëren ook meer interesse en aandacht voor het college ijshockey in hun eigen landen en regio’s.
<br/>
<br/>
College ijshockey is dus een sport die profiteert van de internationale invloeden. Het is een sport die spelers van over de hele wereld verwelkomt en waardeert.

</MKTypography>


            
              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 5" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 5: IJshockeybeurzen en de Toekomst
                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              Als je een passie hebt voor ijshockey en je wilt je talenten verder ontwikkelen, dan is een ijshockeybeurs in Amerika misschien wel iets voor jou. Een ijshockeybeurs is een financiële ondersteuning die je krijgt van een college om daar te studeren en te spelen. Met een ijshockeybeurs kun je niet alleen je sportieve dromen waarmaken, maar ook je academische doelen bereiken. In dit hoofdstuk zullen we het belang van ijshockeybeurzen bespreken en hoe ik je kan helpen om er een te bemachtigen.
              </MKTypography>

              <MKBox id="5.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                5.1 Het Belang van Beurzen                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                IJshockey is een populaire en competitieve sport in Amerika, waar veel talentvolle spelers strijden om een plekje in de college teams. Een ijshockeybeurs is een geweldige kans om jezelf te onderscheiden van de rest en om te laten zien wat je in huis hebt. Een ijshockeybeurs geeft je namelijk de volgende voordelen:
                </MKTypography>
                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
Je kunt studeren aan een gerenommeerde universiteit in Amerika, waar je een diploma kunt behalen dat internationaal erkend wordt.
</li>
<li>
Je kunt trainen en spelen onder professionele begeleiding, waar je je vaardigheden en techniek kunt verbeteren.

</li>
<li>
Je kunt deelnemen aan spannende wedstrijden en toernooien, waar je je kunt meten met de beste spelers van het land.
</li>
<li>
Je kunt genieten van het college leven, waar je nieuwe vrienden kunt maken en deel kunt uitmaken van een hechte gemeenschap.
</li>
<li>
Je kunt profiteren van de faciliteiten en voorzieningen die de colleges bieden, zoals moderne accommodaties, sportcentra, bibliotheken, cafetaria’s, etc.
</li>
</ul>
</MKTypography>
<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
Een ijshockeybeurs is dus een unieke kans om je persoonlijke en professionele ontwikkeling te stimuleren. Bovendien kan een ijshockeybeurs ook de weg openen naar een carrière in de professionele ijshockeywereld, zoals we in het vorige hoofdstuk hebben gezien.

</MKTypography>


              </MKBox>
              <MKBox id="5.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                5.2 Hoe Sportbeurs Amerika NL Kan Helpen 
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
  <img
    src={Image4}
    alt="Logo van Sportbeurs Amerika NL, bestaande uit een voetbal met het continent Noord-Amerika erop afgebeeld, met daarboven een afstudeerhoed (graduation cap) die bovenop de bal rust alsof het een hoofd is. De naam van het bedrijf is prominent weergegeven, wat de focus op sportbeurzen in Amerika voor Europese studenten benadrukt."
    style={{
      maxWidth: "100%",
      height: "auto",
      border: "2px solid #fff",
      boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)", // Apply shadow around the entire image
      cursor: "pointer",
      outline: "none",
      borderRadius: "50%", // Set border-radius to 50% for a circular image
      marginTop: "0px", // Add top margin to move the image up


    }}
    onClick={() => openModal4(Image4)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
  
</figcaption>
</figure>
Sportbeurs Amerika NL is een organisatie gespecialiseerd in het begeleiden van jonge sporttalenten naar sportbeurzen in Amerika.  Met een gepassioneerde en ervaren expert aan het roer, ondersteunt Sportbeurs Amerika NL de student-atleet bij iedere stap in het traject, vanaf de eerste oriëntatie tot aan de uiteindelijke plaatsing. Sportbeurs Amerika NL biedt onder andere de volgende diensten aan:
                </MKTypography>

                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
Een persoonlijke intakegesprek om de wensen, mogelijkheden en verwachtingen van de student-atleet te bespreken.
</li>
<li>
Een professionele evaluatie van het sportieve en academische niveau van de student-atleet.
</li>
<li>
Een uitgebreid netwerk van contacten met Amerikaanse coaches, universiteiten en colleges.
</li>
<li>
Een effectieve promotie van het profiel en de video’s van de student-atleet bij potentiële coaches.
</li>
<li>
Een deskundig advies over de beste opties voor de student-atleet op basis van zijn of haar voorkeuren, doelen en budget.
</li>
<li>
Een onderhandeling over de beste beursaanbiedingen namens de student-atleet.
</li>
<li>
Een hulp bij het aanvragen van de benodigde documenten, zoals het visum, het transcript, het diploma en de toelatingstesten.
</li>
<li>
Een voorbereiding op het leven in Amerika, zoals de cultuur, de taal, de regels en de tips.
</li>
<li>
Een nazorg en een follow-up tijdens het verblijf in Amerika.</li>

</ul>
</MKTypography>

<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Wil jij ook je passie voor American football combineren met een goede opleiding in Amerika? <Link to="/Diensten-Producten">Bekijk het volledige aanbod</Link> van Sportbeurs Amerika NL en maak een vrijblijvende afspraak om je te laten informeren over de mogelijkheden. 
      Ik sta voor je klaar om je te helpen bij het realiseren van je Amerikaanse droom.
    </MKTypography>


              </MKBox>
            </MKBox>

          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;
/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";



// Images
import Image1 from "assets/images/Sporten_Images/Voetbal1.jpg";
import Image2 from "assets/images/Sporten_Images/Voetbal2.jpg";
import Image3 from "assets/images/Sporten_Images/Voetbal3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import "../../../../H1asH3.css"


// Images

import { Link } from 'react-router-dom';


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Voetballen en studeren in Amerika: een droom die werkelijkheid kan worden', isSubheader: false },

    { id: 'Hoofdstuk 1', title: '1. De Geschiedenis en Ontwikkeling van Voetbal in Amerika', isSubheader: false },
    { id: '1.1', title: '1.1 Vroege Jaren en de Rol van Colleges', isSubheader: true },
    { id: '1.2', title: '1.2 Groei en Professionalisering', isSubheader: true },

    { id: 'Hoofdstuk 2', title: '2. Voetbal op Collegenniveau Vandaag de Dag', isSubheader: false },
    { id: '2.1', title: '2.1 Belangrijke Competities en Conferenties voor voetbal', isSubheader: true },
    { id: '2.2', title: '2.2 Topprogramma’s en Prestigieuze Teams voor voetbal', isSubheader: true },

    { id: 'Hoofdstuk 3', title: '3: Van College naar de Profs', isSubheader: false },
    { id: '3.1', title: '3.1 Het Pad naar Professionalisme', isSubheader: true },

    { id: 'Hoofdstuk 4', title: '4. De Invloed van Internationaal Voetbal', isSubheader: false },
    { id: '4.1', title: '4.1 Buitenlandse Invloeden', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikanen in het Buitenland', isSubheader: true },

    { id: 'Hoofdstuk 5', title: '5. Voetbalbeurzen en de Toekomst', isSubheader: false },
    { id: '5.1', title: '5.1 Het Belang van Beurzen', isSubheader: true },
    { id: '5.2', title: '5.2 Hoe Sportbeurs Amerika NL Kan Helpen', isSubheader: true },

  ];

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Inhoud
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Verberg</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Toon</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
            <MKBox id="intro" mb={3}>
            <MKTypography variant="h1" className="h1-as-h3" mb={2}>
              Voetballen en studeren in Amerika: een droom die werkelijkheid kan worden
              </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
  <img
    src={Image1}
    alt="Creighton's stadion, met ruimte voor meer dan 7000 toeschouwers, tijdens een college voetbalwedstrijd."
    style={{
      maxWidth: "100%",
      border: "2px solid #fff",
      boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
      cursor: "pointer",
      outline: "none",
    }}
    onClick={() => openModal1(Image1)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
Creighton stadion: thuisbasis voor meer dan 7000 voetbalfans.
</figcaption>
</figure>
              Voetbal is een van de populairste sporten ter wereld, maar in Amerika heeft het lang in de schaduw gestaan van andere sporten zoals basketbal, honkbal en American football. Toch is voetbal in de afgelopen decennia enorm gegroeid in populariteit en kwaliteit in de VS, mede dankzij het succes van het nationale team, de opkomst van de Major League Soccer (MLS) en de invloed van internationale sterren.
<br/>
<br/>
Maar wist je dat voetbal in Amerika ook een unieke kans biedt voor jonge talenten om hun droom na te jagen? Door te voetballen en studeren in Amerika kun je namelijk niet alleen je sportieve vaardigheden ontwikkelen, maar ook je academische diploma behalen en een onvergetelijke ervaring opdoen. Dit alles is mogelijk dankzij de voetbalbeurzen die Amerikaanse universiteiten aanbieden aan getalenteerde spelers.
<br/>
<br/>
In dit artikel vertellen we je alles wat je moet weten over voetballen en studeren in Amerika, van de geschiedenis en ontwikkeling van voetbal in de VS tot de voordelen en mogelijkheden van een voetbalbeurs. Ook leggen we uit hoe Sportbeursamerika.nl je kan helpen om deze droom werkelijkheid te laten worden. Lees dus snel verder en ontdek hoe jij kunt profiteren van deze unieke kans!
 
              </MKTypography>
              {/* Add bulleted list or other content as needed */}
            </MKBox>
             {/* Main chapters and subchapters with placeholder text */}
            <MKBox id="Hoofdstuk 1" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 1: De Geschiedenis en Ontwikkeling van Voetbal in Amerika
              </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              Voetbal, of soccer zoals de Amerikanen het noemen, is niet zo’n nieuwe sport in de VS als je misschien denkt. Sterker nog, de eerste officiële voetbalwedstrijd in Amerika vond al plaats in 1869, tussen twee universiteitsteams: Princeton en Rutgers. Sindsdien heeft voetbal een lange en boeiende geschiedenis gekend in de VS, die we in dit hoofdstuk zullen bespreken.
              </MKTypography>

              <MKBox id="1.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                1.1 Vroege Jaren en de Rol van Colleges
                </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                In de late 19e en vroege 20e eeuw was voetbal vooral populair onder studenten, die het spel speelden volgens verschillende regels en formaten. Zo waren er bijvoorbeeld teams die met 11, 15 of zelfs 25 spelers speelden, en die soms ook elementen van rugby of American football gebruikten.
<br/>
<br/>
Om meer eenheid en structuur te creëren, werd in 1905 de Intercollegiate Association of Amateur Athletes of America (IAAAA) opgericht, de voorloper van de National Collegiate Athletic Association (NCAA). De NCAA is de belangrijkste organisatie die het collegevoetbal reguleert en organiseert in de VS.
<br/>
<br/>
In de eerste helft van de 20e eeuw groeide het aantal collegevoetbalteams gestaag, en ontstonden er ook regionale competities en kampioenschappen. Een van de bekendste daarvan is de College Cup, die sinds 1959 wordt gehouden als het nationale kampioenschap voor mannen. Voor vrouwen werd de eerste College Cup pas in 1982 gehouden, maar sindsdien heeft het vrouwenvoetbal een enorme vlucht genomen in de VS, mede dankzij het succes van het nationale team op internationale toernooien.
                </MKTypography>
              </MKBox>
              <MKBox id="1.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                1.2 Groei en Professionalisering
                </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Naast het collegevoetbal ontwikkelde zich ook het professionele voetbal in de VS, zij het met meer ups en downs. De eerste professionele competitie was de American Soccer League (ASL), die werd opgericht in 1921 en vooral bestond uit teams uit het noordoosten van het land. De ASL kende een aantal succesvolle jaren, maar raakte in conflict met de Amerikaanse voetbalbond over de organisatie en regelgeving van het spel. Dit leidde tot een splitsing en uiteindelijk tot het einde van de ASL in 1933.
<br/>
<br/>
Na de Tweede Wereldoorlog probeerden verschillende competities het professionele voetbal nieuw leven in te blazen, maar geen van hen wist lang te overleven. Pas in 1967 werd er weer een stabiele competitie opgericht: de North American Soccer League (NASL). De NASL bracht een aantal grote namen naar Amerika, zoals Pelé, Franz Beckenbauer en Johan Cruijff, en trok veel publiek naar de stadions. Maar ook de NASL kon niet standhouden tegen de financiële problemen, de concurrentie van andere sporten en de gebrekkige belangstelling van de media. In 1984 hield de NASL op te bestaan.
<br/>
<br/>
Het duurde tot 1996 voordat er weer een professionele competitie werd opgericht: de Major League Soccer (MLS). De MLS is tot op heden de hoogste divisie van het Amerikaanse voetbal, en bestaat uit 27 teams uit zowel de VS als Canada. De MLS heeft zich ontwikkeld tot een competitieve en aantrekkelijke competitie, die zowel binnenlandse als buitenlandse spelers aantrekt. Ook heeft de MLS bijgedragen aan de popularisering van voetbal in Amerika, door onder meer stadions te bouwen, jeugdopleidingen te ondersteunen en samen te werken met andere competities.
<br/>
<br/>
Het professionele voetbal heeft ook invloed gehad op het collegevoetbal, en vice versa. Zo zijn er bijvoorbeeld spelers die na hun collegecarrière overstappen naar de MLS, of spelers die vanuit de MLS teruggaan naar de universiteit om hun studie af te maken. Ook zijn er spelers die zowel voor hun college als voor een professioneel team uitkomen, dankzij speciale regelingen. Daarnaast zijn er ook professionele teams die samenwerken met collegeprogramma’s, om zo talenten te scouten en te ontwikkelen.
                </MKTypography>
              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 2" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 2: Voetbal op Collegenniveau Vandaag de Dag
                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              Zoals we in het vorige hoofdstuk hebben gezien, heeft voetbal op collegenniveau een lange en rijke traditie in Amerika. Maar hoe ziet het er nu uit? Welke competities en conferenties zijn er? Welke programma’s en teams zijn het meest prestigieus en succesvol? En hoe kun je als speler deelnemen aan deze spannende wereld? In dit hoofdstuk geven we je een overzicht van het voetbal op collegenniveau vandaag de dag, en laten we je zien wat je kunt verwachten als je kiest voor een voetbalbeurs in Amerika.
              </MKTypography>

              <MKBox id="2.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                2.1 Belangrijke Competities en Conferenties voor voetbal
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
  <img
    src={Image2}
    alt="Santa Clara voetbalster in actie tijdens het NCAA toernooi."
    style={{
      maxWidth: "100%",
      border: "2px solid #fff",
      boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
      cursor: "pointer",
      outline: "none",
    }}
    onClick={() => openModal2(Image2)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
  Voetbalbalsters in actie tijdens NCAA tournament wedstrijd
</figcaption>
</figure>
                Zoals we al eerder hebben vermeld, is de NCAA de belangrijkste organisatie die het collegevoetbal reguleert en organiseert in de VS. De NCAA bestaat uit drie divisies: Division I, Division II en Division III. Elke divisie heeft zijn eigen regels, eisen en beurzen voor student-atleten.
<br/>
<br/>
Division I is de hoogste en meest competitieve divisie, waar de beste spelers en teams spelen. Division I bestaat uit ongeveer 200 teams voor mannen en 300 teams voor vrouwen, die verdeeld zijn over verschillende regionale conferenties. Een conferentie is een groep van universiteiten die samenwerken om een competitie te organiseren binnen hun regio. Enkele voorbeelden van bekende conferenties zijn de Atlantic Coast Conference (ACC), de Big Ten Conference, de Pac-12 Conference en de Southeastern Conference (SEC).
<br/>
<br/>
Elk jaar strijden de teams binnen hun conferentie om de titel, en om een plek in het nationale kampioenschap: de College Cup. De College Cup is een knock-outtoernooi dat wordt gehouden aan het einde van het seizoen, meestal in november en december. Om zich te kwalificeren voor de College Cup, moeten de teams zich eerst plaatsen via hun conferentie of via een at-large bid, dat wordt toegekend door een selectiecommissie op basis van hun prestaties gedurende het seizoen. De College Cup bestaat uit 48 teams voor mannen en 64 teams voor vrouwen, die in verschillende rondes spelen tot er één kampioen overblijft.
<br/>
<br/>
Division II en Division III zijn minder competitief dan Division I, maar bieden nog steeds veel mogelijkheden voor student-atleten om zich te ontwikkelen en te genieten van het spel. Division II bestaat uit ongeveer 160 teams voor mannen en 200 teams voor vrouwen, die ook verdeeld zijn over verschillende conferenties. Division II heeft ook een nationaal kampioenschap, dat bestaat uit 36 teams voor mannen en 48 teams voor vrouwen. Division III bestaat uit ongeveer 400 teams voor mannen en 430 teams voor vrouwen, die ook in conferenties spelen. Division III heeft echter geen beurzen voor student-atleten, maar wel andere vormen van financiële steun. Division III heeft ook een nationaal kampioenschap, dat bestaat uit 62 teams voor mannen en 64 teams voor vrouwen.
<br/>
<br/>
Naast de NCAA zijn er ook andere organisaties die collegevoetbal organiseren in de VS, zoals de National Association of Intercollegiate Athletics (NAIA), de National Junior College Athletic Association (NJCAA) en de United States Collegiate Athletic Association (USCAA). Deze organisaties hebben elk hun eigen divisies, regels, beurzen en kampioenschappen, die meestal kleiner en minder bekend zijn dan die van de NCAA.
                </MKTypography>
              </MKBox>
              <MKBox id="2.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                2.2 Topprogramma’s en Prestigieuze Teams voor voetbal
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Binnen elke divisie en conferentie zijn er sommige programma’s en teams die zich onderscheiden door hun geschiedenis, prestaties, reputatie en faciliteiten. Deze programma’s en teams trekken vaak de beste spelers aan, bieden de beste beurzen aan, hebben de beste coaches en stafleden in dienst, beschikken over de beste trainings- en wedstrijdfaciliteiten, hebben de grootste fanbase en media-aandacht, en leveren vaak spelers af aan het professionele circuit.
<br/>
<br/>
Het is moeilijk om een definitieve lijst te maken van de beste programma’s en teams voor voetbal in Amerika, omdat er veel factoren zijn die meespelen, en omdat de ranglijsten elk jaar kunnen veranderen. Maar om je een idee te geven, zullen we hier enkele voorbeelden noemen van programma’s en teams die vaak worden beschouwd als de top van het collegevoetbal in Amerika.
<br/>
<br/>
Voor mannen is een van de meest prestigieuze programma’s dat van de University of Maryland, dat 4 keer het nationale kampioenschap heeft gewonnen (1968, 2005, 2008 en 2018), en dat meer dan 20 spelers heeft voortgebracht die in de MLS hebben gespeeld, zoals Omar Gonzalez, Graham Zusi en Zack Steffen. Andere toonaangevende programma’s voor mannen zijn die van de University of Virginia , de Indiana University , de Stanford University  en de Wake Forest University.
<br/>
<br/>
Voor vrouwen is een van de meest dominante programma’s dat van de University of North Carolina, dat meer dan 20 keer het nationale kampioenschap heeft gewonnen, en dat meer dan 40 spelers heeft voortgebracht die voor het nationale team hebben gespeeld, zoals Mia Hamm, Kristine Lilly en Tobin Heath. Andere toonaangevende programma’s voor vrouwen zijn die van de University of Notre Dame , de University of Portland , de Stanford University en de Florida State University.
<br/>
<br/>
Dit zijn slechts enkele voorbeelden van de vele uitstekende programma’s en teams die er zijn in het collegevoetbal in Amerika. Als je meer wilt weten over de verschillende programma’s en teams, kun je online zoeken naar ranglijsten, statistieken, recensies en getuigenissen van spelers en coaches. Ook kun je contact opnemen met Sportbeursamerika.nl, die je kan helpen om het beste programma en team te vinden dat bij jou past.
                </MKTypography>
              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 3" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 3: Van College naar de Profs
                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              Veel jonge voetballers dromen ervan om ooit professioneel te spelen, en om hun idolen na te volgen op het hoogste niveau. Maar hoe kun je die droom waarmaken? Wat zijn de stappen die je moet nemen om van college naar de profs te gaan? En wat zijn de kansen en uitdagingen die je onderweg tegenkomt? In dit hoofdstuk zullen we deze vragen beantwoorden, en je laten zien hoe collegevoetbal kan dienen als een springplank naar een professionele carrière.
              </MKTypography>

              <MKBox id="3.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                3.1 Het Pad naar Professionalisme
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Een van de belangrijkste voordelen van collegevoetbal is dat het je de mogelijkheid biedt om je zowel sportief als academisch te ontwikkelen, terwijl je ook geniet van het studentenleven. Door te voetballen en studeren in Amerika kun je namelijk niet alleen je voetbalvaardigheden verbeteren, maar ook je kennis vergroten, je netwerk uitbreiden, je persoonlijkheid vormen en je toekomstperspectieven verbeteren.
<br/>
<br/>
Maar collegevoetbal kan ook een opstapje zijn naar een professionele carrière, als je dat wilt. Er zijn namelijk verschillende manieren waarop je vanuit het collegevoetbal kunt doorstromen naar het professionele circuit, zowel in Amerika als in het buitenland. De meest voorkomende manieren zijn:
                </MKTypography>
                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
De MLS SuperDraft: Dit is een jaarlijks evenement waarbij de MLS-teams om de beurt spelers kunnen kiezen uit een pool van beschikbare spelers, die meestal afkomstig zijn van het collegevoetbal. De MLS SuperDraft bestaat uit vier rondes, waarin elk team één speler per ronde mag kiezen. De volgorde van de keuzes wordt bepaald door een aantal factoren, zoals de prestaties van de teams in het vorige seizoen, de loting en eventuele ruilhandel. De MLS SuperDraft is een van de belangrijkste manieren om als collegevoetballer in de MLS te komen, en biedt ook de kans om een contract en een beurs te krijgen.

</li>
<li>
De MLS Homegrown Player Rule: Dit is een regel die het mogelijk maakt voor MLS-teams om spelers te contracteren die afkomstig zijn uit hun eigen jeugdopleiding, zonder dat ze daarvoor een draftkeuze hoeven te gebruiken. Deze regel is bedoeld om de ontwikkeling van lokale talenten te stimuleren en te belonen. Veel MLS-teams hebben samenwerkingsverbanden met collegeprogramma’s, waardoor ze spelers kunnen volgen en begeleiden die zowel voor hun college als voor hun jeugdteam spelen. De MLS Homegrown Player Rule is een andere manier om als collegevoetballer in de MLS te komen, en biedt ook de kans om een contract en een beurs te krijgen.
</li>
<li>
De Generation Adidas Program: Dit is een programma dat wordt gesponsord door Adidas, en dat bedoeld is om jonge spelers te helpen bij hun overgang van het collegevoetbal naar het professionele voetbal. Het programma selecteert elk jaar een aantal veelbelovende spelers, meestal onder de 20 jaar, die nog niet zijn afgestudeerd aan hun universiteit. Deze spelers krijgen dan een contract aangeboden door de MLS, waardoor ze kunnen deelnemen aan de MLS SuperDraft zonder hun college-eligibility te verliezen. Dit betekent dat ze nog steeds kunnen terugkeren naar hun universiteit om hun studie af te maken, als ze dat willen. Het programma biedt ook educatieve beurzen aan de spelers, zodat ze hun studiekosten kunnen betalen.
</li>
<li>
De Overseas Opportunities: Dit zijn de mogelijkheden die er zijn voor collegevoetballers om in het buitenland te spelen, bijvoorbeeld in Europa, Azië of Zuid-Amerika. Deze mogelijkheden zijn meestal afhankelijk van verschillende factoren, zoals het niveau, de nationaliteit, de leeftijd en het netwerk van de speler. Sommige spelers kunnen bijvoorbeeld gebruik maken van hun dubbele nationaliteit of hun familieachtergrond om in aanmerking te komen voor een buitenlands paspoort, waardoor ze makkelijker kunnen spelen in een ander land. Andere spelers kunnen profiteren van hun contacten met scouts, agenten of coaches, die hen kunnen helpen om een contract te krijgen bij een buitenlandse club. De overseas opportunities zijn een andere manier om als collegevoetballer een professionele carrière na te streven, maar ze brengen ook meer risico’s en onzekerheden met zich mee.
</li>
</ul>
</MKTypography>

<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
Dit zijn slechts enkele van de manieren waarop je van college naar de profs kunt gaan, maar er zijn er nog meer. Het belangrijkste is dat je je bewust bent van de mogelijkheden die er zijn, en dat je je goed voorbereidt op de uitdagingen die je kunt tegenkomen. Ook is het belangrijk dat je realistisch bent over je kansen en verwachtingen, en dat je niet al je eieren in één mandje legt. Collegevoetbal is een geweldige kans om je te ontwikkelen als speler, maar ook als persoon. Het is dus verstandig om ook andere opties open te houden, en om niet alleen te focussen op het voetbal, maar ook op je studie, je hobby’s, je vrienden en je familie.
<br/>
<br/>
In het volgende hoofdstuk zullen we kijken naar de invloed van internationaal voetbal op het collegevoetbal, en zien we hoe dit collegevoetballers beïnvloedt en inspireert.
                </MKTypography>



              </MKBox>
              </MKBox>

            <MKBox id="Hoofdstuk 4" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 4: De Invloed van Internationaal Voetbal
                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              Voetbal is een wereldwijde sport die miljoenen mensen over de hele wereld verbindt. Het is dan ook niet verwonderlijk dat internationaal voetbal een grote invloed heeft op het collegevoetbal in Amerika. In dit hoofdstuk zullen we kijken hoe buitenlandse invloeden het collegevoetbal beïnvloeden en inspireren, en hoe Amerikaanse spelers hun weg vinden naar het buitenland.
              </MKTypography>

              <MKBox id="4.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                4.1 Buitenlandse Invloeden
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
  <img
    src={Image3}
    alt="De prestigieuze NCAA voetbaltrofee."
    style={{
      maxWidth: "100%",
      border: "2px solid #fff",
      boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
      cursor: "pointer",
      outline: "none",
    }}
    onClick={() => openModal3(Image3)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
  De felbegeerde trofee van de NCAA
</figcaption>
</figure>
                De populariteit van internationaal voetbal in de VS is de laatste jaren enorm toegenomen. Volgens een onderzoek van Gallup uit 2023 is voetbal de op twee na populairste sport onder Amerikanen, na American football en basketbal. Dit komt mede door de groeiende diversiteit van de Amerikaanse bevolking, de toegankelijkheid van internationale wedstrijden via tv en internet, en de successen van het Amerikaanse nationale team op het wereldtoneel.
<br/>
<br/>
Deze interesse in internationaal voetbal heeft ook zijn weerslag op het collegevoetbal. Veel collegevoetballers kijken op naar hun favoriete buitenlandse spelers en teams, en proberen hun speelstijl en techniek na te bootsen. Sommige collegevoetballers hebben zelfs een buitenlandse achtergrond of dubbele nationaliteit, waardoor ze een extra band hebben met een bepaald land of regio.
<br/>
<br/>
Een voorbeeld hiervan is Gio Reyna, de zoon van voormalig Amerikaans international Claudio Reyna. Gio Reyna werd geboren in Engeland, waar zijn vader speelde voor Sunderland en Manchester City. Hij groeide op met een liefde voor het Engelse voetbal, en speelde zelf voor de jeugdteams van New York City FC. In 2023 maakte hij de overstap naar Borussia Dortmund in Duitsland, waar hij al snel uitgroeide tot een van de grootste talenten van Europa. Hij maakte ook zijn debuut voor het Amerikaanse nationale team, waar hij samen met andere jonge sterren zoals Christian Pulisic en Weston McKennie een nieuwe generatie vormt.
<br/>
<br/>
Een ander voorbeeld is Catarina Macario, een Braziliaanse aanvalster die op haar twaalfde naar de VS verhuisde. Ze speelde voor Stanford University, waar ze twee keer de Hermann Trophy won als beste collegevoetbalster van het land. Ze kreeg ook de Amerikaanse nationaliteit, waardoor ze kon uitkomen voor het Amerikaanse vrouwenteam. In 2023 tekende ze een contract bij Olympique Lyon in Frankrijk, een van de beste vrouwenteams ter wereld.
<br/>
<br/>
Deze voorbeelden laten zien hoe buitenlandse invloeden het collegevoetbal verrijken en diversifiëren, en hoe collegevoetballers hun dromen kunnen waarmaken in het internationale voetbal.
                </MKTypography>
              </MKBox>
              <MKBox id="4.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                4.2 Amerikanen in het Buitenland
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Niet alleen buitenlandse invloeden hebben een impact op het collegevoetbal, maar ook Amerikaanse spelers die hun geluk beproeven in het buitenland. Steeds meer Amerikaanse spelers kiezen ervoor om na hun collegecarrière naar Europa of andere continenten te gaan, waar ze zich kunnen meten met de beste spelers ter wereld.
<br/>
<br/>
Een van de meest succesvolle voorbeelden hiervan is Clint Dempsey, die speelde voor Furman University voordat hij naar de MLS ging. In 2023 maakte hij de overstap naar Fulham in Engeland, waar hij zich ontpopte tot een doelpuntenmachine. Hij scoorde meer dan 50 goals voor de club, waaronder een historische treffer tegen Juventus in de Europa League. Hij speelde ook voor Tottenham Hotspur en Seattle Sounders, en was een vaste waarde voor het Amerikaanse nationale team.

<br/>
<br/>
Een ander succesverhaal is Tobin Heath, die speelde voor North Carolina University, waar ze twee keer het nationale kampioenschap won. Ze speelde ook voor verschillende clubs in de VS, Frankrijk en Engeland, waaronder Paris Saint-Germain en Manchester United. Ze won vier keer de Olympische gouden medaille en twee keer het WK met het Amerikaanse vrouwenteam, waar ze bekend staat om haar technische vaardigheden en creativiteit.
<br/>
<br/>
Deze voorbeelden laten zien hoe Amerikaanse spelers zich kunnen ontwikkelen en onderscheiden in het buitenland, en hoe ze het collegevoetbal op de kaart kunnen zetten als een kweekvijver van talent.
                </MKTypography>
              </MKBox>
            </MKBox>

            <MKBox id="Hoofdstuk 5" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
              Hoofdstuk 5: Voetbalbeurzen en de Toekomst
                </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              Voetbalbeurzen zijn een geweldige kans voor jonge voetballers om hun passie te combineren met een kwalitatief hoogstaande opleiding. In dit hoofdstuk zullen we kijken naar het belang van beurzen voor de toekomst van het voetbal in Amerika, en hoe Sportbeursamerika.nl kan helpen om deze kans te grijpen.
              </MKTypography>

              <MKBox id="5.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                5.1 Het Belang van Beurzen
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Een voetbalbeurs geeft je de kans om te spelen voor een collegevoetbalteam, terwijl je ook studeert aan een Amerikaanse universiteit. Dit heeft vele voordelen, zowel op sportief als op academisch gebied.
<br/>
<br/>
Op sportief gebied kun je jezelf ontwikkelen als voetballer, door te trainen en te spelen onder professionele coaches en met getalenteerde teamgenoten. Je kunt ook deelnemen aan spannende competities en toernooien, waar je je kunt meten met andere topvoetballers uit het hele land. Je kunt ook profiteren van de uitstekende faciliteiten en voorzieningen die de Amerikaanse colleges bieden, zoals stadions, fitnesscentra, medische zorg en voedingsadvies.
<br/>
<br/>
Op academisch gebied kun je een internationaal erkend diploma behalen, dat je kan helpen om een succesvolle carrière op te bouwen in het veld van jouw keuze. Je kunt kiezen uit een breed scala aan studierichtingen, die aansluiten bij jouw interesses en ambities. Je kunt ook genieten van de academische uitdagingen en mogelijkheden die de Amerikaanse universiteiten bieden, zoals onderzoek, stages, uitwisselingen en extracurriculaire activiteiten.
<br/>
<br/>
Een voetbalbeurs kan dus een levensveranderende ervaring zijn, die je kan helpen om je potentieel te bereiken als voetballer en als persoon. Bovendien kan een voetbalbeurs ook bijdragen aan de toekomst van het voetbal in Amerika, door meer talent en diversiteit te brengen in het collegevoetbal en het professionele circuit.
                </MKTypography>
              </MKBox>
              <MKBox id="5.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                5.2 Hoe Sportbeurs Amerika NL Kan Helpen 
                  </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
  <img
    src={Image4}
    alt="Logo van Sportbeurs Amerika NL, bestaande uit een voetbal met het continent Noord-Amerika erop afgebeeld, met daarboven een afstudeerhoed (graduation cap) die bovenop de bal rust alsof het een hoofd is. De naam van het bedrijf is prominent weergegeven, wat de focus op sportbeurzen in Amerika voor Europese studenten benadrukt."
    style={{
      maxWidth: "100%",
      height: "auto",
      border: "2px solid #fff",
      boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)", // Apply shadow around the entire image
      cursor: "pointer",
      outline: "none",
      borderRadius: "50%", // Set border-radius to 50% for a circular image
      marginTop: "0px", // Add top margin to move the image up


    }}
    onClick={() => openModal4(Image4)}
  />
  <figcaption style={{ 
    textAlign: 'left', 
    fontWeight: 'bold', 
    fontSize: '12px', 
    wordWrap: 'break-word', 
   }}>
  
</figcaption>
</figure>
Sportbeurs Amerika NL is een organisatie gespecialiseerd in het begeleiden van jonge sporttalenten naar sportbeurzen in Amerika.  Met een gepassioneerde en ervaren expert aan het roer, ondersteunt Sportbeurs Amerika NL de student-atleet bij iedere stap in het traject, vanaf de eerste oriëntatie tot aan de uiteindelijke plaatsing. Sportbeurs Amerika NL biedt onder andere de volgende diensten aan:
                </MKTypography>

                <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
<ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
<li>
Een persoonlijke intakegesprek om de wensen, mogelijkheden en verwachtingen van de student-atleet te bespreken.
</li>
<li>
Een professionele evaluatie van het sportieve en academische niveau van de student-atleet.
</li>
<li>
Een uitgebreid netwerk van contacten met Amerikaanse coaches, universiteiten en colleges.
</li>
<li>
Een effectieve promotie van het profiel en de video’s van de student-atleet bij potentiële coaches.
</li>
<li>
Een deskundig advies over de beste opties voor de student-atleet op basis van zijn of haar voorkeuren, doelen en budget.
</li>
<li>
Een onderhandeling over de beste beursaanbiedingen namens de student-atleet.
</li>
<li>
Een hulp bij het aanvragen van de benodigde documenten, zoals het visum, het transcript, het diploma en de toelatingstesten.
</li>
<li>
Een voorbereiding op het leven in Amerika, zoals de cultuur, de taal, de regels en de tips.
</li>
<li>
Een nazorg en een follow-up tijdens het verblijf in Amerika.</li>

</ul>
</MKTypography>

<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Wil jij ook je passie voor American football combineren met een goede opleiding in Amerika? <Link to="/Diensten-Producten">Bekijk het volledige aanbod</Link> van Sportbeurs Amerika NL en maak een vrijblijvende afspraak om je te laten informeren over de mogelijkheden. 
      Ik sta voor je klaar om je te helpen bij het realiseren van je Amerikaanse droom.
    </MKTypography>


              </MKBox>
            </MKBox>

          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;